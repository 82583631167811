import {Offer, Order, Task, TaskExecuteAction} from "@/types";
import {executeTask} from "@/store/services/task/workflow";
import {settings} from "@/settings";
import {api} from "@/auth/api";

const odm_offer = 'odm-offer'
export async function executeCompletePreconditions(task: Task) {
    const taskExecuteAction: TaskExecuteAction = {
        taskId: task.taskId || '',
        variables: {}
    }
    try {
        return executeTask(taskExecuteAction)
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}
export const getGatheringProcessFromOffer = async (offerId: any) => {
    const baseUrl = `${settings.api_url}/${odm_offer}/api/1/${odm_offer}`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().get(`${baseUrl}/offer/` + offerId + '/associatedgatheringprocess/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const updateCheckPointsStatus = async (payload: any) => {
    const baseUrl = `${settings.api_url}/odm-supportingdocument/api/1/odm-supportingdocument`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().put(`${baseUrl}/businessrulevalue/` + payload.resourceUid + '/',
                payload
            )
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
