import moment from "moment";

export function required(val: any, message: string = 'Filed is mandatory'): boolean | string {
    return val && val.length > 0 || message
}

export function lengthMin(val: any, min: number, message: string = `Filed must be greater than ${min}`): boolean | string {
    return val && val.length > min || message
}

export function lengthMax(val: any, max: number, message: string = `Filed must be greater than ${max}`): boolean | string {
    return val && val.length < max || message
}

/**
 * Validates if a given value is a valid email address.
 * 
 * @param {any} val - The value to be checked.
 * @returns {boolean|string} - Returns true if the value is a valid email, otherwise returns the string "Invalid email".
 */
export function isValidEmail(val: any): boolean | string {
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || "Invalid email";
  }

export function  FilterInput(event:any) {
    let keyCode = ('which' in event) ? event.which : event.keyCode;
    let isNotWanted = (keyCode == 69 || keyCode == 101);
    return !isNotWanted;
};

export function ToFixNumber(newValue: any, model: any, key: any) {
    model[key] = newValue && Number(newValue) < 0 ? "0" : newValue;
}

export function checkKeyDownAlpha(event: any) {
    if (!/[a-zA-Z\s]/.test(event.key)) {
        event.key ? event.key : "";
        event.preventDefault();
    } 
}

export function validateBirthDate(birthDate: any) {
    if (birthDate) {
        const format = 'DD/MM/YYYY';
        const today = moment();
        const birthDateMoment = moment(birthDate, format);
        return birthDateMoment.isSameOrBefore(today, 'day') || "Birth Date need to be less or equal than current date"
    }else {
        return true
    }
} 

