import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn, {
        class: "btn btn-outline-primary q-mr-lg",
        onClick: _ctx.onSendDocumentClick,
        label: _ctx.$t('task.buttons.send_document'),
        disable: !_ctx.selectedTemplates.length
      }, null, 8, ["onClick", "label", "disable"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_btn, {
        class: "btn btn-primary q-mr-lg",
        onClick: _ctx.onDownloadDocumentClick,
        label: _ctx.$t('task.buttons.download_document'),
        disable: !_ctx.selectedTemplates.length,
        loading: _ctx.isDownloadDocumentLoading
      }, {
        loading: _withCtx(() => [
          _createVNode(_component_q_spinner_facebook)
        ]),
        _: 1
      }, 8, ["onClick", "label", "disable", "loading"])
    ])
  ]))
}