
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { formatWithMask } from "@/utils/configuration/formatters-config";

@Options({
  components: {
    LabelAndValue,
  },
  props: ["proposal", "valueStyle", "labelStyle", "divValueStyle"],
})
export default class SummaryLoan extends Vue {
  proposal?: any;
  valueStyle?: any;
  labelStyle?: any;
  divValueStyle?: any;

  get price() {
    return formatWithMask(this.proposal.financedValue, 2);
  }
  get monthly() {
    return formatWithMask(this.proposal.financialPaymentWTax, 2);
  }
  get amountRefunded() {
    return !this.proposal.sumOfTotalPayments
      ? "-"
      : formatWithMask(this.proposal.sumOfTotalPayments.amount, 2);
  }
  get duration() {
    return this.proposal.numberOfPaymentTerm;
  }
  get tax() {
    return this.proposal.nominalRate;
  }
  get residualValue() {
    return !this.proposal.residualValueAmount
      ? "-"
      : `${formatWithMask(this.proposal.residualValueAmount, 2)} || ${
          this.proposal.residualValuePercentage
        } %`;
  }
  get firstPayment() {
    return !this.proposal.firstPaymentWTax
      ? "-"
      : `${formatWithMask(this.proposal.firstPaymentWTax, 2)} || ${
          this.proposal.firstPaymentPercentage
        } %`;
  }
  get downPayment() {
    return !this.proposal.downPayment
      ? "-"
      : `${formatWithMask(this.proposal.downPayment, 2)} || ${
          this.proposal.downPaymentPercentage
        } %`;
  }
  get taeg() {
    return formatWithMask(this.proposal.aprc, 2);
  }
  get taea() {
    return formatWithMask(this.proposal.air, 2);
  }

  get durationUnit() {
    return this.proposal.periodBetween2Installments.unit
  }

}
