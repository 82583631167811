
import {Options, Vue} from "vue-class-component";

import store from "@/store";
import {NoticeTemplate} from "@/types";

@Options({
  watch: {
    templateSelected(newValue) {
      if (!newValue || !newValue.length) {
        this.onClose();
      }
    },
  },
})
export default class PrintDocumentSender extends Vue {
  emailSendList: string[] = [];

  get templateSelected() {
    return store.state.printDocumentModule.templates.filter((t) => t.selected);
  }

  created() {
    store.dispatch("printDocumentModule/getNoticeTemplateBody");

    switch (this.currentRoute) {
      case "order-edit": {
        store.dispatch('printDocumentModule/getEmailSendListOption', {
          entity: store.state.orderModule.order,
          entityType: 'order'
        })
        break
      }
      case "TaskDetail": {
        const entityType = store.getters['taskModule/isTaskModuleOffer'] ? 'offer' : 'order'
        if (entityType === 'offer') {
          store.dispatch('printDocumentModule/getEmailSendListOption', {
            entity: store.state.taskModule.offer,
            entityType: entityType
          })
        } else {
          store.dispatch('printDocumentModule/getEmailSendListOption', {
            entity: store.state.taskModule.order,
            entityType: entityType
          })
        }
        break
      }
      case "demand-edit": {
        store.dispatch('printDocumentModule/getEmailSendListOption', {
          entity: store.state.demandModule.offer,
          entityType: 'offer'
        })
        break
      }
      case "third-edit": {
        store.dispatch('printDocumentModule/getEmailSendListOption', {
          entity: store.state.thirdModule.third,
          entityType: 'party'
        })
        break
      }
    }
  }

  get body() {
    return store.state.printDocumentModule.body;
  }


  get senderEmails() {
    return store.getters['printDocumentModule/getEmailSendListOption'];
  }

  onClose() {
    store.dispatch("printDocumentModule/changeShowSendTemplate", false);
  }

  removeFile(tempalte: NoticeTemplate) {
    store.dispatch("printDocumentModule/setTemplateSelected", {
      template: tempalte,
      selected: false,
    });
  }
  isLoadingSend = false;
  sendMail() {
    this.isLoadingSend = true;
    store.dispatch("printDocumentModule/generateDocument", {
      templatesSelected: this.templateSelected,
      currentRoute: this.currentRoute,
      callback: this.onSendDocument,
    });
  }

  onSendDocument(){
    const emailSendList: string[] = [];
    this.emailSendList.forEach((element: any) => {
      emailSendList.push(element.value);
    });
    store.dispatch("printDocumentModule/sendDocument", {
      emailSendList: emailSendList,
      fileId: this.fileId,
      callback: this.sendResponse,
    });
  }

  sendResponse() {
    this.isLoadingSend = false;
  }

  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }
  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }
}
