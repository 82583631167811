import store from "@/store";
import { DossierState, Dossier } from "@/types";
import { Module } from "vuex";
import Notify from 'quasar/src/plugins/Notify.js';;
import { dossiers as searchDossiers } from "@/store/services/dossiersService";

const dossierModule: Module<DossierState, any> = {
  namespaced: true,
  state: {
    rowFilter: {},
    openDossier: false,
    mode: "view",
    dossiers: [],
    unfilteredDossiers: [],
    selectedDossierdetails: {},
    isEditing: false,
  },
  getters: {
    getDossierSelectedInit() {
      return {
        reference: "",
        cdtLoueur: "",
        client: "",
        vendeur: "",
        DateCommande: "",
        DateDeLivraison: "",
        dateRDVClient: "",
        Status: "",
        name: "",
        deliveryAdress:"",
      };
    },
    getMode(state) {
      return state.mode
  },
  },
  actions: {
    filterDossiers({ commit }, payload) {
      commit("setDossiersFilter", payload.filter);
      commit("filterCurrentDossiers", payload);
    },
    searchDossiers({ commit }, payload) {
      const { callback } = payload;
      searchDossiers()
        .then((res) => {
          callback(res);
          commit("setCurrentDossiers", res);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    resetDossier({ commit }, payload) {
      commit("resetDossier", payload);
    },
    showDossierDetail({ commit }) {
      commit("showDossierDetail");
    },
    hideDossierDetail({ commit }) {
      commit("hideDossierDetail");
    },
    setMode({ commit }, payload) {
      commit("setMode", payload);
    },
    acceptProps({ commit }, payload) {
      const getter = store.getters["dossierModule/getDossierSelectedInit"];
      commit("resetDossier", getter);
      commit("setSelectedDossierdetails", payload.row);
    },
    toggleIsEditing({ commit }) {
      commit("toggleIsEditing");
    },

  },
  mutations: {
    setDossiersFilter(state, payload) {
      state.rowFilter = payload;
    },
    setCurrentDossiers(state, payload) {
      state.dossiers = payload;
    },
    setDossiers(state, payload) {
      state.dossiers = payload;
    },
    setUnfilteredDossiers(state, dossiers) {
      state.unfilteredDossiers = dossiers;
    },
    resetFilters(state) {
      state.rowFilter = {};
      state.dossiers = state.unfilteredDossiers;
    },
    filterCurrentDossiers(state, payload) {
      const { filter } = payload;
      const filteredResult = state.unfilteredDossiers?.filter((row) => {
        return Object.keys(filter).every((field) => {
          const filterValue = filter[field];
          const rowValue = row[field as keyof Dossier];
          if (Array.isArray(filterValue)) {
            return filterValue.includes(rowValue);
          } else {
            return String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase());
          }
        });
      });

      state.dossiers = filteredResult;
    },
    resetDossier(state, payload) {
      state.selectedDossierdetails = payload;
    },
    showDossierDetail(state) {
      state.openDossier = !state.openDossier;
    },
    hideDossierDetail(state) {
      state.openDossier = false;
    },
    setMode(state, payload) {
      state.mode = payload;
    },
    setSelectedDossierdetails(state, payload) {
      state.selectedDossierdetails = payload;
    },
    toggleIsEditing(state) {
      state.isEditing = !state.isEditing;
    },
  },
};

export default dossierModule;