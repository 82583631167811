
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { formatWithMask } from "@/utils/configuration/formatters-config";
import { ProposalAssetItem, ProposalAssetItemType } from "@/types";
import i18n from "@/i18n";

@Options({
  methods: { formatWithMask },
  components: { LabelAndValue },
  props: ["asset"],
})
export default class SummaryAsset extends Vue {
  readonly asset: any;

  option = ProposalAssetItemType.option;
  other = ProposalAssetItemType.other;
  accessory = ProposalAssetItemType.accessory;

  get assets() {
    return this.asset.proposalAssets;
  }

  getTotalAllAssets() {
    let totalAssets = 0;
    for (let i = 0; i <= this.assets.length - 1; i++) {
      totalAssets += this.totalPriceWithTax(i);
    }
    return totalAssets;
  }

  assetItem(index: number) {
    return this.assets[index];
  }

  totalPriceWithTax(index: number) {
    const totalAmountWTax = this.assetItem(index)
      ? this.assetItem(index).totalAmountWTax.amount || 0
      : 0;
    return this.proposalAssetItemsTotalWithTax(index) + totalAmountWTax || 0;
  }

  proposalAssetItems(index: number) {
    return this.assetItem(index) ? this.assets[index].proposalAssetItems || [] : [];
  }
  proposalAssetItemsTotalWithTax(index: number) {
    return this.proposalAssetItems(index)
      .filter((item: any) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWTax?.amount || 0);
      }, 0);
  }

  proposalAssetOptionItems(index: number) {
    return (this.assetItem(index).proposalAssetItems || []).filter(
      (item: any) => item.type.resourceUid === this.option
    );
  }

  proposalAssetOptionItemsTotalWithTax(index: number) {
    return this.proposalAssetOptionItems(index)
      .filter((item: any) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWTax?.amount || 0);
      }, 0);
  }

  proposalAssetAccessoryItems(index: number) {
    return (this.assetItem(index).proposalAssetItems || []).filter(
      (item: any) => item.type.resourceUid === this.accessory
    );
  }
  proposalAssetAccessoryItemsTotalWithTax(index: number) {
    return this.proposalAssetAccessoryItems(index)
      .filter((item: any) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWTax?.amount || 0);
      }, 0);
  }
  proposalAssetOtherItems(index: number) {
    return (this.assetItem(index).proposalAssetItems || []).filter(
      (item: any) => item.type.resourceUid === this.other
    );
  }

  proposalAssetOtherItemsTotalWithTax(index: number) {
    return this.proposalAssetOtherItems(index)
      .filter((item: any) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWTax?.amount || 0);
      }, 0);
  }

  getBrand(brand: string) {
    return brand ? brand.replace(/asset.good.vehicle.brand./g, "") : "";
  }
  getCategory(item:any){
    return item.proposalAssetCategories && item.proposalAssetCategories[0]&& item.proposalAssetCategories[0].category&& item.proposalAssetCategories[0].category.value ? i18n.global.t("demand.asset.categoryItems."+item.proposalAssetCategories[0].category.value ): "-"
  }
  getSubCategory(item:any){
    return item.proposalAssetCategories && item.proposalAssetCategories[0]&& item.proposalAssetCategories[0].categoryClass&& item.proposalAssetCategories[0].categoryClass.value ? i18n.global.t("demand.asset.subCategoryItems." + item.proposalAssetCategories[0].categoryClass.value.substring(item.proposalAssetCategories[0].categoryClass.value.lastIndexOf(".") + 1) ): "-"
  }
  getModel(item: any) {
    return item.config && item.config.formBind && item.config.formBind.range && item.config.formBind.range.label ? item.config.formBind.range.label : "-";
  }
  getSupplierName(item: any) {
    return item.config.formBind && item.config.formBind.supplier && item.config.formBind.supplier.name ? item.config.formBind.supplier.name : "-"
  }
  getSupplierAddress(item: any) {
    return item.config.formBind && item.config.formBind.supplierAddress? item.config.formBind.supplierAddress : "-" 
  }

  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    // "color": "black"
  };

  valueStyleTotal = {
    "font-weight": "bold",
    color: "#1976D2",
  };

  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "0.5rem",
  };

  divValueItem = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "0.5rem",
  };

  divValueItems = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "0.5rem",
  };

  labelStyleTotal = {
    "font-weight": "bold",
    "font-size": "1rem",
    color: "#1976D2",
  };

  labelStyleAsset = {
    "font-weight": "bold",
    "font-size": "1rem",
    color: "black",
  };

  labelStyleItems = {
    "font-weight": "bold",
    "font-size": "1rem",
    color: "gray",
  };
}
