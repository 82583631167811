
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";
import store from "@/store";
import router from "@/router";

@Options({
  props: {
    show: Boolean
  }
})
export default class ConfirmationDeleteDialog extends Vue {

  name = 'confirmation-delete'
  show?: boolean;

  get dialogDeleteState() {
    return store.state.confirmationDeleteDialogState
  }

  @Emit("onConfirm")
  onConfirm() {
      const target = this.dialogDeleteState?.target || ''
      store.dispatch('executeDeleteConfirmation' + this.dialogDeleteState?.deleteAction, this.dialogDeleteState?.payload).then(() => {
        router.push(target)
      })
  }

  @Emit("onCancel")
  onCancel() {
    store.dispatch('hideConfirmationDeleteDialog')
  }

  @Emit("onClose")
  onClose() {
    store.dispatch('hideConfirmationDeleteDialog')
  }

}
