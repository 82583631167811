import {Module} from "vuex";
import {DecisionState, RootState} from "@/types";
import { getDecision, getListDecision, getDecisionReason, getConnectedUser } from "@/store/services/task/actions/decisionService";
import {formatDate} from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";
import { getUser } from "@/store/services/middle/middleOfficeService";

const decisionModule: Module<DecisionState, RootState> = {
  namespaced: true,
  state: {
    decision: {
      decisionDate: formatDate(new Date(), "/"),
      comment: "",
      decisionReserves: [],
      decision: {
        value: "",
        label: "",
      },
      decisionReasons: [],
      daaq: "/",
    },
    acceptDecision: {
      decisionDate: formatDate(new Date(), "/"),
      comment: "",
      decision: {
        value: "",
        label: "",
      },
      decisionReasons: [],
      daaq: "/",
    },
    decisionDetails: {
      
      decisionStatus: {
          id: '',
          label: '',
          config: {}
      },
      decisionReason:  [],
    },
    selectedDecision:{},
    selectedDecisionStatus:"",
    connectedUser:{
      userName:"",
      userProfile:""
    },
  },
  getters: {},
  actions: {
    listDecision({ commit }, payload) {
      const { filter, callback } = payload;
      getListDecision(filter)
        .then((res) => callback(res))
        .catch((e) => console.error(e));
    },
    updateDecisionProcess({ commit }, payload) {
      // to be implemented
    },
    fetchDecision({ commit }, payload) {
      const { decisionProcessId, callback } = payload;
      getDecision(decisionProcessId).then((res) => {
        callback(res);
      }).catch((e) => console.error(e));
    },
    cleanSelectedReasons({ commit }, payload) {
      const { action } = payload;
      commit("cleanSelectedReasons", action);
    },
    cleanState({ commit }) {
      commit("setInitial");
    },
    decisionReason({ commit }, payload) {
      const { filter, callback } = payload
      getDecisionReason(filter)
          .then(res => {
            console.log("res getDecisionReason", res)
              callback(res);
          })
          .catch(e => console.error(e))
  },
  initDecisionReason({ commit }) {
    commit("initDecisionReason");
  },
  initselectedDecisionReason({ commit }) {
    commit("initselectedDecisionReason");
  },
  setselectedDecision({ commit }, payload) {
    commit("setselectedDecision", payload);
  },
  updateDecisionReason({ commit },payload) {
    commit("updateDecisionReason", payload);
  },
  initDecisionStatus({ commit }) {
    commit("initDecisionStatus");
  },
  updateDecisionReserves({ commit },payload) {
    commit("updateDecisionReserves", payload);
  },
  setDecisionReason({ commit },payload) {
    commit("setDecisionReason", payload);
  },
  async setConnectedUser({ commit }, payload) {
     const  userObject = {
      userName: "",
      userProfile: ""
    }
     const connectedUser =await  getConnectedUser()

     if (connectedUser.data.resourceUid) {
       const user = await getUser(connectedUser.data.resourceUid)
       user.userGroups.forEach((userGroup: any) =>
       userObject.userProfile =(userObject.userProfile ? userObject.userProfile.concat(' || ') : '') + i18n.global.t("middleOffice.decision.profil.".concat(userGroup?.group?.resourceUid)));
       userObject.userName = user.firstName + ' ' + user.lastName
       commit("setConnectedUser", userObject);
     }
}
  
  },
  mutations: {
    setInitial(state) {
      state.decision = {
        decisionDate: formatDate(new Date(), "/"),
            comment: "",
            decisionReserves: [],
            decision: {
          value: "",
              label: "",
        },
        decisionReasons: [],
            daaq: "/",
      };
      state.acceptDecision = {
        decisionDate: formatDate(new Date(), "/"),
            comment: "",
            decision: {
          value: "",
              label: "",
        },
        decisionReasons: [],
            daaq: "/",
      };
    },
    cleanSelectedReasons(state, payload) {
      if (payload === "ACCEPT_OFFER") {
        if (state.acceptDecision) state.acceptDecision.decisionReasons = []
      } else {
        if (state.decision) state.decision.decisionReasons = []
      }
    },
    initDecisionReason(state) {
      state.decisionDetails.decisionReason =  []
    },
    initselectedDecisionReason(state) {
      state.selectedDecision.decisionReasons =  []
    },
  setselectedDecision(state, payload) {
    state.selectedDecision = payload;
    state.selectedDecision.opinions.forEach(async (opinion: any) => {
      if(opinion.opinionMakerId && opinion.opinionMakerId.resourceUid){
        const user = await getUser(opinion.opinionMakerId.resourceUid);
        opinion.opinionMaker = user.firstName+ ' ' + user.lastName
      }
    });

    state.selectedDecisionStatus=state.selectedDecision.status.resourceUid
    if (state.selectedDecision.comment=== null|| state.selectedDecision.comment=== undefined){
      state.selectedDecision.comment=""
    }
    if (state.selectedDecision.status.resourceUid=== null || state.selectedDecision.status.resourceUid=== undefined || state.selectedDecision.status.resourceUid=== "CREATED"){
      state.selectedDecision.status.resourceUid=""
    }
  },
    updateDecisionReason(state, payload) {
      const { reasons } = payload;
      let emptyDecisionReason = {}
      state.selectedDecision.decisionReasons=[]
      reasons.forEach((reason: any) => {
         emptyDecisionReason = {
         // resourceUid: "",
          objectType: "odm.decision.decisionreason",
          systemUid: "odm-decision",
          businessData: null,
          //daaq: "/",
          reason: {
            objectType: "odm.decision.reason",
            systemUid: "odm-decision",
            resourceUid: reason.id ? reason.id : "",
          },
        };
        state.selectedDecision.decisionReasons.push(emptyDecisionReason);

      });
  },
  initDecisionStatus(state) {
    state.selectedDecision.status = {
        objectType: "odm.decision.decisionstatus",
        systemUid: "odm-decision",
        resourceUid: ""
  }
  
  },
  updateDecisionReserves(state,payload) {
  state.selectedDecision.decisionReserves=payload
  },
  setDecisionReason(state) {
    state.decisionDetails.decisionReason = state.selectedDecision.decisionReasons.map((reason: any) => {
        return {
            id: reason.resourceUid ? reason.resourceUid : "",
            label: i18n.global.t("task.reasons." + reason.reason.resourceUid ),
            config: {
                decision_status_code: state.selectedDecision.status.resourceUid ? state.selectedDecision.status.resourceUid : ""
            }
        };
    });
  },
  setConnectedUser(state,payload) {
  state.connectedUser=payload
  }

}
};

export default decisionModule;
