
import {Options, Vue} from 'vue-class-component';
import {Emit} from "vue-property-decorator";
import store from "@/store";
import router from "@/router";

@Options({
  props: {
    show: Boolean
  }
})
export default class ConfirmationDialog extends Vue {

  name = 'confirmation-dialog'
  show?: boolean;

  get dialogState() {
    return store.state.confirmationDialogState
  }

   @Emit("onConfirm")
  onConfirm() {
    const target = this.dialogState?.target || ''
   if (this.dialogState?.action){
       store.dispatch('executeConfirmationDialog',{payload: this.dialogState?.payload, action:this.dialogState?.action, target:this.dialogState?.target })
   }
   else { 
   store.dispatch('releaseLockState').then(() => {
      router.push(target)
    })
  }
  }


  @Emit("onCancel")
  onCancel() {
    store.dispatch('hideConfirmationDialog')
  }

  @Emit("onClose")
  onClose() {
    store.dispatch('hideConfirmationDialog')
  }

}
