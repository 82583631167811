import { Module } from "vuex";
import { RootState, TaskActionEvent } from "@/types";
import { getFinancingRequestRows } from "@/store/services/task/actions/financingRequestService";

const financingRequestModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    comment: "",
    rows: [],
  },
  getters: {},
  actions: {
    getFinancingRequestRows({ commit }) {
      return getFinancingRequestRows(this.state)
    },
  },
  mutations: {},
};

export default financingRequestModule;
