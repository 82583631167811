import {api} from "@/auth/api";
import {settings} from "@/settings";
import {Order} from "@/types";

const odm_product ='odm-product'
const odm_simulation ='odm-simulation'
export const getCommissionProductPack = async (payload: any) => {
    const baseUrl = `${settings.api_url}/${odm_product}/api/1/${odm_product}`
    return new Promise<any>(async (resolve, reject) => {
        try {

            const orderInfo = JSON.stringify(payload)
            const body={context:"DISTRIBUTION",
            maxResults: 100,
            applicationName:"ORIGINATION",
            orderInfo
            }
            const result = await api().post(`${baseUrl}/product/` ,body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const getCommissionPackItems = async (payload: any,commissionProductPackUid:any) => {
    const baseUrl = `${settings.api_url}/${odm_product}/api/1/${odm_product}`
    return new Promise<any>(async (resolve, reject) => {
        try {

            const orderInfo = JSON.stringify(payload)
            const body={context:"DISTRIBUTION",
            maxResults: 100,
            applicationName:"ORIGINATION",
            orderInfo
        }
            const result = await api().post(`${baseUrl}/commissionProductPack/`+commissionProductPackUid+'/packItems/' ,body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const calculationCommission = async (payload: any,packItems:any) => {
    const baseUrl = `${settings.api_url}/${odm_simulation}/api/1/${odm_simulation}`
    return new Promise<any>(async (resolve, reject) => {
        try {

            const orderInfo = JSON.stringify(payload)
            const items= []
            for(const item of packItems.items){
                item.accessoryName=item.name
                delete item.status
                delete item.qualification
                delete item.amountMin
                delete item.amountMax
                delete item.annualRateMin
                delete item.annualRateMax
                delete item.name
                item.objectType='odm.simulation.simulationaccessoryitem.commissionitem'
                item.productUid= item.resourceUid
                items.push(item)
            }
            

            const body={orderInfo,
                commissions:items
        }
            const result = await api().post(`${baseUrl}/simulation/commission/`,body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const UpdateOrderCommission = async (order: any,packItem:any,commissionProductPackUid:any) => {
    const Commission =await calculationCommission(order,packItem)
    const orderCommissions=[]
    for(let i=0;i<packItem.items.length;i++ ){
        const orderCommission={
            objectType: "odm.order.ordercommission",
            systemUid: "odm-order",
            label:packItem.items[i].name ,
            basisValue: Commission.commissions[i].basisValue,
            basis: packItem.items[i].basis,
            rate: Commission.commissions[i].rate,
            taxCode:packItem.items[i].tax ,
            taxValue: null,
            calculatedAmountWTax: Commission.commissions[i].calculatedAmount,
            calculatedAmountWoTax: null,
            accessoryProduct: {
                objectType: "odm.product.financingproductpackitem.accessoryproductitem.commissionproductitem.assetcommissionproductitem",
                systemUid: "odm-product",
                resourceUid: packItem.items[i].resourceUid
            },
            commissionType: packItem.items[i].commissionType,
            adjustAmount: {
                amount: 0,
                currency: "EUR",
            },
            commissionAmountWTax: null,
            commissionAmountWoTax: null,
            orderAccessoryCalculationMethodOption:{
                resourceUid: packItem.items[i].calculationMethodOption,
                objectType: "odm.order.orderaccessorycalculationmethodoption",
                systemUid: "odm-order"
            },
            orderAccessoryPaymentDatesOption: null,
            beneficiary: packItem.items[i].beneficiaryParty,
            role:packItem.items[i].beneficiaryRole
    
    }
    orderCommissions.push(orderCommission)
    }
    order.orderCommissions=orderCommissions
    order.commissionProductPack={
        objectType: "odm.product.productsummary",
        systemUid: "odm-product",
        resourceUid: commissionProductPackUid
      }

    return order
    
}

export const calculateOrderCommission = async (order?: Order) => {
//     TODO remove mock
    return Promise.resolve([
        {
            beneficiary: "Vendeur",
            number: 1,
            type: "Véhicule",
            description: "Prime Fixe",
            calculationType: "Taux",
            rateType: "%",
            amount: 5000,
            result: "10%",
            revision: "5,00",
            commission: "50,00"
        },
        {
            beneficiary: "Vendeur",
            number: 2,
            type: "Véhicule",
            description: "Prime Fixe",
            calculationType: "Taux",
            rateType: "%",
            amount: 5000,
            result: "10%",
            revision: "5,00",
            commission: "50,00"
        },
        {
            beneficiary: "Vendeur",
            number: 2,
            type: "Véhicule",
            description: "Prime Fixe",
            calculationType: "Taux",
            rateType: "%",
            amount: 5000,
            result: "10%",
            revision: "5,00",
            commission: "50,00"
        },
        {
            beneficiary: "Vendeur",
            number: 2,
            type: "Véhicule",
            description: "Prime Fixe",
            calculationType: "Taux",
            rateType: "%",
            amount: 5000,
            result: "10%",
            revision: "5,00",
            commission: "50,00"
        },
        {
            beneficiary: "Intermediarie",
            number: 2,
            type: "Véhicule",
            description: "Prime Fixe",
            calculationType: "Taux",
            rateType: "%",
            amount: 5000,
            result: "10%",
            revision: "5,00",
            commission: "50,00"
        },
    ])
}


export const getOrderCommissionTask = () => {
    return {
        "resourceUid": null,
        "objectType": "odm.workflow.taskdetailinfo",
        "systemUid": "odm-workflow",
        "businessData": null,
        "daaq": "/COMPANY1/PEUGEOT/CONCESSION1/VENDOR1/",
        "taskId": "a3c44076-e07f-11ee-be9e-36da1d89fc69",
        "taskName": "Start Order",
        "priority": 50,
        "creationDate": "2024-03-12 14:48:50",
        "dueDate": "2024-03-14 14:48:50",
        "endDate": null,
        "followUpDate": null,
        "status": null,
        "assignee": "Sales01 Sales01 ## Sales01",
        "owner": null,
        "candidateUser": null,
        "candidateGroup": null,
        "processDefinitionId": "OrderProcess:25:ee9a4d72-e071-11ee-be9e-36da1d89fc69",
        "processInstanceId": "a3c3f226-e07f-11ee-be9e-36da1d89fc69",
        "variables": {
            "orderId": {
                "type": "String",
                "value": "65f06b47a3854e6402e5a564",
                "valueInfo": {}
            },
            "module": {
                "type": "String",
                "value": "Order",
                "valueInfo": {}
            },
            "associated_networks_id": {
                "type": "String",
                "value": "PEUGEOT",
                "valueInfo": {}
            },
            "orderStatus": {
                "type": "String",
                "value": "ORDER",
                "valueInfo": {}
            },
            "orderCreationDate": {
                "type": "String",
                "value": "12/03/2024",
                "valueInfo": {}
            },
            "customerRessourceUid": {
                "type": "String",
                "value": "ofit4rucSBaOoQS-h7Vglw",
                "valueInfo": {}
            },
            "customerName": {
                "type": "String",
                "value": "Yassine Deschamps ",
                "valueInfo": {}
            },
            "customerBirthDate": {
                "type": "String",
                "value": "04/03/1980",
                "valueInfo": {}
            },
            "associated_network_nodes_id": {
                "type": "String",
                "value": "Concessionnaire_1",
                "valueInfo": {}
            },
            "customerType": {
                "type": "String",
                "value": "person",
                "valueInfo": {}
            },
            "customerReference": {
                "type": "String",
                "value": "P001360",
                "valueInfo": {}
            },
            "orderReference": {
                "type": "String",
                "value": "TS014289",
                "valueInfo": {}
            },
            "applicationName": {
                "type": "String",
                "value": "TWDClientApp",
                "valueInfo": {}
            }
        },
        "executionId": "a3c41962-e07f-11ee-be9e-36da1d89fc69",
        "parentTask": null,
        "taskDefinition": "STEP_ORDER_COMMISSION",
        "isLoading": true
    }
}
