import {Module} from "vuex";
import {RootState,  ValidationOrderState} from "@/types";
import {formatDate} from "@/utils/configuration/formatters-config";

const validationOrderModule: Module<ValidationOrderState, RootState> = {
    namespaced: true,
    state: {
        bind: {
            decisionDate: formatDate(new Date(), "/"),
            comment: '',
            decision: ''
        }
    },
    actions: {
        cleanState({ commit }) {
            commit("setInitial");
        },
    },
    mutations: {
        setInitial(state) {
            state.decisionDate = formatDate(new Date(), "/")
            state.comment = ''
            state.decision = ''
        },
    },
};

export default validationOrderModule;
