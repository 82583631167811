import { api } from "@/auth/api";
import { settings } from "@/settings";
import { filter } from "@/types";
import { globalConfig, headers } from "@/utils";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import axios from "axios";
import { getInOperator } from "../picklist";
import {getTaskCountByUser} from "@/store/services/task/workflow";

export async function getSavedFilters(queryId: any) {

    const listNotFiltered = await listOfFilters(queryId)
    listNotFiltered.sort((a: filter, b: filter) => a.numOrder - b.numOrder);
    for (const filter of listNotFiltered) {
        if (filter.businessData && filter.businessData.queryId) {
          const data: any = await searchCount(filter)
          filter.count = data.count
        } else if(filter.screenId === 'search-task'){

            const filterCamunda: any = {...filter}

            delete filterCamunda.screenId
            delete filterCamunda.description
            delete filterCamunda.color
            delete filterCamunda.hasPublicAccess
            delete filterCamunda.numOrder

            filterCamunda.initializeVariables = filter.businessData.initializeVariables
            filterCamunda.candidateGroups = filter.businessData.candidateGroups
            filterCamunda.searchVariables = filter.businessData.searchVariables
            filterCamunda.taskDefinitionKeyIn = filter.businessData.taskDefinitionKeyIn
            filterCamunda.includeAssignedTasks = filter.businessData.includeAssignedTasks

            const data: any = await getTaskCountByUser(filterCamunda)
            filter.count = data.data.count
        } else {
          filter.count = 0
        }
    }
    return listNotFiltered
}

async function listOfFilters(queryId: any) {
  const systemUid = "odm-configuration";
  return (
    await api().get(
      `/${systemUid}/api/1/${systemUid}/filterstore/screenid/${queryId}/`
    )
  ).data;
}

export const saveFilter = async (payload: any) => {
  const systemUid = "odm-configuration";
  delete payload.count;
  return (
    await api().post(`/${systemUid}/api/1/${systemUid}/filterstore/`, payload)
  ).data;
};


export const updateFilter = async (payload: any) => {
  const systemUid = 'odm-configuration'
  delete payload.count
  return (await api().put(`/${systemUid}/api/1/${systemUid}/filterstore/` + payload.resourceUid + '/', payload)).data
}

export function getOperator(
  table: string,
  field: string,
  operator: string,
  value?: any,
  parameter?: string,
  inParameters?: string[]
) {
  return {
    expression: {
      table: table,
      field: field,
      operator: operator,
      value: value,
      parameter: parameter,
      inValue:
        inParameters && inParameters.length > 0
          ? {
              values: inParameters,
            }
          : null,
    },
  };
}

export function getQcOrder(filter: any) {
  const qc_header: any = {
    qc: {
      queryId: "order-search-filter",
      offset: 0,
      limit: 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];

  if (filter.filterReference) {
    operators.push(
      getOperator(
        "lower(order_order",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.filterReference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.externalReference) {
    operators.push(
      getOperator(
        "lower(order_order_item",
        "external_reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.externalReference + "%")
          .toLowerCase(),
        "external_reference"
      )
    );
  }
  if (filter.filterStatus) {
    operators.push(
      getOperator(
        "order_order",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.filterStatus),
        "status_code"
      )
    );
  }
  if (filter.manufacturingNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "serial_number)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.manufacturingNumber + "%")
          .toLowerCase(),
        "serial_number"
      )
    );
  }
  if (filter.registrationNumber) {
    operators.push(
      getOperator(
        "lower(order_order_asset",
        "matriculation)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.registrationNumber + "%")
          .toLowerCase(),
        "matriculation"
      )
    );
  }
  if (filter.activity !== undefined) {
    operators.push(
      getOperator(
        "order_order_asset",
        "flag_new",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.activity),
        "flag_new"
      )
    );
  }
  if (filter.delivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNOTNULL")
    );
  }
  if (filter.notDelivered) {
    operators.push(
      getOperator("order_order", "effective_delivery_date", "ISNULL")
    );
  }
  if (filter.brand) {
    operators.push(
      getOperator(
        "order_order_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand),
        "brand"
      )
    );
  }
  if (filter.chassisReference) {
    operators.push(
      getOperator(
        "order_order_asset",
        "chassis_reference",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.chassisReference),
        "chassis_reference"
      )
    );
  }
  if (filter.contractStatus) {
    operators.push(
      getOperator(
        "order_order_item",
        "status_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.contractStatus),
        "status_code"
      )
    );
  }

  if (filter.deliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order",
        "effective_delivery_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.deliveryDateFrom, "DD/MM/YYYY", "fr").toString,
        "deliveryDateFrom"
      )
    );
  }
  if (filter.deliveryDateTo) {
    operators.push(
      getOperator(
        "order_order",
        "effective_delivery_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.deliveryDateTo, "DD/MM/YYYY", "fr").toString,
        "deliveryDateTo"
      )
    );
  }
  if (filter.previsonalDeliveryDateFrom) {
    operators.push(
      getOperator(
        "order_order",
        "prevision_delivery_date",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.previsonalDeliveryDateFrom, "DD/MM/YYYY", "fr")
          .toString,
        "previsonalDeliveryDateFrom"
      )
    );
  }
  if (filter.previsonalDeliveryDateTo) {
    operators.push(
      getOperator(
        "order_order",
        "prevision_delivery_date",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.previsonalDeliveryDateTo, "DD/MM/YYYY", "fr")
          .toString,
        "previsonalDeliveryDateTo"
      )
    );
  }
  if (filter.supplierName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.supplierName + "%")
          .toLowerCase(),
        "name"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party_1",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.clientReference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  operators.push(
    getOperator(
      "order_order_party_1",
      "role_code",
      "EQUAL",
      globalConfig.formatters.getEqualValue(filter.role_code),
      "role_code"
    )
  );

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export function searchCount(filter: any): Promise<any[]> {
  return new Promise<any[]>((resolve, reject) => {
    const url = settings.api_query_count_url;
    const _headers = headers({
      qc: JSON.stringify(filter.businessData),
    });
    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export function getQcOffer(filter: any) {
  const qc_header: any = {
    qc: {
      queryId: "offer-filter-search",
      offset: 0,
      limit: 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];

  if (filter.demandStatus) {
    operators.push(
      getOperator(
        "offer_offer_status",
        "id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.demandStatus),
        "id"
      )
    );
  }
  if (filter.reference) {
    operators.push(
      getOperator(
        "lower(offer_offer",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.reference + "%")
          .toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "first_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.firstName + "%")
          .toLowerCase(),
        "firstName"
      )
    );
  }
  if (filter.familyName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "family_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.familyName + "%")
          .toLowerCase(),
        "familyName"
      )
    );
  }
  if (filter.clientReference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "reference)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.clientReference + "%")
          .toLowerCase(),
        "clientReference"
      )
    );
  }
  if (filter.from) {
    operators.push(
      getOperator(
        "offer_offer",
        "_when_created",
        "GREATER_THAN_EQUAL",
        formatDateLocale(filter.from, "DD/MM/YYYY", "fr").toString,
        "from"
      )
    );
  }
  if (filter.until) {
    operators.push(
      getOperator(
        "offer_offer",
        "_when_created",
        "LESS_THAN_EQUAL",
        formatDateLocale(filter.until, "DD/MM/YYYY", "fr").toString,
        "until"
      )
    );
  }
  if (filter.channel) {
    operators.push(
      getOperator(
        "offer_offer",
        "sales_channel_code",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.channel.id),
        "channel"
      )
    );
  }
  if (filter.typeClient) {
    operators.push(
      getOperator(
        "party_party",
        "_discriminator",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.typeClient.id),
        "typeClient"
      )
    );
  }
  if (filter.network) {
    operators.push(
      getOperator(
        "offer_offer",
        "associated_networks_id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.network.id),
        "network"
      )
    );
  }
  if (filter.networkNode) {
    operators.push(
      getOperator(
        "offer_offer",
        "associated_network_nodes_id",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.networkNode.id),
        "networkNode"
      )
    );
  }
  if (filter._decisionAnalysisProcessId) {
    operators.push(
      getOperator(
        "offer_offer",
        "_decisionAnalysisProcessId",
        "EQUAL",
        globalConfig.formatters.getEqualValue(
          filter._decisionAnalysisProcessId.id
        ),
        "_decisionAnalysisProcessId"
      )
    );
  }
  if (filter.brand) {
    operators.push(
      getOperator(
        "offer_proposal_asset",
        "brand",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.brand.id),
        "brand"
      )
    );
  }
  if (filter.range) {
    operators.push(
      getOperator(
        "offer_proposal_asset",
        "range",
        "EQUAL",
        globalConfig.formatters.getEqualValue(filter.range.id),
        "range"
      )
    );
  }
  if (filter.immatriculation) {
    operators.push(
      getOperator(
        "lower(offer_proposal_asset",
        "matriculation)",
        "EQUAL",
        globalConfig.formatters
          .getEqualValue(filter.immatriculation)
          .toLowerCase(),
        "immatriculation"
      )
    );
  }
  if (filter.commercialName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters
          .getEqualValue(filter.commercialName + "%")
          .toLowerCase(),
        "commercialName"
      )
    );
  }
  if (filter.siren) {
    operators.push(
      getOperator(
        "lower(party_party",
        "siren)",
        "LIKE",
        globalConfig.formatters.getEqualValue(filter.siren + "%").toLowerCase(),
        "siren"
      )
    );
  }
  if (filter.role && filter.role.length > 0) {
    operators.push(
      getInOperator(
        "party_party",
        "id",
        "IN",
        null,
        null,
        globalConfig.formatters.getInValue(filter.role)
      )
    );
  } else {
    operators.push(
      getOperator(
        "offer_associated_party",
        "role_code",
        "EQUAL",
        "'CUSTOMER'",
        "role_code"
      )
    );
  }

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export function getQcParty(filter: any) {
  const qc_header: any = {
    qc: {
      queryId: "party-filter-search",
      offset: 0,
      limit: 100,
    },
  };

  qc_header.qc.parameters = {
    ...filter,
  };
  const operators = [];
  if (filter.reference) {
    operators.push(
      getOperator(
        "lower(party_party",
        "reference)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.reference).toLowerCase(),
        "reference"
      )
    );
  }
  if (filter.siren) {
    operators.push(
      getOperator(
        "lower(party_party",
        "siren)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.siren).toLowerCase(),
        "siren"
      )
    );
  }
  if (filter.family_name) {
    operators.push(
      getOperator(
        "lower(party_party",
        "family_name)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.family_name).toLowerCase(),
        "family_name"
      )
    );
  }
  if (filter.birthday) {
    operators.push(
      getOperator(
        "party_party",
        "birth_date",
        "EQUAL",
        formatDateLocale(filter.birthday, "DD/MM/YYYY", "fr").toString,
        "birthday"
      )
    );
  }
  if (filter.firstName) {
    operators.push(
      getOperator(
        "lower(party_party",
        "first_name)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.firstName.toLowerCase()),
        "firstName"
      )
    );
  }
  if (filter.discriminator) {
    operators.push(
      getOperator(
        "party_party",
        "_discriminator",
        "LIKE",
        globalConfig.formatters.getLikeValue(
          filter.discriminator === "party-FrenchPerson"
            ? "Person"
            : "Organization"
        ),
        "discriminator"
      )
    );
  }
  if (filter.city) {
    operators.push(
      getOperator(
        "party_postal_code",
        "city",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.city),
        "city"
      )
    );
  }
  if (filter.postalCode) {
    operators.push(
      getOperator(
        "party_postal_code",
        "postal_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.postalCode),
        "postalCode"
      )
    );
  }
  if (filter.siret) {
    operators.push(
      getOperator(
        "lower(party_facility",
        "siret)",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.siret).toLowerCase(),
        "siret"
      )
    );
  }
  if (filter.phoneNumber) {
    operators.push(
      getOperator(
        "party_contact_mechanism",
        "phone_number",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.phoneNumber),
        "phoneNumber"
      )
    );
  }
  if (filter.relationTypeCode) {
    operators.push(
      getOperator(
        "party_party_relation",
        "relation_type_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.relationTypeCode),
        "relationTypeCode"
      )
    );
  }
  if (filter.role) {
    operators.push(
      getOperator(
        "party_party_role",
        "role_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.role.value),
        "role_code"
      )
    );
  }
  if (filter.commercial_name) {
    operators.push(
      getOperator(
        "lower(party_party",
        "commercial_name)",
        "LIKE",
        globalConfig.formatters.getLikeValue(
          filter.commercial_name.toLowerCase()
        ),
        "commercial_name"
      )
    );
  }
  if (filter.current_status_code) {
    operators.push(
      getOperator(
        "party_party",
        "current_status_code",
        "LIKE",
        globalConfig.formatters.getLikeValue(filter.current_status_code.id),
        "current_status_code"
      )
    );
  }

  if (operators.length > 0) {
    qc_header.qc.otherFilters = {
      expressions: [
        {
          and: {
            operators,
          },
        },
      ],
    };
  }
  return qc_header.qc;
}

export async function deleteFilter(filter: any) {
  const { resourceUid, systemUid } = filter;
  const url = `/${systemUid}/api/1/${systemUid}/filterstore/${resourceUid}/`;
  return (await api().delete(url));
}
