
import SummaryPerson from "@/components/summary-panel/SummaryPerson.vue";
import SummaryOrganization from "@/components/summary-panel/SummaryOrganization.vue";
import { Options, Vue } from "vue-class-component";
import { OfferAssociatedParty } from "@/types";

@Options({
  components: { SummaryPerson, SummaryOrganization },
  props: ["arrParties"],
})
export default class SummaryTiers extends Vue {
  arrParties?: Array<OfferAssociatedParty>;
  tab?: string = "";
  filteredParties?: Array<OfferAssociatedParty>;
  get buildParties() {
    if (this.arrParties) {
      this.filteredParties = this.arrParties.filter((el) => el.role_code !== "DIRG");
      this.tab = this.filteredParties[0].associatedParty?.resourceUid;
      return this.filteredParties;
    }
    return [];
  }
}
