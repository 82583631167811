
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import SummaryLoan from "@/components/summary-panel/SummaryLoan.vue";
import SummaryLease from "@/components/summary-panel/SummaryLease.vue";
import { AdvancedQuote, SimpleQuote } from "@/types";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import SummaryFinancingService from "@/components/summary-panel/SummaryFinancingService.vue";
import SummaryFinancingGuarantee from "@/components/summary-panel/summaryFinancingGuarantee.vue";

@Options({
  components: {
    SummaryLoan,
    SummaryLease,
    LabelAndValue,
    SummaryFinancingService,
    SummaryFinancingGuarantee
  },
})
export default class SummaryFinancing extends Vue {
  get isOfferActivityTypeLease() {
    return this.activityType === "LEASE";
  }

  get activityType() {
    return this.proposal?.activityType || "-";
  }

  get offer() {
    return store.state.summaryPanelModule.offer;
  }

  get proposal(): AdvancedQuote | SimpleQuote | undefined {
    return this.offer?.proposals[0].proposalItems[0];
  }

  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };

  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "10px",
  };
  labelStyleItem = {
    "font-size": "1rem",
    color: "gray",
    "font-weight": "bold",
  };
}
