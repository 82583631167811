import { Command, Dossier, Third } from "@/types";
import { getThird } from "@/store/services/searchThird";
export interface Column {
  name: string;
  required?: boolean;
  label: string;
  align?: 'left' | 'center' | 'right';
  field?: (row: any) => any;
  format?: (val: any) => string;
  sortable?: true;
  sort?: (a: string, b: string) => number;
  type?: string;
  options?: any[]
}

export interface Row {
  name: string;
  Ref: string;
  Cdtlouer: string;
  Client: string;
  Vendeur: string;
  DateCommande: String;
  DateDeLivraison: String;
  DateRDVclient: String;
  Status: string;
  asset: string;
  type: string;
  deliveryAddress: string;
  price: number;
  discount: number;
  priceHT: number;
  priceTTC: number;
}

export async function getThirdName(third: Third | undefined) {
  let thirdName;
  if (third?.resourceUid && third?.objectType && third?.systemUid) {
    await getThird(third.resourceUid, third.objectType, third.systemUid).then((res) => {
      if (res.person) {
        thirdName = res.person?.firstName + " " + res.person?.familyName;
      } else {
        thirdName = res.organization?.name;
      }
    });
  }
  return thirdName;
}

export const columns: Column[] = [
  {
    name: "reference",
    align: "center",
    label: "Réf",
    field: (row: Dossier) => row.reference,
    sortable: true,
    type: "text",
  },
  {
    name: "cdtLoueur",
    label: "Cdt louer ",
    field: (row: Dossier) => row.cdtLoueur,
    sortable: true,
    type: "text",
  },
  {
    name: "client",
    label: "Client",
    field: (row: Dossier) => row.client?.firstname ? row.client?.firstname + " " + row.client?.familyname : row.client?.name,
    sortable: true,
    type: "select",
    options: ["France", "DARCHE", "DARCHE"],
  },
  {
    name: "vendeur",
    label: "Vendeur",
    field: (row: Dossier) => row.vendeur?.name,
    sortable: true,
    type: "select",
    options: ["Supplier A", "Adolfo", "Supplier A"],
  },
  {
    name: "dateCommande",
    label: "Date Commande ",
    field: (row: Dossier) => row.dateCommande,
    sortable: true,
    type: "text",
  },
  {
    name: "dateLivraison",
    label: "Date De Livraison",
    field: (row: Dossier) => row.dateLivraison,
    sortable: true,
    type: "text",
  },
  {
    name: "dateRDVClient",
    label: "Date RDV client",
    field: (row: Dossier) => row.dateRDVClient,
    sortable: true,
    type: "text",
  },
  {
    name: "status",
    label: "Status",
    field: (row: Dossier) => row.status?.value,
    sortable: true,
    type: "select",
    options: [{ label: "Regrouper Docs command", value: "Regrouper Docs command" },
    { label: "option1", value: "option1" },
    { label: "option2", value: "option2" }
    ],
  },
  {
    name: "adresseLivraison",
    align: "center",
    label: "deliveryAddress",
    field: (row: Dossier) => row.adresseLivraison,
    sortable: true,
    type: "text",
  }

];


export const rows = async () => {
  return await [
    {
      name: 'row 1',
      Ref: 'TS30997',
      Cdtlouer: 'TS30997',
      Client: 'Lease Plan France SAS',
      Vendeur: 'Adolfo',
      DateCommande: '10/09/2023',
      DateDeLivraison: '30/01/2024',
      DateRDVclient: '30/01/2024',
      Status: 'Creation de Dossier',
      asset: "Peugeot Partner B...",
      type: "Véhicule",
      deliveryAddress: "SARP-OSIS OUESTZONE INDISTRIELL, 2 RUE DE PRONY, 37300 JOUE LES TOURS",
      price: "$23,267.00",
      discount: "0%",
      priceHT: "$20,952.00",
      priceTTC: "$25,139.00"
    },
    {
      name: 'row 2',
      Ref: 'T230927',
      Cdtlouer: 'T230927',
      Client: 'AUTOCARS DARCHE GROS',
      Vendeur: 'Adolfo',
      DateCommande: '30/09/2023',
      DateDeLivraison: '05/10/2023',
      DateRDVclient: '05/10/2023',
      Status: 'Activer Signature Electronique',
      asset: "Hyundai i20",
      type: "Véhicule",
      deliveryAddress: "SARP-OSIS OUESTZONE INDISTRIELL, 2 RUE DE PRONY, 37300 JOUE LES TOURS",
      price: "$33,267.00",
      discount: "2%",
      priceHT: "$30,952.00",
      priceTTC: "$35,139.00"
    },
    {
      name: 'row 3',
      Ref: 'T567876',
      Cdtlouer: 'T567876',
      Client: 'AUTOCARS DARCHE GROS',
      Vendeur: 'Adolfo',
      DateCommande: '20/08/2023',
      DateDeLivraison: '30/01/2024',
      DateRDVclient: '30/01/2024',
      Status: 'Regrouper Docs Commande',
      asset: "Mercedes Benz.",
      type: "Véhicule",
      deliveryAddress: "SARP-OSIS OUESTZONE INDISTRIELL, 2 RUE DE PRONY, 37300 JOUE LES TOURS",
      price: "$43,267.00",
      discount: "5%",
      priceHT: "$40,952.00",
      priceTTC: "$45,139.00"
    },

  ];
}


export interface Comandrow {
  name: string;
  Ref: string;
  Type: string;
  Asset: string;
  DateCommande: string;
  DateDeLivraison: string;
  Fournisseur: string;
  AdresseDeLivraison: string;
  Montant: string;
  Status: string;
}
export const visibleColumns = ['Ref', 'Cdtlouer', 'Client', 'Vendeur', 'DateCommande',]

export const comandcolumns: Column[] = [
  {
    name: "reference",
    align: "center",
    label: "Ref",
    field: (row: Command) => row.reference,
    sortable: true,
    type: "text",
  },
  {
    name: "type",
    align: "center",
    label: "Type",
    field: (row: Command) => row.type,
    sortable: true,
    type: "text",
  },
  {
    name: "Asset",
    align: "center",
    label: "Asset",
    field: (row: Command) => row.asset.assetItems[0].label,
    sortable: true,
    type: "Asset",
  },
  {
    name: "DateCommande",
    label: "Date Commande ",
    field: (row: Command) => row.dateCommande,
    sortable: true,
    type: "text",
  },
  {
    name: "DateDeLivraison",
    label: "Date Livraison",
    field: (row: Command) => row.dateLivraison,
    sortable: true,
    type: "text",
  },
  {
    name: "fournisseur",
    label: "Fournisseur",
    field: (row: Command) => row.vendeur?.name,
    sortable: true,
    type: "select",
    options: ["Adolfo", "Adolfo", "Adolfo"],
  },
  {
    name: "deliveryAddress",
    align: "center",
    label: "Adresse de livraison",
    field: (row: Command) => row.adresseLivraison,
    sortable: true,
    type: "text",
  },
  {
    name: "price",
    align: "center",
    label: "Montant",
    field: (row: Command) => row.prix,
    sortable: true,
    type: "number",
  },
  {
    name: "status",
    label: "status",
    field: (row: Command) => row.status?.value,
    sortable: true,
    type: "text",
    options: ["Creation de Dossier", "Signature", "Docs"],
  },
  {
    name: "referenceCommande",
    align: "center",
    label: "Cmd Principale",
    field: (row: Command) => row.referenceCommande,
    sortable: true,
    type: "text",
  },
];

export const searchComandRows = [
  {
    name: 'row 1',
    Ref: 'T567876',
    Type: 'Véhicule',
    Asset: 'Peugeot Partner B...',
    DateCommande: '01/01/2024',
    DateDeLivraison: '01/01/2024',
    Fournisseur: 'PSA RETAIL Mérignac',
    AdresseDeLivraison: 'SARP-OASIS OUESTZONE...',
    Montant: '123,045.00',
    Status: 'Approuvé'
  },
  {
    name: 'row 2',
    Ref: 'F567548',
    Type: 'C.Fournisseur',
    Asset: 'Peugeot Partner B...',
    DateCommande: '01/01/2024',
    DateDeLivraison: '01/01/2024',
    Fournisseur: 'SD SERVICES EYBEN SAS',
    AdresseDeLivraison: 'SARP-OASIS OUESTZONE...',
    Montant: '123,045.00',
    Status: 'Approuvé'
  }
];
export const visibleComandColumns1 = [
  "reference",
  "type",
  "Asset",
  "DateCommande",
  "DateDeLivraison",
  "fournisseur",
  "deliveryAddress",
  "price",
  "status",
  "referenceCommande",
];
export const visibleComandColumns2 = [
  "reference",
  "type",
  "Asset",
  "DateCommande",
  "DateDeLivraison",
  "fournisseur",
  "deliveryAddress",
  "price",
  "status",
];

