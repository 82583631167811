import {ComboBoxItem, MoneyAmount, UniformOdmName} from "@/types";

export class GuaranteeProductClass implements UniformOdmName {
    resourceUid?: string
    systemUid?: string
    objectType?: string
    amount?: MoneyAmount
    rate?: number
    role?: UniformOdmName
    partyReference?: UniformOdmName
    name?: string
    reference?: string
    guaranteeType?: string
    commissionFlag?: boolean
    basis?: ComboBoxItem | UniformOdmName
    evaluationMethod?: ComboBoxItem | UniformOdmName
    underlyingType?: ComboBoxItem | UniformOdmName

    constructor(guaranteeProduct: GuaranteeProductClass) {
        this.resourceUid = guaranteeProduct.resourceUid
        this.systemUid = guaranteeProduct.systemUid
        this.objectType = guaranteeProduct.objectType
        this.evaluationMethod = guaranteeProduct.evaluationMethod
        this.amount = guaranteeProduct.amount
        this.basis = guaranteeProduct.basis
        this.rate = guaranteeProduct.rate
        this.role = guaranteeProduct.role
        this.partyReference = guaranteeProduct.partyReference
        this.name = guaranteeProduct.name
        this.reference = guaranteeProduct.reference
        this.guaranteeType = guaranteeProduct.guaranteeType
        this.commissionFlag = guaranteeProduct.commissionFlag
        this.underlyingType = guaranteeProduct.underlyingType
    }
}
