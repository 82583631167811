import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "default-border-radius p-2 bg-white" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "pl-3 flex-fill" }
const _hoisted_7 = {
  key: 1,
  class: "row d-flex align-items-center"
}
const _hoisted_8 = { class: "pl-3 flex-fill" }
const _hoisted_9 = {
  key: 2,
  class: "row d-flex align-items-center"
}
const _hoisted_10 = { class: "pl-3 flex-fill" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGatheringProcess, (gatheringProcess, index) => {
    return (_openBlock(), _createBlock(_component_q_expansion_item, {
      "switch-toggle-side": "",
      "header-class": " card-header bg-transparent no-border p-4 tw-order-summaryPanel-header-class default-border-radius",
      class: "tw-items-dashed-group tw-items-group-with-bg tw-level-two-group q-expansion-item--expanded",
      onShow: _cache[0] || (_cache[0] = () => {}),
      onHide: _cache[1] || (_cache[1] = () => {}),
      key: index
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.capitalize(
                  _ctx.$t("order.preconditions.label." + gatheringProcess.assessmentContext).toLowerCase()
              )), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gatheringProcess.checkpoints, (checkpoint, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mb-2"
            }, [
              (checkpoint.objectType.includes('documentvalue'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkpoint.documents, (document, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i,
                        class: "row d-flex align-items-center"
                      }, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", {
                            class: _normalizeClass(_ctx.setStatusBadgeClass(document.status||'PENDING'))
                          }, _toDisplayString(_ctx.$t('task.uploadDocument.headers.supportingDocumentStatus.'.concat(document.status||'PENDING'))), 3)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('task.supportingDocumentLabel.'.concat(document.documentType))), 1)
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (checkpoint.objectType.includes('fieldvalue'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.setStatusBadgeClass(checkpoint.status.resourceUid))
                      }, _toDisplayString(_ctx.$t('order.preconditions.fieldvalue.status.'.concat(checkpoint.status.resourceUid))), 3)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.preconditions.fieldvalue.label." + checkpoint.i18nLabel)), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (checkpoint.objectType.includes('businessrulevalue'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.setStatusBadgeClass(checkpoint.status.resourceUid))
                      }, _toDisplayString(_ctx.$t('order.preconditions.fieldvalue.status.'.concat(checkpoint.status.resourceUid))), 3)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.preconditions.fieldvalue.label." + checkpoint.i18nLabel)), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _: 2
    }, 1024))
  }), 128))
}