import {ContactType, PartyContactCorrespondent, PartyContactMechanism, UniformOdmName} from "@/types";
import i18n from "@/i18n";
export let contacts: Map<string | undefined, string | undefined>

export const populateContactsMap = <T extends PartyContactMechanism | PartyContactCorrespondent>(arr: T[]) => {
    contacts  = new Map<string | undefined, string | undefined>();
    arr.forEach(value => {
        switch (value.contactMechanism.type?.id) {
            case ContactType.EMAIL_CONTACT:
                contacts.set(value.contactMechanism.type?.id, value.contactMechanism.emailAddress);
                break;
            case ContactType.PHONE_CONTACT:
                contacts.set(value.contactMechanism.type?.id, value.contactMechanism.phoneNumber);
                break;
            case ContactType.MESSENGER_CONTACT:
                contacts.set(value.contactMechanism.type?.id, value.contactMechanism.identifier);
                break;
            default:
                contacts.set(value.contactMechanism.type?.id, value.contactMechanism.address?.resourceUid);
                break;
        }
    })
}

export const getPartyClass = <T extends {partyClass: UniformOdmName}>(arr: T[]) => {
    return (arr && arr.length > 0) ? i18n.global.t(`demand.party.partyClass.${arr.find(el => !!el)?.partyClass}`) : '';
}
