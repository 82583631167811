import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-4 mb-2" }
const _hoisted_2 = { class: "col-4 mb-2" }
const _hoisted_3 = { class: "col-4 mb-2" }
const _hoisted_4 = { class: "col-4 mb-2" }
const _hoisted_5 = { class: "col-4 mb-2" }
const _hoisted_6 = { class: "col-4 mb-2" }
const _hoisted_7 = { class: "col-4 mb-2" }
const _hoisted_8 = { class: "col-4 mb-2" }
const _hoisted_9 = { class: "col-4 mb-2" }
const _hoisted_10 = { class: "col-4 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.price',
        value: _ctx.price,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.monthly',
        value: _ctx.monthly,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.duration',
        value: _ctx.duration,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.durationUnit',
        value: _ctx.frequency,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.firstPayment',
        value: _ctx.firstPayment,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.tax',
        value: _ctx.tax,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.gracePeriodTerms',
        value: _ctx.gracePeriodTerms,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.gracePeriodTermsType',
        value: _ctx.gracePeriodTermsType,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.gracePeriodTermsTax',
        value: _ctx.gracePeriodTermsTax,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_LabelAndValue, {
        "i18n-key": 'summaryPanel.financing.lease.residualValue',
        value: _ctx.residualValue,
        oneLine: true,
        "value-style": _ctx.valueStyle,
        "label-style": _ctx.labelStyle,
        "div-value-style": _ctx.divValueStyle
      }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
    ])
  ], 64))
}