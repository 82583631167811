import {ActionType, Offer, Task, TaskExecuteAction} from "@/types";
import {executeTask} from "@/store/services/task/workflow";
import {saveOfferFromTask} from "@/store/services/task/taskService";
import store from "@/store";

export async function executeCompleteOfferAction(userId: string, offer: Offer, task: Task, action: ActionType, formValid: boolean, isPreviousformValid: boolean) {
    if (!formValid || !isPreviousformValid) return Promise.reject('Invalid form!')
    try {
        if (action === ActionType.VALIDATE) {
            const taskExecuteAction: TaskExecuteAction = {
                taskId: task.taskId || ''
            }
            const newOffer=await saveOfferFromTask(offer)
            store.state.taskModule.offer=newOffer
            return executeTask(taskExecuteAction)
        }
    } catch (e: any) {
        console.error(e)
        return Promise.reject(e.message)
    }
}
