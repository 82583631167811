import { Module } from "vuex";
import { ControlsState } from "@/types";
import { evaluateControls } from "@/store/services/controls/controls";
import i18n from "@/i18n";
import { buildActionsControls, buildOfferControls, buildPartyControls } from "@/store/services/controls/controlsMapper";
import { getUiControls } from "@/store/services/controls/controlUI";
import { buildRequestedData, initanalysisData } from "@/utils";
import store from "@/store";


const controlsModule: Module<ControlsState, any> = {
    namespaced: true,
    state: {
        errors: [],
        warnings: [],
        show: false,
        menuUiStatus:[],
        UiStatus: [],
        blockedMandatoryFields:[]
    },
    actions: {

        setBlockedMandatoryFields({ commit }, payload) {
            commit('setBlockedMandatoryFields', payload)
        },

        // async evaluateMenuUiControls({ commit }, payload) {
        //     const { callAPI, router, screenId, operation, callback } = payload
        //     const currentRoute = router.currentRoute;
        // },

        async evaluateUiControls({ commit }, payload) {
            const { callAPI, router, screenId, operation, callback } = payload
            const currentRoute = router.currentRoute;

            if (callAPI) {
                const requestData = await buildRequestedData(currentRoute, { operation: operation })
                if (!requestData) {
                    throw new Error('Payload are mandatory')
                }

                const uiControlsObj = {
                    screenId: screenId,
                    data: {
                        ...requestData
                    }
                }
                await getUiControls(uiControlsObj).then((response: any) => {
                   
                   
                    console.log(response)
                    commit('setUiStatus', response.componentList)
                    if (callback) {
                        callback(response.componentList)
                    }

                }).catch((err: any) => {
                    console.error('Error evaluating controls')
                    console.error(err)
                })

            }
            else {
                if (callback) {

                    callback(store.state.controlsModule.UiStatus)
                }
            }



        },
        async evaluatePartyControls({ dispatch }, payload) {
            if (!payload) {
                throw new Error('onSuccess and onFail are mandatory')
            }
            const result = await buildPartyControls(this.state)
            if (!result) {
                throw new Error('Internal Error')
            }
            payload.data = result.data
            payload.entity = result.entity
            dispatch('evaluateControls', payload)
        },
        async evaluateOfferControls({ dispatch }, payload) {
            if (!payload) {
                throw new Error('onSuccess and onFail are mandatory')
            }
            const result = await buildOfferControls(this.state)
            if (!result) {
                throw new Error('Internal Error')
            }
            payload.data = result.data
            payload.entity = result.entity
            dispatch('evaluateControls', payload)
        },
        async evaluateActionsControls({ dispatch }, payload) {
            if (!payload) {
                throw new Error('onSuccess and onFail are mandatory')
            }
            const result = buildActionsControls(this.state, payload.entity)
            if (!result) {
                throw new Error('Internal Error')
            }
            payload.data = result.data
            payload.entity = result.entity
            dispatch('evaluateControls', payload)
        },
        evaluateControls({ commit }, payload) {
            const { entity, data, onSuccess, onFail } = payload;
            if (!entity || !data || !onSuccess || !onFail) {
                throw new Error('Entity, Data, onSuccess and onFail are mandatory')
            }
            evaluateControls(entity, data).then((response: any) => {
                const { errors, warnings } = response
                commit('setErrors', errors)
                commit('setWarnings', warnings)
                if (errors.length) {
                    onFail(i18n.global.t('controls.errorMessage'))
                    commit('setShow', true)
                } else {
                    onSuccess()
                }
            }).catch((err: any) => {
                console.error('Error evaluating controls')
                console.error('data', data)
                console.error(err)
            })
        },
        toggleShow({ commit }) {
            commit('toggleShow')
        },
        cleanState({ commit }) {
            commit('cleanState')
        }
    },
    mutations: {
        cleanState(state) {
            state.errors = [];
            state.warnings = [];
        },
        toggleShow(state) {
            state.show = !state.show;
        },
        setShow(state, payload) {
            state.show = payload;
        },
        setErrors(state, payload) {
            state.errors = payload;
        },
        setWarnings(state, payload) {
            state.warnings = payload;
        },
        setUiStatus(state, payload) {
            state.UiStatus = payload
        },
        setBlockedMandatoryFields(state,payload) {
            state.blockedMandatoryFields = payload
        }
    },
}

export default controlsModule;
