import {
    DocumentTypeManual,
    GatheringProcess,
    LoadCheckListEvent,
    Offer,
    SupportingDocManual,
    Task,
    TaskExecuteAction,
    TaskState,
} from "@/types";

import {executeTask} from "@/store/services/task/workflow";
import store from "@/store";
import {saveOfferFromTask} from "../taskService";
import {settings} from "@/settings";
import {api} from "@/auth/api";
import {deepClone, deleteAttributesRecursively, headers, removeAttributesRecursively, UON} from "@/utils";
import {saveFile} from "@/store/services/offerService";
import {
    getProcess,
    getRequiredDocument,
    qualifyFile,
    receiveFile
} from "@/store/services/task/actions/uploadDocumentService";
import i18n from "@/i18n";
import {addMetadata, processDocuments} from "@/commons/gathering-process";
import axios from "axios";

const odm_supportingDocument = 'odm-supportingdocument'

export async function executeModifyConditionAction(
    taskState: TaskState,
    userId: string,
    offer: Offer,
    task: Task
) {
    try {
        const taskExecuteAction: TaskExecuteAction = {
            taskId: task.taskId || ''
        }
        const newOffer = await saveOfferFromTask(offer)
        store.state.taskModule.offer = newOffer
        return executeTask(taskExecuteAction)

    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

export async function getAssessList(loadCheckListEvent: LoadCheckListEvent) {
    const baseUrl = `${settings.api_url}/${odm_supportingDocument}/api/1/${odm_supportingDocument}/`
    const body = loadCheckListEvent
    return new Promise<GatheringProcess>(async (resolve, reject) => {
        try {
            const result = await api().post(`${baseUrl}assess/`, body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export async function buildOfferInfo(offerParam: any) {
    const offer = deepClone(offerParam)
    const proposal = offer.proposals[0]
    const proposalItem = proposal.proposalItems[0]
    const accessoryProductInfo = proposalItem.proposalAccessories
        .map((item: any) => {
            const {
                amountWoTax,
                taxValue,
                basisValue,
                quantity,
                proposalAccessoryCalculationMethodOption,
                proposalAccessoryPaymentDatesOption
            } = item
            return {
                quantity,
                basis: basisValue,
                currency: amountWoTax.currency,
                paymentDatesOption: proposalAccessoryPaymentDatesOption.resourceUid,
                amount: amountWoTax,
            }
        })

    const assetList = proposal.proposalItems
        .map((item: any) => item.proposalAssets)
        .reduce((a: any, b: any) => {
            a.push(deepClone(b));
            return a
        }, [])

    const partyInfo = (offer.associatedParties || []).map((offerAssociatedParty: any) => {
        const { role_code } = offerAssociatedParty
        const { third } = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled', 'config'])
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })

    const assetInfo = assetList.map((asset: any) => {
        removeAttributesRecursively(asset)
        deleteAttributesRecursively(asset, ['isDisabled', 'config'])
        return {
            assetData: JSON.stringify(asset)
        }
    })
    const quoteInfo = JSON.stringify(proposal)
    deleteAttributesRecursively(quoteInfo, ['isDisabled', 'config'])
    const offerObject = {
        partyInfo,
        quoteInfo,
    };

    return offerObject;

}


export const handleSupportingDocumentsManual = async (supportingDocManual: SupportingDocManual,
                                                      gatheringProcessItemResourceUid: string,
                                                      processResourceUid: string) => {
    const {
        typeJustify,
        documentType,
        comment,
        fileContent,
        targetEntity
    } = supportingDocManual

    const { originalFileName, format, content, metadata  } = fileContent
    const request: any = {
        originalFileName,
        content,
        format,
        relations: {
            items: [
                {
                    qualifier: {
                        rawValue: documentType
                    },
                    objectUON: {
                        objectType: "odm.filemngt.filecontent",
                        systemUid: UON.SystemUid.odmFileManagement,
                    }
                }
            ]
        }
    }

    const saveResult = await saveFile(request)

    metadata.forEach((m: any) => {
        addMetadata(saveResult.resourceUid, m);
    })

    const file = {
        fileReference: {
            objectType: saveResult.objectType,
            systemUid: saveResult.systemUid,
            resourceUid: saveResult.resourceUid
        },
        creationLabel: saveResult.originalFileName,
        qualification: [
            {
                documentType: {
                    resourceUid: documentType
                },
                label: saveResult.originalFileName
            }
        ],
        systemUid: 'odm-supportingdocument'
    }

    await manualAddDocument(processResourceUid, {
        documentFamily: typeJustify,
        manualCreationComment: comment,
        targetEntity: {
            resourceUid: targetEntity.resourceUid,
            objectType: targetEntity.objectType,
            systemUid: targetEntity.systemUid,
        }
    })

    const receivedFile = await receiveFile(processResourceUid, gatheringProcessItemResourceUid, file)
    await qualifyFile(processResourceUid, gatheringProcessItemResourceUid, receivedFile.resourceUid, file)

    const gatheringProcess = await getProcess(processResourceUid)
    const requiredDocument = await getRequiredDocument(processResourceUid)
    gatheringProcess.supportingDocuments.forEach(item => item.config = {
        statusModel: {
            label: i18n.global.t(`task.validateDocument.headers.supportingDocumentStatus.${item.status.resourceUid}`),
            value: item.status.resourceUid
        }
    })
    gatheringProcess.config = {requiredDocument: requiredDocument}
    if (requiredDocument.length) {
        await processDocuments({
            gatheringProcess: gatheringProcess,
            requiredDocuments: requiredDocument,
            supportingDocuments: gatheringProcess.supportingDocuments,
            receivedFiles: gatheringProcess.gatheringProcessItem[0].receivedFiles
        })
    }
}


export const getGatheringProcessItemResourceUid = async (processResourceUid: string) => {
    const process = await getProcess(processResourceUid)
    const gatheringProcessItem = process.gatheringProcessItem.filter((value: any) => value.objectType.includes('documentgathering'))[0]
    return gatheringProcessItem?.resourceUid
}

export const manualAddDocument = async (processId: string, payload: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingDocument}/api/1/${odm_supportingDocument}`
    return (await api().post(`${baseUrl}/process/${processId}/supporting/`, payload)).data
}


export const manualDeleteDocument = async (processId: string) => {
    const baseUrl = `${settings.api_url}/${odm_supportingDocument}/api/1/${odm_supportingDocument}`
    return (await api().delete(`${baseUrl}/process/${processId}/supporting/`)).data
}

export const getDocumentTypesManualFromJustifyTypeManual = async (justifyTypeId: any) => {
    return new Promise<DocumentTypeManual[]>(async (resolve, reject) => {
        const qc_header: any = {
            qc: {
                queryId: 'supportingdocument-required-document-type-manual',
                offset: 0,
                limit: 1000000,
            }
        }
        qc_header.qc.parameters = {
            supportingDocFamilyManual: justifyTypeId
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(settings.api_query_url, {
            headers: _headers
        }).then(res => {
            if (res.data.length) {
                resolve(res.data)
            } else {
                reject('error getting document types manual')
            }
        }).catch(err => reject(err))
    })
}
