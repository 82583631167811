
import {Options, Vue} from 'vue-class-component';
import {ref} from 'vue'
import store from '@/store';
import {formatWithMask} from "@/utils/configuration/formatters-config";

@Options({
  props: ['isOpen'],
  watch: {
  }
})
export default class DemandSimulationSummary extends Vue {
  isOpen: any ;

  get assets() {
    return store.state.demandModule.assetState.proposalAssets
  }

  get price() {
    return store.state.financingModule.proposalAssetTotalWithTax
  }

  get product() {
    return store.state.simulationModule.calculationRequest.financialProductUid.resourceUid
  }

  get financingType() {
    return store.state.simulationModule.isFinancingTypeTTC ? 'TCC' : 'HT'
  }

  get services() {
    return store.state.simulationModule.selectedServices
  }

  get monthlyPayment() {
    if(store.state.simulationModule.simulationBody?.monthlyPayment) {
      return store.state.simulationModule.simulationBody.monthlyPayment
    } else {
      return 0.0
    }
  }

  get firstPayment() {
    if(store.state.simulationModule.simulationBody?.firstPayment) {
      return store.state.simulationModule.simulationBody.firstPayment
    } else {
      return 0.0
    }
  }

  get duration() {
    if(store.state.simulationModule.simulationBody?.duration) {
      return store.state.simulationModule.simulationBody.duration
    } else {
      return 0
    }
  }

  get residualValue() {
    if(store.state.simulationModule.simulationBody?.residualValue) {
      return store.state.simulationModule.simulationBody.residualValue
    } else {
      return 0.0
    }
  }
  formatNumberWithMask(value: number, minDecimal: number){
    return formatWithMask(value, minDecimal)
  }
  toogleSummary() {
    store.state.middleOfficeModule.showSummary = !store.state.middleOfficeModule.showSummary
    store.state.demandModule.showSummary = !store.state.demandModule.showSummary
    store.state.demandModule.showRightSummary = !store.state.demandModule.showRightSummary
  }

}
