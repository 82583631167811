import {useApi} from "@/requests/useApi";
import {AssociatedPartyClass} from "@/store/services/financing/guarantee/domain/AssociatedPartyClass";

/**
 * Returns the last segment of the `objectType` property of the given `associatedParty` object.
 *
 * @param {IAssociatedParty} associatedParty - The associated party object.
 * @return {string | undefined} The last segment of the `objectType` property, or `undefined` if it doesn't exist.
 */
const getPath = (associatedParty: AssociatedPartyClass): string | undefined => {
    const lastDotIndex = associatedParty.objectType?.lastIndexOf('.');
    if (lastDotIndex) {
        return associatedParty.objectType?.substring(lastDotIndex + 1);
    }
    return undefined;
}

/**
 * Saves the associated party API.
 *
 * @param {IAssociatedParty} associatedParty - The associated party to be saved.
 * @return {Promise<AssociatedPartyClass>} The saved associated party.
 */
export const saveAssociatedPartyApi = async (associatedParty: AssociatedPartyClass): Promise<AssociatedPartyClass> => {
    const path = getPath(associatedParty);
    if (!path) {
        throw new Error('Entity without an objectType.');
    }

    const {response, error} = await useApi(associatedParty.systemUid, path).upsert(associatedParty);

    if ( error ) {
        throw new Error(`Error in saveAssociatedParty with payload ${JSON.stringify(associatedParty)}`);
    }

    return response as AssociatedPartyClass;
}
