import { Module } from "vuex";
import { PicklistState } from "@/types";
import {
  fetchReasons,
  getAssetsMock,
  getJobOccupationType,
  getNamesServices,
  getNationality,
  getPicklistInitialValues,
  getPostalCodes,
  getScaleClassification,
} from "@/store/services/picklist";
import Notify from 'quasar/src/plugins/Notify.js';;
import { translateComboBox, translatePicklist } from "@/utils";
import axios from 'axios';
import store from "..";

let cancelTokenSource = axios.CancelToken.source();


const picklistModule: Module<PicklistState, any> = {
  namespaced: true,
  state: {},
  getters: {
    financialProfileItems: (state) => state.financialProfile?.items,
    scaleStatusItems: (state) => {
      if (state.scaleStatus?.items) {
        return translateComboBox(state.scaleStatus.items, 'scales.picklist.status');
      }
    },
    scaleCategoryItems: (state) => state.scaleCategory?.items,
    assetCategoryItems: (state) => state.categoryAsset?.items,
    usageAssetItems: (state) => state.usageAsset?.items,
    colorsList: (state) => state.colors?.items,
    activityList: (state) => state.activity?.items,
    productTax: (state) => {
      if (state.productTax?.items) {
        return translateComboBox(state.productTax.items, 'picklists.productTaxOptions');
      }
    },
    productTaxServicesItems: (state) => state.productTaxServices?.items,
    scaleFrequencyItems: (state) => state.scalefrequency?.items,
    scaleRateBasisItems: (state) => state.scaleRateBasis?.items,
    scaleGracePeriodCalculationMethodItems: (state) => state.scaleGracePeriodCalculationMethod?.items,
    signatureMethodsItems: (state) => state.signatureMethods?.items,
    serviceTypeItems: (state) => {
      if (state.serviceType?.items) {
        return translateComboBox(state.serviceType.items, 'scales.picklist.serviceTypes');
      }
    },
    partyClassificationTypeItems: (state) => {
      if (state.partyClassificationType?.items) {
        return translateComboBox(state.partyClassificationType.items, 'picklists.partyClassificationType');
      }
    },
    productBasisItems: (state) => {
      if (state.productBasis?.items) {
        return translateComboBox(state.productBasis.items, 'scales.picklist.productBasis');
      }
    },
    accessoryCalculationMethodItems: (state) => {
      if (state.accessoryCalculationMethod?.items) {
        return translateComboBox(state.accessoryCalculationMethod.items, 'scales.picklist.accessoryCalculationMethod');
      }
    },
    accessoryPaymentDatesOptionItems: (state) => state.accessoryPaymentDatesOption?.items,
    assetTypeItems: (state) => state.assetTypeItems,
    
    assetStatutItems: (state) => state.assetStatut?.items,
    assetCatalogItems: (state) => state.assetCatalog?.items,
    garantyStatusItems: (state) => {
      if (state.garantyStatus?.items) {
        return translateComboBox(state.garantyStatus?.items, 'garanty.garantyFilter.picklist.status');
      }
    },
    garantyTypeItems: (state) => {
      if (state.garantyType?.items) {
        return translateComboBox(state.garantyType.items, 'garanty.garantyFilter.picklist.type');
      }
    },
    typeOutstandingItems: (state) => state.typeOutstanding?.items,
    financialInstitutionItems: (state) => state.financialInstitution?.items,
    underlyingTypeItems: (state) => state.underlyingType?.items,
    garantybasisItems: (state) => state.garantyBasis?.items,
    genderItems: (state) => state.gender?.items,
    propertyStatusItems: (state) => state.propertyStatus?.items,
    contractTypeItems: (state) => state.contractType?.items,
    productAvailabilityContextItems: (state) => state.productAvailabilityContext?.items,
    //third
    roleItems: (state) => {
      if (state.role?.items) {
        return translateComboBox(state.role.items, 'demand.party.roles');
      }
    },
    geographicZoneItems: (state) => {
      if (state.geographicZone?.items) {
        return translateComboBox(state.geographicZone.items, 'demand.party.geographicZone');

      }
    },
    facilityTypeItems: (state) => {
      if (state.facilityType?.items) {
        return translateComboBox(state.facilityType.items, 'middleOffice.party.organization.establishments.types');
      }
    },
    qualificationTypeItems: (state) => {
      if (state.qualificationType?.items) {
        return translateComboBox(state.qualificationType.items, 'middleOffice.party.organization.contact.fonction');
      }
    },
    natureItems: (state) => {
      if (state.nature?.items) {
        return translateComboBox(state.nature.items, 'incomeAndExpenses.type');
      }
    },
    statusMaritalItems: (state) => {
      if (state.statusMarital?.items) {
        return translateComboBox(state.statusMarital.items, 'demand.party.statusMarital');
      }
    },
    matrimonialRegimeItems: (state) => {
      if (state.matrimonialRegime?.items) {
        return translateComboBox(state.matrimonialRegime.items, 'demand.party.regimeMatrimonial');
      }
    },

    //demand
    demandStatusItems: (state) => {
      if (state.demandStatus?.items) {
        return translatePicklist(state.demandStatus.items, 'enum.offer.status');
      }
    },
    brandItems: (state) => state.brand?.items,

    clientTypeItems: (state) => state.clientType?.items,
    channelItems: (state) => state.channel?.items,
    moduleItems: (state) => state.module?.items,

    //order
    orderStatus: (state) => {
      if (state.orderStatus?.items) {
        return translateComboBox(state.orderStatus.items, 'order.picklist.status');
      }
    },

    orderItemStatus: (state) => state.orderItemStatus?.items,
    orderServiceTypeItems : (state) => state.orderServiceType?.items,

    //Decision
    decisionOpinionItems: (state) => state.decisionOpinion?.items,
    delegationLevelsItems: (state) => {
      if (state.delegationLevels?.items) {
        return translateComboBox(state.delegationLevels.items, 'decision.label');
      }
    },
    userSecurityGroupItems: (state) => state.userSecurityGroup?.items,
    userTeamItems: (state) => state.userTeam?.items,

    userNetwork: (state) => state.userNetwork?.items,
    userNetworkNode: (state) => state.userNetworkNode?.items,

  },
  actions: {
    initializePicklists({ commit }) {
        getPicklistInitialValues()
        .then((res) => {
          commit("setPicklistInitialValue", res);
        })
        .catch((e) => {  
          if(store.state.authModule?.user.loggedIn) {
            Notify.create({
              message: `Request returned an error \n ${e}`,
              color: "negative",
            });
          }        
        });
    
    },
    setNamesServices({ commit }, payload) {
      const { parentId } = payload;
      getNamesServices(parentId)
        .then((res) => {
          commit("setPickListNamesServicesValue", res);
        })
        .catch((e) => {
          Notify.create({
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyPostalCodeQuery({ commit }, payload) {
      const { country_code, query } = payload;
      getPostalCodes(country_code, query)
        .then((res) => {
          commit("setPickListPostalCodeValue", res);
        })
        .catch((e) => {
          Notify.create({
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyNationalityQuery({ commit }, payload) {
      const { query } = payload;
      getNationality(query)
        .then((res) => {
          commit("setPickListNationalityValue", res);
        })
        .catch((e) => {
          Notify.create({
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyJobOccupationQuery({ commit }, payload) {
      const { query } = payload;
      getJobOccupationType(query)
        .then((res) => {
          commit("setPickListJobOccupationValue", res);
        })
        .catch((e) => {
          Notify.create({
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    applyAssetsQuery({ commit }, payload) {
      const { query } = payload;
      getAssetsMock(query)
        .then((res) => {
          commit("setPickListAssetsValue", res);
        })
        .catch((e) => {
          Notify.create({
            message: `Request returned an error \n ${e}`,
            color: "negative",
          });
        });
    },
    fetchReasonsByStatus({ commit }, payload) {
      fetchReasons(payload)
        .then(response => commit('setReasons', response))
        .catch((e) => console.error(e));
    },
    populateScaleClassifications({ commit }, payload) {
      const { categoryId, callback } = payload;
      getScaleClassification(categoryId)
        .then(res => callback(res))
        .catch(err => console.error(err));
    }
  },
  mutations: {
    async setPicklistInitialValue(state, payload) {
      for (const property in payload) {
        if (payload[property]?.items && Array.isArray(payload[property].items)) {
          payload[property].items = await Promise.resolve(payload[property].items.sort((a: any, b: any) => a.label?.localeCompare(b?.label)));
        }
      }
      state.term = payload.term;
      state.paymentDate = payload.paymentDate;
      state.paymentFrequence = payload.paymentFrequence;
      state.baseCalcule = payload.baseCalcule;
      state.typeService = payload.typeService;
      state.demandStatus = payload.demandStatus;
      state.brand = payload.brand;
      state.range = payload.range;
      state.clientType = payload.clientType;
      state.current_status_code = payload.current_status_code;
      state.network = payload.network;
      state.networkNode = payload.networkNode;
      state.role = payload.role;
      state.garantyrole = payload.garantyrole;
      state.activitySector = payload.activitySector;
      state.legalCategory = payload.legalCategory;
      state.relations = payload.relations;
      state.countries = payload.countries;
      state.geographicZone = payload.geographicZone;
      state.cities = payload.cities;
      state.postalCodes = payload.postalCodes;
      state.statusMarital = payload.statusMarital;
      state.matrimonialRegime = payload.matrimonialRegime;
      state.addressType = payload.addressType;
      state.addressPreferenceType = payload.addressPreferenceType;
      state.titles = payload.titles;
      state.occupation = payload.occupation;
      state.periodRateOption = payload.periodRateOption;
      state.warrantMethod = payload.warrantMethod;
      state.contactType = payload.contactType;
      state.IdProof = payload.IdProof;
      state.facilityType = payload.facilityType;
      state.qualificationType = payload.qualificationType;
      state.relationType = payload.relationType;
      state.accountType = payload.accountType;
      state.accountStatus = payload.accountStatus;
      state.natureBalanceSheet = payload.natureBalanceSheet;
      state.roleStatus = payload.roleStatus;
      state.partyClass = payload.partyClass;
      state.nationality = payload.nationality;
      state.jobActivity = payload.jobActivity;
      state.jobPositionType = payload.jobPositionType;
      state.jobOccupationType = payload.jobOccupationType;
      state.nature = payload.nature;
      state.periodicity = payload.periodicity;
      state.partyRelationType = payload.partyRelationType;
      state.language = payload.language
      state.partyClassificationType = payload.partyClassificationType
      state.gender = payload.gender
      state.contractType = payload.contractType
      state.propertyStatus = payload.propertyStatus
      state.documentType = payload.documentType
      state.codeNaceOptions = payload.codeNaceOptions
      state.signatureMethods = payload.signatureMethods
      //Asset
      state.categoryAsset = payload.categoryAsset;
      state.usageAsset = payload.usageAsset;
      state.colors = payload.colors;
      state.activity = payload.activity;
      state.bodyAsset = payload.bodyAsset;
      state.brandAsset = payload.brandAsset;
      state.modelAsset = payload.modelAsset;
      state.finishingAsset = payload.finishingAsset;
      state.fuelTypeAsset = payload.fuelTypeAsset;
      state.emissionCO2Asset = payload.emissionCO2Asset;
      state.powerHPAsset = payload.powerHPAsset;
      state.assets = payload.assets;
      state.typeCredits = payload.typeCredits;
      state.organization = payload.organization;
      state.objects = payload.objects;

      state.channel = payload.channel;
      //Task
      state.actionType = payload.actionType;
      state.module = payload.module
      //decision
      state.decisionMotif = payload.decisionMotif;
      state.decisionreserve = payload.decisionreserve;
      state.decisionStatus = payload.decisionStatus;
      state.decisionOpinion = payload.decisionOpinion;
      // Accept Offer
      state.acceptDecisionStatus = payload.acceptDecisionStatus;

      state.financialProfile = payload.financialProfile;
      state.scaleStatus = payload.scaleStatus;
      state.scaleCategory = payload.scaleCategory;
      state.productTax = payload.productTax;
      state.productTaxServices = payload.productTaxServices;
      state.scalefrequency = payload.scalefrequency;
      state.scaleRateBasis = payload.scaleRateBasis;
      state.scaleGracePeriodCalculationMethod = payload.scaleGracePeriodCalculationMethod;
      state.serviceType = payload.serviceType;
      state.productBasis = payload.productBasis;
      state.accessoryCalculationMethod = payload.accessoryCalculationMethod;
      state.accessoryPaymentDatesOption = payload.accessoryPaymentDatesOption;
      state.assetTypeItems = payload.assetTypeItems;
      state.assetStatut = payload.assetStatut;
      state.assetCatalog = payload.assetCatalog;

      //Garanty
      state.garantyStatus = payload.garantyStatus;
      state.garantyType = payload.garantyType;
      state.typeOutstanding = payload.typeOutstanding;
      state.financialInstitution = payload.financialInstitution;
      state.underlyingType = payload.underlyingType
      state.garantyBasis = payload.garantyBasis;

      state.productAvailabilityContext = payload.productAvailabilityContext;

      // order
      state.orderStatus = payload.orderStatus;
      state.orderItemStatus = payload.orderItemStatus;
      state.orderTypeFinancing = payload.orderTypeFinancing;
      state.orderServiceType=payload.orderServiceType

      state.delegationLevels = payload.delegationLevels;

      state.justifyTypes = payload.justifyTypes;
      state.supportingDocumentType = payload.supportingDocumentType;
      // User
      state.userSecurityGroup = payload.userSecurityGroup;
      state.userTeam = payload.userTeam;
      state.userNetwork = payload.userNetwork;
      state.userNetworkNode = payload.userNetworkNode;

    },
    setPickListFinancingProduct(state, payload) {
      state.financingProduct = payload;
    },
    setPickListNamesServicesValue(state, payload) {
      state.namesServices = payload;
    },
    setPickListPostalCodeValue(state, payload) {
      state.postalCodes = payload;
    },
    setPickListNationalityValue(state, payload) {
      state.nationality = payload;
    },
    setPickListJobOccupationValue(state, payload) {
      state.jobOccupationType = payload;
    },
    setPickListAssetsValue(state, payload) {
      state.assets = payload;
    },
    setReasons(state, payload) {
      state.reasons = {
        items: []
      }
      state.reasons = {
        items: payload.map((item: any) => {
          return {
            value: item.resourceUid,
            label: item.resourceUid
          };
        })
      }
    }
  },
};

export default picklistModule;
