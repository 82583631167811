import {UniformOdmName} from "@/types";
import {getProductGuaranteeApi} from "@/store/services/financing/product";
import {UON} from "@/utils";
import {GuaranteeProductClass} from "@/store/services/financing/guarantee/domain/GuaranteeProductClass";
import {ProposalGuaranteeClass} from "@/store/services/financing/guarantee/domain/ProposalGuaranteeClass";
import {useApi} from "@/requests/useApi";

/**
 * Sets the Uniform Odm Name (UON) for a given resource UID.
 *
 * @param {string} resourceUid - The UID of the resource.
 * @return {UniformOdmName} The UON object with the system UID and the resource UID.
 */
const setUON = (resourceUid: string): UniformOdmName => {
    return {
        systemUid: UON.SystemUid.odmProduct,
        resourceUid: resourceUid
    };
}

/**
 * Sets the guarantee type based on the given object type.
 *
 * @param {string} objectType - the object type
 * @return {string} the product type with guarantee
 */
const setGuaranteeType = (objectType: string): string => {
    const type = objectType.substring(objectType.lastIndexOf('.') + 1);
    return 'product-' + type.charAt(0).toUpperCase() + type.slice(1);
}

/**
 * Returns the type of the path.
 *
 * @param {string} objectType - the full object type
 * @return {string} the type of the path
 */
const setPathType = (objectType: string): string => objectType.substring(objectType.lastIndexOf('.') + 1);

/**
 * Retrieves the product guarantee for a given resource.
 *
 * @param {string} resourceUid - The unique identifier of the resource.
 * @param {string} guaranteeType - The type of guarantee to retrieve.
 * @return {Promise<unknown>} A Promise that resolves to the product guarantee.
 */
export const getProductGuarantee = async (resourceUid: string, guaranteeType: string): Promise<GuaranteeProductClass> => {
    const productGuarantee = await getProductGuaranteeApi(setUON(resourceUid), guaranteeType) as GuaranteeProductClass;
    if ( productGuarantee ) {
        productGuarantee.guaranteeType = setGuaranteeType(productGuarantee.objectType as string);
    }
    return productGuarantee;
}

/**
 * Updates the proposal guarantee using the provided guarantee product class.
 *
 * @param {GuaranteeProductClass} proposalGuarantee - the guarantee product class to update
 * @return {Promise<void>} a promise that resolves with no value upon successful update
 */
export const updateProposalGuarantee = async (proposalGuarantee: ProposalGuaranteeClass): Promise<void> => {
    const {error} = await useApi(UON.SystemUid.odmOffer, setPathType(proposalGuarantee.objectType as string)).upsert(proposalGuarantee);
    if ( error ) {
        console.error(`Error in updateProposalGuarantee with payload ${JSON.stringify(proposalGuarantee)}`);
        throw new Error(error);
    }
}
