import {
    FinancialElement,
    GeneralData,
    QcHeader,
    ScaleManagement,
    ScaleSearch,
    ServiceBenefit,
    Criteria,
    SimpleFinancialOffer,
    ScaleStatus,
    ScaleBuisinessRuleSection
} from "@/types";
import { deepClone, formatDateRecursively, getEntity, getOperator, globalConfig, headers, Masks, operator, Paths, setOtherFilters, UON, useDate } from "@/utils";
import { api } from "@/auth/api";
import { settings } from "@/settings";
import {
    getProductFeature,
    saveFinancingProductPackApi,
    saveProductFeatureApi,
    saveSimpleFinancingOfferApi,
    saveSimplePackageFutureApi
} from "@/store/services/financing/product";
import { extract, rulePayload, setOrderedRule, setRule } from "@/commons/businessRules";
import { useApi } from "@/requests/useApi";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import store from "@/store";
import { getInOperator } from "../picklist";
import axios from "axios";
import { convertPaymentScheduleObjectToDownload } from "../financing/financingGlobalPaymentScheduleMapper";



export const outputFormConfigurationCommission: any = {
    calculationMethodOption: ["calculationMethodOption"],
    amountLabel: ["amountMin", "amountMax", "amount"],
    annualRateLabel: ["annualRateMin", "annualRateMax", "annualRate"],
    basis:["basis"]
}

export const outputFormConfiguration: any = {
    currency: ["currency"],
    firstPayment: ["minFirstPayment", "maxFirstPayment", "firstPayment"],
    downPayment: ["minDownPayment", "maxDownPayment", "downPayment"],
    gracePeriodTerms: ["minGracePeriodTerms", "maxGracePeriodTerms", "defaultGracePeriodTerms", "gracePeriodCalculationMethodOption"],
    duration: ["minDuration", "maxDuration", "numberOfPaymentTerms", "periodBetween2InstallmentsDuration", "periodBetween2InstallmentsUnit"],
    rate: ["basisRate", "minAnnualRate", "maxAnnualRate", "annualRate"],
    payment: ["outstandingBalanceBefore", "outstandingBalanceAfter", "lastPayment", "payment"]
}

export const commisionBuisinessRuleResults = {
    amountMax: "",
    amountMin: "",
    annualRate: "",
    annualRateMax: "",
    annualRateMin: "",
    basis: "",
    calculationMethodOption: "",
    currency: "",
    advancedConfigurationToggle: false
}
export const commissionApplicationRuleResults = {
    advancedConfigurationToggle: true,
    packageFeatureQualification: ""
}
const buisinessRuleResults = {
    minDuration: "",
    maxDuration: "",
    periodBetween2InstallmentsDuration: "",
    numberOfPaymentTerms: "",
    currency: "EUR",
    typeRate: "",
    annualRate: "",
    minAnnualRate: "",
    maxAnnualRate: "",
    basisRate: "",
    firstPayment: "",
    minFirstPayment: "",
    maxFirstPayment: "",
    downPayment: "",
    maxDownPayment: "",
    minDownPayment: "",
    lastPayment: "",
    outstandingBalanceBefore: "",
    outstandingBalanceAfter: "",
    payment: "",
    minNumberGracePeriodTerms: "",
    maxNumberGracePeriodTerms: "",
    defaultNumberGracePeriodTerms: "",
    minGracePeriodTerms: "",
    maxGracePeriodTerms: "",
    defaultGracePeriodTerms: "",
    advancedConfigurationToggle: false,
    firstPaymentToggle: false,
    downPaymentToggle: false,
    gracePeriodToggle: false
}

export const scaleFilter = async (filter: ScaleSearch) => {
    const operators = [];

    const qc_header: QcHeader = {
        qc: {
            queryId: 'scales-search-filter',
            offset: 0,
            limit: 100,
            parameters: {
                ...filter
            }
        }
    };

    const PRODUCT_FEATURE = "product_product_feature";
    const PRODUCT_CLASSIFICATION = "product_product_classification";

    if (filter?.name) {
        operators.push(
            operator(
                { val: filter.name, table: PRODUCT_FEATURE, dbField: "name" }
            ).LIKE
        );
    }

    if (filter?.reference) {
        operators.push(
            operator(
                { val: filter.reference, table: PRODUCT_FEATURE, dbField: "reference" }
            ).LIKE
        );
    }

    if (filter?.validity?.from && filter?.validity?.from !== '') {
        operators.push(
            operator(
                { val: filter.validity.from, table: PRODUCT_FEATURE, dbField: "validity_from" }
            ).GREATER_THAN_EQUAL
        );
    }

    if (filter?.validity?.until && filter?.validity?.until !== '') {
        operators.push(
            operator(
                { val: filter.validity.until, table: PRODUCT_FEATURE, dbField: "validity_until" }
            ).LESS_THAN_EQUAL
        );
    }

    if (filter?.financialProfile) {
        operators.push(
            operator(
                { val: filter.financialProfile, table: PRODUCT_FEATURE, dbField: "financial_profile_code" }
            ).EQUAL
        );
    }

    if (filter?.status) {
        operators.push(
            operator(
                { val: filter.status, table: PRODUCT_FEATURE, dbField: "status_code" }
            ).EQUAL);
    }

    /**
     * Checks if the array contains any value that is not undefined.
     *
     * @template T
     * @param {T[]} arr - The array to check.
     * @return {boolean} True if the array contains any non-undefined value, false otherwise.
     */
    const notOnlyUndefined = <T>(arr: T[]): boolean => arr.some(item => item !== undefined);

    /**
     * Removes any undefined values from an array.
     *
     * @template T
     * @param {T[]} arr - The array to remove undefined values from.
     * @return {T[]} The array without any undefined values.
     */
    const removeTheUndefined = <T>(arr: T[]): T[] => arr.filter(item => item !== undefined);

    if (filter?.category && filter.category.length > 0 && notOnlyUndefined(filter.category)) {
        operators.push(
            operator(
                {
                    val: removeTheUndefined(filter.category),
                    table: PRODUCT_CLASSIFICATION,
                    dbField: "category_code"
                }
            ).IN
        );
    }

    if (filter?.class && filter.class.length > 0 && notOnlyUndefined(filter.class)) {
        operators.push(
            operator(
                {
                    val: removeTheUndefined(filter.class),
                    table: PRODUCT_CLASSIFICATION,
                    dbField: "category_class_code"
                }
            ).IN
        );
    }

    setOtherFilters(operators, qc_header);

    return await api().get(settings.api_query_url, {
        headers: headers({
            qc: JSON.stringify(qc_header.qc)
        })
    });
}

export const scaleManagementHandle = async (scaleManagement: ScaleManagement) => {
    const { generalData, financialElement, services, commissions } = scaleManagement;

    if (generalData) {
        generalData.resourceUid = (
            await saveFinancingProductPack(deepClone(generalData), scaleManagement.daaqResourceUid)
        ).resourceUid;
    }

    if (financialElement) {

        financialElement.resourceUid = (
            await saveSimpleFinancingOffer(deepClone(financialElement), scaleManagement.daaqResourceUid)
        ).resourceUid;

        if (financialElement?.resourceUid && generalData?.resourceUid) {
            await saveSimplePackageFuture(generalData?.resourceUid, financialElement?.resourceUid);
        }
    }

    if (services && services.length > 0) {
        for (const service of services) {

            service.resourceUid = (await saveProductFeature(<ServiceBenefit>deepClone(service))).resourceUid;
            if (service.advancedPackageFeatureRule && service.advancedPackageFeatureRule.packageFeatureQualification != "") {
                // saveAdvancedPackageFuture
                await saveAdvancedProductFeature(<string>generalData?.resourceUid, <ServiceBenefit>service);
            } else {
                // save simpleProductFeature
                await saveSimplePackageFuture(<string>generalData?.resourceUid, <string>service.resourceUid, service?.required);
            }

        }
    }
    if (commissions && commissions.length > 0) {
        for (const commission of commissions) {

            commission.resourceUid = (await saveProductFeature(<ServiceBenefit>deepClone(commission))).resourceUid;
            if (commission.advancedPackageFeatureRule.packageFeatureQualification != "") {
                // saveAdvancedPackageFuture
                await saveAdvancedProductFeature(<string>generalData?.resourceUid, <ServiceBenefit>commission);
            } else {
                // save simpleProductFeature
                await saveSimplePackageFuture(<string>generalData?.resourceUid, <string>commission.resourceUid, commission?.required);
            }

        }
    }

    return scaleManagement;
}

const setResourceUid = (uid?: string) => uid && uid !== '' ? uid : undefined

const saveFinancingProductPack = async (generalDataState?: GeneralData, daaqResourceUid?: string): Promise<GeneralData> => {
    const generalData = generalDataState ? deepClone(generalDataState) : null
    let payload;
    if (generalData) {
        //FIXME remove this delete when the field is added in the API as mentioned in the US
        // regarding the ticket https://teamwill.atlassian.net/browse/TP-3030
        delete generalData.depot
        delete generalData.financialProfileOptions
        generalData.classifications?.forEach((classification: any) => {
            delete classification.isClone
            formatDateRecursively(classification.validity, Masks.dateMask_Sys, Masks.dateMask)
        })
        formatDateRecursively(generalData.validity, Masks.dateMask_Sys, Masks.dateMask)

        payload = {
            objectType: generalData.objectType,
            daaq: '/',
            resourceUid: setResourceUid(generalData.resourceUid),
            name: generalData.name,
            validity: generalData.validity,
            status: {
                resourceUid: generalData.status
            },
            qualifier: 'product-FinancingProductPack',
            reference: generalData.reference,
            financialProfile: {
                resourceUid: generalData.financialProfile
            },
            classifications: generalData.classifications

        }
    }
    return await saveFinancingProductPackApi(payload, daaqResourceUid);
}

const saveSimpleFinancingOffer = async (financialElement?: FinancialElement, daaqResourceUid?: string): Promise<FinancialElement> => {

    const rule = setRule(financialElement?.simpleFinancialOffer);

    const orderedRule: { [p: string]: string } = setOrderedRule(rule, simpleFinancingOfferKeys);

    if (financialElement?.simpleFinancialOffer?.advancedConfigurationToggle) {
        (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.FINANCIAL as any] as any).selectedBuisinessRule!.returnMultipleResults = true
    }

    const payload = {
        systemUid: financialElement?.systemUid,
        objectType: financialElement?.objectType,
        resourceUid: setResourceUid(financialElement?.resourceUid),
        name: financialElement?.name,
        status: financialElement?.status,
        tax: financialElement?.tax,
        validity: {
            from: useDate.sqlFormat(financialElement?.validity?.from),
            until: useDate.sqlFormat(financialElement?.validity?.until)
        },
        reference: financialElement?.reference,
        simpleFinancialOffer: {
            ruleEngineId: null,
            rule: financialElement?.simpleFinancialOffer?.advancedConfigurationToggle ? (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.FINANCIAL as any] as any).selectedBuisinessRule : rulePayload({ inputAttribute: 'numberOfPaymentTerms', inputDataType: 'NUMERIC', orderedRule }),
        }
    }
    return await saveSimpleFinancingOfferApi(payload, daaqResourceUid);
}

const saveProductFeature = async (service: ServiceBenefit) => {

    const rule = setRule(service.accessoryProductRule);

    const orderedRule: { [p: string]: string } = setOrderedRule(rule, productFeatureKeys);
    const payload: any = {
        systemUid: service?.systemUid,
        objectType: service?.objectType,
        resourceUid: setResourceUid(service?.resourceUid),
        name: service?.name,
        validity: {
            from: useDate.sqlFormat(service?.validity?.from),
            until: useDate.sqlFormat(service?.validity?.until)
        },
        status: {
            resourceUid: service?.status
        },
        accessoryPaymentDatesOption: {
            resourceUid: service?.paymentType
        },
        tax: service?.tax,
        reference: service?.reference,
        accessoryProductRule: {
            ruleEngineId: null,
            rule: service.accessoryProductRule.advancedConfigurationToggle ? service.rule : rulePayload({ inputAttribute: 'quoteinfo', inputDataType: 'TEXT', orderedRule }),
        }
    };

    if (service.objectType && !service.objectType.includes('originationcommissionproduct')) {
        payload.flagIncludingAPRC = service?.apcr
    }

    return await saveProductFeatureApi(payload, (service.serviceType as unknown as string).toLowerCase());
}

const saveAdvancedProductFeature = async (scaleUid: string, service: ServiceBenefit) => {


    const payload: any = {
        systemUid: service?.systemUid,
        objectType: "odm.product.packagefeature.advancedpackagefeature",
        resourceUid: undefined,
        businessData: null,
        daaq: "/",
        feature: {
            objectType: service.objectType,
            systemUid: "odm-product",
            resourceUid: service.resourceUid
        },
        includeInPackagePrice: false,
        packageFeatureType: null,
        advancedPackageFeatureRule: {
            ruleEngineId: null,
            rule: service.advancedPackageFeatureRule.rule,
        }
    };


    return await saveProductFeatureApi(payload, 'productpack/' + scaleUid + '/advancedpackagefeature');
}
export const saveSimplePackageFuture = async (parentUid: string, productFeatureUid: string, required?: boolean, productFeatureStatus?: string) => {
    const payload: any = {
        objectType: 'odm.product.packagefeature.simplepackagefeature',
        daaq: "/",
        feature: {
            resourceUid: productFeatureUid
        },
        includeInPackagePrice: false,
        packageFeatureType: null,
        required: required
    }
    if (productFeatureStatus) {
        payload.status = {
            resourceUid: productFeatureStatus
        }
    }
    return await saveSimplePackageFutureApi({
        payload,
        path: `productpack/${parentUid}/${Paths.simplePackageFeature}`,
        daaqResourceUid: payload.daaq
    });
}

export const simpleFinancingOfferKeys: string[] = [
    'minDuration',
    'maxDuration',
    'numberOfPaymentTerms',
    'typeRate',
    'basisRate',
    'minAnnualRate',
    'maxAnnualRate',
    'annualRate',
    'minFirstPayment',
    'maxFirstPayment',
    'firstPayment',
    'periodBetween2Installments',
    'periodBetween2InstallmentsDuration',
    'periodBetween2InstallmentsUnit',
    'currency',
    'minDownPayment',
    'maxDownPayment',
    'downPayment',
    'lastPayment',
    'outstandingBalanceBefore',
    'outstandingBalanceAfter',
    'payment',
    'minNumberGracePeriodTerms',
    'maxNumberGracePeriodTerms',
    'defaultNumberGracePeriodTerms',
    'minGracePeriodTerms',
    'maxGracePeriodTerms',
    'defaultGracePeriodTerms',
    'gracePeriodCalculationMethodOption'
];

export const productFeatureKeys: string[] = [
    'currency',
    'amount',
    'amountMin',
    'amountMax',
    'calculationMethodOption',
    'annualRate',
    'annualRateMin',
    'annualRateMax',
    'basis'
]

export async function getSelectedScale(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.financingProductPack).get(resourceUid)
    return response
}


export function buildScaleGeneralInfo(scaleGeneral: any) {
    scaleGeneral.status = scaleGeneral.status.resourceUid
    scaleGeneral.financialProfile = scaleGeneral.financialProfile.resourceUid
    scaleGeneral.depot = false
    if (!scaleGeneral.validity) {
        intiValidity(scaleGeneral)
    }
    scaleGeneral.classifications?.forEach((item: any) => {
        item.validity = {
            from: item.validity && item.validity.from ? item.validity.from : '',
            until: item.validity && item.validity.until ? item.validity.until : '',
        }
    })


    return scaleGeneral
}

export async function getFinancialElement(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.simpleFinancingOffer).get(resourceUid)
    // response.simpleFinancialOffer = extractSimpleFinancialOffer(response.simpleFinancialOffer.rule) // To be Fixed next scope
    return response
}


export async function buildScaleFinancialElement(scaleGeneral: any) {
    const financialElementFeatures = scaleGeneral.features.filter((product: any) => product.feature && product.feature.objectType && product.feature.objectType === 'odm.product.productfeature.financingoffer.simplefinancingoffer')
    const response = []
    for (const financialElementFeature of financialElementFeatures) {
        const financialElement = await getFinancialElement(financialElementFeature.feature.resourceUid)



        financialElement.status = financialElement.status ? financialElement.status.resourceUid : ""
        financialElement.tax = financialElement.tax ? financialElement.tax.resourceUid : ""
        if (!financialElement.validity) {
            intiValidity(financialElement)
        }

        if (financialElement.simpleFinancialOffer.rule) {
            if (financialElement.simpleFinancialOffer.rule.returnMultipleResults) {

                (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.FINANCIAL as any] as any).selectedBuisinessRule = financialElement.simpleFinancialOffer.rule
            }
            const extracRule = extract(financialElement.simpleFinancialOffer.rule, buisinessRuleResults);

            store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.FINANCIAL });

            financialElement.simpleFinancialOffer = extracRule[0] || {}
            if (financialElement.simpleFinancialOffer.minFirstPayment || financialElement.simpleFinancialOffer.maxFirstPayment || financialElement.simpleFinancialOfferfirstPayment) {
                financialElement.simpleFinancialOffer.firstPaymentToggle = true
            }
            if (financialElement.simpleFinancialOffer.minDownPayment || financialElement.simpleFinancialOffer.maxDownPayment || financialElement.simpleFinancialOffer.downPayment) {
                financialElement.simpleFinancialOffer.downPaymentToggle = true
            }
            if (financialElement.simpleFinancialOffer.minGracePeriodTerms || financialElement.simpleFinancialOffer.maxGracePeriodTerms || financialElement.simpleFinancialOffer.defaultGracePeriodTerms) {
                financialElement.simpleFinancialOffer.gracePeriodToggle = true
            }
            }
        response.push(financialElement)
    }


    return response[0]
}

function isEmpty(obj: any) {
    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }
    return true
}

export async function buildScaleCommision(scaleGeneral: any, ApplicationRule: any) {
    const commisionList = scaleGeneral.features.filter((product: any) => product.feature && product.feature.objectType && product.feature.objectType === 'odm.product.productfeature.product.accessoryproduct.originationcommissionproduct')
    const commissions = []
    for (const comm of commisionList) {
        const commission = await getEntity('odm-product', 'originationcommissionproduct', comm.feature.resourceUid)
        const commissionApplicationRule = ApplicationRule
        commission.status = commission.status.resourceUid
        commission.tax = commission.tax.resourceUid
        commission.apcr = false
        commission.required = comm.required
        if (!commission.validity) {
            intiValidity(commission)
        }
        if (commission.accessoryPaymentDatesOption) {
            commission.paymentType = commission.accessoryPaymentDatesOption.resourceUid
        }
        commission.serviceType = "originationcommissionproduct"

        if (commission.accessoryProductRule.rule) {
            commission.rule = commission.accessoryProductRule.rule
            const commissionRule = extractResults(commission.accessoryProductRule.rule)
            commission.accessoryProductRule = commissionRule


        }

        const commisionAdvancedpackageFeatures = commissionApplicationRule.features.filter((item: any) => item.objectType === "odm.product.packagefeature.advancedpackagefeature" && item.feature.objectType === "odm.product.productfeature.product.accessoryproduct.originationcommissionproduct" && item.feature.resourceUid === commission.resourceUid)
        if (commisionAdvancedpackageFeatures.length > 0) {
            commisionAdvancedpackageFeatures.forEach((elem: any) => {
                if (elem.advancedPackageFeatureRule.rule) {
                    const tempRule = deepClone(elem.advancedPackageFeatureRule.rule)
                    const commissionRule = extractApplicationRules(elem.advancedPackageFeatureRule.rule)
                    commission.advancedPackageFeatureRule = commissionRule
                    commission.advancedPackageFeatureRule.rule = tempRule

                }
            });


        }

        commissions.push(commission)
    }
    return commissions

}



export async function getSimpleserviceproduct(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.simpleserviceproduct).get(resourceUid)
    return response
}


export async function getPartyinsuranceproduct(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.partyinsuranceproduct).get(resourceUid)
    return response
}


export async function getAssetinsuranceproduct(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.assetinsuranceproduct).get(resourceUid)
    return response
}
export async function getmaintenanceproduct(resourceUid: string): Promise<any> {
    const { response } = await useApi(UON.SystemUid.odmProduct, Paths.maintenanceproduct).get(resourceUid)
    return response
}

export async function buildScalePrestationService(scaleGeneral: any, ApplicationRule: any) {

    const servicesList = scaleGeneral.features.filter((product: any) => product.feature && product.feature.objectType && (product.feature.objectType.includes('simpleserviceproduct') || product.feature.objectType.includes('partyinsuranceproduct') || product.feature.objectType.includes('assetinsuranceproduct') || product.feature.objectType.includes('maintenanceproduct')))
    const services = []
    for (const service of servicesList) {
        const type = service.feature.objectType
        let response: any
        switch (type) {
            case 'odm.product.productfeature.product.accessoryproduct.serviceproduct.maintenanceproduct':
                response = await getmaintenanceproduct(service.feature.resourceUid)
                break;
            case 'odm.product.productfeature.product.accessoryproduct.serviceproduct.insuranceproduct.assetinsuranceproduct':
                response = await getAssetinsuranceproduct(service.feature.resourceUid)
                break;

            case 'odm.product.productfeature.product.accessoryproduct.serviceproduct.simpleserviceproduct':
                response = await getSimpleserviceproduct(service.feature.resourceUid)
                break;

            case 'odm.product.productfeature.product.accessoryproduct.serviceproduct.insuranceproduct.partyinsuranceproduct':
                response = await getPartyinsuranceproduct(service.feature.resourceUid)
                break;
            default:
                throw new Error(`Unsupported product type: ${type}`);
        }
        const servicesApplicationRule = ApplicationRule
        response.status = response.status.resourceUid
        response.tax = response.tax.resourceUid
        response.apcr = false
        response.required = service.required
        if (!response.validity) {
            intiValidity(response)
        }
        if (response.accessoryPaymentDatesOption) {
            response.paymentType = response.accessoryPaymentDatesOption.resourceUid
        }
        response.serviceType = extractProductType(type)

        if (response.accessoryProductRule.rule) {
            response.rule = response.accessoryProductRule.rule
            const serviceRule = extractResults(response.accessoryProductRule.rule)
            response.accessoryProductRule = serviceRule
        }

        const servicesAdvancedpackageFeatures = servicesApplicationRule.features.filter((item: any) => item.objectType === "odm.product.packagefeature.advancedpackagefeature" && item.feature.objectType !== "odm.product.productfeature.product.accessoryproduct.originationcommissionproduct" && item.feature.resourceUid === response.resourceUid)
        if (servicesAdvancedpackageFeatures.length > 0) {
            servicesAdvancedpackageFeatures.forEach((elem: any) => {
                if (elem.advancedPackageFeatureRule.rule) {
                    const tempRule = deepClone(elem.advancedPackageFeatureRule.rule)
                    const serviceRule = extractApplicationRules(elem.advancedPackageFeatureRule.rule)
                    response.advancedPackageFeatureRule = serviceRule
                    response.advancedPackageFeatureRule.rule = tempRule

                }
            });


        }
        services.push(response)
    }
    return services
}

function extractProductType(type: string) {
    const match = type.match(/\.(\w+)$/);
    return match && match[1];
}

function intiValidity(body: any) {
    body.validity = {
        from: "",
        until: ""
    }
}


function extractResults(rule: any) {
    let results = {
        amountMax: "",
        amountMin: "",
        annualRate: "",
        annualRateMax: "",
        annualRateMin: "",
        basis: "",
        calculationMethodOption: "",
        currency: "",
        advancedConfigurationToggle: false
    }

    rule.childBusinessRuleItem.forEach((item: any) => {
        item.childBusinessRuleResult.forEach((businessRuleResult: any) => {
            const outputHeaderId = businessRuleResult.outputHeader.externalId;
            const result = businessRuleResult.result;
            const extractBusinessRuleResult = (match => match ? (match[1] === 'null' ? null : match[1]) : null)(result.match(/^return '([^']+)'$/));
            const extractBusinessRuleType = (match => match ? match[1] : null)(outputHeaderId.match(/^oh-(\w+)-businessRuleId$/));
            if (extractBusinessRuleType) {
                (results as any)[extractBusinessRuleType] = extractBusinessRuleResult ? extractBusinessRuleResult : ''
            }
        });
    });

    return results;
}

function extractApplicationRules(rule: any) {
    let results = {
        packageFeatureQualification: "",
        advancedConfigurationToggle: false
    }

    rule.childBusinessRuleItem.forEach((item: any) => {
        item.childBusinessRuleResult.forEach((businessRuleResult: any) => {
            const outputHeaderId = businessRuleResult.outputHeader.externalId;
            const result = businessRuleResult.result;
            const extractBusinessRuleResult = (match => match ? (match[1] === 'null' ? null : match[1]) : null)(result.match(/^return '([^']+)'$/));
            const extractBusinessRuleType = (match => match ? match[1] : null)(outputHeaderId.match(/^oh-(\w+)-businessRuleId$/));
            if (extractBusinessRuleType) {
                (results as any)[extractBusinessRuleType] = extractBusinessRuleResult ? extractBusinessRuleResult : ''
            }
        });
    });

    return results;
}
export function getListProductFeature(filter: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-feature-search',
            }
        }
        if (filter) {
            filter.date = new Date();
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.status_code != null && filter.status_code != '') {
            operators.push(getOperator("product_product_feature", "status_code", "LIKE", globalConfig.formatters.getLikeValue(filter.status_code), "status_code"))
        }
        if (filter && filter.reference != null && filter.reference != '') {
            operators.push(getOperator("lower(product_product_feature", "reference)", "LIKE", globalConfig.formatters.getLikeValue(filter.reference).toLowerCase(), "reference"))
        }
        if (filter && filter.name != null && filter.name != '') {
            operators.push(getOperator("lower(product_product_feature", "name)", "LIKE", globalConfig.formatters.getLikeValue(filter.name).toLowerCase(), "name"))
        }
        if (filter && filter.from && filter.from != '') {
            operators.push(getOperator("product_product_feature", "validity_from", "GREATER_THAN_EQUAL", (formatDateLocale(filter.from, 'DD/MM/YYYY', 'fr')).toString, "from"))
        }
        if (filter && filter.until && filter.until != '') {
            operators.push(getOperator("product_product_feature", "validity_until", "LESS_THAN_EQUAL", (formatDateLocale(filter.until, 'DD/MM/YYYY', 'fr')).toString, "until"))
        }
        if (filter && filter._when_created && filter._when_created != '') {
            operators.push(getOperator("DATE(product_product_feature", "_when_created)", "EQUAL", (formatDateLocale(filter.until, 'DD/MM/YYYY', 'fr')).toString, "_when_created"))
        }
        if (filter._discriminator && filter.discriminatorFilter === '' ) {
            operators.push(getInOperator("product_product_feature", "_discriminator", "IN", null, null, globalConfig.formatters.getInValue(filter._discriminator)))
        }
        if (filter._discriminator && filter.discriminatorFilter !== '') {
            operators.push(getOperator("lower(product_product_feature", "_discriminator)", "LIKE", globalConfig.formatters.getLikeValue(filter._discriminator).toLowerCase(), "_discriminator"))        
        }
        if (filter.status && filter.status.length > 0) {
            operators.push(getInOperator("product_product_feature", "status_code", "IN", null, null, globalConfig.formatters.getInValue(filter.status)))
        }


        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res)
        }).catch(err => reject(err));


    })
}



export const criteriasMock = async (): Promise<Criteria[]> => {

    return new Promise<any>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'criteria-target',
            }
        }

        qc_header.qc.otherOrders = null
        qc_header.qc.offset = 0
        qc_header.qc.limit = 50


        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {



            const response = res.data.map((resItem: any) => {
                return {
                    label: resItem.description,
                    dataType: resItem.datatype,
                    attribute: resItem.namecriteria,
                    parentEntity: 'proposalItems',
                    object: resItem.nametarget,
                    expression: resItem.expres,
                    dataTypeAttribute: resItem.datatypeattribute
                }
            })

            resolve(response)


        }).catch(err => reject(err));


    })
};
export async function SearchFinancialElement(resourceUid: string): Promise<any> {
    const financialElement = await getFinancialElement(resourceUid)
    financialElement.status = financialElement.status ? financialElement.status.resourceUid : ""
    financialElement.tax = financialElement.tax ? financialElement.tax.resourceUid : ""
    if (!financialElement.validity) {
        intiValidity(financialElement)
    }
    if (financialElement.simpleFinancialOffer.rule) {
        buisinessRuleResults.advancedConfigurationToggle = false
        if (financialElement.simpleFinancialOffer.rule.returnMultipleResults) {

            (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.FINANCIAL as any] as any).selectedBuisinessRule = financialElement.simpleFinancialOffer.rule
            buisinessRuleResults.advancedConfigurationToggle = true
        }
        const extracRule = extract(financialElement.simpleFinancialOffer.rule, buisinessRuleResults);
        store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.FINANCIAL });

        financialElement.simpleFinancialOffer = extracRule[0] || {}
        if (financialElement.simpleFinancialOffer.minFirstPayment || financialElement.simpleFinancialOffer.maxFirstPayment || financialElement.simpleFinancialOfferfirstPayment) {
            financialElement.simpleFinancialOffer.firstPaymentToggle = true
        }
        if (financialElement.simpleFinancialOffer.minDownPayment || financialElement.simpleFinancialOffer.maxDownPayment || financialElement.simpleFinancialOffer.downPayment) {
            financialElement.simpleFinancialOffer.downPaymentToggle = true
        }
        if (financialElement.simpleFinancialOffer.minGracePeriodTerms || financialElement.simpleFinancialOffer.maxGracePeriodTerms || financialElement.simpleFinancialOffer.defaultGracePeriodTerms) {
            financialElement.simpleFinancialOffer.gracePeriodToggle = true
        }
    }
    return financialElement
}
function extractSimpleFinancialOffer(rule: any) {
    let results = {
        minDuration: "",
        maxDuration: "",
        periodBetween2InstallmentsDuration: "",
        numberOfPaymentTerms: "",
        currency: "EUR",
        typeRate: "",
        annualRate: "",
        minAnnualRate: "",
        maxAnnualRate: "",
        basisRate: "",
        firstPayment: "",
        minFirstPayment: "",
        maxFirstPayment: "",
        downPayment: "",
        maxDownPayment: "",
        minDownPayment: "",
        lastPayment: "",
        outstandingBalanceBefore: "",
        outstandingBalanceAfter: "",
        payment: "",
        minNumberGracePeriodTerms: "",
        maxNumberGracePeriodTerms: "",
        defaultNumberGracePeriodTerms: "",
        minGracePeriodTerms: "",
        maxGracePeriodTerms: "",
        defaultGracePeriodTerms: "",
        advancedConfigurationToggle: false,
        firstPaymentToggle: false,
        downPaymentToggle: false,
        gracePeriodToggle: false
    };

    rule.childBusinessRuleItem.forEach((item: any) => {
        item.childBusinessRuleResult.forEach((businessRuleResult: any) => {
            const outputHeaderId = businessRuleResult.outputHeader.externalId;
            const result = businessRuleResult.result;

            const resultMatch = result.match(/^return '([^']*)'$/);
            const outputHeaderIdMatch = outputHeaderId.match(/^oh-(\w+)-businessRuleId$/);

            if (resultMatch && outputHeaderIdMatch) {
                const extractBusinessRuleResult = resultMatch[1] === 'null' ? null : resultMatch[1];
                const extractBusinessRuleType = outputHeaderIdMatch[1];

                (results as any)[extractBusinessRuleType] = extractBusinessRuleResult || '';
            }
        });
    });
    results.currency = "EUR"
    if (results.minFirstPayment || results.maxFirstPayment || results.firstPayment) {
        results.firstPaymentToggle = true
    }
    if (results.minDownPayment || results.maxDownPayment || results.downPayment) {
        results.downPaymentToggle = true
    }
    if (results.minNumberGracePeriodTerms || results.maxNumberGracePeriodTerms || results.defaultNumberGracePeriodTerms) {
        results.gracePeriodToggle = true
    }

    return results;
}
export async function getPrestationService(row: any): Promise<any> {
    let response
    switch (row._discriminator) {
        case 'product-MaintenanceProduct':
            response = await getmaintenanceproduct(row.id)
            break;
        case 'product-AssetInsuranceProduct':
            response = await getAssetinsuranceproduct(row.id)
            break;

        case 'product-SimpleServiceProduct':
            response = await getSimpleserviceproduct(row.id)
            break;

        case 'product-PartyInsuranceProduct':
            response = await getPartyinsuranceproduct(row.id)
            break;
        default:
            throw new Error(`Unsupported product type: ${row._discriminator}`);
    }
    response.status = response.status.resourceUid
    response.tax = response.tax.resourceUid
    response.apcr = false
    response.serviceType = extractProductType(response.objectType)
    if (!response.validity) {
        intiValidity(response)
    }
    if (response.accessoryPaymentDatesOption) {
        response.paymentType = response.accessoryPaymentDatesOption.resourceUid
    }
    if (response.accessoryProductRule.rule) {
        if (response.accessoryProductRule.rule.returnMultipleResults) {
            (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.SERVICE as any] as any).selectedBuisinessRule = response.accessoryProductRule.rule
        }
        const extracRule = extract(response.accessoryProductRule.rule, commisionBuisinessRuleResults);
        if (response.accessoryProductRule.rule.returnMultipleResults) {
            extracRule.forEach((element: any) => {
                element.advancedConfigurationToggle = true
            });
        }
        store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.SERVICE });
        response.accessoryProductRule = extracRule[0] || {}
        response.apcr = false
    }

    return response
}
export async function getCommission(row: any): Promise<any> {
    // const advancedCommission = await getEntity('odm-product', 'productpack', row.id + "/advancedpackagefeature")
    const commission = await getEntity('odm-product', 'originationcommissionproduct', row.id)
    // commission.advancedPackageFeatureRule = advancedCommission[0].advancedPackageFeatureRule
    commission.status = commission.status.resourceUid
    commission.tax = commission.tax.resourceUid
    if (!commission.validity) {
        intiValidity(commission)
    }
    if (commission.accessoryPaymentDatesOption) {
        commission.paymentType = commission.accessoryPaymentDatesOption.resourceUid
    }
    commission.serviceType = "originationcommissionproduct"

    if (commission.accessoryProductRule.rule) {
        if (commission.accessoryProductRule.rule.returnMultipleResults) {
            (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.COMISSION as any] as any).selectedBuisinessRule = commission.accessoryProductRule.rule
        }
        const extracRule = extract(commission.accessoryProductRule.rule, commisionBuisinessRuleResults);
        if (commission.accessoryProductRule.rule.returnMultipleResults) {
            extracRule.forEach((element: any) => {
                element.advancedConfigurationToggle = true
            });
        }
        store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.COMISSION });
        commission.accessoryProductRule = extracRule[0] || {}
        commission.apcr = false
    }
    return commission
}

export async function toggleScaleActive(isActive: boolean, scaleManagement: ScaleManagement): Promise<any> {
    const { generalData, financialElement, services, commissions } = scaleManagement
    if (generalData?.resourceUid) {
        const { resourceUid } = generalData
        const productFeature: any = await getProductFeature(resourceUid)

        productFeature.status.resourceUid = isActive ? ScaleStatus.USE : ScaleStatus.SUSPENDED
        formatDateRecursively(productFeature, 'DD/MM/YYYY', 'YYYY-MM-DD')
        const { objectType, systemUid, reference, name, validity, status } = productFeature
        await saveProductFeatureApi({ objectType, resourceUid, systemUid, reference, name, validity, status }, Paths.productFeature)
        if (isActive && financialElement) {
            await saveFinancialElement(financialElement)
            await activeScaleItems(resourceUid, services)
            await activeScaleItems(resourceUid, commissions)
        }
    }
}

async function saveFinancialElement(financialElement: any) {
    financialElement.status = ScaleStatus.USE
    await saveSimpleFinancingOffer(financialElement)
}

async function activeScaleItems(parentUid: string, items?: any) {
    for (const item of items || []) {
        await activeScaleItem(parentUid, item)
    }
}

async function activeScaleItem(parentUid: string, item?: any) {
    if (item && item.resourceUid) {
        item.status = ScaleStatus.USE
        return saveProductFeature(item)
    }
}

export const commissionsApplicationRulesKeys: string[] = [
    'packageFeatureQualification'
]

export const outputFormCommissionsApplicationRules: any = {
    packageFeatureQualification: ["packageFeatureQualification"]
}

export const servicesApplicationRulesKeys: string[] = [
    'packageFeatureQualification'
]

export const outputFormServicesApplicationRules: any = {
    packageFeatureQualification: ["packageFeatureQualification"]
}
