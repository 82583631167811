import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.divValueStyle)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.oneLine ? 'col-6' : 'col-12')
    }, [
      _createElementVNode("label", {
        class: _normalizeClass(["d-block font-semi-bold mb-0 font-size color font-weight", _ctx.labelStyle ? _ctx.labelStyle : 'text-muted'])
      }, _toDisplayString(_ctx.$t(_ctx.i18nKey)), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.oneLine ? 'col-6' : 'col-12')
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["font-weight margin-left color", _ctx.valueStyle])
      }, _toDisplayString(_ctx.value), 3)
    ], 2)
  ], 2))
}