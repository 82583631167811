import {Module} from "vuex";
import {FinancingState, FormState, RootState} from "@/types";
import store from "@/store";

const formModule: Module<FormState, RootState> = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    actions: {
        cleanState ({commit}) {
            commit('cleanSliders')
        }
    },
    mutations: {
        cleanSliders (state) {
            state.sliders = undefined;
        }
    },
}

export default formModule
