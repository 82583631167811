import {Module} from "vuex";
import {RootState, ServiceState} from "@/types";
import {calculatePartyInsurance, reevaluatePartyInsurance} from "@/store/services/financing/partyInsurance";
import Notify from 'quasar/src/plugins/Notify.js';;


const serviceModule: Module<ServiceState, RootState> = {
    namespaced: true,
    state: {
        services: [],
        calculationLoading: false,
        calculationPendent: false
    },
    getters: {
    },
    actions: {
        async refreshPartyInsurance ({commit}) {
            try {
                const calculatedPartyInsuranceList = await reevaluatePartyInsurance(this.state)
                commit('setCalculationPendent', true)
                await this.dispatch('financingModule/setPartyInsurance', { calculatedPartyInsuranceList: calculatedPartyInsuranceList || [] })
            } catch (err: any) {
                console.error(err)
                Notify.create({
                    color: "negative",
                    message: err.message || err,
                })
            }
        },
        async refreshPartyInsuranceForScales ({commit}, payload) {
            try {
                const {scale} = payload
                if(scale) {
                    const calculatedPartyInsuranceList = await reevaluatePartyInsurance(this.state, scale)
                    commit('setCalculationPendent', true)
                    await this.dispatch('financingModule/setPartyInsuranceScale', { scale, calculatedPartyInsuranceList: calculatedPartyInsuranceList || [] })
                }
            } catch (err: any) {
                console.error(err)
                Notify.create({
                    color: "negative",
                    message: err.message || err,
                })
            }
        },
        async refreshPartyInsuranceForAllScales ({dispatch}) {
            const scales = this.state.financingModule.scalesModule.scales || []
            scales.forEach((s:any) => dispatch('refreshPartyInsuranceForScales', {scale: s}))
        },
        async calculatePartyInsurance ({commit}) {
            commit('setCalculationLoading', true)
            const calculatedPartyInsuranceList = await calculatePartyInsurance(this.state)
            try {
                await this.dispatch('financingModule/setPartyInsurance', { calculatedPartyInsuranceList })
            } catch (err: any) {
                console.error(err)
                Notify.create({
                    color: "negative",
                    message: err.message || err,
                })
            } finally {
                commit('setCalculationLoading', false)
                commit('setCalculationPendent', false)
            }
        },
        setCalculationPendent ({commit}, payload) {
            const {calculationPendent} = payload
            if (calculationPendent === undefined) {
                throw new Error('calculationPendent is mandatory')
            }
            commit('setCalculationPendent', calculationPendent)
        },
        setCalculationLoading ({commit}, payload) {
            const {calculationLoading} = payload
            if (calculationLoading === undefined) {
                throw new Error('CalculationLoading is mandatory')
            }
            commit('setCalculationLoading', calculationLoading)
        },
        setServices ({commit}, payload) {
            const {services} = payload
            if (!services) {
                throw new Error('Services is mandatory')
            }
            commit('setServices', services)
        },
        refreshState ({commit}, payload) {
            const {services} = payload
            if (services) {
                commit('setServices', services)
            }
        },
        cleanState ({commit}) {
            commit('setServices', [])
        },
        setServiceSelected ({commit}, payload) {
            const {service} = payload
            if (service) {
                commit('setServiceSelected', service)
            }
        },
    },
    mutations: {
        setCalculationPendent(state, payload) {
            state.calculationPendent = payload
        },
        setCalculationLoading(state, payload) {
            state.calculationLoading = payload
        },
        setServices(state, payload) {
            state.services = payload
        },
        setServiceSelected (state, payload) {
            const service = state.services.filter((s: any) => s.resourceUid === payload.resourceUid)[0]
            if (service) {
                service.config.selected = payload.config.selected
            }
        }
    },
}

export default serviceModule
