
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import {NoticeTemplate, PackNoticeTemplateInfo} from "@/types";
import PrintDocumentListItem from "@/components/document/list/PrintDocumentListItem.vue";
import PrintDocumentActions from "@/components/document/list/PrintDocumentActions.vue";

@Options({
  components: {
    PrintDocumentActions,
    PrintDocumentListItem
  }
})
export default class PrintDocumentList extends Vue {

  checkAllModel = true;

  get templates() {
    return store.state.printDocumentModule.templates;
  }

  onSelectAllChange() {
    store.dispatch("printDocumentModule/setAllTemplateSelected", {
      selected: this.checkAllModel,
    });
  }

  handleCheckboxes(item: NoticeTemplate, action: boolean) {
    const templates = store.state.printDocumentModule.templates;
    this.handleCheckboxWithAction(item, action, templates);
    let findItem: any = templates.find((el) => !el.selected);
    let findPackItem: any = templates
        .filter((el) => el.isPack)
        .map((el: NoticeTemplate) =>
            el.items?.find((el: PackNoticeTemplateInfo) => !el.selected)
        );
    if (findItem || findPackItem[0]) return true;
  }

  private handleCheckboxWithAction(
      item: NoticeTemplate,
      action: boolean,
      templates: NoticeTemplate[]
  ) {
    if (action) {
      item.selected = true;
      templates.forEach((el: NoticeTemplate) => {
        if (el.fileName !== item.fileName) {
          el.selected = false;
          if (el.items && el.items.length > 0) {
            el.items.forEach((packItem) => (packItem.selected = false));
          }
        } else if (
            item.isPack &&
            item.items?.filter((item) => item.selected).length === 0
        ) {
          el.items?.forEach((packItem) => (packItem.selected = true));
        }
      });
    }
  }

  updateParentBoolean(item: NoticeTemplate, action: boolean) {
    if (this.handleCheckboxes(item, action)) {
      this.checkAllModel = false;
    } else this.checkAllModel = true;
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

  created() {
    if (!this.templates || !this.templates.length) {
      store.dispatch("printDocumentModule/getTemplates", {
        currentRoute: this.currentRoute
      });
    }
  }

}
