import {settings} from "@/settings";
import {api} from "@/auth/api";
import {Availability, ProductSelectionParameters, RootState, UniformOdmName} from "@/types";
import {formatDateRecursively, Paths, UON} from "@/utils";
import {evaluateTaxRate} from "./financingService";
import {setEvaluatedTaxRate} from "@/store/services/offerService";
import {useApi} from "@/requests/useApi";
import i18n from "@/i18n";

const basePath = `${settings.api_url}/odm-product/api/1/odm-product`

export async function evaluateAllPartyInsuranceAccessory (financingProductPackUid: string | undefined, body: ProductSelectionParameters, state?: RootState, taxValue?: number) {
    const result = (await api().post(`${basePath}/financingProductPack/${financingProductPackUid}/partyInsuranceAccessory/`,body)).data
    const res =   result.map((r: any) => {
        r.objectType = 'odm.simulation.simulationaccessoryitem.simulationinsuranceitem.simulationpartyinsuranceitem'
        const beneficiaries = r.beneficiaries.map((beneficiary: any) => {
            formatDateRecursively(beneficiary, 'DD/MM/YYYY', 'YYYY-MM-DD')
            beneficiary.objectType = 'odm.simulation.simulationaccessoryitem.simulationinsuranceitem.simulationpartyinsuranceitem.simulationpartyinsurancebeneficiary'
            beneficiary.coverage = beneficiary.coverage || 100
            return {
                _controls: {
                  selected: true
                },
                objectType: 'odm.simulation.simulationaccessoryitem.simulationinsuranceitem.simulationpartyinsuranceitem.simulationpartyinsurancebeneficiary',
                coverage: beneficiary.coverage || 100,
                systemUid: beneficiary.systemUid,
                partyUid: beneficiary.partyUid,
                partyName: beneficiary.partyName,
                partyBirthDate: beneficiary.partyBirthDate,
                partyRole: beneficiary.partyRole,
                amount: beneficiary.amount || {
                    amount: 0,
                    currency: 'EUR'
                },
                rate: beneficiary.annualRate,
                basis: beneficiary.basis,
            }
        })
        const resourceUid = r.resourceUid
        return {
            resourceUid,
            objectType: 'odm.simulation.simulationaccessoryitem.simulationinsuranceitem.simulationpartyinsuranceitem',
            productUid: resourceUid,
            currency: r.currency,
            amount: r.amount,
            flagIncludingAPRC: r.flagIncludingAPRC,
            quantity: r.quantity,
            paymentDatesOption: r.paymentDatesOption,
            calculationMethodOption: r.calculationMethodOption,
            annualRate: r.annualRate,
            basis: r.basis,
            basisValue: r.basisValue,
            beneficiaries,
            tax: r.tax,
            qualification:r.qualification,
            _controls: {
                selected: true
            },
        }
    })
    for (const item of res) {
        item.taxValue = state ? await evaluateTaxRate(item.tax, state) : taxValue
    }
    return res

}

export async function completeSimpleFinancing (productUid: string, body: ProductSelectionParameters ) {
    return (await api().post(`${basePath}/financingOffer/${productUid}/completion/`,body)).data
}

export const setTaxValue = async (taxRequest: unknown, taxCode?: string) => {
    if(taxCode){
        const taxRates = await setEvaluatedTaxRate(taxCode, taxRequest);
        return taxRates ? (taxRates.length > 0 ? taxRates[0]?.taxRate : null) : null;
    }
    else return null;
}

export const saveFinancingProductPackApi = async (payload: unknown, daaqResourceUid?: string) => {
    const systemUid = UON.SystemUid.odmProduct;
    const path = Paths.financingProductPack;
    const {response, error} = await useApi(systemUid, path).upsert(payload, daaqResourceUid);

    if ( error ) {
        if (error.response.data.detail.includes("ConstraintViolationException")) {
            let obj: any = payload;
            throw new Error(`${i18n.global.t('scales.error.constraintViolationExceptionScale')} ${obj.reference}`)
        }
        throw new Error(`Error in saveFinancingProductPack with payload ${JSON.stringify(payload)}`)
    }

    return response;
}

export const saveSimpleFinancingOfferApi = async (payload: unknown, daaqResourceUid?: string) => {
    const systemUid = UON.SystemUid.odmProduct;
    const path = Paths.simpleFinancingOffer;
    const {response, error} = await useApi(systemUid, path).upsert(payload, daaqResourceUid);

    if ( error ) {
        if (error.response.data.detail.includes("ConstraintViolationException")) {
            let obj: any = payload;
            throw new Error(`${i18n.global.t('scales.error.constraintViolationExceptionFinancing')} ${obj.reference}`)
        }
        throw new Error(`Error in saveSimpleFinancingOffer with payload ${JSON.stringify(payload)}`)
    }

    return response;
}

export const saveProductFeatureApi = async (payload: unknown, path: string, _daaq = '/') => {
    const systemUid = UON.SystemUid.odmProduct;
    const {response, error} = await useApi(systemUid, path).upsert(payload, _daaq);
    if ( error ) {
        if (error.response.data.detail.includes("ConstraintViolationException")) {
            let obj: any = payload;
            if (obj.objectType && obj.objectType.includes('originationcommissionproduct')) {
                throw new Error(`${i18n.global.t('scales.error.constraintViolationExceptionCommission')} ${obj.reference}`)
            }
            throw new Error(`${i18n.global.t('scales.error.constraintViolationExceptionServices')} ${obj.reference}`)
        }
        throw new Error(`Error in the ${path} with payload ${JSON.stringify(payload)}`)
    }

    return response;
}

export const saveSimplePackageFutureApi = async (options: {
    path: string,
    payload: {
        daaq: string;
        feature: { resourceUid: string | undefined };
        includeInPackagePrice: boolean;
        resourceUid?: string | undefined;
        packageFeatureType: null;
        required: boolean;
        objectType: string
    },
    daaqResourceUid: string
}) => {

    const {payload, path, daaqResourceUid} = options;

    const params = {systemUid: UON.SystemUid.odmProduct, path: path}

    const {response, error} = await useApi(params.systemUid, params.path).upsert(payload, daaqResourceUid);

    if ( error ) throw new Error(`Error in saveSimplePackageFuture with payload ${JSON.stringify(payload)}`)

    return response;
}

export const getFinancialProfile = async (resourceUid: string) => {
    const {response, error} = await useApi(UON.SystemUid.odmProduct, Paths.financialProfile).get(resourceUid);
    if ( error ) throw new Error(`Error in getFinancialProfile with resourceUid ${resourceUid}`)
    return response;
}

/**
 * Retrieves the product guarantee from the API.
 *
 * @param {UniformOdmName} uon - The uniform ODM name.
 * @param {string} path - The path to the API.
 * @return {Promise<unknown>} A promise that resolves to the product guarantee.
 */
export const getProductGuaranteeApi = async (uon: UniformOdmName, path: string): Promise<unknown> => {
    const { systemUid, resourceUid } = uon;

    try {
        return (await useApi(systemUid, path).get(resourceUid)).response;
    } catch (error) {
        console.error(error);
        throw new Error(`Error in getProductGuarantee with resourceUid ${resourceUid}`);
    }
}

export const getProductFeature = async (resourceUid: string): Promise<unknown> => {
        const {response, error} = await useApi(UON.SystemUid.odmProduct, Paths.productFeature).get(resourceUid);
        if ( error ) throw new Error(`Error in getProductFeature with resourceUid ${resourceUid}`)
        return response;
}

export const saveProductAvailability = async (payload: Availability, daaqResourceUid?: string) => {
    const systemUid = UON.SystemUid.odmProduct;
    const path = Paths.productAvailability;
    const {response, error} = await useApi(systemUid, path).upsert(payload, daaqResourceUid);

    if ( error ) throw new Error(`Error in saveProductAvailability with payload ${JSON.stringify(payload)}`)

    return response;
}

export const getCarProduct = async (resourceUid: string) => {
    const {response, error} = await useApi(UON.SystemUid.odmProduct, Paths.carProduct).get(resourceUid);
    if ( error ) throw new Error(`Error in getCarProduct with resourceUid ${resourceUid}`)
    return response;
}
