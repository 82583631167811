import {Organization, PartyContactMechanism, PartyRelation} from "@/types";
import {useApi} from "@/requests/useApi";
import {deepClone, Paths, UON, useDate} from "@/utils";
import {getPartyRelations} from "@/store/services/thirdService";
import {buildAddress} from "@/store/services/searchThird";
import i18n from "@/i18n";
import moment from "moment/moment";

export const deletePartyContactMechanisms = (contacts: Array<PartyContactMechanism>) => {
    if (contacts) {
        contacts.forEach(async (contact: PartyContactMechanism) => {
            if ( contact.resourceUid && contact.contactMechanism.resourceUid) {
                const {response} = await useApi(UON.SystemUid.odmParty, `${Paths.partyContactMechanism.path}`).remove(contact.resourceUid, contact.daaq);
                if ( response ) {
                    await useApi(UON.SystemUid.odmParty, `${Paths.partyContactMechanism.contactMechanism}`).remove(contact.contactMechanism.resourceUid, contact.contactMechanism.daaq);
                }
            }
        });
    }
}

export const buildManagerRelation = async (organization: any) => {
  if (organization &&  organization.resourceUid) {
    const {response} = await useApi(UON.SystemUid.odmParty, `${Paths.partyRelation}/${organization.resourceUid}/relationsAsc`).get();
    const validPartyRelations: Array<PartyRelation> = response.filter((el: PartyRelation) => {
        return el.validity && el.validity.from &&
            useDate.isPeriodValid(useDate.currDate, <string>useDate.sqlFormat(el.validity.from), <string>useDate.sqlFormat(el.validity.until || undefined))
    });

    const partyRelations = await useApi(UON.SystemUid.odmParty, `${Paths.partyRelation}/${organization.resourceUid}/relationsAsc`).get();

    let el: object = {
        objectType: "odm.party.partyrelation",
        relationType: {
            objectType: 'odm.party.relationtype',
            resourceUid: 'DIRG'
        },
        daaq: '/',
    };

    const thirdRelation = await getPartyRelations( organization.resourceUid);

    const load = () => {
        if ( thirdRelation ) {
            return thirdRelation;
        }
    }
    const loadRelation = () => {
        if ( validPartyRelations ) {
            return validPartyRelations;
        }
    }

    const loadAscRelations = () => {
        if ( partyRelations.response ) {
            return partyRelations.response;
        }
    }

    const upsert = () => {
        let validity = { from: moment(new Date()).format('YYYY-MM-DD') }
        let relationType = undefined
        if (organization.manager) {
          if (organization.manager.validity && organization.manager.validity.from && organization.manager.validity.from.trim().length != 0) {
              validity = { from: organization.manager.validity.from }
          }
            if (organization.manager.relationType && organization.manager.relationType.type) {
                relationType = { resourceUid: organization.manager?.relationType?.type?.value }
            }
        }

        if ( validPartyRelations && validPartyRelations.length > 0 ) {
            validity = { from: <string>useDate.sqlFormat(validPartyRelations[0].validity.from) }
            relationType = {resourceUid: validPartyRelations[0].relationType.resourceUid }
            el = {
                ...validPartyRelations[0],
                validity,
                relationType,
            }
        }
        else {
            el = {
                ...el,
                partyAsc: organization.manager?.resourceUid,
                partyDesc: organization.resourceUid,
                validity,
                relationType,
            }
        }
        return el;
    }
     return {load, upsert, loadRelation, loadAscRelations}
    }
}

export const buildPreferences = async (params: {preferences: any[], partyAddress: any, addressUID: string}) => {
    const listOfPrefences =deepClone(params.preferences)

    // for(let i=0;i<listOfPrefences.length;i++){
    //     if (listOfPrefences[i].resourceUid) {
    //         params.preferences.splice(
    //             params.preferences.findIndex(
    //               (pref) =>
    //               pref.resourceUid ===
    //               listOfPrefences[i].resourceUid
    //             ),
    //             1
    //           );
    //     await useApi(UON.SystemUid.odmParty, Paths.partyAddressPreference).remove(listOfPrefences[i].resourceUid);
    //     }
    // }

    const partyTypeList = params.partyAddress.type?.type?.value ?? params.partyAddress.type?.resourceUid
    if( partyTypeList && partyTypeList.length!=0){
        partyTypeList.forEach((value: any) => {
            if (value) {
                params.preferences.push({
                    preferenceType: {
                        objectType: "odm.party.preferencetype",
                        systemUid: "odm-party",
                        resourceUid: value
                    },
                    address: {
                        systemUid: UON.SystemUid.odmParty,
                        objectType: UON.ObjectType.address,
                        resourceUid: params.addressUID
                    },
                    objectType: "odm.party.partypreference.partyaddresspreference",
                    systemUid: "odm-party",
                    daaq: "/"
                });
            }
        });
    }


    return params.preferences;
}

export const buildPartyTypePreference = async (preferences: any, partyAddresses: any) => {
    const ListAddress: any[] = []
    for ( const pAddress of partyAddresses ) {
        const newPartyAddress = await buildAddress(pAddress)
        const preferenceTypes: any[] = [];
        preferences.forEach((preference: any) => {
            if ( pAddress.address?.resourceUid === preference.address?.resourceUid ) {
                preferenceTypes.push(preference.preferenceType.resourceUid);
            }
        })
        newPartyAddress.address = {
            ...newPartyAddress.address,
            type: {
                type: {
                    value: preferenceTypes
                }
            }
        }
        newPartyAddress.livingSituation={
            type:{
                value: newPartyAddress.livingSituation?.resourceUid,
                label: newPartyAddress.livingSituation?.resourceUid?.toString() !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + newPartyAddress.livingSituation?.resourceUid) : ''
            }
        };;
        ListAddress.push(newPartyAddress)
    }
    partyAddresses = ListAddress
}
