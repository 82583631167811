import {settings} from "@/settings";
import {api} from "@/auth/api";
import store from "@/store";
import {comMessageTemplate, MessageStatusEnum, Message, transferMsg} from "@/types";
import {UON} from "@/utils";
import {mockState} from "@/assets/mock/communication-state";
import { getGroupsByUserId } from "../task/taskService";

const systemUid = `odm-contactmechanism`;

const baseUrlGenerated = `${settings.api_url}/${systemUid}/api/1/event/${systemUid}`;

// mock for test
export function _getAllMessageByCurrentUser(): Promise<any> {
  return Promise.resolve(mockState.communicationModule._notifications);
}

/**
 * Retrieves all messages sent to the currently authenticated user.
 * @returns A promise that resolves to the API response data.
 */
export async function getAllMessageByCurrentUser(): Promise<any> {
  /**
   * API request parameters to retrieve messages.
   * @param messageRecipients - The list of recipients .
   * @param objectType - The recipient's object type that can be 'odm.user.leaseforgeuser' or 'odm.user.securitygroup'.
   * @param systemUid - The recipient's system UID 'odm-user'.
   * @param resourceUid - The recipient's resource UID or the group UID.
   */
  const userId = store.state.authModule?.user?.userId!; //*
  const myGroups = await getGroupsByUserId(userId);
  const ListGroups = myGroups.items.map((item: any) => item.label);
  const userCloned = {
    messageRecipients: [
      {
        recipient: {
        objectType: "odm.user.leaseforgeuser",
        systemUid: "odm-user",
        resourceUid: store.state.authModule?.user.userId
        }
    },
    ...ListGroups.map((resourceUid: string) => ({
      recipient: {
        objectType: "odm.user.securitygroup",
        systemUid: "odm-user",
        resourceUid: resourceUid
      }
    }))
  ]

    // userGroupRecipient: ListGroups.map((resourceUid: string) => ({
    //   objectType: "odm.user.user-UserGroup",
    //   systemUid: "odm-contactmechanism",
    //   resourceUid,
    // })),
  };
  /**
   * Send a POST request to the API to retrieve the messages.
   * @result The API response data.
   */
  return (await api().post(`${baseUrlGenerated}/messageevent/`, userCloned)).data;
}

/**
 * Retrieves message by ressourceUid.
 * @returns A promise that resolves to the API response data.
 */
export async function getMessageByRessourceUid(ressourceUid:any): Promise<any> {

  /**
   * Send a POST request to the API to retrieve the messages.
   * @result The API response data.
   */
  return (await api().get(`${baseUrlGenerated}/messageevent/${ressourceUid}/`)).data;
}


export const updateMessageStatus = async (message: comMessageTemplate,ActionType:string) => {
  /**
   * Send a PUT request to the API to update the message.
   * @result The API response data.
   */
  message.status = {
    ...message.status,
    resourceUid: ActionType === "ChangeStatus"?
        MessageStatusEnum.READ :
        MessageStatusEnum.CLOSED
  };
  message.clientApplication = {
    objectType: "odm.contactmechanism.contactmechanismclient",
    systemUid: UON.SystemUid.odmContactMechanism,
    resourceUid: "ApplicationClient"
  }
  return (await api().put(`${baseUrlGenerated}/messageevent/${message.resourceUid}/`, message)).data;
}


