import {
  Offer,
  Task,
  ActionType,
  TaskActionEvent,
  OfferStatus,
  ProposalStatus,
  Proposal,
  TaskExecuteAction,
  RootState,
  UniformOdmName, TaskSearchCriteria,
} from "@/types";
import {
  updateOfferStatus,
  updateProposalStatus,
} from "@/store/services/offerService";
import {
  executeTask,
  getTaskHistoryByEntity,
} from "@/store/services/task/workflow";
import moment from "moment";

export async function executeFinancingRequestOfferAction(
  userId: string,
  offer: Offer,
  task: Task,
  action: ActionType
) {
  const taskExecuteAction: TaskExecuteAction = {
    taskId: task.taskId || "",
    variables: { submitOffer: { value: true, type: "Boolean" } }
  };
  try {
    if (action === ActionType.VALIDATE) {
      const proposal = getProposalSelected(offer);
      await updateProposalStatus(
        proposal.resourceUid || "",
        ProposalStatus.DECISION
      );
      await updateOfferStatus(offer.resourceUid || "", OfferStatus.DECISION);
    } else if (action === ActionType.CANCEL) {
      taskExecuteAction.variables = { submitOffer: { value: false, type: "Boolean" } }
    }
    return executeTask(taskExecuteAction);
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

function getProposalSelected(offer: Offer): Proposal {
  return offer.proposals.filter((proposal) => proposal.flagSelected)[0];
}
export async function getFinancingRequestRows(state: RootState) {
  const offerId = state.taskModule.offer?.resourceUid || "";
  const taskSearchCriteria: TaskSearchCriteria = {
    entityId: offerId,
  };
  const result = await getTaskHistoryByEntity(taskSearchCriteria);
  return result.data.filter((res: any) => !!res.endDate).map((resTask: any) => {
    return {
      name: resTask.taskDefinition,
      user: resTask.assignee?.substring(0, resTask.assignee?.indexOf(' ## ')),
      date: moment(resTask.endDate).format('DD/MM/YYYY HH:mm'),
    };
  });
}
