import { Module } from "vuex";
import {
    Classifications,
    FinancialElement,
    GeneralData,
    ScaleBuisinessRuleSection,
    ScaleManagement,
    ScaleState, ScaleStatus,
    ServiceBenefit,
    ServiceBenefitWIndex
} from "@/types";
import {
    buildScaleCommision,
    buildScaleFinancialElement,
    buildScaleGeneralInfo,
    buildScalePrestationService,
    getFinancialElement,
    criteriasMock,
    getListProductFeature,
    getSelectedScale,
    scaleFilter,
    scaleManagementHandle,
    getPrestationService,
    getCommission,
    SearchFinancialElement,
    toggleScaleActive,
    commisionBuisinessRuleResults,
    commissionApplicationRuleResults
} from "@/store/services/configuration/ScaleService";
import { deepClone, getEntity } from "@/utils";
import { getFinancialProfile } from "@/store/services/financing/product";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import store from "@/store";
import { extract } from "@/commons/businessRules";
import router from "@/router";

const initServiceBenefit = (): ServiceBenefitWIndex => ({
    serviceType: undefined,
    name: undefined,
    reference: undefined,
    validity: {
        from: '',
        until: ''
    },
    status: 'DRAFT',
    apcr: false,
    required: true,
    accessoryProductRule: {
        advancedConfigurationToggle: false,
        currency: 'EUR',
    },
    advancedPackageFeatureRule: {
        advancedConfigurationToggle: true,
        packageFeatureQualification: ""
    }
})

const getStartScale = (): ScaleManagement => {
    return {
        systemUid: "odm-product",
        objectType: 'odm.product.packagefeature.simplepackagefeature',
        daaq: '',
        generalData: {
            systemUid: "odm-product",
            objectType: "odm.product.productfeature.product.productpack.financingproductpack",
            name: "",
            status: 'DRAFT',
            validity: {
                from: '',
                until: ''
            },
            financialProfile: undefined,
            depot: false,
            classifications: [],
            reference: ""
        },
        financialElement: {
            systemUid: "odm-product",
            objectType: "odm.product.productfeature.financingoffer.simplefinancingoffer",
            name: "",
            reference: "",
            validity: {
                from: '',
                until: ''
            },
            tax: undefined,
            status: 'DRAFT',
            simpleFinancialOffer: {
                minDuration: undefined,
                maxDuration: undefined,
                periodBetween2InstallmentsDuration: undefined,
                periodBetween2InstallmentsUnit: undefined,
                numberOfPaymentTerms: undefined,
                currency: 'EUR',
                annualRate: undefined,
                minAnnualRate: undefined,
                maxAnnualRate: undefined,
                basisRate: undefined,
                firstPayment: null,
                minFirstPayment: null,
                maxFirstPayment: null,
                downPayment: null,
                maxDownPayment: null,
                minDownPayment: null,
                minGracePeriodTerms: undefined,
                maxGracePeriodTerms: undefined,
                defaultGracePeriodTerms: undefined,
                gracePeriodCalculationMethodOption: null,
                lastPayment: undefined,
                outstandingBalanceBefore: undefined,
                outstandingBalanceAfter: undefined,
                payment: undefined,
                advancedConfigurationToggle: false,
                firstPaymentToggle: false,
                downPaymentToggle: false,
                gracePeriodToggle: false
            }
        },
        serviceBenefitSelected: initServiceBenefit(),
        services: [],
        commissionSelected: initServiceBenefit(),
        commissions: [],
    }
}

const scaleModule: Module<ScaleState, unknown> = {
    namespaced: true,
    state: {
        isLoading: false,
        selectedScale: {},
        openScale: false,
        scaleFilterRef: "",
        scaleManagement: getStartScale(),
        deletedClassificationUid: '',
        inputHeaders: [],
    },
    getters: {
        getScaleManagement: (state): ScaleManagement | undefined => {
            return state.scaleManagement;
        },
        getGeneralData: (state): GeneralData | undefined => {
            return state.scaleManagement?.generalData;
        },
        getFinancialElement: (state): FinancialElement | undefined => {
            return state.scaleManagement?.financialElement;
        },
        getServiceBenefitInit: (): ServiceBenefit | undefined => initServiceBenefit(),
        getServiceBenefitSelected: (state): ServiceBenefit | undefined => {
            return state.scaleManagement?.serviceBenefitSelected;
        },
        getCommissionsInit: (): ServiceBenefit | undefined => initServiceBenefit(),
        getCommissionSelected: (state): ServiceBenefit | undefined => {
            return state.scaleManagement?.commissionSelected;
        },

        getClassification: (state): Classifications[] | undefined => {
            return state.scaleManagement?.generalData?.classifications;
        },
        getScaleClassificationInit() {
            return {
                businessData: null,
                daaq: "/",
                validity: {
                    from: '',
                    until: ''
                },
                categoryClass: {
                    objectType: "odm.product.productcategoryclass",
                    systemUid: "odm-product",
                    resourceUid: ''
                },
                priority: null,
                category: {
                    objectType: "odm.product.productcategory",
                    systemUid: "odm-product",
                    resourceUid: ''
                },
                isClone: true
            }

        },
    },
    actions: {
        toggleScaleActive({ getters }, payload) {
            const { isActive, callback } = payload
            const scaleManagement = getters['getScaleManagement']
            if (scaleManagement) {
                toggleScaleActive(isActive, scaleManagement)
                    .then(response => {
                        if (callback) callback(response)
                    })
                    .catch(err => console.error(err));
            }
        },
        setFinancialElement({ commit }, payload) {
            commit("setFinancialElement", payload);
        },
        searchScale(context, payload) {
            const { filter, callback } = payload
            scaleFilter(filter)
                .then(response => {
                    callback(response)
                })
                .catch(err => console.error(err));
        },
        searchProductFeature({ commit }, payload) {
            const { filter, callback } = payload
            getListProductFeature(filter)
                .then(response => callback(response))
                .catch(err => console.error(err))
        },
        setInputHeaders({ commit }, payload) {
            commit("setInputHeaders", payload);
        },

        showScaleDetail({ commit }, payload) {
            commit("showScaleDetail");
            commit('startScale')
        },
        showScaleEdit({ commit }, payload) {
            commit("showScaleDetail");

        },
        saveScale(context) {
            if (context.state.scaleManagement) {
                context.commit("toggleIsLoading")
                scaleManagementHandle(context.state.scaleManagement)
                    .then(res => {
                        context.commit('setScale', res);
                        Notify.create({
                            message: i18n.global.t('main.scales.success', [res.generalData?.resourceUid]),
                            color: 'positive'
                        });
                        context.commit("toggleIsLoading")
                        store.dispatch("releaseLockState").then(() => {
                            store.dispatch("scaleModule/SetScaleFilterRef",res.generalData?.reference)
                            router.push("/scale/list")
                        })
                    })
                    .catch(e => {
                        Notify.create({
                            message: e.message,
                            color: 'negative'
                        });
                        context.commit("toggleIsLoading")

                    })
            }
        },
        executeSelectScale({ commit }, payload) {
            const { id, callback } = payload;
            getSelectedScale(id).then(async setScaleG => {
                const general = buildScaleGeneralInfo(setScaleG);
                commit('setScaleGeneralData', general);
                getFinancialProfile(general.financialProfile).then(res => commit('setFinancialProfileOptions', res))
                    .catch(e => console.error(e));
                if (setScaleG.features.length) {
                    const ApplicationRule = await getEntity('odm-product', 'financingproductpack', id)

                    const commission = await buildScaleCommision(setScaleG, ApplicationRule)
                    commit('setCommission', commission);
                    const financialElement = await buildScaleFinancialElement(setScaleG)
                    commit('setFinancialElement', financialElement)
                    const services = await buildScalePrestationService(setScaleG, ApplicationRule)
                    commit('setScalePrestationServices', services)

                }
                if (callback) {
                    callback(general)
                }
            }).catch(err => {
                Notify.create({
                    message: `Error: \n ${err}`,
                    color: 'negative'
                });
            })
        },
        searchFinancialProfile({ commit }, payload) {
            const { id, callback } = payload;
            SearchFinancialElement(id.id).then(async financialProfile => {

                if (callback) {
                    callback(financialProfile)
                }
            }).catch(err => {
                Notify.create({
                    message: `Error: \n ${err}`,
                    color: 'negative'
                });
            })

        },
        searchPrestationService({ commit }, payload) {
            const { id, callback } = payload;
            getPrestationService(id).then(async service => {

                if (callback) {
                    callback(service)
                }
            }).catch(err => {
                Notify.create({
                    message: `Error: \n ${err}`,
                    color: 'negative'
                });
            })

        },
        searchCommission({ commit }, payload) {
            const { id, callback } = payload;
            getCommission(id).then(async service => {

                if (callback) {
                    callback(service)
                }
            }).catch(err => {
                Notify.create({
                    message: `Error: \n ${err}`,
                    color: 'negative'
                });
            })

        },
        fetchFinancialProfile(context, payload) {
            const { resourceUid } = payload;
            getFinancialProfile(resourceUid).then(res => context.commit('setFinancialProfileOptions', res))
                .catch(e => console.error(e));
        },
        initService(context) {
            context.commit('initService')
        },
        editService(context, payload) {

            if (payload.rule) {
                if (payload.rule.returnMultipleResults) {

                    (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.SERVICE as any] as any).selectedBuisinessRule = payload.rule
                }
                const extracRule = extract(payload.rule, commisionBuisinessRuleResults);
                if (payload.rule.returnMultipleResults) {
                    extracRule.forEach((element: any) => {
                        element.advancedConfigurationToggle = true
                    });
                }
                store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.SERVICE });
                if (extracRule[0])
                    payload.accessoryProductRule = extracRule[0]
            }

            if (payload.advancedPackageFeatureRule && payload.advancedPackageFeatureRule.rule) {
                if (payload.advancedPackageFeatureRule.rule.returnMultipleResults) {
                    (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.SERVICE_APPLICATION_RULE as any] as any).selectedBuisinessRule = payload.advancedPackageFeatureRule.rule
                }
                const extracRule = extract(payload.advancedPackageFeatureRule.rule, commissionApplicationRuleResults);

                if (payload.advancedPackageFeatureRule.rule.returnMultipleResults) {
                    extracRule.forEach((element: any) => {
                        element.advancedConfigurationToggle = true
                    });
                }
                store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.SERVICE_APPLICATION_RULE });
                if (extracRule[0]) {
                    extracRule[0] = {
                        ...extracRule[0],
                        rule: payload.advancedPackageFeatureRule.rule
                    }
                    payload.advancedPackageFeatureRule = extracRule[0]
                }

            }
            else {
                payload.advancedPackageFeatureRule = {
                    advancedConfigurationToggle: true,
                    packageFeatureQualification: ""
                }
            }
            context.commit('editService', payload);
        },
        deleteService(context, rowIndex) {
            context.commit('deleteService', rowIndex);
        },
        initCommission(context) {
            context.commit('initCommission')
        },
        editCommission(context, payload) {


            if (payload.rule) {
                if (payload.rule.returnMultipleResults) {

                    (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.COMISSION as any] as any).selectedBuisinessRule = payload.rule
                }
                const extracRule = extract(payload.rule, commisionBuisinessRuleResults);
                if (payload.rule.returnMultipleResults) {
                    extracRule.forEach((element: any) => {
                        element.advancedConfigurationToggle = true
                    });
                }
                store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.COMISSION });
                if (extracRule[0])
                    payload.accessoryProductRule = extracRule[0]
            }
            if (payload.advancedPackageFeatureRule && payload.advancedPackageFeatureRule.rule) {
                if (payload.advancedPackageFeatureRule.rule.returnMultipleResults) {
                    (store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.COMMISSION_APPLICATION_RULE as any] as any).selectedBuisinessRule = payload.advancedPackageFeatureRule.rule
                }
                const extracRule = extract(payload.advancedPackageFeatureRule.rule, commissionApplicationRuleResults);

                if (payload.advancedPackageFeatureRule.rule.returnMultipleResults) {
                    extracRule.forEach((element: any) => {
                        element.advancedConfigurationToggle = true
                    });
                }
                store.dispatch("queryBuilderModule/setbrResluts", { extracRule: extracRule, currentSection: ScaleBuisinessRuleSection.COMMISSION_APPLICATION_RULE });
                if (extracRule[0]) {
                    extracRule[0] = {
                        ...extracRule[0],
                        rule: payload.advancedPackageFeatureRule.rule
                    }
                    payload.advancedPackageFeatureRule = extracRule[0]
                }

            }
            else {
                payload.advancedPackageFeatureRule = {
                    advancedConfigurationToggle: true,
                    packageFeatureQualification: ""
                }
            }
            context.commit('editCommission', payload);
        },
        deleteCommission(context, rowIndex) {
            context.commit('deleteCommission', rowIndex);
        },
        setProductObjectType(context, objectType) {
            let objType = 'odm.product.productfeature.product.accessoryproduct.serviceproduct.';

            if (objectType.includes('insuranceproduct')) {
                objType = objType.concat('insuranceproduct.');
            }
            if (objectType.includes('originationcommissionproduct')) {
                context.commit('setProductObjectTypeCommission', 'odm.product.productfeature.product.accessoryproduct.originationcommissionproduct')
            } else {
                context.commit('setProductObjectType', objType.concat(objectType))
            }


        },
        resetScales({ commit }, payload) {
            commit("startScale", payload)
        },
        SetScaleFilterRef({ commit }, payload) {
            commit('SetScaleFilterRef', payload);
        }
    },
    mutations: {
        setInputHeaders(state, payload) {
            state.inputHeaders?.push(payload)
        },

        toggleIsLoading(state) {
            state.isLoading = !state.isLoading
        },
        setScalePrestationServices(state, payload) {
            state.scaleManagement.services = payload
        },

        showScaleDetail(state) {
            state.openScale = !state.openScale;
        },
        setScale(state, payload) {
            const { generalData, financialElement } = state.scaleManagement;
            generalData ? generalData.resourceUid = payload.generalData.resourceUid : null;
            financialElement ? financialElement.resourceUid = payload.financialElement.resourceUid : null;
        },

        startScale(state) {
            state.scaleManagement = getStartScale()
        },
        setScaleGeneralData(state, payload) {
            state.scaleManagement.generalData = payload
        },
        setFinancialProfileOptions(state: ScaleState, payload) {
            if (state.scaleManagement?.generalData) {
                state.scaleManagement.generalData.financialProfileOptions = payload
            }
        },
        initService(state) {
            state.scaleManagement.serviceBenefitSelected = store.getters["scaleModule/getServiceBenefitInit"]
        },
        editService(state, payload) {
            state.scaleManagement.serviceBenefitSelected = payload;
        },
        deleteService(state, payload) {
            state.scaleManagement?.services?.splice(payload, 1);
        },
        initCommission(state) {
            state.scaleManagement.commissionSelected = store.getters["scaleModule/getServiceBenefitInit"]
        },
        editCommission(state, payload) {
            state.scaleManagement.commissionSelected = payload;
        },
        deleteCommission(state, payload) {
            state.scaleManagement?.commissions?.splice(payload, 1);
        },
        setCommission(state, payload) {
            state.scaleManagement.commissions = payload;
        },
        setFinancialElement(state, payload) {
            state.scaleManagement.financialElement = payload
            if ((store.state.queryBuilderModule.queryBuilder[ScaleBuisinessRuleSection.FINANCIAL as any] as any).selectedBuisinessRule?.returnMultipleResults) {
                store.state.scaleModule.scaleManagement.financialElement!.simpleFinancialOffer!.advancedConfigurationToggle = true
            }

        },
        setProductObjectType(state, objectType) {
            if (state.scaleManagement.serviceBenefitSelected) {
                state.scaleManagement.serviceBenefitSelected.objectType = objectType;
            }
        },
        setProductObjectTypeCommission(state, objectType) {
            if (state.scaleManagement.commissionSelected) {
                state.scaleManagement.commissionSelected.objectType = objectType;
            }
        },
        SetScaleFilterRef(state, payload) {
            state.scaleFilterRef = payload;
        },

    }
}

export default scaleModule;
