
import {Options, Vue} from "vue-class-component";
import store from "@/store";

@Options({

})
export default class PrintDocumentActions extends Vue {

  isDownloadDocumentLoading = false
  isSendDocumentLoading = false


  get selectedTemplates() {
    return (store.state.printDocumentModule.templates || [] ).filter((item) => item.selected)
  }

  get templates() {
    return store.state.printDocumentModule.templates
  }

  onDownloadDocumentClick() {
    this.isDownloadDocumentLoading = true
    this.isSendDocumentLoading = false
    store.dispatch('printDocumentModule/generateDocument', {
      currentRoute: this.currentRoute,
      templatesSelected: this.selectedTemplates,
      callback: this.downloadFile
    })
  }

  downloadFile(){
    store.dispatch("printDocumentModule/downloadFile", {
      request: {
        resourceUid: this.fileId
      },
      callback: this.downloadDocumentResponse,
    });
  }

  downloadDocumentResponse(response: any) {
    if (response) {
      const { content } = response;
      const linkSource = `data:${response.format};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = response.originalFileName;
      downloadLink.click();
      this.viewDocumentResponse()
    }
  }

  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }

  viewDocumentResponse() {
    this.isDownloadDocumentLoading = false
    this.isSendDocumentLoading = false
  }

  async onSendDocumentClick() {

    this.isSendDocumentLoading = true
    await store.dispatch("printDocumentModule/sendTemplate", {
      template: this.selectedTemplates,
    }).then(() => {
      this.isSendDocumentLoading = true
    });
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

}
