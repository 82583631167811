
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["value", "valueStyle", "oneLine", "divValueStyle"],
})
export default class OnlyValue extends Vue {
  value?: string;
  valueStyle?: any;
  divValueStyle?: any;
  oneLine?: boolean;
}
