export const vehiculeMock = async () => {
  return await [
    {
      images: [
        {
          name: "Mountains",
          Url: "https://i.pinimg.com/474x/ee/b8/ca/eeb8ca5de4898df8c24a67de9f5be737.jpg",
        },
        {
          name: "Parallax1",
          Url: "https://i.pinimg.com/736x/ac/26/28/ac26286e0bec441599185ce65aa1a277.jpg",
        },
        { name: "Parallax2", Url: "https://cdn.quasar.dev/img/parallax2.jpg" },
        { name: "Quasar", Url: "https://cdn.quasar.dev/img/quasar.jpg" },
      ],
      energy: "Diesel",
      power: "130CH",
      gearbox: "Automatique",
      emission_CO2: "-",
      color: "Gris",
    },
  ];
};

export const tabAssetMock = async () => {
  return await [
    {
      vehiculeTab: {
        vehicule: "Car",
        price: 25000,
        discount: 1000,
        priceHT: 24000,
        priceTTC: 28000,
        refComm: "N° 789",
      },
      optionTab: [
        {
          option: "GPS",
          price: 500,
          discount: 5520,
          priceHT: 500,
          priceTTC: 600,
          refComm: "N° 54321",
        },
        {
          option: "GPS",
          price: 1200,
          discount: 200,
          priceHT: 1000,
          priceTTC: 1300,
          refComm: "N° 67890",
        },
        {
          option: "teamWill",
          price: 1200,
          discount: 200,
          priceHT: 1000,
          priceTTC: 1300,
          refComm: "N° 5558",
        },
      ],
      accessoryTab: [
        {
          accessoire: "Roof Rack",
          price: 300,
          discount: 50,
          priceHT: 250,
          priceTTC: 300,
          refComm: "N° 543",
        },
      ],
      feesTab: [
        {
          fees: "Roof Rack",
          price: 300,
          discount: 50,
          priceHT: 250,
          priceTTC: 300,
          refComm: "N° 554321",
        },
      ],
    },
  ];
};

export const commandeFournisseurMock = () => {
  return [
    {
      article: "Plancher bois antidepapnt",
      price: 500,
      discount: 5520,
      priceHT: 500,
      priceTTC: 600,
      refComm: "N° 663890",
    },
    {
      article: "Banquette multiflex",
      price: 1200,
      discount: 200,
      priceHT: 1000,
      priceTTC: 1300,
      refComm: "N° 467890",
    },
  ];
};
