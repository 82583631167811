import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/avatar.png'


const _hoisted_1 = { class: "theme-1" }
const _hoisted_2 = { class: "tw-user-profile-card card border-0" }
const _hoisted_3 = { class: "align-items-center row card-header bg-white p-3" }
const _hoisted_4 = { class: "tw-user-avatar" }
const _hoisted_5 = ["alt"]
const _hoisted_6 = { class: "pl-2" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12 font-size-12 font-semi-bold" }
const _hoisted_9 = { class: "col-12 small text-success" }
const _hoisted_10 = { class: "card-body p-3" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-lg-12 col-md-12 col-sm-12 text-language text-left pb-2" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-lg-12 col-md-12 col-sm-12" }
const _hoisted_15 = { class: "align-items-center d-flex justify-between" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "mb-0 font-size-11 font-weight-normal" }
const _hoisted_18 = { class: "flex-grow-1 pl-3" }
const _hoisted_19 = {
  class: "ellipsis",
  style: {"max-width":"175px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_label = _resolveComponent("q-label")!
  const _component_country_flag = _resolveComponent("country-flag")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              class: "tw-user-avatar-img-small",
              alt: _ctx.user.username,
              src: _imports_0
            }, null, 8, _hoisted_5)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.user.username), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.user.loggedIn == true ? "Active" : "Inactive"), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_q_label, {
              class: "btn-language cursor-pointer",
              onClick: _ctx.UserPreference
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("userPreference.userPreference")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("userPreference.language")), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_q_select, {
                  class: "mb-0 w-100 bg-light",
                  modelValue: _ctx.user.selectedLanguage,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.selectedLanguage) = $event)),
                    _cache[1] || (_cache[1] = ($event: any) => (_ctx.i18nTranslations($event)))
                  ],
                  options: _ctx.languageOptions,
                  "hide-dropdown-icon": "",
                  outlined: "",
                  dense: ""
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_country_flag, {
                      country: _ctx.treatLocale(_ctx.user.selectedLanguage.id).country,
                      size: "small",
                      class: "rounded-10"
                    }, null, 8, ["country"])
                  ]),
                  "selected-item": _withCtx((scope) => [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(scope.opt.label), 1)
                  ]),
                  option: _withCtx((scope) => [
                    _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, { class: "font-size-11" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.opt.label), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_country_flag, {
                              country: _ctx.treatLocale(scope.opt.id).country,
                              size: "small",
                              class: "rounded-10"
                            }, null, 8, ["country"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}