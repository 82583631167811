import store from "@/store";
import { garantyList, getSelectedGaranty, SaveGaranty, buildSelectedGaranty, getSelectedParty, deleteSelectedGaranty } from "@/store/services/configuration/GarantyService";

import { GarantyState } from "@/types";
import { deepClone } from "@/utils";

import { Module } from "vuex";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
const garantyModule: Module<GarantyState, any> = {
  namespaced: true,
  state: {
    openGaranty: false,
    selectedguarantor: {},
    mode: "add",
    selectedGaranty: {},
    selectedgarantydetails: {
      objectType: "",
      businessData: null,
      daaq: "/",
      qualifier: null,
      feature: [],
      commissionFlag: false,
    },
    reloadListGaranty: "",
    validField: true,
    saveAuthorized: false,
    isSaving: false
  },
  getters: {
    getGarantyselectedInit() {
      return {
        resourceUid: "",
        reference: "",
        name: "",
        validity: {
          from: "",
          until: "",
        },
        status: {
          type: {
            value: ''
          }
        },
        qualifier: null,
        tax: "",
        feature: [],
        classifications: [],
        role: {
          type: {
            value: ''
          }
        },
        partyReference: {},
        evaluationMethod: {},
        basis: {
          type: {
            value: ''
          }
        },
        amount:
        {
          "amount": "",
          "currency": "EUR"
        },
        rate: "",
        underlyingType: {
          type: {
            value: ''
          }
        },
        commissionFlag: false,

        garantietype: {
          type: {
            value: ''
          }
        },
      }

    }
  },
  actions: {

    isSaving({ commit }) {
      commit("isSaving");
    },




    /**
     * Action that change the value of ValidField
     *
     * @param {object} context - The context object for ValidField.
     * @param {object} payload - The payload object with new value of ValidField.
     * @return {void}
     */
    changeValidField({ commit }, payload) {
      commit("changeValidField", payload);
    },
    /**
     * Action that change the value of SaveAuthorized
     *
     * @param {object} context - The context object for SaveAuthorized.
     * @param {object} payload - The payload object with new value of SaveAuthorized.
     * @return {void}
     */
    changeSaveAuthorized({ commit }, payload) {
      commit("changeSaveAuthorized", payload);
    },
    /**
     * Searches for garanty based on the given filter.
     * @param {object} context - The context object.
     * @param {object} payload - The payload object containing the filter and a callback function.
     * @return {void}
     */
    searchGaranty(context, payload) {
      // Gets the callback function from the payload.
      const { filter, callback } = payload;
      // Calls the `garantyList()` function to fetch the list of garanty.
      garantyList(filter)
        .then((response) => {
          // Passes the response to the callback function.
          callback(response);

        })
        .catch((err) => {
          // Logs the error to the console.
          console.error(err);
        });
    },
    /**
     * Action that Resets the selected guaranty details in the module's state.
     *
     * @param {object} context - The context object for the guaranty module.
     * @param {object} payload - The payload object with data to reset the selected guaranty details.
     * @return {void}
     */
    resetGaranty({ commit }, payload) {
      commit("resetGaranty", payload);
    },
    /**
 * Action that Sets the party reference in the selected guaranty details in the module's state.
 *
 * @param {object} context - The context object for the guaranty module.
 * @param {object} payload - The payload object with party reference information.
 * @return {void}
 */
    setGarantyPartyReference({ commit }, payload) {
      commit("setGarantyPartyReference", payload);
    },
    /**
     * Action that Saves or updates the guaranty details in the module's state and triggers an API call.
     *
     * @param {object} context - The context object for the guaranty module.
     * @param {object} payload - The payload object containing the guaranty details.
     * @return {Promise<void>}
     */
    async saveGaranty({ commit }, payload) {
      commit('isSaving');
      const { garanty } = payload;
      const garantyDetails = deepClone(garanty);
      let notifyType = "";
      if (!garantyDetails.resourceUid) {
        notifyType = "Addsuccess"
      }
      else {
        notifyType = "Editsuccess"
      }
      await SaveGaranty(garantyDetails).then((res: any) => {
        Notify.create({
          message: i18n.global.t('garanty.dialog.' + notifyType),
          color: 'positive'
        })

        commit('isSaving');
      }).catch(err => {
        // Logs the error to the console.
        console.error(err);
        Notify.create({
          message: i18n.global.t('garanty.dialog.error'),
          color: 'negative'
        })
      })
    },
    /**
     * Actio that Fetches and updates the selected guaranty details based on the provided ID and discriminator.
     *
     * @param {object} context - The context object for the guaranty module.
     * @param {object} payload - The payload object with ID and discriminator for fetching the guaranty.
     * @return {void}
     */
    executeSelectGaranty({ commit }, payload) {
      const { id, discriminator } = payload;

      getSelectedGaranty(id, discriminator).then(async garanty => {
        if (garanty.partyReference?.resourceUid) {
          getSelectedParty(garanty.partyReference.resourceUid).then(async party => {
            const partytype = party.objectType.substring(party.objectType.lastIndexOf('.') + 1);
            const partyTypeId = partytype === "frenchperson" ? "party-FrenchPerson" : partytype === "frenchorganization" ? "party-FrenchOrganization" : '';
            const id = party.resourceUid;
            const type = {
              id: partyTypeId,
              label: partyTypeId?.toString() !== "" ? i18n.global.t("enum.party.type." + partyTypeId) : ''
            };
            let person: any;
            let organization: any;

            if (partytype === "frenchperson") {
              person = party;
              commit('setSelectedGarantor', { id: id, type: type, person: person });
            } else {
              {
                organization = party
                commit('setSelectedGarantor', { id: id, type: type, organization: organization });
              }
            }
          })
        }
        const garantySelected = await buildSelectedGaranty(garanty)
        commit('saveGaranty', garantySelected);

      }).catch(err => {
        Notify.create({
          message: `Error searching Good \n ${err}`,
          color: 'negative'
        });
      })
    },
    /**
   * Action that Deletes the selected guaranty based on the provided ID and discriminator and triggers an API call.
   *
   * @param {object} context - The context object for the guaranty module.
   * @param {object} payload - The payload object with ID and discriminator for deleting the guaranty.
   * @return {Promise<void>}
   */
    async executeDeleteGaranty({ commit }, payload) {
      const { id, discriminator } = payload;
      await deleteSelectedGaranty(id, discriminator).then((res: any) => {

        commit('reloadListGranty', res.message.split(':')[2])
        Notify.create({
          message: i18n.global.t('garanty.dialog.Removesuccess'),
          color: 'positive'
        })



      }).catch(err => {
        console.error(err);
        Notify.create({
          message: i18n.global.t('garanty.dialog.error'),
          color: 'negative'
        })
      })
    },
    /**
     * Action that Sets the selected guarantor in the module's state.
     *
     * @param {object} context - The context object for the guaranty module.
     * @param {object} payload - The payload object containing the selected guarantor data.
     * @return {void}
     */
    setSelectedGarantor({ commit }, payload) {
      commit("setSelectedGarantor", payload);
    },
    /**
   * Action that Resets the selected guarantor in the module's state.
   *
   * @param {object} context - The context object for the guaranty module.
   * @param {object} payload - The payload object with data to reset the selected guarantor.
   * @return {void}
   */
    resetselectedguarantor({ commit }, payload) {
      commit("resetSelectedGarantor", payload);
    },
    /**
   * Action that Toggles the visibility of the guaranty details panel in the module's state.
   *
   * @param {object} context - The context object for the guaranty module.
   * @return {void}
   */
    showGarantyDetail({ commit }) {
      commit("showGarantyDetail");
    },
    setMode({ commit }, payload) {
      commit("setMode", payload);
    },
  },


  mutations: {
    isSaving(state) {
      state.isSaving = !state.isSaving
    },
    reloadListGranty(state, payload) {
      state.reloadListGaranty = payload;
    },
    /**
     * Resets the selected guaranty details to a provided payload.
     *
     * @param {Object} state - The current state of the guaranty module.
     * @param {Object} payload - The data used to reset the selected guaranty details.
     */
    resetGaranty(state, payload) {
      if (state.selectedgarantydetails)
        state.selectedgarantydetails = payload;
    },
    /**
 * Sets the party reference in the selected guaranty details.
 *
 * @param {Object} state - The current state of the guaranty module.
 * @param {Object} payload - The data containing party reference information.
 */
    setGarantyPartyReference(state, payload) {
      if (state.selectedgarantydetails) {
        state.selectedgarantydetails.partyReference = { objectType: payload.objectType, systemUid: payload.systemUid, resourceUid: payload.resourceUid }
      }
    },
    /**
 * Saves the guaranty details in the module's state.
 *
 * @param {Object} state - The current state of the guaranty module.
 * @param {Object} payload - The data representing the guaranty details to be saved.
 */
    saveGaranty(state, payload) {
      state.selectedgarantydetails = payload
    },
    /**
 * Resets the selected guarantor to a provided payload.
 *
 * @param {Object} state - The current state of the guaranty module.
 * @param {Object} payload - The data used to reset the selected guarantor.
 */
    resetSelectedGarantor(state, payload) {
      if (state.selectedguarantor) {
        state.selectedguarantor = payload
      }
    },
    /**
 * Sets the selected guarantor to a provided payload.
 *
 * @param {Object} state - The current state of the guaranty module.
 * @param {Object} payload - The data representing the selected guarantor.
 */
    setSelectedGarantor(state, payload) {
      if (state.selectedguarantor) {
        state.selectedguarantor = payload
      }
    },
    /**
 * Toggles the visibility of the guaranty details panel.
 *
 * @param {Object} state - The current state of the guaranty module.
 */
    showGarantyDetail(state) {
      state.openGaranty = !state.openGaranty;
    },
    setMode(state, payload) {
      state.mode = payload
    },
    /**
    * Change the value of ValidField
    *
    * @param {Object} state - The current state of ValidField.
    * @param {Object} payload - The new value of ValidField.
    */
    changeValidField(state, payload) {
      state.validField = payload
    },
    /**
    * Change the value of SaveAuthorized
    *
    * @param {Object} state - The current state of SaveAuthorized.
    * @param {Object} payload - The new value of SaveAuthorized.
    */
    changeSaveAuthorized(state, payload) {
      state.saveAuthorized = payload
    }
  },
};
export default garantyModule;
