import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row flex-column" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_SummaryFinancingPartyInsurance = _resolveComponent("SummaryFinancingPartyInsurance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_expansion_item, {
          "default-opened": "",
          "expand-icon-toggle": "",
          "header-class": "align-items-center bg-secondary justify-content-between mb-3 mt-3 "
        }, {
          header: _withCtx(() => [
            _createVNode(_component_q_item_section, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.$t("summaryPanel.financing.service.label").toLowerCase())), 1)
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-4 mb-2",
                  key: index
                }, [
                  _createVNode(_component_LabelAndValue, {
                    "i18n-key": item.label,
                    value: item.value,
                    oneLine: true,
                    "label-style": _ctx.labelStyleItem,
                    "div-value-style": _ctx.divValueStyle
                  }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyInsurance, (item, index) => {
      return (_openBlock(), _createBlock(_component_SummaryFinancingPartyInsurance, {
        key: index,
        data: item
      }, null, 8, ["data"]))
    }), 128))
  ], 64))
}