import axios from "axios";
import {settings} from "@/settings";
import {formatDateRecursively, removeAttributesRecursively, SESSION_TOKEN_KEY} from "@/utils";
import router from "@/router";

export function api(daaqResourceUid?: string, anonymousToken?: string) {

    const token = anonymousToken ? `Bearer ${anonymousToken}` : `Bearer ${sessionStorage.getItem(SESSION_TOKEN_KEY)}`;

    const api = axios.create({
        baseURL: settings.api_url,
        headers: {
            Authorization: token
        }
    });

    api.interceptors.request.use(config => {
        if (config.headers && sessionStorage.getItem(SESSION_TOKEN_KEY)) {
            config.headers['Authorization'] = token;
            if (config.method === 'post') {
                config.headers['daaqResourceUid'] = daaqResourceUid || ''
            }
            removeAttributesRecursively(config.data);
            return config;
        }
        else {
            router.push('/login')
        }
    });

    api.interceptors.response.use(
        (response) => {
            formatDateRecursively(response.data);
            return response;
        },
        (error) => {
            if (error.response?.status === 401) {
                return router.push('/login')
            }
            return Promise.reject(error);
        });

    return api;
}
