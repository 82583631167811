import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'

import i18n from '../i18n'
import Home from "@/components/Home.vue";
import { tokenIsValid } from "@/utils";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/auth/Login.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/components/auth/Registration.vue')
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/components/auth/ForgotPassword.vue')
    },
    {
        path: '/demand/list',
        name: 'demand-list',
        component: () => import('@/views/demand/DemandViewList.vue')
    },
    {
        path: '/demand/edit/:id?',
        name: 'demand-edit',
        component: () => import('@/views/demand/DemandViewEdit.vue')
    },
    {
        path: '/order/edit/:id?',
        name: 'order-edit',
        component: () => import('@/views/order/OrderViewEdit.vue')
    },
    {
        path: '/mo/demand/detail/:id?',
        name: 'mo-demand-detail',
        component: () => import('@/views/middle-office/MiddleOfficeView.vue')
    },
    {
        path: "/order",
        name: "order",
        component: () => import("@/views/order/OrderViewList.vue")
    },
    {
        path: '/third/list',
        name: 'third-list',
        component: () => import('@/views/party/ThirdViewList.vue')
    },
    {
        path: '/third/edit',
        name: 'third-edit',
        component: () => import('@/views/party/ThirdViewEdit.vue')
    },
    {
        path: '/task/list',
        name: 'task-list',
        component: () => import('@/views/tasks/TaskViewList.vue')
    },
    {
        path: '/task/detail',
        name: 'TaskDetail',
        component: () => import('@/views/tasks/TaskView.vue')
    },
    {
        path: '/scale/list',
        name: 'scale-list',
        component: () => import('@/views/configuration/scales/ScaleViewList.vue')
    },
    {
        path: '/scale/edit',
        name: 'scale-edit',
        component: () => import('@/views/configuration/scales/ScaleViewDetails.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/user-preference/ProfileView.vue')
    }, {
        path: '/garanty/list',
        name: 'garanty-list',
        component: () => import('@/views/configuration/garanty/GuaranteeView.vue')
    },
    {
        path: '/good/list',
        name: 'asset-list',
        component: () => import('@/views/configuration/goods/GoodViewList.vue')
    },
    {
        path: '/userAdmin/list',
        name: 'user-list',
        component: () => import('@/views/configuration/users/UserViewList.vue')
    },
    {
        path: '/userAdmin/edit',
        name: 'user-edit',
        component: () => import('@/views/configuration/users/UserViewDetails.vue'),
    },
    {
        path: '/good/edit',
        name: 'good-edit',
        component: () => import('@/views/configuration/goods/GoodViewDetails.vue')
    }, {
        path: '/rule',
        name: 'rule',
        component: () => import('@/views/rule.vue')
    },
    {
        path: '/availability/list',
        name: 'availability-list',
        component: () => import('@/views/configuration/availabilities/AvailabilityViewList.vue')
    },
    {
        path: '/opinion/detail',
        name: 'OpinionDetail',
        component: () => import('@/views/tasks/OpinionView.vue')
    },
    {
        path: '/analysis/detail',
        name: 'AnalysisDetail',
        component: () => import('@/views/middle-office/AnalysisView.vue')
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        component: () => import('@/components/auth/ChangePassword.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.path.includes('task')) {
        store.dispatch('taskModule/setFilterByOfferReference', '')
    }
    if (to.path.search("demand") > 0) {
        store.dispatch("module", "demand");
    } else if (to.path.search("third") > 0) {
        store.dispatch("module", "third");
    } else if (to.path.search("task") > 0) {
        store.dispatch("module", "task");
    }
    else if (to.path.search("good") > 0) {
        store.dispatch("module", "good");
    }
    else if (to.path.search("dossier") > 0) {
        store.dispatch("module", "dossier");
    } else {
        store.dispatch("module", "home");
    }
    if ((to.path !== '/' &&
        to.path !== '/login' &&
        to.path !== '/signup' &&
        to.path !== '/forgotPassword' ) && !tokenIsValid()) {
        next('/login')
    }
    else if ((to.path === '/login' ||
        to.path === '/signup' ||
        to.path === '/forgotPassword') && tokenIsValid()) {
        next('/home')
    }
     else if (to.path === '/changePassword' && tokenIsValid()) {
        store.commit('authModule/setChangePassWordIn',false)
        next()
    }
    else {
        const lock = store.state.lockState
        if (lock) {
            store.state.authModule!.user.loggedIn = true
            store.dispatch("showConfirmationDialog", {
                message: i18n.global.t('main.confirmationDialog.message'),
                target: to.fullPath
            });
            next(false)
        } else {
            next()
        }
    }
})

export default router
