import { createI18n } from 'vue-i18n'
import fr from './locales/fr-FR.json'


export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "fr", //default Local to be updated by the response from API
  fallbackLocale: "fr", // used when key missing from json related to selected local
  messages: { fr } //object for fallback "static JSON file into locales repo"
})
