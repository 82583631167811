
import messageCom from "@/components/communication/MessageCom.vue";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapState } from "vuex";
import { User, Group } from "@/types";
import store from "@/store";
import i18n from "@/i18n";

@Options({
  name: "Communication",
  components: {
    messageCom,
  },
  computed: {
    ...mapState("messagingModule", ["subjects", "recipients"]),
  },
  created() {
    /**
     * fetching subjects and recipients
     * @returns {Array} List of options for selection.
     */
    this.onloadData();
    /**
     * loading PARTY's/THIRD's references
     */
    this.onSearch();
  },
  methods: {
    ...mapActions("messagingModule", [
      "fetchSubjects",
      "fetchReferences",
      "fetchCReference",
      "fetchRecipients",
    ]),

    toggleChatForm() {
      this.$refs.messageCom.showComDialog = true;
      this.$refs.messageCom.subjects = this.subjects.map(
        (subject: any) => subject.id
      );
      this.$refs.messageCom.references = this.optionsReference;
      this.fetchRecipients();
      this.$refs.messageCom.recipients = this.recipients;
    },
    /**
     *This function retrieves the context selected in a given temporary option.
     @param {any} tempOption - The temporary option to retrieve the selected context.
     @returns {void}
     */
    getSelectedContext(tempOption: any) {
      this.reference = tempOption.filter(
        (item: any) =>
          (item.reference || item.id) ===
          (store.state.demandModule.offer?.reference ||
            store.state.thirdModule.third?.id?.resourceUid)
      );
    },
    /**
     * This function updates the list of reference options for the request.
     @param {any} res - The result to use to update the list of reference options for the request.
     @returns {void}
     */

    updateResultRows(res: any) {
      this.optionsRefrence = res;
    },

    /**
     * load comboBox lists (subjects, refs, recipients)
     */
    onloadData() {
      this.fetchSubjects();
      this.fetchRecipients();
    },

    /**
     This function triggers a search to retrieve game and query results.
     @returns {void}
     */
    onSearch() {
      store.dispatch("demandModule/searchOfferParty", {
        callback: this.updateResultRows,
        offerFilter: {},
        filterParty: {},
      });
    },
  },
})
export default class MessageCom extends Vue {
  optionsRefrence = [];

  /**
   * Gets the list of options for a selection.
   @returns {Array} List of options for selection.
   */
  get optionsReference() {
    // merge the options of the list of party references and the list of application references
    return this.optionsRefrence;
  }
}
