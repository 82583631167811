import { Module } from "vuex";
import { RootState } from "@/types";
import { downloadFile, saveFile } from "@/store/services/offerService";
import {
    getOfferDocument,
    getProcess,
    getRequiredDocument, getRequiredDocumentModifyCondition,
    qualifyFile,
    receiveFile,
    sendToControl
} from "../../../services/task/actions/uploadDocumentService";
import i18n from "@/i18n";
import { deleteProcessFile } from "@/store/services/task/actions/validateDocumentService";
import store from "@/store";

const errorTreatment = (options: Options) => {
    const {
        error,
        action = ''
    } = options;
    console.error(`${action} action => ${error.response?.data?.title ?? ''} \n ${error}`)
}

interface Options {
    error: any,
    action: string
}

const uploadDocumentModule: Module<any, RootState> = {
    namespaced: true,
    state: {},
    getters: {

    },
    actions: {

        uploadTempFiles: ({ commit }, paylod) => {
            commit('setFiles', paylod)
        },
        uploadFile: ({ commit }, payload) => {
            const { request, document, processid, documentType, callback , documentReceivedFileID } = payload
            saveFile(request).then(response => {
                if (callback) {
                    getProcess(processid).then((res: any) => {
                        res.gatheringProcessItem.forEach((element: any) => {
                            const file = {
                                fileReference: {
                                    objectType: response.objectType,
                                    systemUid: response.systemUid,
                                    resourceUid: response.resourceUid
                                },
                                creationLabel: response.originalFileName,
                                qualification: [
                                    {
                                        documentType: {
                                            resourceUid: documentType
                                        },
                                        label: response.originalFileName
                                    }
                                ],
                                targetEntityId: document?.targetEntity?.resourceUid,
                                systemUid: 'odm-supportingdocument'
                            }

                            receiveFile(processid, element.resourceUid, file).then(res => {
                                if(documentReceivedFileID){
                                 deleteProcessFile(processid,documentReceivedFileID )
                                }
                                qualifyFile(processid, element.resourceUid, res.resourceUid, file).then(qualificationRes => {
                                    if (!store.getters["taskModule/isTaskUploadDocuments"]){
                                    if (document && qualificationRes.status.resourceUid === "QUALIFIED") {
                                        document.status = i18n.global.t(
                                            `task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`
                                        );
                                    }
                                    }
                                })
                            })
                            callback(response)
                        });
                    });
                }
            })

        },
        downloadFile: ({ commit }, payload) => {
            const { request, row, callback } = payload
            downloadFile(request.resourceUid).then(response => {
                if (callback) {
                    callback(response, row)
                }
            })
                .catch(e => errorTreatment({ error: e, action: 'downloadFile' }))
        },
        getOfferDocument: ({ commit }, payload) => {
            const { request, callback } = payload
            getOfferDocument(request.variable).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        getProcess: ({ commit }, payload) => {
            const { request, callback } = payload
            getProcess(request.resourceUid).then(response => {
                if (callback) {
                    callback(response, request.res)
                }
            })
        },
        getRequiredDocument: ({ commit }, payload) => {
            const { request, callback } = payload
            getRequiredDocument(request.resourceUid).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        getRequiredDocumentModifyCondition: ({ commit }, payload) => {
            const { request, callback } = payload
            getRequiredDocumentModifyCondition(request.resourceUid).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        gatheringProcess: ({ commit }, payload) => {
            const { request, callback } = payload
            commit('setGatheringProcess', request.gatheringProcess)
        },
        sendToControl: ({ commit }, payload) => {
            const { resourceUid, callback } = payload
            sendToControl(resourceUid).then(response => {
                if (callback) {
                    callback(response)
                }
            })

        },
    },
    mutations: {
        setFiles(state, payload) {
            state.files = payload
        }
    }
}

export default uploadDocumentModule
