import {PartyInfo, QcHeader} from "@/types";
import {headers, isParty} from "@/utils";
import {settings} from "@/settings";
import {api} from "@/auth/api";

export const getPartyInfoWithCustomer = (partyInfos: PartyInfo[]) => {
    return partyInfos.filter((partyInfo: PartyInfo) => {
        if ( isParty(partyInfo.partyData) ) {
            return partyInfo.partyData.roles?.some(item => {
                if(item.role?.type) {
                    return item.role.type.value === 'CUSTOMER'
                } else {
                    return item.role?.resourceUid === 'CUSTOMER'
                }
            })
        }
    });
}

export const getTeamMembersByManagerCode = async (managerCode: string) => {
    const qc_header: QcHeader = {
        qc: {
            queryId: "user-team-members",
            offset: 0,
            limit: 100,
            parameters: { manager: managerCode }
        }
    };
    const {data} = await api().get(settings.api_query_url, {
        headers: headers({qc: JSON.stringify(qc_header.qc)})
    });

    return data.map((el: { username: string, first_name: string; family_name: string; _daaq: string }) => ({
        value: `${el.first_name} ${el.family_name} ## ${el.username}`,
        label: `${el.first_name.trim()} ${el.family_name.trim()}`
    }));

}
