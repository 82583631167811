import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6b3ee4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tw-notfication-content w-100" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-start" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-muted" }
const _hoisted_5 = { class: "font-size-12" }
const _hoisted_6 = { class: "text-primary" }
const _hoisted_7 = { class: "tw-notification-actions" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "bg-white p-2 border default-border-radius tw-notification-comment-wrapper mt-2" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "pl-3 mb-0" }
const _hoisted_14 = { class: "text-muted" }
const _hoisted_15 = { class: "font-size-12" }
const _hoisted_16 = { class: "text-primary" }
const _hoisted_17 = { class: "text-primary" }
const _hoisted_18 = { class: "text-primary" }
const _hoisted_19 = { class: "bg-white tw-notification-comment-wrapper" }
const _hoisted_20 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historicMessages, (m, mIndex) => {
    return (_openBlock(), _createElementBlock("div", {
      key: mIndex,
      class: _normalizeClass(["tw-notification-container tw-historic-messsage-container border-bottom p-3 d-flex justify-between", { 'tw-notification-status-NEW': _ctx.shouldChangeColor(m.messagePack[0]) }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("span", {
              class: _normalizeClass(["font-size-14 font-medium", { 'text-primary': _ctx.shouldChangeColor(m.messagePack[0]) }])
            }, [
              _createTextVNode(_toDisplayString(m.messagePack[0].subject) + " ", 1),
              (_ctx.shouldChangeColor(m.messagePack[0]))
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    rounded: "",
                    color: "blue"
                  }))
                : _createCommentVNode("", true)
            ], 2),
            (_ctx.activeSubjectUid !== mIndex)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("span", {
                    class: _normalizeClass({
              'text-dark': !_ctx.shouldChangeColor(m.messagePack[0]),
              'text-primary': _ctx.shouldChangeColor(m.messagePack[0]),
            })
                  }, [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.useDate.withPattern(m.messagePack[0].date, _ctx.Masks.timestampMask_Sys)) + " - ", 1),
                    _createElementVNode("span", _hoisted_5, [
                      _createTextVNode(_toDisplayString(m.messagePack[0].sender) + " ", 1),
                      _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t("communication.comMessage.send")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("communication.comMessage.sendMessage") + (m.messagePack[0].recipientname ? m.messagePack[0].recipientname : _ctx.setGroupName(m.messagePack[0].recipient_resourceuid))), 1)
                    ])
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (Number(_ctx.toggleCountMessage(m.messagePack)) !== 1)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  onClick: ($event: any) => (_ctx.toggleList(mIndex)),
                  class: "tw-button-message badge badge-primary d-flex align-items-center pl-2 pr-2 pt-2 pb-2",
                  icon: "question_answer",
                  size: "sm",
                  label: _ctx.toggleCountMessage(m.messagePack)
                }, null, 8, ["onClick", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.activeSubjectUid !== mIndex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass(["d-flex", m.messagePack[0].showComment ? '' : 'tw-truncate-text-comment'])
                }, _toDisplayString(m.messagePack[0].showComment ? m.messagePack[0].body : _ctx.truncatedText(m.messagePack[0].body)), 3),
                (_ctx.shouldDisplayShowCommentButton(m.messagePack[0]))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                      _createElementVNode("button", {
                        class: "btn p-0 bg-transparent text-primary",
                        style: {"text-decoration":"underline !important"},
                        onClick: ($event: any) => (_ctx.toggleComment(m.messagePack[0]))
                      }, _toDisplayString(!m.messagePack[0].showComment
                ? _ctx.$t("communication.comNotification.showMore")
                : _ctx.$t("communication.comNotification.showLess")), 9, _hoisted_11)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.activeSubjectUid === mIndex)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("ul", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(m.messagePack, (msg, msgIndex) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: msgIndex,
                    class: "tw-list-style mt-2 pb-3"
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass({
              'text-dark': !_ctx.shouldChangeColor(msg),
              'text-primary': _ctx.shouldChangeColor(msg),
            })
                    }, [
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.useDate.withPattern(msg.date, _ctx.Masks.timestampMask_Sys)) + " -", 1),
                      _createElementVNode("span", _hoisted_15, [
                        _createTextVNode(_toDisplayString(msg.sender) + " ", 1),
                        (msg.replymsgparent)
                          ? _renderSlot(_ctx.$slots, "default", { key: 0 }, () => [
                              _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.$t("communication.comMessage.response")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("communication.comMessage.responseMessage")), 1)
                            ], true)
                          : (msg.transfermsgparent)
                            ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
                                _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.$t("communication.comMessage.tansfert")), 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t("communication.comMessage.tansfertMessage") + (msg.recipientname ? msg.recipientname : _ctx.setGroupName(msg.recipient_resourceuid))), 1)
                              ], true)
                            : _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
                                _createElementVNode("a", _hoisted_18, _toDisplayString(_ctx.$t("communication.comMessage.send")), 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t("communication.comMessage.sendMessage") + (msg.recipientname ? msg.recipientname : _ctx.setGroupName(msg.recipient_resourceuid))), 1)
                              ], true)
                      ]),
                      (_ctx.shouldChangeColor(msg))
                        ? (_openBlock(), _createBlock(_component_q_badge, {
                            key: 0,
                            rounded: "",
                            color: "blue"
                          }))
                        : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(msg.body), 1)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2))
  }), 128))
}