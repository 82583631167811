import {
  deleteFilter,
  getSavedFilters,
  saveFilter,
 updateFilter} from "@/store/services/filter/filterService";
import { RootState, filter, filterState } from "@/types";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";
import store from "@/store";

const filterModule: Module<filterState, RootState> = {
    namespaced: true,
    state: {
        listOfFilters: [],
        ShowFilter: false,
        showEditMode: false,
        isUpdateButton: false,
        selectedFilter: undefined
    },
    getters: {
        getFilterInit(): filter {
            return {
                description: '',
                hasPublicAccess: false,
                numOrder: 1,
                screenId: '',
                count: 0,
                color: '',
                businessData: {},
                objectType: "odm.configuration.filterstore",
                systemUid: "odm-configuration"
            }
        },
        listOfFilters(state) {
            return state.listOfFilters;
        },
        showFilter(state) {
            return state.ShowFilter;
        },
        getSelectedFilter(state) {
            return state.selectedFilter;
        },
        getIsUpdateButton(state) {
            return state.isUpdateButton;
        },
    },
    actions: {
        showFilters({commit}) {
            commit('setShowListOfFilter', true)
        },
        disappearFilters({commit}) {
            commit('setShowListOfFilter', false)
        },
        showFilter({commit}) {
            commit('setShowFilter', true)
        },
        disappearFilter({commit}) {
            commit('setShowFilter', false)
        },
        setSelectedFilter({commit}, payload) {
            commit('setSelectedFilter', payload)
        },
        editSelectedFilter({commit}, payload) {
            commit('editSelectedFilter', payload)
        },
        async addFilter({dispatch}, payload) {
            if (!payload.resourceUid) {
                await saveFilter(payload)
                    .catch((err) => {
                        console.error(err);
                        Notify.create({
                            message: err,
                            color: "negative",
                        });
                    });
            } else{
                await updateFilter(payload)
                    .catch((err) => {
                        const message = err.message; 
                        Notify.create({
                            message,
                            color: "negative",
                        });
                    });

            }
            await dispatch("getSavedFilters", { queryId: payload.screenId });       
        },
        async getSavedFilters({commit}, payload) {
            commit("setListOfFilters", []);
            const {queryId} = payload;
            await getSavedFilters(queryId)
                .then((response) => {
                    commit("setListOfFilters", response);
                })
                .catch((err) => {
                    Notify.create({
                        message: err.message,
                        color: "negative",
                    });
                });
        },
    async deleteSelectedFilter({ dispatch }, payload) {
      await deleteFilter(payload)
        .then(() => {
          dispatch("getSavedFilters", { queryId: payload.screenId });
          store.dispatch("hideConfirmationDeleteDialog");
        })
        .catch((err) => {
          Notify.create({
            message: err,
            color: "negative",
          });
        });
     }
    },
    mutations: {
        setShowFilter(state, payload) {
            state.ShowFilter = payload
        },
        setListOfFilters(state, payload) {
            state.listOfFilters = payload
        },
        setSelectedFilter(state, payload) {
            state.selectedFilter = payload;
            state.showEditMode = false;
            
        },
        editSelectedFilter(state, payload) {
            state.selectedFilter = payload;
            state.showEditMode = true;
        },
        setIsUpdateButton(state , payload) {
            state.isUpdateButton = payload;
        },
        setUpdatedFilter(state , payload) {
            state.selectedFilter = payload;
        },
    }
}
export default filterModule;
