import {
    InventoryStatusEnum,
    Order,
    OrderFinancing,
    OrderItem,
    OrderNetwork,
    OrderNetworkNode,
    OrderVehicle,
    Picklist,
    Task,
    TaskSearchCriteria
} from "@/types";
import {
    createAssetDelivery,
    createNetwork,
    createNetworkNode,
    createOrder,
    createOrderFinancing,
    createOrderParty,
    getNetwork,
    getNetworkNode,
    getOrder,
    getOrderAssetDelivery,
    getOrderParty,
    getParty
} from "@/store/services/order/orderApi";
import {deepClone, formatDateRecursively, getOperator, globalConfig, headers, Masks} from "@/utils";
import {getThird} from "../searchThird";
import {settings} from "@/settings";
import {getTaskHistoryByEntity, getTaskUserAction, initWorkflowProcess} from "@/store/services/task/workflow";
import {getTasks} from "@/store/services/task/taskService";
import store from "@/store";
import {variablesWorkflowOrder} from "@/utils/configuration/table-config";
import {getOrderFinancing, getOrderFinancingInit} from "@/store/services/order/financing/orderFinancingService";
import axios from "axios";


export const saveOrder = async (order: Order) => {
    const payload = deepClone(order)
    delete payload.config
    if (payload.orderCommissions  && payload.orderCommissions.length>0) {
    for (const commission of payload.orderCommissions){
        delete commission.role
       if(commission.adjustAmount)
          commission.adjustAmount={
              amount: typeof commission.adjustAmount === 'string' ? commission.adjustAmount : commission.adjustAmount.amount,
              currency:"EUR"
              }
        }
    }
    formatDateRecursively(payload, 'DD/MM/YYYY', Masks.dateMask)
    await handleOrderItem(payload)

    if(order.orderParties &&
        (order.orderParties.length == 1  && store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid ||
        (order.orderParties.length > 1
            && store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid
            && order.orderParties[1].orderParty?.party?.resourceUid != store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid))){
       const financingOrganizationParty : any = {
           "orderParty": {
               "party": {
                   "objectType": "odm.party.party.person.frenchperson",
                   "systemUid": "odm-party",
                   "resourceUid": store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid
               },
               "role": {
                   "resourceUid": store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.role?.resourceUid,
                   "objectType": "odm.order.orderpartyrole"
               },
               "objectType": "odm.order.orderparty",
               "systemUid": "odm-order"
           },
           "objectType": "odm.order.orderassociatedparty",
           "systemUid": "odm-order"
       }
       if(order.orderParties.length == 1){
           order.orderParties.push(financingOrganizationParty)
       }else{
           order.orderParties[1] = financingOrganizationParty
       }

    }

    const saveOrderPartiesResponse = await saveOrderParties(order.orderParties || [])
    if (saveOrderPartiesResponse.length) {
        payload.orderParties = saveOrderPartiesResponse.map((resultItem: any) => {
            const { resourceUid } = resultItem
            return {
                orderParty: {resourceUid}
            }
        })
    }

    const networkResponse = await  saveNetwork(order.orderNetworks)
    if (networkResponse) {
        payload.orderNetworks = {
            resourceUid: networkResponse.resourceUid
        }
    }

    const networkNodeResponse = await  saveNetworkNode(order.orderNetworkNodes)
    if (networkNodeResponse) {
        payload.orderNetworkNodes = {
            resourceUid: networkNodeResponse.resourceUid
        }
    }

    let orderFinancingResourceUid = null
    //It means update mode
    if(order.financing && order.financing.resourceUid){
        orderFinancingResourceUid = order.financing.resourceUid
    }
    order.financing = await handleOrderFinancing(saveOrderPartiesResponse, order)

    //It means that the user does not want to create a new financing
    //TODO - check with Marwa if the financing is mandatory
    if(order.financing){
        if(orderFinancingResourceUid){
            order.financing.resourceUid = orderFinancingResourceUid
        }

        const financingResponse = await saveFinancing(order.financing)
        if (financingResponse) {
            payload.financing = {
                resourceUid: financingResponse.resourceUid
            }
        }
    }

    if (payload.resourceUid === '') {
        delete payload.resourceUid
    }
    
    return createOrder(payload)
}

const handleOrderItem = async (order: Order) => {
    for (const orderItem of order.orderItems || []) {
        if (!orderItem.reference || orderItem.reference === '') {
            orderItem.reference = 'TS' + (Math.random() + 1).toString().substring(2,8)
        }
        if (orderItem.orderAssets) {
            const orderAsset = orderItem.orderAssets[0]
            delete orderAsset.config
            if (orderAsset.assetDelivery) {
                const assetDelivery = await saveAssetDelivery(orderItem)
                orderAsset.assetDelivery = {
                    resourceUid: assetDelivery.resourceUid,
                    objectType: assetDelivery.objectType,
                    systemUid: assetDelivery.systemUid
                }
            }
            if (orderAsset.supplier && orderAsset.supplier?.party?.resourceUid) {
                const supplier = orderAsset.supplier
                if(supplier.third){
                    delete supplier.third
                }
                
                if (supplier.resourceUid === '') {
                    delete supplier.resourceUid
                }
                const party = await createOrderParty(supplier)
                orderAsset.supplier = {
                    resourceUid: party.resourceUid,
                    objectType: 'odm.order.orderparty',
                    systemUid: 'odm-order',
                }
            } else {
                delete orderAsset.supplier
            }
            if(orderAsset.orderAssetServices){
                orderAsset.orderAssetServices= handleOrderAssetServices(orderAsset.orderAssetServices)
            }
            if ((orderAsset as any).inventoryItemCode) {
                orderAsset.assetInventory= {
                    resourceUid: (orderAsset as any).inventoryItemCode,
                    objectType: 'odm.product.inventoryitem.inventoryitemvehicle.inventoryitemcar',
                    systemUid: 'odm-product',
                }
                delete (orderAsset as any).inventoryItemCode
            }

        }
    }
}
const handleOrderAssetServices =  (orderAssetServices: any) => {
    const ListOrderAssetServices=[]
    for(const orderAssetservice of orderAssetServices){
        delete orderAssetservice.type.type
        orderAssetservice.purchase = orderAssetservice.purchase.includes('true')
        ListOrderAssetServices.push(orderAssetservice)
    }
    return ListOrderAssetServices
}


export const saveOrderParties = async (orderParties: any) => {
    const result = []
    for(const orderParty of orderParties) {
        const payload = orderParty.orderParty
        delete payload.third
        if (payload.resourceUid === '') {
            delete payload.resourceUid
        }
        result.push(await createOrderParty(payload))
    }
    return result
}

export const saveNetwork = async (orderNetwork?: OrderNetwork) => {
    if (orderNetwork) {
        if (orderNetwork.resourceUid === '') {
            delete orderNetwork.resourceUid
        }
        return createNetwork(orderNetwork)
    }
}

export const saveNetworkNode = async (orderNetworkNode?: OrderNetworkNode) => {
    if (orderNetworkNode) {
        if (orderNetworkNode.resourceUid === '') {
            delete orderNetworkNode.resourceUid
        }
        return createNetworkNode(orderNetworkNode)
    }
}


export const saveAssetDelivery = async (orderVehicle?: any) => {
    const orderAsset = orderVehicle.orderAssets[0]
    if (orderAsset && orderAsset.assetDelivery) {
        if (orderAsset.assetDelivery.resourceUid === '') {
            delete orderAsset.assetDelivery.resourceUid
        }
        return createAssetDelivery(orderAsset.assetDelivery)
    }
}


async function handleParty(orderParty: any) {
    const orderPartyResourceEntity = await getOrderParty(orderParty.resourceUid)
    const orderPartyResourceEntityResourceUid = orderPartyResourceEntity.party.resourceUid || ''
    const type = orderPartyResourceEntity.party.objectType === "odm.party.party.person.frenchperson" ? "party-FrenchPerson" : "party-FrenchOrganization"
    orderPartyResourceEntity.third = await getThird(orderPartyResourceEntityResourceUid, type, '')
    return orderPartyResourceEntity;
}

export const getOrderEditMode = async (resourceUid: string) => {
    const order = await getOrder(resourceUid)
    const {
        orderNetworks,
        orderNetworkNodes,
        orderParties,
        orderItems
    } =  order

    order.orderNetworks = await getNetwork(orderNetworks.resourceUid)
    order.orderNetworkNodes = await getNetworkNode(orderNetworkNodes.resourceUid)

    const {orderParty} = orderParties[0]
    const orderPartyResourceEntity = await handleParty(orderParty);
    order.orderParties[0].orderParty = orderPartyResourceEntity
    const partyName = await getPartyName(orderPartyResourceEntity.party.resourceUid, orderPartyResourceEntity.party.objectType)
    order.config = {
        partyName
    }
    await handleOrderItemEditMode(orderItems)

    if(order.financing && order.financing.resourceUid){
        if(orderParties.length > 1){
            const {orderParty} = orderParties[1]
            order.orderParties[1].orderParty = await handleParty(orderParty)
        }
        store.state.orderModule.orderFinancingModule.financing = await getOrderFinancing(order.financing.resourceUid)
        store.state.orderModule.orderFinancingModule.financing.associatedParty = { "orderParty": order.orderParties[1].orderParty }
    }else{
        store.state.orderModule.orderFinancingModule.financing = await getOrderFinancingInit();
    }

    return order
}


export const getPartyName = async (resourceUid: string, objectType: string) => {
    const party = await getParty(resourceUid, objectType)
    if (objectType.includes('person')) {
        return party.firstName
    } else {
        return party.name
    }
}

const handleOrderItemEditMode = async (orderItems: OrderItem[]) => {
    for (const orderItem of orderItems) {
        const {orderAssets} = orderItem
        for (const orderAsset of orderAssets || []) {
            const { supplier, assetDelivery } = orderAsset
            if (supplier) {
                const orderPartyResourceEntity = await getOrderParty(supplier.resourceUid)
                if (orderPartyResourceEntity.party) {
                    supplier.party = orderPartyResourceEntity.party
                    supplier.role = orderPartyResourceEntity.role
                    const supplierPartyName = await getPartyName(orderPartyResourceEntity.party.resourceUid, orderPartyResourceEntity.party.objectType)
                    orderAsset.config = {
                        supplierPartyName
                    }
                    const type = "party-FrenchOrganization"
                    const third = await getThird( orderPartyResourceEntity.party.resourceUid,type,'')
                    supplier.third=third
                }
            }
            if (assetDelivery) {
                orderAsset.assetDelivery = await getOrderAssetDelivery(assetDelivery.resourceUid)
            }
            if(orderAsset.orderAssetServices){
                for (const service of orderAsset.orderAssetServices){
                    if(service.type&&service.type.resourceUid){
                        service.type.type={value:service.type.resourceUid,label:service.type.resourceUid}
                    }
                    service.purchase=service.purchase?"true":"false"
                    
                }
            }
        }
    }
}


export const initOrderWorkflow = async (order: Order) => {
    const {
        data
    } = await getTasks({
        searchVariables: JSON.stringify([
            { name: 'orderReference',  operator: "eq",  value: order.reference }
        ])
    })
    if(!data || !data.length) {
        const variables = extractOrderVariables(order)
        await initWorkflowProcess({ processDefinitionId: settings.processDefinitionId, variables: JSON.stringify(variables) })
    }
}

const extractOrderVariables = (order: Order) => {

    const {
        resourceUid,
        reference,
        status,
        channel,
        orderNetworks,
        effectiveDeliveryDate,
        orderNetworkNodes ,
        orderParties
    } = order

    const variables = [
        { variableName: 'module', value: 'Order' },
        { variableName: 'daaq' , value: order.daaq || '' },
        { variableName: 'applicationName', value: 'TWDClientApp' },
        { variableName: 'env', value: settings.env },
        { variableName: 'orderId', value: resourceUid },
        { variableName: 'orderReference', value: reference },
        { variableName: 'orderCreationDate', value: effectiveDeliveryDate },
        { variableName: 'channel', value: channel },
        { variableName: 'orderStatus', value: status?.resourceUid },
        { variableName: 'order', value: JSON.stringify(order) },
    ]

    const orderNetworksId = orderNetworks?.network?.resourceUid
    if (orderNetworksId)
        variables.push({ variableName: 'associated_networks_id', value: orderNetworksId })
    const associatedNetworkNodesId = orderNetworkNodes?.networkNode?.resourceUid
    if (associatedNetworkNodesId)
        variables.push({ variableName: 'associated_network_nodes_id', value: associatedNetworkNodesId })

    const customer: any = (orderParties || [])
        .filter((party: any) => party.orderParty?.role?.resourceUid === 'CUSTOMER')[0]
    if (customer) {
        const third = customer.orderParty.third
        if (third.id.objectType.includes('person')) {
            const person = customer.orderParty.third.person
            const customerName = `${person.firstName} ${person.familyName}`
            variables.push({ variableName: 'customerRessourceUid', value: person.resourceUid })
            variables.push({ variableName: 'customerName', value: customerName })
            variables.push({ variableName: 'customerReference', value: person.reference })
            variables.push({ variableName: 'customerType', value: 'person' })
            variables.push({ variableName: 'customerBirthDate', value: person.birthDate })
        } else {
            const organization = customer.orderParty.third.organization
            variables.push({ variableName: 'customerResourceUid', value: organization.resourceUid })
            variables.push({ variableName: 'customerName', value: organization.name })
            variables.push({ variableName: 'customerReference', value: organization.reference })
            variables.push({ variableName: 'customerType', value: 'organization' })
        }
    }

    return variables

}

export async function getOrderTaskHistory() {
    const taskSearchCriteria: TaskSearchCriteria = {
      entityId: store.state.orderModule.order?.resourceUid || "" ,//"65f04e58aad7522c7dce6d2b",
      initializeVariables: variablesWorkflowOrder };
    const tasklist = await getTaskHistoryByEntity(taskSearchCriteria);
    const result = await setTaskUserAction(tasklist.data);
    return result;
  }
  export async function setTaskUserAction(payload: any) {
    payload.forEach(async (element: Task) => {
      if (element.taskId) {
        element.histories = await (await getTaskUserAction(element.taskId)).data;
        element.opened = true;
      }
    });
  
    return payload.sort((a: any, b: any) => Date.parse(b.creationDate) - Date.parse(a.creationDate));
  }


export const saveFinancing = async (orderFinancing?: OrderFinancing) => {
    if (orderFinancing) {
        if (orderFinancing.resourceUid === '') {
            delete orderFinancing.resourceUid
        }
        return createOrderFinancing(orderFinancing)
    }
}

async function handleOrderFinancing(saveOrderPartiesResponse: any, order: any):Promise<any> {
    let orderFinancingRequest = null
    if(store.state.orderModule.orderFinancingModule.financing?.label){
        const financingParty = saveOrderPartiesResponse.filter((party: any) => party.party?.resourceUid == store.state.orderModule.orderFinancingModule.financing?.associatedParty?.orderParty?.party?.resourceUid);
        orderFinancingRequest = {...store.state.orderModule.orderFinancingModule.financing };

        if (financingParty && store.state.orderModule.orderFinancingModule.financing) {
            orderFinancingRequest.financingOrganization = {
                resourceUid: financingParty[0].resourceUid,
                objectType: financingParty[0].objectType,
                systemUid: financingParty[0].systemUid,
            }
        }
        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.contribution) {
            orderFinancingRequest.contribution = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.contribution,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.financedAmountWoTax) {
            orderFinancingRequest.financedAmountWoTax = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.financedAmountWoTax,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.financedAmountWTax) {
            orderFinancingRequest.financedAmountWTax = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.financedAmountWTax,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && store.state.orderModule.orderFinancingModule?.financing?.firstRent) {
            orderFinancingRequest.firstRent = {
                amount: store.state.orderModule.orderFinancingModule?.financing?.firstRent,
                currency: "EUR"
            }
        }

        if (orderFinancingRequest && orderFinancingRequest.type) {
            orderFinancingRequest.type = orderFinancingRequest.type.value
            //TODO - check with Marwa what we will do about this field - currently is the scale
            orderFinancingRequest.financingProductPack = null
            orderFinancingRequest.objectType = 'odm.order.orderfinancing'
        }
        delete orderFinancingRequest?.scale
        delete orderFinancingRequest?.associatedParty
        delete orderFinancingRequest?.config
    }


    return orderFinancingRequest
}

export function searchAssetOrder(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'asset-order-search-filter',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }

        const operators = []
        if (filter && filter.brand != null) {
            operators.push(getOperator("lower(ppc_brand", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.brand.label + '%').toLowerCase(), "ppc_brand"))
        }
        if (filter && filter.model != null) {
            operators.push(getOperator("lower(ppc_model", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.model.label + '%').toLowerCase(), "ppc_model"))
        }
        if (filter && filter.color != null) {
            operators.push(getOperator("lower(ppc_color", "category_class_code)", "LIKE", globalConfig.formatters.getEqualValue('%' + filter.color + '%').toLowerCase(), "ppc_color"))  }
        if (filter && filter.activity != null) {
            operators.push(getOperator("product_inventory_item", "type_code", "LIKE", globalConfig.formatters.getLikeValue(filter.activity), "type_code"))
        }
        operators.push(getOperator("product_inventory_item", "status_code", "LIKE", globalConfig.formatters.getLikeValue('IN_STOCK'), "status_code"))

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            res.data.map((resItem: any) => {
                return {
                ...resItem,
                    opendetailInfo : false
                }
              })
            resolve(res.data)
        }).catch((error) => {
            reject(error);
        });
    })
}