
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import ControlsDialog from "@/components/controls/ControlsDialog.vue";

@Options({
  components: { ControlsDialog }
})
export default class ControlsButton extends Vue {

  toggleShowControls() {
    return store.dispatch('controlsModule/toggleShow')
  }

  get controls() {
    return store.state.controlsModule.errors.length + store.state.controlsModule.warnings.length
  }

}
