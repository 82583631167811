
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { formatWithMask } from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";

@Options({
  components: {
    LabelAndValue,
  },
  props: ["proposal", "valueStyle", "labelStyle", "divValueStyle"],
})
export default class SummaryLease extends Vue {
  proposal?: any;
  valueStyle?: any;
  labelStyle?: any;
  divValueStyle?: any;

  get price() {
    return formatWithMask(this.proposal.financedValue, 2);
  }
  get monthly() {
    return formatWithMask(this.proposal.financialPaymentWTax, 2);
  }
  get amountRefunded() {
    return !this.proposal.sumOfTotalPayments
      ? "-"
      : formatWithMask(this.proposal.sumOfTotalPayments.amount, 2);
  }
  get duration() {
    return `${this.proposal.numberOfPaymentTerm} ` + this.durationUnit();
  }
  get tax() {
    return this.proposal.nominalRate;
  }
  get residualValue() {
    return !this.proposal.residualValueAmount
      ? "-"
      : `${formatWithMask(this.proposal.residualValueAmount, 2)} || ${
          this.proposal.residualValuePercentage
        } %`;
  }
  get firstPayment() {
    return !this.proposal.firstPaymentWTax
      ? "-"
      : `${formatWithMask(this.proposal.firstPaymentWTax, 2)} || ${
          this.proposal.firstPaymentPercentage
        } %`;
  }

  camelSentence(str?: string) {
    return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
    {
      return chr.toUpperCase();
    });
  }

  get frequency() {
    return this.durationUnit('frequency').replace(/[\])[(]/g, '');
  }

  get gracePeriodTerms() {
    // TODO TP-3015
    return !this.proposal.defaultNumberGracePeriodTerms ? "-" : formatWithMask(this.proposal.defaultNumberGracePeriodTerms, 2)
  }

  get gracePeriodTermsType() {
    // TODO TP-3015
    return !this.proposal.gracePeriodTermsType ? "-" : this.proposal.gracePeriodTermsType
  }

  get gracePeriodTermsTax() {
    // TODO TP-3015
    return !this.proposal.gracePeriodTermsTax ? "-" : `${formatWithMask(this.proposal.gracePeriodTermsTax, 2)} %`
  }



  durationUnit(type?: string) {
    const basePath = 'main.labels.sliders'
    switch (this.proposal.periodBetween2Installments.duration) {
      case 1:
        return i18n.global.t(`${basePath}.1_months.presentation`)
      case 3:
        return i18n.global.t(`${basePath}.3_months.slider`)
      case 6:
        return i18n.global.t(`${basePath}.6_months.slider`)
      case 12:
        return i18n.global.t(`${basePath}.12_months.slider`)
      default:
        return type ? i18n.global.t(`${basePath}.default.slider`, {duration: this.proposal.periodBetween2Installments.duration, param2: this.proposal.numberOfPaymentTerm, period2: this.camelSentence(this.proposal.periodBetween2Installments.unit)}) :
            i18n.global.t(`${basePath}.default.label`, {period: this.camelSentence(this.proposal.periodBetween2Installments.unit)})
    }
  }

}
