
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import i18n from "@/i18n";

@Options({
  props: ["query"],
})
export default class FilterList extends Vue {
  get listOfFilters() {
    return store.getters["filterModule/listOfFilters"];
  }

  get connectedUserDaaq() {
    const { createDAAQ, defaultCreateDAAQ } = store.getters["authModule/getUserConnected"]?.daaqList[0]?.config;
    return { createDAAQ, defaultCreateDAAQ };
  }

  /**
   * Searches for order based on the filter selected.
   * @return {void}
   */
  onSelectedFilter(filter: any) {
    store.dispatch("filterModule/setSelectedFilter", filter);
  }

  onDeleteFilter(filter: any) {
    store.dispatch("showConfirmationDeleteDialog", {
      message: `Are you sure you want to delete the filter ${filter.description}?`,
      deleteAction: 'QueryFilter',
      payload: filter,
      confirmation: true
    })
  }

  onEditFilter(filter: any) {
    store.dispatch("filterModule/editSelectedFilter", filter);
  }

  isDaaqMatching(filterDaaq: string): boolean {
    const { createDAAQ, defaultCreateDAAQ } = this.connectedUserDaaq;
    if (createDAAQ === "/" || defaultCreateDAAQ === "/") return true;
    const segments = filterDaaq.split("/").filter(segment => segment);
    const patterns = segments.map((_, index) => {
      const subPath = segments.slice(0, index + 1).join("/");
      return new RegExp(`^/${subPath}/$`);
    });
    return patterns.some(pattern => pattern.test(createDAAQ) || pattern.test(defaultCreateDAAQ));
  }
}
