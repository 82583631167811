import {settings} from "@/settings";
import {AuthUser} from "@/types";
import {createUser, login, Paths, requestForgotPasswordValidationCode, resetPassword, UON, validateCode} from "@/utils";
import {api} from "@/auth/api";
import {useApi} from "@/requests/useApi";

export function signIn(user: AuthUser): Promise<string> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDemo'
    }
    return login(user, {
        systemUid: odmAuthentication,
        path: `${odmAuthentication}/token/?applicationName=${user.applicationName}`,
    });
}

export function signUp(user: AuthUser): Promise<AuthUser> {
    const odm_user = UON.SystemUid.odmUser;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDemo'
    }
    return createUser(user, {
        systemUid: odm_user,
        path: `${odm_user}/${Paths.user}/`,
    })
}

export function codeConfirmation(user: AuthUser): Promise<{ result: string }> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDemo'
    }
    return validateCode({"smsCode": user.smsCode}, {
        systemUid: odmAuthentication,
        path: `anonymous/${odmAuthentication}/validate/smsCode/${user.username}/?applicationName=${user.applicationName}`,
    })
}

export function forgotPassword(user: AuthUser): Promise<void> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDemo'
    }
    return requestForgotPasswordValidationCode({"login": user.username}, {
        systemUid: odmAuthentication,
        path: `${odmAuthentication}/forgotPassword/?applicationName=${user.applicationName}`,
    })
}

export function forgotPasswordCodeConfirmation(user: AuthUser): Promise<void> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDemo'
    }
    return resetPassword({"login": user.username, "smsCode": user.smsCode, "password": user.password}, {
        systemUid: odmAuthentication,
        path: `${odmAuthentication}/forgotPasswordValidate/?applicationName=${user.applicationName}`,
    })
}

export function getAuthorities() {
    return api().get(`${settings.api_url}/odm-user/api/1/odm-user/whoami/`);
}

export function getUser(userId: string) {
    return api().get(`${settings.api_url}/odm-user/api/1/odm-user/leaseforgeuser/${userId}/`);
}

export function updateLanguage(payload: any, userId: string) {
    return api().put(`${settings.api_url}/odm-user/api/1/odm-user/leaseforgeuser/${userId}/`, payload)
}

export async function updatePassWordService(payload: any) {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    const res= await  api().put(`${settings.api_url}/${odmAuthentication}/api/1/${odmAuthentication}/change-password/`, payload)
    return res
}
/**
 * get historic messages based on the offer reference
 * @param payload
 */
export const getUserApi = async (payload: string) => {
    const {
        response,
        error
    } = await useApi(UON.SystemUid.odmUser, `leaseforgeuser`).get(payload);
    if ( error ) {
        throw error;
    }

    return response;
}


