
// import { Message } from "@/types"
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import i18n from "@/i18n";
import ComboRecipients from "@/components/communication/comboRecipients.vue";
import { convertToUON } from "@/utils";
import {
  clientAppMsg,
  messageTemplate,
  objectType_odmTransferReplyMsg,
  recipient,
  senderUid,
  subject,
  typeMsg,
} from "@/types";

const systemUid = `odm-contactmechanism`;
@Options({
  name: "MessageCom",
  components: {
    ComboRecipients,
  },
  props: ["subjects", "references", "reference", "recipients", "destinations"],
  computed: {
    isSending() {
      return this.sendingStatus;
    },
  },
  watch: {
    "$store.state.currentContext"(currentContext) {
      this.reference = currentContext;
    },
  },
  data() {
    return {
      reference: null,
      references: [],
      recipients: [],
      /**
       * holding original refs list
       */
      optionsReference: [],
    };
  },
  methods: {
    ...mapState("messagingModule", ["isSendingMessage"]),
    ...mapActions("messagingModule", ["messageSend"]),
    ...mapActions("messagingModule", ["fetchRecipients"]),

    sendMessage() {
      const _recipients: recipient[] = convertToUON(
        this.$refs.comboRecipients.destinations
      );

      senderUid.resourceUid = this.$store.state.authModule?.user.userId;
      subject.resourceUid = this.subject;
      clientAppMsg.resourceUid = "68815"; //!
      
      if (
        this.subject &&
        this.recipients &&
        this.recipients.length > 0 &&
        this.message &&
        this.$store.state.authModule?.user.userId
      ) {
       _recipients.forEach((el:any)=>{
          delete el.recipient.read_daaq
        })
        //In case is not related to an entity
        if(this.reference){
          store
          .dispatch("messagingModule/messageSend", {
            objectType: objectType_odmTransferReplyMsg,
            systemUid,
            daaq: `${store.state.authModule?.daaq}${store.state.authModule?.daaqResourceUid}/${store.state.authModule?.daaqLabel}`,
            sender: `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`,
            senderUid: senderUid,
            priority: this.importantTag,
            subject: subject,
            type: typeMsg,
            recipients: _recipients,
            body: this.message,
            relatedObjects: null, //*
            clientApplication: clientAppMsg,
            parameters: {
              Context: `${this.reference.id ? "PARTY" : "OFFER"}`,
              Entity_Reference: this.reference.reference,
              Entity_Daaq:this.reference._daaq,
              Entity_Status: `${
                this.reference.statuscode
                  ? this.reference.statuscode
                  : this.reference.current_status_code
              } `,
            },

            messageTemplate: messageTemplate,
          })
          .then(() => {
            this.closeDialog();
          });

        }
        else{
          store
          .dispatch("messagingModule/messageSend", {
            objectType: objectType_odmTransferReplyMsg,
            systemUid,
            daaq: `${store.state.authModule?.daaq}${store.state.authModule?.daaqResourceUid}/${store.state.authModule?.daaqLabel}`,
            sender: `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`,
            senderUid: senderUid,
            priority: this.importantTag,
            subject: subject,
            type: typeMsg,
            recipients: _recipients,
            body: this.message,
            relatedObjects: null, //*
            clientApplication: clientAppMsg,
            messageTemplate: messageTemplate,
            parameters: {
              Context: null,
              Entity_Reference: null,
              Entity_Daaq:null,
              Entity_Status:null,
            }
          })
          .then(() => {
            this.closeDialog();
          });
        }
      }
    },
    /**
     * reset message form
     */
    closeDialog() {
      this.showComDialog = false;
      this.importantTag = false;
      this.subject = "";
      this.reference = null;
      this.recipients = [];
      this.recipients = [];
      this.message = "";
    },
    isRequired(val: string | any[]) {
      return (
        (val && val.length > 0) ||
        i18n.global.t("communication.comMessage.missingField")
      );
    },

    importantTagForm() {
      this.importantTag = !this.importantTag;
    },

    filterFn(val: string, update: (newValue: string[]) => void) {
      update(
        (this.optionsRef.value = this.references.filter(
          (v: string) => v.toLowerCase().indexOf(val.toLowerCase()) > -1
        ))
      );
    },

    /**
     * filter references options with input value
     * @param val
     * @param update
     */

    filterRefFn(val: string, update: any) {
      if (val === "" || val === " ") {
        this.optionsReference = this.references;
        update(this.optionsReference);
        return;
      }
       const limitedInterval = setInterval(()=>{
       update(() => {
         this.optionsReference =[]
          store.dispatch("demandModule/searchOfferParty", {
          callback: this.updateResulteRows,
          offerFilter: {
            reference: `%${val}%`,
          },
          filterParty: {
            id: `%${val}%`,
          },
        });
      });
      clearInterval(limitedInterval);
      }, 2000)
      return limitedInterval
    },
    
    updateResulteRows(res: any) {
      this.optionsReference = res;
    },

  /**
   * abort references filter
   */
  abortRefFilter() {
    // reset to original list
    this.optionsReference = this.references;
  },

  /**
     * This function retrieves the selected tag in the given scope.
     @param {any} scope - The scope to retrieve the selected tag.
     @returns {string} - The selected tag in the given scope.
     */
  getSelectedLabel(scope: any) {
    if (scope.opt.id) {
      return {
        id: scope.opt.id.resourceUid ? scope.opt.id.resourceUid : scope.opt.id,
        context: "Party",
      };
    } else if (scope.opt.reference) {
      return {
        id: scope.opt.reference,
        context: "Offer",
      };
    }
  },
}})
export default class MessageCom extends Vue {
  isSending: any;
  get sendingStatus() {
    return store.state.messagingModule?.isSendingMessage;
  }

  // variables
  showComDialog = false;
  importantTag = false;
  subject = null;
  subjects? = [];
  destinations = [];
  message = null;
}
