//Global Configuration for Superset Dashboard
export const supersetConfiguration = {
    base_url: "https://superset-dev.teamwill-digital.com/api/v1/",
    loginPath: "security/login",
    csrfPath: "security/csrf_token/",
    guest_token: "security/guest_token/",
    adminConfig: {
        password: "dX00nv0yMwe9ZyjG8CI12FYB",
        username: "admin",
        provider: "db",
        refresh: "true"
    },
    guestConfig: {
        user: {
            username: "Vendeur_1",
            first_name: "Michael",
            last_name: "Smith"
        },
        resources: [{
            type: "dashboard",
            id: "9fa7814d-6e6e-43aa-9016-a760ff0c1a15"
        }],
        rls: []
    },
    dashboardUiConfig: {
        hideTitle: true,
        filters: {
            visible: true,
        }
    }
}




