import { Module } from "vuex";
import { ContactType, DecisionState, RootState } from "@/types";
import {
  takeDecision,
  getListDecision
} from "@/store/services/task/actions/decisionService";
import { formatDate } from "@/utils/configuration/formatters-config";
import store from "@/store";
import i18n from "@/i18n";

const partyModule: Module<any, RootState> = {
  namespaced: true,
  state: {},
  getters: {
    getAddressInit() {
      return [{
        address: {
          type: {
            type: {
              id: 'Residential',
              label: i18n.global.t(
                "demand.party.addressType.Residential"
              )
            },
            objectType: 'odm.party.addresstype',
            resourceUid: 'Residential'
          },
          line1: '',
          line2: '',
          line3: '',
          territory: '',
          postalCode: {
            type: {
              id: "",
              label: "",
              config: {
                city: "",
                country: {
                  id: "",
                  label: ""
                },
              }
            },
            postalCode: '',
            country: {
              name: '',
              objectType: 'odm.party.country'
            },
            inseeCode: '',
            region: '',
            department: '',
            city: '',
            objectType: 'odm.party.postalcode',
          },
          objectType: 'odm.party.address',
          isDisabled: true,
          daaq: '/'
        },
      }]
    },
    getCorrespondentInit() {
      return [{
        _controls: {
          attToRemove: ['isDisabled'],
        },
        firstName: '',
        middleName: '',
        familyName: '',
        status: { objectType: 'odm.party.partycorrespondentstatus' },
        signingAuthority: false,
        contactCorrespondent: [{ ...store.getters["taskModule/partyModule/getContactCorrespondentInit"] }],
        qualificationType: {
          objectType: 'odm.party.qualificationtype',
          resourceUid: '',
          type: {
            value: 'CEO',
          }
        },
        daaq: '/',
        isDisabled: true
      }]
    },
    getContactCorrespondentInit() {
      return { ...store.getters["taskModule/partyModule/getContactMechanismInit"] }
    },
    getContactPersonCorrespondentInit() {
      return [{ ...store.getters["taskModule/partyModule/getContactMechanismInit"] }]
    },
    getContactMechanismInit() {
      return {
        contactMechanism: {
          _controls: {
            attToRemove: ['type', 'isDisabled', 'businessData'],
          },
          type: {
            id: ContactType.EMAIL_CONTACT,
            label: "E-mail",
            config: {
              objectType: "odm.party.contactmechanism.emailcontact",
              fields: ["emailAddress", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "consent", "facility"]
            }
          },
          objectType: '',
          emailAddress: '',
          identifier: '',
          phoneNumber: '',
          acceptSMS: false,
          nonSolicitation: false,
          isDisabled: true,
          daaq: '/'
        },
      }
    },
    getThirdJobsInit() {
      return [{

        businessData: '',
        daaq: "/",
        contractType: {
          type: {
            label: '',
            value: ''
          }
        },
        positionType: {
          type: {
            label: '',
            value: ''
          }
        },
        occupationType: {
          type: {
            label: '',
            value: ''
          }
        },
        activity: {
          type: {
            label: '',
            value: ''
          }
        },
        validity: {
          from: '',
          until: ''
        },
        nbYearExpereinceActivity: '',
        nbYearExpereincePosition: '',
        employer: '',
        employerName: '',
        principal: false,
        nbHour: 0,
        employerCreationDate: '',
        tenureDate: '',
        jobLocation: { ...store.getters["thirdModule/getAddressInit"] }
      }]
    },
    getThirdAnalysisInit() {
      return [{
        label: "",
        analysisDate: "",
        status: {
          type: {
            value: '',
            label: ''
          }
        },
        yearlyExpensesTotal: {
          currency: "EUR",
          amount: 0
       },
        yearlyIncomeTotal: {
          currency: "EUR",
          amount: 0
      },
        detail: [{ ...store.getters["taskModule/partyModule/getIncomeInit"] }, { ...store.getters["taskModule/partyModule/getExpenseInit"] }],
        isDisabled: false,
      }]
    },
    getIncomeInit() {
      return {
        type: 'INCOME',
        nature: {
          type: {
            value: ''
          }
        },
        periodicity: {
          type: {
            label: '',
            value: ''
          }
        },
        value: '',
        label: "",
        validity: {
          from: '',
          until: ''
        },
        isDisabled: false,
      }
    },
    getExpenseInit() {
      return {
        type: 'EXPENSE',
        nature: {
          type: {
            label: '',
            value: ''
          }
        },
        periodicity: {
          type: {
            label: '',
            value: ''
          }
        },
        value: '',
        label: "",
        validity: {
          from: '',
          until: ''
        },
        isDisabled: false,
      }
    },
  },
  actions: {
    initOrganizationAddressState({ getters }, payload) {
      const { index } = payload
      const address = getters['getAddressInit']
      const offer = this.state.taskModule.offer
      if (offer && offer.associatedParties) {
        const associatedParty = offer.associatedParties[index].associatedParty
        associatedParty!.third!.organization!.addresses = address
        this.dispatch('taskModule/updateOffer', { offer })
      }
    },
    initOrganizationCorrespondentState({ getters }, payload) {
      const { index } = payload
      const correspondents = getters['getCorrespondentInit']
      const offer = this.state.taskModule.offer
      if (offer && offer.associatedParties) {
        const associatedParty = offer.associatedParties[index].associatedParty
        associatedParty!.third!.organization!.correspondents = correspondents
        this.dispatch('taskModule/updateOffer', { offer })
      }
    },
    initPersonContactState({ getters }, payload) {
      const { index } = payload
      const contacts = getters['getContactPersonCorrespondentInit']
      const offer = this.state.taskModule.offer
      if (offer && offer.associatedParties) {
        const associatedParty = offer.associatedParties[index].associatedParty
        associatedParty!.third!.person!.contacts = contacts
        this.dispatch('taskModule/updateOffer', { offer })
      }
    },
    initPersonJobsState({ getters }, payload) {
      const { index } = payload
      const jobs = getters['getThirdJobsInit']
      const offer = this.state.taskModule.offer
      if (offer && offer.associatedParties) {
        const associatedParty = offer.associatedParties[index].associatedParty
        associatedParty!.third!.person!.jobs = jobs
        this.dispatch('taskModule/updateOffer', { offer })
      }
    },
    initPersonAddressState({ getters }, payload) {
      const { index } = payload
      const address = getters['getAddressInit']
      const offer = this.state.taskModule.offer
      if (offer && offer.associatedParties) {
        const associatedParty = offer.associatedParties[index].associatedParty
        associatedParty!.third!.person!.addresses = address
        this.dispatch('taskModule/updateOffer', { offer })
      }
    },
    initPersonRevenueState({ getters }, payload) {
      const { index } = payload
      const revenues = getters['getThirdAnalysisInit']
      const offer = this.state.taskModule.offer
      if (offer && offer.associatedParties) {
        const associatedParty = offer.associatedParties[index].associatedParty
        associatedParty!.third!.person!.revenues = revenues
        this.dispatch('taskModule/updateOffer', { offer })
      }
    },

    cleanState({ commit }) {
      commit('setInitial')
    }
  },
  mutations: {
    setInitial(state) {
      state.decision = {
        decisionDate: formatDate(new Date(), "/"),
        comment: "",
        reserve: [],
        decision: {
          value: "",
          label: "",
        },
        motif: [],
      }
    }
  },
};

export default partyModule;
