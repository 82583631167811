import {
    Account,
    Address,
    AssociatedGuaranteeUnderlyingAssets,
    AssociatedParty,
    Citizenship,
    ComboBox,
    ComboBoxItem,
    ContactType,
    EvaluateTaxRate,
    FileContent,
    FilterParty,
    FinancingProductPackInfo,
    Network,
    Offer,
    OfferAssociatedParty,
    OfferSearch,
    OfferStatus,
    Organization,
    OrganizationClass,
    PartyAddress,
    PartyContactMechanism,
    PartyCorrespondent,
    PartyCorrespondentStatus,
    PartyRelation,
    Person,
    PersonClass,
    PersonMaritalStatus,
    Picklist,
    PicklistItem,
    ProductTax,
    Proposal,
    ProposalAssetCar,
    ProposalAssetTruck,
    ProposalGuarantee,
    ProposalStatus,
    ProposalVehicle,
    Role,
    Roles,
    RootState,
    ScalePresentationItem,
    SearchVariableType,
    SimpleQuote,
    Third,
    ThirdType,
    UniformOdmName
} from "@/types";
import {
    buildBankAccount,
    buildExistingRevenues,
    buildFacility,
    buildFinancialExposure,
    buildRelations,
    filterParty,
    getNatureIncome,
} from '@/store/services/searchThird'
import {
    copyProperties,
    createOffer,
    deepClone,
    deleteAttributes,
    deleteAttributesRecursively,
    deleteNonRequiredAttributes,
    doRef,
    formatDateRecursively,
    getEntity,
    getOperator,
    globalConfig,
    headers,
    isUON,
    Masks,
    mountSelectFields,
    moveElementInArray,
    Paths,
    pickAttributes,
    removeAttributesRecursively,
    removeDuplicatesPreferences,
    unmountSelectFieldsV2,
    UON,
    upsertWithDataReturn,
    useDate
} from "@/utils";
import {settings} from "@/settings";
import {api} from "@/auth/api";
import {
    buildAnalysis,
    buildBirthplace,
    buildBusinessDataPerson,
    buildCitizenshipsPerson,
    buildJobs,
    buildPartyContacts,
    buildSituation,
    saveContactCorrespondent,
    savePartyAddresses,
} from "@/store/services/thirdService";
import {
    calculateMileage,
    calculateResidualValue,
    calculateSimulation,
    saveAssociatedParty
} from '@/store/services/simulation'
import axios from "axios";
import moment from "moment";
import {
    deleteFormatMaskAndConvertToNumber,
    formatDateLocale,
    formatWithMask
} from "@/utils/configuration/formatters-config";
import {
    buildProposalFromSelectedScale,
    getAccessories,
    getHelperFromState,
    getProposalInfo,
    INVESTMENT_BASIS_TAX_EXCLUSIVE,
    mapProposalAccessories
} from "@/store/services/financing/financingMapper";
import i18n from "@/i18n";
import {useApi} from "@/requests/useApi";
import {completeProposalAssetCarInfo, completeProposalAssetTruckInfo} from "@/store/services/asset/assetMapper";
import router from "@/router";
import store from "@/store";
import {
    buildScaleServices,
    getDurationLabels,
    getPackItemsEditMode,
    verifyPartyInsured
} from "@/store/services/financing/financingService";
import {buildManagerRelation, buildPartyTypePreference, buildPreferences} from "@/commons/party-middleware";
import {
    getProductGuarantee,
    updateProposalGuarantee
} from "@/store/services/financing/guarantee/proposalGuaranteeService";
import {saveAssociatedPartyApi} from "@/commons/offer-middleware";
import {evaluateAllPartyInsuranceAccessory, setTaxValue} from "@/store/services/financing/product";
import {mergeBeneficiariesDataResultEdit} from "@/store/services/financing/partyInsurance";
import {ProposalGuaranteeClass} from "@/store/services/financing/guarantee/domain/ProposalGuaranteeClass";
import {AssociatedPartyClass} from "@/store/services/financing/guarantee/domain/AssociatedPartyClass";
import { getQcOffer } from "./filter/filterService";


const api_query_url = settings.api_query_url;
const odm_product = 'odm-product'

function buildOffer(reference: string, offer: Offer, currentUserId: string): Offer {
    const request: Offer = {
        reference,
        channel: offer.channel,
        salesChannel: offer.salesChannel,
        status: {
            resourceUid: 'INITIAL',
            objectType: 'odm.offer.offerstatus'
        },
        validity: {
            from: globalConfig.formatters.formatDateService(new Date(), '-'),
            until: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-')
        },
        assignedSalesPerson: currentUserId,
        associatedNetworks: offer.associatedNetworks,
        associatedNetworkNodes: offer.associatedNetworkNodes,
        proposals: []
    }
    if (request.associatedNetworks === '') {
        deleteAttributes(request, ['associatedNetworks']);
    }
    if (request.associatedNetworkNodes === '') {
        deleteAttributes(request, ['associatedNetworkNodes']);
    }
    return request;
}

const includeProposalGuarantees = async (proposalGuarantees: ProposalGuaranteeClass[],
    resourceUidIsNotPresent: (guarantee: UniformOdmName) => boolean) => {
    const associatedPartyUidIsNotPresent = (guarantee: ProposalGuaranteeClass): boolean =>
        !guarantee.associatedParty.resourceUid || guarantee.associatedParty.resourceUid === '';

    const guaranteesToInclude = proposalGuarantees
        .filter((guarantee: ProposalGuaranteeClass) => resourceUidIsNotPresent(guarantee))

    await Promise.all(guaranteesToInclude.map(async (proposalGuarantee: ProposalGuaranteeClass) => {
        deleteAttributes(proposalGuarantee, ['index', 'associatedPartyFlag', 'associatedGuaranteeUnderlying']);

        proposalGuarantee.productGuarantee = pickAttributes(proposalGuarantee.productGuarantee,
            'resourceUid', 'systemUid', 'objectType'
        );

        async function dealWithAnAssociatedPartyInProposalGuarantee() {
            let associatedParty = proposalGuarantee.associatedParty as AssociatedPartyClass;

            if (associatedParty) {

                const partyAsCombo = (associatedParty.party as ComboBoxItem);

                associatedParty.party = {
                    resourceUid: partyAsCombo.value,
                    objectType: partyAsCombo.config.objectType,
                    systemUid: "odm-offer",
                } as UniformOdmName;

                associatedParty = await saveAssociatedPartyApi(associatedParty);

                if (!partyAsCombo.config.fromOffer) {
                    const offerAssociatedParty = {
                        objectType: UON.ObjectType.offerAssociatedParty,
                        systemUid: UON.SystemUid.odmOffer,
                        daaq: "/",
                        associatedParty: pickAttributes(associatedParty,
                            'resourceUid', 'systemUid', 'objectType'
                        ) as UniformOdmName
                    }
                    offerAssociatedParties.push((offerAssociatedParty as OfferAssociatedParty));
                }
                proposalGuarantee.associatedParty = associatedParty;
                deleteAttributes(proposalGuarantee.associatedParty, ['businessData', 'daaq', 'party', 'role']);
            }
        }
        await dealWithAnAssociatedPartyInProposalGuarantee();
    }));

    return guaranteesToInclude
        .filter((proposalGuarantee: ProposalGuaranteeClass) => !associatedPartyUidIsNotPresent(proposalGuarantee));
}

const updateProposalGuarantees = (proposalGuarantees: ProposalGuaranteeClass[], resourceUidIsNotPresent: (guarantee: UniformOdmName) => boolean) => {
    return proposalGuarantees
        .filter((guarantee: ProposalGuaranteeClass) => !resourceUidIsNotPresent(guarantee))
        .map((proposalGuarantee: ProposalGuaranteeClass) => {
            deleteAttributes(proposalGuarantee, ['index', 'associatedPartyFlag']);
            proposalGuarantee.productGuarantee = pickAttributes(proposalGuarantee.productGuarantee,
                'resourceUid', 'systemUid', 'objectType'
            );
            proposalGuarantee.associatedParty = pickAttributes(proposalGuarantee.associatedParty,
                'resourceUid', 'systemUid', 'objectType');
            proposalGuarantee.associatedGuaranteeUnderlying = []
            return proposalGuarantee;
        });
}

/**
 * Asynchronously builds proposal guarantees based on the given proposals.
 *
 * @param {Proposal[]} proposals - The array of proposals to build guarantees from
 * @return {Promise<Array<ProposalGuarantee>>} The promise of an array of built proposal guarantees
 */
export const buildProposalGuarantees = async (proposals: Proposal[]): Promise<Array<ProposalGuaranteeClass>> => {
    const resourceUidIsNotPresent = (guarantee: UniformOdmName): boolean =>
        !guarantee.resourceUid || guarantee.resourceUid === '';

    const proposalGuarantees = store.getters['financingModule/proposalGuaranteeModule/getProposalGuarantees'];
    for (const proposalGuarantee of proposalGuarantees) {
        if (!proposalGuarantee.description) {
            proposalGuarantee.description = doRef(2);
        }
    }

    const guaranteesWithUnderlying: ProposalGuaranteeClass[] = deepClone(proposalGuarantees);

    const guaranteesToInclude = await includeProposalGuarantees(proposalGuarantees, resourceUidIsNotPresent);

    const guaranteesToUpdate = updateProposalGuarantees(proposalGuarantees, resourceUidIsNotPresent)

    proposals[0].proposalItems[0].proposalGuarantees = [...guaranteesToUpdate, ...guaranteesToInclude];

    return guaranteesWithUnderlying;
}

/**
 * Updates associated proposal guarantees with underlying assets.
 *
 * @param {Offer} resultSavedOffer - the saved offer result
 * @param {Array<ProposalGuaranteeClass>} guaranteesWithUnderlying - array of guarantees with underlying items
 * @return {void}
 */
const updateAssociatedProposalGuaranteeWithUnderlyingAssets = (resultSavedOffer: Offer, guaranteesWithUnderlying: Array<ProposalGuaranteeClass>): void => {
    let proposalAssets: Array<ProposalAssetCar | ProposalAssetTruck> = [];
    let proposalGuarantees: Array<ProposalGuaranteeClass> | undefined = [];
    for (const proposal of resultSavedOffer.proposals) {
        for (const proposalItem of proposal.proposalItems) {
            proposalAssets = proposalItem.proposalAssets;
            proposalGuarantees = proposalItem.proposalGuarantees;
        }
    }

    const checkReference = (underlying: ProposalGuaranteeClass) => {
        return (guarantee: ProposalGuaranteeClass) => guarantee.description === underlying.description;
    }

    for (const withUnderlying of guaranteesWithUnderlying) {
        if (withUnderlying.associatedGuaranteeUnderlying) {
            const underlyingItems: AssociatedGuaranteeUnderlyingAssets[] = [];
            for (const proposalAsset of proposalAssets) {
                for (const associatedUnderlying of withUnderlying.associatedGuaranteeUnderlying) {
                    if (proposalAsset.assetProduct?.resourceUid === associatedUnderlying.value) {
                        const underlyingAsset = {
                            objectType: UON.ObjectType.associatedGuaranteeUnderlyingAssets,
                            proposalAsset: pickAttributes(proposalAsset, 'resourceUid', 'systemUid', 'objectType')
                        };
                        underlyingItems.push(underlyingAsset);
                    }
                }
            }
            const recovered = proposalGuarantees?.find(checkReference(withUnderlying));
            if (recovered) {
                recovered.associatedGuaranteeUnderlying = underlyingItems;
                updateProposalGuarantee(recovered).catch(e => console.error(e));
            }
        }
    }
}

export async function handleOfferForSave(offer: Offer, state: RootState): Promise<any> {
    if (state.authModule?.user.userId) {
        const currentUser = state.authModule.user.userId
        let request: Offer = offer
        if (request.reference === '') {
            const reference = (await api()
                .post(`/odm-configuration/api/1/odm-configuration/reference/generate/Offer/`, {})).data.reference
            request = buildOffer(reference, offer, currentUser);
        }
        const {
            offerAssociatedParties,
            associatePartyOriginalParty
        } = await buildAssociatedParties(offer, state.authModule?.daaqResourceUid)
        request.associatedParties = offerAssociatedParties
        request.proposals = await getProposalInfo(state)

        const guaranteesWithUnderlying = await buildProposalGuarantees(request.proposals);

        await handleProposalAssetsParty(request.proposals)
        request.assignedSalesPerson = currentUser
        const resultSavedOffer = await saveOffer(request, state.authModule?.daaqResourceUid);
        await handleOfferPartyBeneficiaries(resultSavedOffer, state, associatePartyOriginalParty);

        updateAssociatedProposalGuaranteeWithUnderlyingAssets(resultSavedOffer, guaranteesWithUnderlying);

        return getOffer(<string>resultSavedOffer.resourceUid, state);
    } else {
        return router.push('/login')
    }
}

let offerAssociatedParties: OfferAssociatedParty[] = [];

export async function buildAssociatedParties(offer: Offer, daaqResourceUid?: string) {

    if (offer.associatedParties) {
        const associatePartyOriginalParty: any = []
        offer.associatedParties.sort(function (a, b) {
            if (a.role_code && a.role_code === "DIRG") {
                return 1;
            }
            if (b.role_code && b.role_code === "DIRG") {
                return -1;
            }
            return 0;
        });
        offerAssociatedParties = Array<OfferAssociatedParty>();
        for (const offerAssociatedParty of offer.associatedParties) {
            const partySaved = await buildParty(offerAssociatedParty, offer.associatedParties, daaqResourceUid);
            let associatedPartyUid
            if (offerAssociatedParty.resourceUid) {
                deleteNonRequiredAttributes(offerAssociatedParty.associatedParty, ['objectType', 'resourceUid', 'systemUid'])
                deleteNonRequiredAttributes(offerAssociatedParty, ['objectType', 'resourceUid', 'systemUid', 'associatedParty', 'daaq','signatureMethod'])
                offerAssociatedParties.push(offerAssociatedParty)
                associatedPartyUid = offerAssociatedParty.associatedParty?.resourceUid
            }
            else {
                const offerAssociatedPartySaved = await buildOfferAssociatedParty(partySaved, offerAssociatedParty.role_code || '', daaqResourceUid,offerAssociatedParty.signatureMethod)
                associatedPartyUid = offerAssociatedPartySaved.associatedParty?.resourceUid
                offerAssociatedParties.push(await buildOfferAssociatedParty(partySaved, offerAssociatedParty.role_code || '', daaqResourceUid,offerAssociatedParty.signatureMethod));
            }
            if (partySaved.objectType.includes(ThirdType.TYPE_PERSON)) {
                associatePartyOriginalParty.push({
                    associatedPartyUid: associatedPartyUid,
                    partyUid: partySaved?.resourceUid
                })
            }

        }

        return { offerAssociatedParties, associatePartyOriginalParty }
    }
    return { offerAssociatedParties: undefined, associatePartyOriginalParty: [] }
}

export const deleteManager = async (params: { manager: AssociatedParty, daaqResourceUid: string }) => {
    const { manager, daaqResourceUid } = params;
    if (manager.resourceUid) {
        const result = await useApi(
            UON.SystemUid.odmOffer,
            Paths.associatedParty
        ).remove(manager.resourceUid, daaqResourceUid);
        if (result.error) {
            console.error("Error: {}", result.error);
            throw new Error(`${i18n.global.t('demand.party.manager.delete')} ${manager.resourceUid}`)
        }
    }
}

async function handleProposalAssetsParty(proposals: Proposal[]) {
    for (const proposal of proposals) {
        for (const proposalItem of proposal.proposalItems) {
            for (const proposalAsset of proposalItem.proposalAssets) {
                if (proposalAsset.party && proposalAsset.party.party && proposalAsset.party.party.resourceUid) {
                    const associatedPartyResourceUid = await saveAssociatedParty(proposalAsset.party)
                    proposalAsset.party = {
                        resourceUid: associatedPartyResourceUid,
                        objectType: 'odm.offer.associatedparty',
                        systemUid: 'odm-offer'
                    }
                }
            }
        }
    }
}

async function handleOfferPartyBeneficiaries(offer: any, state: RootState, associatePartyOriginalParty: any) {
    const contractOriginationId = offer.proposals[0].proposalItems[0].resourceUid
    const scale = state.financingModule.scalesModule.scaleSelected
    if (scale && contractOriginationId) {
        const servicePartyInsuranceBeneficiary: any = []
        for (const partyInsurance of scale.scalePresentation.servicePartyInsurance.filter((p: any) => p._controls.selected)) {
            const associatedInsuranceBeneficiaries: any = []
            for (const partyInsuranceItem of partyInsurance.beneficiaries.filter((b: any) => b._controls.selected)) {
                const associatedPartyUid: any = associatePartyOriginalParty
                    .filter((a: any) => a.partyUid === partyInsuranceItem.partyUid)
                    .map((a: any) => a.associatedPartyUid)[0]
                if (associatedPartyUid) {
                    const payload = {
                        objectType: 'odm.offer.associatedinsurancebeneficiaries',
                        systemUid: 'odm-offer',
                        coverage: partyInsuranceItem.coverage,
                        basisValue: partyInsuranceItem.basisValue,
                        basis: partyInsuranceItem.basis,
                        rate: partyInsuranceItem.rate,
                        taxValue: partyInsuranceItem.taxValue,
                        amountWTax: {
                            amount: partyInsuranceItem.amountWTax,
                            currency: 'EUR'
                        },
                        amountWoTax: {
                            amount: partyInsuranceItem.amountWoTax,
                            currency: 'EUR'
                        },
                        associatedParty: {
                            resourceUid: associatedPartyUid,
                            objectType: 'odm.offer.associatedparty',
                            systemUid: 'odm-offer'
                        },
                    }
                    associatedInsuranceBeneficiaries.push(payload)
                }

            }
            if (associatedInsuranceBeneficiaries.length) {
                await useApi('odm-offer', `contractorigination/${contractOriginationId}/proposalpartyinsurance/`).upsert({
                    associatedInsuranceBeneficiaries,
                    basisValue: partyInsurance.basisValue,
                    taxValue: partyInsurance.taxValue,
                    amountWTax: {
                        amount: partyInsurance._controls.amountValueWTax,
                        currency: 'EUR'
                    },
                    amountWoTax: {
                        amount: partyInsurance._controls.amountValueWoTax,
                        currency: 'EUR'
                    },
                    accessoryProduct: {
                        resourceUid: partyInsurance.resourceUid || partyInsurance.productUid,
                        objectType: 'odm.product.productfeature.product.accessoryproduct',
                        systemUid: 'odm-product'
                    }, //
                    proposalAccessoryCalculationMethodOption: {
                        resourceUid: partyInsurance.calculationMethodOption,
                        objectType: 'odm.offer.proposalaccessorycalculationmethodoption',
                        systemUid: 'odm-offer'
                    },
                    proposalAccessoryPaymentDatesOption: {
                        resourceUid: partyInsurance.paymentDatesOption,
                        objectType: 'odm.offer.proposalaccessorypaymentdatesoption',
                        systemUid: 'odm-offer'
                    },
                    objectType: 'odm.offer.proposalaccessories.proposalinsurance.proposalpartyinsurance',
                    systemUid: 'odm-offer',
                });
            }
        }
    }

}

async function saveAddresses(address: Address, roles?: Role[]) {
    delete address.isDisabled;

    const clonedAddress = deepClone(address);

    delete address.type;

    if (address.postalCode && !address.postalCode.resourceUid) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }
    if (address.postalCode && address.postalCode.type && address.postalCode.type.value) {
        address.postalCode.resourceUid = typeof address.postalCode.type?.value === 'string' ? address.postalCode.type?.value : address.postalCode.type?.value?.value
    }
    deleteNonRequiredAttributes(address.postalCode, ["resourceUid"]);

    if (address.street) {
        const result = await useApi('odm-party', 'address').upsert(address);
        if (result.response) {
            if (roles && roles.length > 0) {
                for (const role of roles) {
                    const params = {
                        preferences: role.preferences,
                        partyAddress: clonedAddress,
                        addressUID: result.response.resourceUid
                    }
                    const rolePreference = await buildPreferences(params);
                    role.preferences = rolePreference;
                }
            }
            return result.response.resourceUid;
        }
        else console.error(`ERROR: address was not saved [${result.error}]`);
    }
}

export async function saveOffer(offer: Offer, daaqResourceUid?: string): Promise<Offer> {
    const offerCloned = deepClone(offer)
    formatDateRecursively(offerCloned, 'DD/MM/YYYY', Masks.dateMask)
    return createOffer(offerCloned, {
        systemUid: UON.SystemUid.odmOffer,
        path: `${UON.SystemUid.odmOffer}/${Paths.offer}/`
    }, daaqResourceUid)
}

const makePersonAddress = async (person: Person) => {
    const promises = person?.addresses?.map(partyAddress => saveAddresses(partyAddress.address, person.roles));
    let livingSituation = ''
    if (promises) {
        const allAddressSaved = await Promise.all(promises)
        const personAddress = []
        let index = 0;
        for (const addr of allAddressSaved) {
            if (addr) {
                const partyAddressRelationship = person?.addresses?.filter(partyAddress => partyAddress.address.resourceUid === addr)[0];
                if (person?.addresses) {
                    livingSituation = (person.addresses[index] as any).livingSituation && (person.addresses[index] as any).livingSituation.resourceUid ? (person.addresses[index] as any).livingSituation.resourceUid : (person.addresses[index] as any).livingSituation  &&  (person.addresses[index] as any).livingSituation.type ? (person.addresses[index] as any).livingSituation.type.value : ''

                }
                const partyAddress: PartyAddress = {
                    resourceUid: partyAddressRelationship?.resourceUid || undefined,
                    objectType: 'odm.party.partyaddress',
                    daaq: "/",
                    address: {
                        resourceUid: addr,
                        objectType: 'odm.party.address'
                    },
                    livingSituation: livingSituation !== '' && livingSituation !== undefined ? {
                        resourceUid: livingSituation
                    } : null,

                }
                personAddress.push(partyAddress)
            }
            index++
        }
        person.addresses = personAddress
    }
}

//TODO: To remove
async function handleWithAddress(party: Third) {
    const promises = party.person?.addresses?.map(partyAddress => saveAddresses(partyAddress.address))
    if (promises) {
        const allAddressSaved = await Promise.all(promises)
        for (const addr of allAddressSaved) {
            if (addr) {
                const partyAddress: PartyAddress = {
                    daaq: "/",
                    address: {
                        resourceUid: addr,
                        objectType: 'odm.party.address'
                    },
                    objectType: 'odm.party.partyaddress'
                }
                if (party.person?.addresses) {
                    party.person.addresses = []
                    //const index = party.person.addresses.findIndex(item => item.address.resourceUid === partyAddress.address.resourceUid)
                    //if (index > -1) party.person.addresses.splice(index, 1)
                    party.person.addresses.push(partyAddress)
                }
            }
            else {
                if (party.person?.addresses) party.person.addresses = []
            }
        }
    }
}

function validateMaritalStatuses(person: Person) {
    return person?.currentMaritalStatus && person.currentMatrimonialRegime
}

const makePerson = async (person: Person, daaqResourceUid?: string) => {
    unmountSelectFieldsV2(person, [
        'title',
        'nationality',
        'role',
        'status',
        'currentMaritalStatus',
        'currentMatrimonialRegime',
        'nature',
        'periodicity',
        'classifications',
        'gender'
    ]);

    deleteAttributes(person, ['validity', 'relationType', 'managerType', 'show','type','status']);

    person.daaq = daaqResourceUid ?? '/';

    if (person.correspondents) {
        person.correspondents = person.correspondents.filter(correspondent => {
            return correspondent.firstName !== "" && correspondent.familyName !== "" && correspondent.qualificationType.resourceUid !== "";
        });
        await buildPartyCorrespondent(person.correspondents);
    }
    if (person.contacts) {
        await buildPartyContacts(person.contacts);
        person.contacts.forEach(contact =>{
            if( contact.contactMechanism &&  ! contact.contactMechanism.resourceUid){
              deleteAttributes(contact, ['contactMechanism','daaq']);
            }
          } )
    }

    if (person.title && !person.title.resourceUid){
        person.title.resourceUid = 'M'
    }

    if (person.ids && person.ids[0]?.reference!=='') {
        buildIds(person)
    }
    else{
        delete person.ids
        person.ids = []
    }

    if (person.addresses) {
        await makePersonAddress(person);
    }

    if (person.homemakers !== "") {
        buildBusinessDataPerson(person)
    }

    if (person.nationality && person?.nationality.resourceUid !== '') {
        buildCitizenshipsPerson(person)
    } else {
        delete person.nationality
    }

    if ((person.birthplace && person.birthplace.resourceUid && person.birthplace.resourceUid !== '') || (person.birthplace && person.birthplace.postalCode && person.birthplace.postalCode !== '')) {
        buildBirthplace(person)
    } else {
        delete person.birthplace
    }
    if (person.gender && !person.gender.resourceUid) {
        delete person.gender
    }

    if (validateMaritalStatuses(person)) {
        buildSituation(person)
    } else {
        delete person.maritalStatuses
        person.maritalStatuses = []
    }
    if (person.revenues && person.revenues.length > 0 && person.revenues[0].analysisDate) {
        buildAnalysis(person)
    } else {
        delete person.revenues
        person.revenues = []
    }
    if (person.financialExposures && person.financialExposures.length > 0) {
        person.financialExposures = buildFinancialExposures(person.financialExposures)
    }
    if (person.jobs && person.jobs.length > 0 && person.jobs[0].employerName) {
        person = await buildJobs(person, true)
        person.jobs?.map((job: any) => {
            if (job.jobLocation && !job.jobLocation.resourceUid) {
                delete job.jobLocation
            }
        })

    } else {
        delete person.jobs
        person.jobs = []
    } if (person.classifications && person.classifications.length > 0) {
        unmountSelectFieldsV2(person.classifications, [
            'partyClass'
        ]);
        person.classifications.forEach((value: any) => {
            delete value.classType
        })
    }
    if (person.accounts) {
        const accounts: Account[] = []
        let i = 0
        for (const item of person.accounts) {
            const data: Account = {
                objectType: 'odm.party.partybankaccount',
                bankAccount: {
                    resourceUid: item.bankAccount.resourceUid,
                    objectType: 'odm.party.bankaccount'
                },
                daaq: '/',
                resourceUid: person.accounts[i].resourceUid ? person.accounts[i].resourceUid : ''
            }
            accounts.push(data);
            i++
        }
        person.accounts = accounts
    }

    deleteAttributes(person, [
        'currentMaritalStatus',
        'currentMatrimonialRegime',
        'homemakers',
        'retirementPassage',
        'birthChildren',
        'removedSituation',
        'age',
    ])
    person.objectType = UON.ObjectType.frenchPerson;
    person.systemUid = UON.SystemUid.odmParty;
    delete person.relations

    if (!person.reference || person.reference === '') {
        person.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
    }
    delete person.driverLicenceDate

    return savePerson(person, 'odm-party', daaqResourceUid);
}

const saveManager = async (options: { associatedParty: AssociatedParty, parties?: OfferAssociatedParty[], daaqResourceUid?: string }) => {
    const { associatedParty, parties, daaqResourceUid } = options;
    const organization = associatedParty.third?.organization;

    if (organization?.manager && organization.manager.firstName) {
        unmountSelectFieldsV2(organization.manager, ['title', 'role', 'status', 'birthplace']);
        deleteAttributesRecursively(organization.manager, ['age', 'show']);

        const person = deepClone(organization.manager);

        organization.manager = {
            ...organization.manager,
            ...await makePerson(person, daaqResourceUid) as Person
        }

        const result = await useApi(
            UON.SystemUid.odmParty, Paths.partyRelation
        ).upsert((await buildManagerRelation(organization))?.upsert(), daaqResourceUid);

        if (result.error) throw new Error(`save manager method => ${result.error}`);

        if (!parties?.find(el => el.role_code === Roles.DIRG)) {
            offerAssociatedParties.push(await buildOfferAssociatedParty(organization.manager, Roles.DIRG, daaqResourceUid));
        }
        delete organization.manager;
    }
    else if (organization?.relations && organization.relations.length > 0) {
        const partyRelations = organization.relations.filter((el: any) => {
            const from = el.validity.from;
            const until = el.validity.until;
            if (from) {
                return useDate.isPeriodValid(useDate.currDate, from, until);
            }
        });
        for (const relation of partyRelations) {
            if (!parties?.find(el => el.associatedParty?.third?.resourceUid === relation.partyAsc.resourceUid)) {
                offerAssociatedParties.push(await buildOfferAssociatedParty({
                    resourceUid: relation.partyAsc.resourceUid,
                    objectType: UON.ObjectType.frenchPerson,
                    systemUid: UON.SystemUid.odmParty
                }, Roles.DIRG, daaqResourceUid))
            }
        }
    }
}


function buildIds(person: any) {

    if (person.ids && person.ids.length > 0) {
        person.ids?.forEach((item: any) => {
            item.emissionDate = item.validity.from ? formatDateLocale(item.validity.from, 'YYYY-MM-DD', 'fr') : '';
            item.validity.from = item.validity.from ? formatDateLocale(item.validity.from, 'YYYY-MM-DD', 'fr') : '';
            item.validity.until = item.validity.until ? formatDateLocale(item.validity.until, 'YYYY-MM-DD', 'fr') : '';
            Object.keys(item.documentType).forEach(index => {
                item.documentType.resourceUid = item.documentType.type?.value;
            });
            delete item.documentType.type
            delete item.isDisabled
        });
    } else {
        delete person.ids
        person.ids = []
    }


}

function buildFinancialExposures(financialExposures: any) {
    const exposures: any[] = []
    if (financialExposures && financialExposures.length > 0) {
        financialExposures.map((item: any) => {
            const exposure =
            {
                resourceUid: item.resourceUid,
                objectType: item.objectType,
                systemUid: item.systemUid,
                daaq: item.daaq,
                grossOutstanding: {
                    amount: item.grossOutstanding,
                    currency: "EUR"
                },
                netOutstanding: {
                    amount: item.netOutstanding,
                    currency: "EUR"
                },
                residualValue: {
                    amount: item.residualValue,
                    currency: "EUR"
                },
                flagFinancialPool: item.flagFinancialPool,
                flagRiskPool: item.flagRiskPool,
                shareFinancialPool: item.shareFinancialPool,
                shareRiskPool: item.shareRiskPool,
                effectiveDate: item.effectiveDate,
                contractReference: item.contractReference,
                contractValidity: {
                    from: item.contractValidity?.from ? item.contractValidity.from : '',
                    until: item.contractValidity?.until ? item.contractValidity.until : '',
                },
                outstandingType: item.outstandingType?.value ? item.outstandingType?.value : item.outstandingType,
                financialInstitution: item.financialInstitution?.value ? item.financialInstitution?.value : item.financialInstitution,

            }
            exposures.push(exposure)
        })

        return exposures;
    }
    else {
        return []
    }

}

function deletedManagerIsDifferentFromOrganizationManager(associatedParty: AssociatedParty) {
    const getDeletedManager = store.getters['demandModule/getDeletedManager'];
    if (getDeletedManager) {
        return associatedParty.third?.organization?.manager &&
            getDeletedManager.third.person.resourceUid !== associatedParty.third?.organization?.manager?.resourceUid;
    }
    else return true;
}

async function buildOrganization(associatedParty: AssociatedParty | undefined, parties?: OfferAssociatedParty[], daaqResourceUid?: string) {
    if (associatedParty?.third?.organization) {
        const organization = associatedParty.third.organization;
        daaqResourceUid = store?.state?.authModule?.daaqResourceUid
        unmountSelectFieldsV2(organization, ['legalCategory', 'activitySector', 'partyClass', 'codeNACE'])
        if (!organization.codeNACE?.resourceUid) {
            delete organization.codeNACE
        }
        organization.daaq = '/',
            organization.objectType = UON.ObjectType.frenchOrganization;
        organization.systemUid = UON.SystemUid.odmParty;
        if (organization.classifications && organization.classifications.length > 0) {
            unmountSelectFieldsV2(organization.classifications, [
                'partyClass'
            ]);
            organization.classifications.forEach((value: any) => {
                delete value.classType
            })
        }
        if (organization.financialExposures && organization.financialExposures.length > 0) {
            organization.financialExposures = buildFinancialExposures(organization.financialExposures)
        }
        await buildPartyCorrespondent(organization.correspondents);
        await buildOrganizationAddress(associatedParty.third);
        if (!organization.legalCategory?.resourceUid) {
            delete organization.legalCategory
        }

        const clone = deepClone(organization);
        delete clone.manager
        delete clone.relations
        delete clone.codeApe
        delete clone.type
        delete clone.status
        clone.facilities.forEach((value: any) => {
            delete value.livingSituation
        })

        const savedOrganization = saveOrganization(clone, 'odm-party', daaqResourceUid);

        organization.resourceUid = (await savedOrganization).resourceUid

        if (deletedManagerIsDifferentFromOrganizationManager(associatedParty)) {
            await saveManager({ associatedParty, parties, daaqResourceUid });
        }

        return savedOrganization;
    }
}

function checkOrganization(type: PicklistItem | string | undefined): boolean {
    if (typeof type === 'object') {
        return type.id === ThirdType.ORGANIZATION
    }
    else {
        return !!type?.includes(ThirdType.TYPE_ORGANIZATION)
    }
}

function buildParty(offerAssociatedParty: OfferAssociatedParty, parties?: OfferAssociatedParty[], daaqResourceUid?: string) {
    let isOrganization: boolean
    if (offerAssociatedParty.associatedParty?.third?.type) {
        isOrganization = checkOrganization(offerAssociatedParty.associatedParty?.third?.type)
    } else {
        isOrganization = checkOrganization(offerAssociatedParty.associatedParty?.third?.objectType)
    }
    if (isOrganization) {
        return buildOrganization(offerAssociatedParty.associatedParty, parties, daaqResourceUid)
    } else {
        return makePerson(<Person>offerAssociatedParty.associatedParty?.third?.person, daaqResourceUid);
    }
}

async function buildOfferAssociatedParty(party: UniformOdmName | any, role: string, daaqResourceUid?: string, signatureMethod?: any) {
    const associatedParty: AssociatedParty = {
        party: {
            resourceUid: party.resourceUid,
            objectType: party.objectType,
            systemUid: party.systemUid
        },
        role: {
            resourceUid: role,
            objectType: 'odm.offer.associatedpartyrole',
            systemUid: 'odm-offer'
        }
    }
    const offerAssociatedParty: OfferAssociatedParty = {
        id: 0,
        showDeleteBtn: false,
        tabName: "",
        objectType: UON.ObjectType.offerAssociatedParty,
        systemUid: UON.SystemUid.odmOffer,
        daaq: "/",
        signatureMethod: signatureMethod,
        associatedParty: {
            resourceUid: await saveAssociatedParty(associatedParty, daaqResourceUid),
            objectType: UON.ObjectType.associatedParty,
        }
    }
    deleteNonRequiredAttributes(offerAssociatedParty, ['objectType', 'systemUid', 'daaq', 'resourceUid', 'associatedParty','signatureMethod']);
    return offerAssociatedParty;
}

const saveOrganization = async (organization: Organization, systemUid: string, daaqResourceUid?: string) => {
    organization.correspondents?.forEach(correspondent => {

        correspondent.contactCorrespondent.forEach(contact =>{
          if( ! contact.contactMechanism.resourceUid){
            deleteAttributes(contact, ['contactMechanism','daaq']);
          }
        } )

        deleteAttributes(correspondent, ['businessData']);
        deleteNonRequiredAttributes(correspondent.qualificationType, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.contacts?.forEach(contact => {
        deleteNonRequiredAttributes(contact.contactMechanism, ['objectType', 'systemUid', 'resourceUid']);
    })

    if (organization.facilities) {

        organization.facilities.forEach(item => {
            deleteNonRequiredAttributes(item.address, ['objectType', 'systemUid', 'resourceUid']);
            if (item.address?.resourceUid === undefined) {
                item.address = null;
            }
        })
        organization.facilities.forEach(item => {
            delete item.facilityType?.type
        })
    }

    organization.addresses?.forEach(address => {
        deleteNonRequiredAttributes(address.address, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.accounts?.forEach(account => {
        deleteNonRequiredAttributes(account.bankAccount, ['objectType', 'systemUid', 'resourceUid']);
    })

    organization.roles?.forEach(role => {
        unmountSelectFieldsV2(role, ['status', 'role'])
    })

    if (!organization.reference || organization.reference === '') {
        organization.reference = (await api().post(`/odm-configuration/api/1/odm-configuration/reference/generate/Party/`, {})).data.reference
    }
    organization.financialExposures?.forEach((financialExposure: any) => {
        if (financialExposure.outstandingType.value === "") {
            delete financialExposure.outstandingType
        }
        if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
            delete financialExposure.financialInstitution
        }
    })


    buildBalanceSheets(organization);


    if (organization.roles) {
        // Iterate through each item in the array
        for (const item of organization.roles) {
            // Check if 'preferences' key exists and it's not empty
            if (item.preferences && item.preferences.length > 0) {
                item.preferences = removeDuplicatesPreferences(item.preferences);
            }
        }
    }

    return upsertWithDataReturn(systemUid, organization, Paths.frenchOrganization, undefined, daaqResourceUid);

}

const buildBalanceSheets = (organization: Organization) => {
    organization.balanceSheets?.forEach(balance => {
        deleteNonRequiredAttributes(balance, ['objectType', 'systemUid', 'resourceUid', 'period', 'country','label', 'supportingDocument','detail', 'status']);
        const countryUID = balance.country.id ? balance.country.id : ''
        const statusUID = balance.status.id ? balance.status.id : ''
        if (countryUID && countryUID !== '') {
            balance.country = {
                resourceUid: countryUID
            }
        }
        if (statusUID && statusUID !== '') {
            balance.status = {
                resourceUid: statusUID
            }
        }
        if (!balance.period) {
            balance.period = { until: '', from: '' }
        }
        if (!(balance.status && balance.status.resourceUid)) {
            delete balance.status
        }
        if (!(balance.country && balance.country.resourceUid)) {
            delete balance.country
        }
        if (balance.detail) {
            balance.detail.forEach((dt: any) => {
                const nature = dt.nature.id;
                dt.nature = {
                    objectType: 'odm.party.balanceitemtype',
                    resourceUid: nature
                };
                dt.objectType = 'odm.party.balanceitem';
                dt.daaq = '/'
            });
        }
        delete balance.isDisabled
    })
}

const savePerson = async (person: Person, systemUid: string, daaqResourceUid?: string) => {
    person.financialExposures?.forEach((financialExposure: any) => {
        if (financialExposure.outstandingType && financialExposure.outstandingType.value === "") {
            delete financialExposure.outstandingType
        }
        if (financialExposure.financialInstitution && financialExposure.financialInstitution.value === "") {
            delete financialExposure.financialInstitution
        }

    })

    if (person.roles) {
        // Iterate through each item in the array
        for (const item of person.roles) {
            // Check if 'preferences' key exists and it's not empty
            if (item.preferences && item.preferences.length > 0) {
                item.preferences = removeDuplicatesPreferences(item.preferences);
            }
        }
    }

    return upsertWithDataReturn(systemUid, person, Paths.frenchPerson, undefined, daaqResourceUid);
}

export function searchOfferParty(filterOffer: OfferSearch, partyFilter: FilterParty): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const offerResults = await searchOffer(filterOffer);
            const partyResults = await filterParty(partyFilter);
            const filterResult = [...offerResults, ...partyResults];
            resolve(filterResult);
        } catch (error) {
            reject(error);
        }
    });
}

export function searchOffer(filter: OfferSearch): Promise<OfferSearch[]> {
    return new Promise<OfferSearch[]>((resolve, reject) => {
        const url = settings.api_query_url;

        const qc = getQcOffer(filter);
        const _headers = headers({
            qc: JSON.stringify(qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

const i18nRoleName = (roleCode: string) => i18n.global.t('demand.party.roles.'.concat(roleCode))

const buildValidity = (partyRelation: PartyRelation) => {
    let validity = {
        from: '',
        until: ''
    }
    if (partyRelation.validity) {
        validity = (partyRelation.validity);
        formatDateRecursively(validity);
    }
    return validity;
}

const buildManagers = (associatedParties: Array<any>) => {
    associatedParties.forEach(el => {
        if (el.associatedParty?.role?.resourceUid === Roles.CUSTOMER &&
            el.associatedParty.third.objectType?.includes('organization')) {
            const relations = el.associatedParty.third.organization.relations;
            if (relations && relations.length > 0) {
                relations.forEach((relation: any) => {
                    let associated: any = null
                    if (el.associatedParty.third.person) {
                        associated = associatedParties.find(
                            el => el.associatedParty.third.person &&
                                el.associatedParty.third.person.resourceUid === relation.party_asc_id
                        );
                    }

                    if (associated) {
                        associated.associatedParty.third.person.managerType = relation.relation_type_code
                    }
                })
            }
        }
    })
}

const buildManager = async (associatedParties: Array<any>) => {
    const customer = associatedParties.find((el: any) => el.associatedParty?.role.resourceUid === Roles.CUSTOMER);
    if (customer.associatedParty.third.objectType?.includes('organization')) {
        const offerAssociatedParty = associatedParties.find((el: OfferAssociatedParty) =>
            isUON(el.associatedParty?.role) && el.associatedParty?.role.resourceUid === Roles.DIRG);

        const organization = customer.associatedParty.third.organization;
        if (offerAssociatedParty && organization) {
            organization.manager = offerAssociatedParty.associatedParty.third.person;
        }
        else organization.manager = store.getters["demandModule/getManagerInit"];
    }

}

const buildTabs = (associatedParties: Array<any>) => {
    associatedParties.forEach((offerAssociatedParty: any, i: number) => {
        if (offerAssociatedParty.associatedParty.third && offerAssociatedParty.associatedParty.third.person) {
            const person = offerAssociatedParty.associatedParty.third.person;
            const roleCode = offerAssociatedParty.associatedParty.role?.resourceUid;
            offerAssociatedParty.id = i + 1
            offerAssociatedParty.tabName = i18nRoleName(roleCode) || ''
            if (person && person.managerType) {
                offerAssociatedParty.tabName = i18n.global.t('demand.party.managerType.'.concat(person.managerType)) || ''
            }
            offerAssociatedParty.showDeleteBtn = i > 0
            offerAssociatedParty.role_code = roleCode
            offerAssociatedParty.uid = offerAssociatedParty.associatedParty.third.resourceUid

        }

    })
}

async function getOfferAssociatedParties(offer: any) {
    const associatedParties: Array<any> = [];

    for (const el of offer.associatedParties) {
        const i: number = offer.associatedParties.indexOf(el);
        const offerAssociatedParty = offer.associatedParties[i]
        const associatedParty = offerAssociatedParty.associatedParty
        offerAssociatedParty.associatedParty = await getAssociatedParty(associatedParty?.resourceUid);
        if (el.associatedParty?.role.resourceUid) {
            offerAssociatedParty.associatedParty.role_code = el.associatedParty.role.resourceUid
        }
        if (el.signatureMethod && el.signatureMethod.resourceUid){
            offerAssociatedParty.signatureMethod = el.signatureMethod.resourceUid
        }

        associatedParties.push(offerAssociatedParty);
    }

    await buildManager(associatedParties);

    buildManagers(associatedParties);

    const fromIndex = associatedParties.findIndex((el: any) => el.associatedParty?.role.resourceUid === Roles.DIRG);
    const toLastPosition = associatedParties.length - 1;

    moveElementInArray(associatedParties, fromIndex, toLastPosition);

    buildTabs(associatedParties);
}

export async function getOffer(resourceUid: string, state?: RootState): Promise<any> {
    const offer: any = await getEntity('odm-offer', 'offer', resourceUid)
    if (offer) {
        if (offer?.associatedParties) {
            await getOfferAssociatedParties(offer);
        }
        const offerAssets = []
        let proposalScale
        for (const proposal of offer.proposals) {
            for (const proposalItem of proposal.proposalItems) {
                if (state) {
                    proposalScale = await completeProposalInfo(proposal, proposalItem, offer, state)
                }
                for (const proposalAsset of proposalItem.proposalAssets) {
                    switch (proposalAsset.objectType) {
                        case 'odm.offer.proposalasset.proposalgood.proposalvehicle.proposalcar':
                            await completeProposalAssetCarInfo(proposalAsset)
                            break
                        case 'odm.offer.proposalasset.proposalgood.proposalvehicle.proposaltruck':
                            await completeProposalAssetTruckInfo(proposalAsset)
                            break
                    }
                    if (proposalAsset.usage && proposalAsset.usage.resourceUid) {
                        proposalAsset.usage = {
                            value: proposalAsset.usage.resourceUid,
                            label: i18n.global.t("demand.asset.usageItems." + proposalAsset.usage.resourceUid)
                        }
                    }
                    if (proposalAsset.color && proposalAsset.color) {
                        proposalAsset.color = {
                            value: proposalAsset.color,
                            label: proposalAsset.color?.toString() !== "" ? i18n.global.t("demand.asset.colors." + proposalAsset.color.replace("COLOR.", "")) : ''
                        }
                    }
                    if (proposalAsset.assetDelivery && proposalAsset.assetDelivery.resourceUid) {
                        proposalAsset.assetDelivery = await completeAssetDeliveryInfo(proposalAsset.assetDelivery)
                        if (proposalAsset.assetDelivery.supplierAddressLabel) {
                            proposalAsset.config.formBind.supplierAddress = proposalAsset.assetDelivery.supplierAddressLabel
                            delete proposalAsset.assetDelivery.supplierAddressLabel
                        }

                    }
                    if (proposalAsset.proposalAssetCategories && proposalAsset.proposalAssetCategories.length !== 0) {
                        proposalAsset.proposalAssetCategories = proposalAsset.proposalAssetCategories.filter((item: any) => item.category !== '' && item.categoryClass !== '');

                        for (const proposalAssetCategorie of proposalAsset.proposalAssetCategories) {
                            if (proposalAssetCategorie.category) {
                                proposalAssetCategorie.category = {
                                    value: proposalAssetCategorie.category,
                                    label: i18n.global.t("demand.asset.categoryItems." + proposalAssetCategorie.category)
                                }
                            }
                            if (proposalAssetCategorie.categoryClass) {
                                proposalAssetCategorie.categoryClass = {
                                    value: proposalAssetCategorie.categoryClass,
                                    label: i18n.global.t("demand.asset.subCategoryItems." + proposalAssetCategorie.categoryClass.substring(proposalAssetCategorie.categoryClass.lastIndexOf(".") + 1))
                                }
                            }
                        }
                    }
                    offerAssets.push(proposalAsset)
                }
                if (proposalItem.proposalGuarantees) {
                    await completeProposalGuaranteeInfo({
                        guarantees: proposalItem.proposalGuarantees,
                        assets: proposalItem.proposalAssets
                    })
                }
            }
        }
        return Promise.resolve({ offer, offerAssets, proposalScale })
    }
}
const completeAssetDeliveryInfo = async (assetDelivery: any) => {
    if (assetDelivery.resourceUid) {
        const body: any = {}
        const baseUrl = `${settings.api_url}/odm-offer/api/1/odm-offer/proposalassetdelivery/${assetDelivery.resourceUid}/`;
        const result = await (await api().get(`${baseUrl}`)).data;
        body.deliveryDate = result.deliveryDate
        if (result.deliveryAddress && result.deliveryAddress.resourceUid) {
            const address = await getEntity('odm-party', 'address', result.deliveryAddress.resourceUid)
            body.deliveryAddress = result.deliveryAddress.resourceUid

            const resultPostalCode = (await useApi(UON.SystemUid.odmParty, 'frenchpostalcode').get(address.postalCode.resourceUid)).response;
            const country_code = resultPostalCode.country.resourceUid ? i18n.global.t("demand.party.country." + resultPostalCode.country.resourceUid) : ''
            const supplierAddressLabel = 'N° ' + address.streetNum + ',' + address.street + ' ,' + resultPostalCode.postalCode + ' ' + resultPostalCode.city + ' ' + country_code
            body.supplierAddressLabel = supplierAddressLabel
        }

        return body
    }
}

const completeProposalGuaranteeInfo = async (options: { guarantees: ProposalGuaranteeClass[], assets: ProposalVehicle[] }) => {
    const { guarantees, assets } = options
    if (guarantees.length) {
        for (const guarantee of guarantees) {
            const { objectType, resourceUid } = guarantee.productGuarantee as UniformOdmName;
            const guaranteeType = objectType?.substring((objectType as string)?.lastIndexOf('.') + 1);
            guarantee.productGuarantee = await getProductGuarantee((resourceUid as string), (guaranteeType as string));
            if (guarantee.associatedParty) {
                guarantee.associatedParty = await getAssociatedParty(guarantee.associatedParty?.resourceUid);
            }

            for (const proposalAsset of assets) {
                if (guarantee.associatedGuaranteeUnderlying)
                    for (const [index, underlyingAsset] of guarantee.associatedGuaranteeUnderlying.entries()) {
                        if (proposalAsset.resourceUid === underlyingAsset.proposalAsset?.resourceUid) {
                            underlyingAsset.label = proposalAsset.description;
                            underlyingAsset.value = proposalAsset.assetProduct?.resourceUid;
                            guarantee.associatedGuaranteeUnderlying[index] = underlyingAsset
                        }
                    }
            }
        }
        await store.dispatch('financingModule/proposalGuaranteeModule/setProposalGuarantees', guarantees);
    }
}

async function completeProposalInfo(proposal: any, proposalItem: any, offer: Offer, state: RootState) {
    if (!proposalItem.financingProduct || !proposalItem.financingProductPack || !proposalItem.financingOffer) {
        throw new Error('Invalid offer, one or more mandatory ids are not set')
    }
    const financingProduct = proposalItem.financingProduct || '.'
    const financingProductPackResourceUid = proposalItem.financingProductPack.resourceUid
    const financingOfferResourceUid = proposalItem.financingOffer.resourceUid
    const financingProductPack = (await api().get(`/${UON.SystemUid.odmProduct}/api/1/${UON.SystemUid.odmProduct}/financingproductpack/${financingProductPackResourceUid}/`)).data
    const financingOffer = (await callCompleteOfferApi(financingOfferResourceUid, offer)).data
    //TODO TP-3076
    const packItems = await getPackItemsEditMode(financingProductPackResourceUid, offer)
    const serviceItems = buildScaleServiceItems(packItems, proposalItem)
    const scalePresentation: any = await buildScalePresentation(proposal, proposalItem, financingOffer, serviceItems)
    const financialSituation = buildFinancialSituation(proposalItem)
    const scale = {
        periodRateOption: proposalItem.periodRateOption.resourceUid,
        paymentDatesOption: proposalItem.paymentDatesOption.resourceUid,
        paymentTermsOption: proposalItem.paymentTermsOption.resourceUid,
        calculationResult: {},
        investmentBasis: packItems.investmentBasis,
        financialSituation,
        resourceUid: financingProductPackResourceUid,
        productName: financingProductPack.name,
        category: financingProduct.split('.')[0],
        categoryClass: financingProduct.split('.')[1],
        scalePresentation,
        items: [...packItems.items]
    }
    const proposalItemCalc: any = deepClone(proposal.proposalItems[0])
    const financedValue = proposalItemCalc.financedValue
    proposalItemCalc.financedValue = null
    proposalItemCalc.price = financedValue
    let taxValue: any = null
    const taxCode = packItems.taxOnInterest;
    const partDirig: any = []
    const partyInfo = []
    for (const item of (offer.associatedParties || [])) {
        const offerAssociatedParty: any = item
        const { role_code } = offerAssociatedParty
        const { third } = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
        if (type.includes("Organization")) {
            if (body.manager) partDirig.push(body.manager)
        }
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        const taxRequest = {
            geographicZone: body.taxGeographicZone,
            country: body.citizenships && body.citizenships[0] && body.citizenships[0]?.country.resourceUid,
            evaluationDate: formattedDate,
            objectType: "",
            systemUid: "",
            resourceUid: "",
            businessData: {},
            daaq: ""
        }

        if (role_code === 'CUSTOMER') {
            taxValue = await setTaxValue(taxRequest, taxCode);
        }

        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        partyInfo.push({
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        })
        if (partDirig.length) {
            partyInfo.push({
                partyData: JSON.stringify({
                    type: 'party-FrenchPerson',
                    role: partDirig[0].roles[0].role.resourceUid,
                    flagInsured: true,
                    ...partDirig[0]
                })
            })
        }
    }

    proposal.proposalItems.forEach((el: any) => {
        for (const key in el) {
            if (el[key] === undefined) {
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        delete el.taxCode
        delete el.taxValue
        el.taxCode = taxCode
        el.taxValue = taxValue
    })
    const quoteInfo = JSON.stringify(proposal)
    ///2955
    const assetList = proposal.proposalItems
    const assetInfo = assetList.map((asset: any) => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })

    const servicePartyInsurance = await buildPartyInsurance(financingProductPackResourceUid, quoteInfo, partyInfo, assetInfo, proposalItemCalc);
    const calcResponse = await calculateSimulation({
        searchVariable: SearchVariableType.payment,
        financialProductUid: {
            resourceUid: scale.resourceUid,
            systemUid: 'odm-product'
        },
        productUid: {
            resourceUid: financingOfferResourceUid,
            systemUid: 'odm-product'
        },
        quoteInfo,
        partyInfo,
        assetInfo,
        accessories: getAccessories(scale, servicePartyInsurance)
    })
    scale.scalePresentation.servicePartyInsurance = await mergeBeneficiariesDataResultEdit(calcResponse, servicePartyInsurance, proposal, offer)
    scale.scalePresentation.services = buildScaleServices(calcResponse, scale)
    scale.scalePresentation.services.forEach((calculationService: any) => {
        const packService = serviceItems.filter((ps: any) => ps.reference === calculationService.reference)[0]
        if (packService) {
            calculationService.resourceUid = packService.resourceUid
            calculationService.config.selected = packService.config.selected
        }
    })
    scale.calculationResult = calcResponse
    return scale
}


async function buildPartyInsurance(financialProductUid: string,
    quoteInfo: any,
    partyInfo: any,
    assetInfo: any,
    proposalItem: SimpleQuote) {
    const evaluateAllPartyInsuranceAccessoryBody = {
        applicationName: "ORIGINATION",
        context: "",
        maxResults: 100,
        partyInfo: partyInfo,
        assetInfo: assetInfo,
        accessoryProductInfo: proposalItem.proposalAccessories.map((o: any) => mapProposalAccessories(o)),
        quoteInfo
    }
    return evaluateAllPartyInsuranceAccessory(financialProductUid, evaluateAllPartyInsuranceAccessoryBody, undefined, proposalItem.taxValue)
}

function buildFinancialSituation(proposalItem: any) {
    // TODO - TP-2424 - finish the mapping - sumOfInterests
    const { sumOfInterests, sumOfTotalPayments, aprc, air } = proposalItem
    return {
        sumOfInterests: sumOfInterests || {
            amount: 0
        },
        sumOfTotalPayments: sumOfTotalPayments || {
            amount: 0
        },
        aprc,
        air,
    }
}

const camelSentence = (str: string) => {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase())
}

async function buildScalePresentation(proposal: any, proposalItem: any, financingOffer: any, services: any) {
    const items: ScalePresentationItem[] = []
    const sliders: any = []
    const {
        resourceUid,
        financedValue,
        numberOfPaymentTerm,
        nominalRate,
        firstPaymentPercentage,
        residualValuePercentage,
        downPaymentPercentage,
        financialPaymentWTax,
        mileage,
        periodBetween2Installments,
        rateBaseValue
    } = proposalItem
    const {
        minDuration,
        maxDuration,
        minDownPayment,
        maxDownPayment,
        minFirstPayment,
        maxFirstPayment,
        minAnnualRate,
        maxAnnualRate,
        minNumberGracePeriodTerms,
        maxNumberGracePeriodTerms,
        defaultNumberGracePeriodTerms,
    } = financingOffer
    const price = financedValue
    const payment = formatWithMask(financialPaymentWTax, 2)
    const labelConfig = getDurationLabels(periodBetween2Installments)
    const monthlyPresentation = i18n.global.t(labelConfig.presentation, { param: numberOfPaymentTerm, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration })

    sliders.push({
        id: `${resourceUid}.payment`,
        attribute: 'payment',
        label: i18n.global.t('demand.sliders.payment'),
        interval: 50,
        min: 50,
        max: 10000,
        value: financialPaymentWTax,
        suffix: '€',
        calculationType: 'Payment',
        typeSelected: 'DEFAULT',
        slideType: 'DEFAULT',
        model: financialPaymentWTax,
    })
    sliders.push({
        id: resourceUid + '.duration',
        attribute: 'duration',
        label: i18n.global.t('demand.sliders.duration'),
        interval: 1,
        hideCalculationButton: true,
        min: minDuration.duration,
        max: maxDuration.duration,
        value: numberOfPaymentTerm,
        suffix: 'mois',
        typeSelected: 'DEFAULT',
        slideType: 'DEFAULT',
        model: numberOfPaymentTerm,
    })

    if (defaultNumberGracePeriodTerms && minNumberGracePeriodTerms && maxNumberGracePeriodTerms) {
        sliders.push({
            id: resourceUid + '.gracePeriodTerms',
            attribute: 'gracePeriodTerms',
            label: i18n.global.t('demand.sliders.gracePeriodTerms'),
            interval: 1,
            hideCalculationButton: true,
            min: minNumberGracePeriodTerms,
            max: maxNumberGracePeriodTerms,
            value: defaultNumberGracePeriodTerms,
            suffix: `${i18n.global.t('main.labels.sliders.1_months.label')}`,
            typeSelected: 'DEFAULT',
            slideType: 'DEFAULT',
            model: defaultNumberGracePeriodTerms
        })
    }


    if (downPaymentPercentage && minDownPayment && maxDownPayment) {
        items.push({ label: i18n.global.t('demand.sliders.downPayment'), value: `${downPaymentPercentage} %` })
        const downPaymentValueMinPercent = (minDownPayment.amount || 0)
        const downPaymentValueMaxPercent = (maxDownPayment.amount || 0.5)
        const downPaymentValueMin = (downPaymentValueMinPercent * price) / 100
        const downPaymentValueMax = (downPaymentValueMaxPercent * price) / 100
        const downPaymentPercentValue = (downPaymentPercentage * price)
        sliders.push({
            id: resourceUid + '.downPayment',
            attribute: 'downPayment',
            label: i18n.global.t('demand.sliders.downPayment'),
            interval: .01,
            min: downPaymentValueMin,
            max: downPaymentValueMax,
            value: downPaymentPercentage,
            percentValue: downPaymentPercentValue,
            percentMin: downPaymentValueMinPercent,
            percentMax: downPaymentValueMaxPercent,
            suffix: '€',
            typeSelected: 'PERCENT',
            slideType: 'PERCENT',
            model: downPaymentPercentage,
            calculationType: SearchVariableType.outstandingBalanceBefore,
        })
    }

    if (firstPaymentPercentage && minFirstPayment && maxFirstPayment) {
        items.push({ label: i18n.global.t('demand.sliders.firstPayment'), value: `${firstPaymentPercentage} %` })
        const firstPaymentValueMinPercent = (minFirstPayment.amount || 0)
        const firstPaymentValueMaxPercent = (maxFirstPayment.amount || 0.5)
        const firstPaymentValueMin = (firstPaymentValueMinPercent * price) / 100
        const firstPaymentValueMax = (firstPaymentValueMaxPercent * price) / 100
        const firstPaymentPercentValue = (firstPaymentPercentage * price)
        sliders.push({
            id: resourceUid + '.firstPayment',
            attribute: 'firstPayment',
            label: i18n.global.t('demand.sliders.firstPayment'),
            interval: .01,
            min: firstPaymentValueMin,
            max: firstPaymentValueMax,
            value: firstPaymentPercentage,
            percentValue: firstPaymentPercentValue,
            percentMin: firstPaymentValueMinPercent,
            percentMax: firstPaymentValueMaxPercent,
            suffix: '€',
            typeSelected: 'PERCENT',
            slideType: 'PERCENT',
            model: firstPaymentPercentage,
            calculationType: SearchVariableType.firstPayment,
        })
    }

    if (nominalRate) {
        const rateBaseValueCalc = (rateBaseValue || 0)
        const nominalRateCalc = Number(((nominalRate || rateBaseValueCalc) - rateBaseValueCalc).toFixed(5))
        const minAnnualRateCalc = (minAnnualRate || 0) + rateBaseValueCalc
        const maxAnnualRateCalc = (maxAnnualRate || 2.5) + rateBaseValueCalc
        items.push({ label: i18n.global.t('demand.sliders.annualRate'), value: `${nominalRate} %` })
        sliders.push({
            id: resourceUid + '.AnnualRate',
            attribute: 'rate',
            label: i18n.global.t('demand.sliders.annualRate'),
            interval: .1,
            min: minAnnualRateCalc,
            max: maxAnnualRateCalc,
            value: nominalRateCalc,
            percentValue: nominalRateCalc,
            percentMin: minAnnualRateCalc,
            percentMax: maxAnnualRateCalc,
            suffix: '%',
            optional: false,
            typeSelected: 'RATE',
            slideType: 'RATE',
            model: nominalRateCalc,
            calculationType: SearchVariableType.annualRate,
        })
    }

    if (residualValuePercentage) {
        try {
            const { mileageResponse, residualResponse } = await getLeaseValuesEditMode(proposal, proposalItem)
            const rvMin = price * residualResponse.minResidualValue / 100
            const rvMax = price * residualResponse.maxResidualValue / 100
            const defaultResidualValue = price * residualValuePercentage / 100

            items.push(
                { label: i18n.global.t('demand.sliders.mileage'), value: `${mileage} KM` },
                { label: i18n.global.t('demand.sliders.residualValue'), value: `${defaultResidualValue} €` }
            )
            sliders.push({
                id: resourceUid + '.mileage',
                attribute: 'mileage',
                label: i18n.global.t('demand.sliders.mileage'),
                interval: 1,
                hideCalculationButton: true,
                min: mileageResponse.minMileage,
                max: mileageResponse.maxMileage,
                value: mileage,
                suffix: 'KM',
                typeSelected: 'DEFAULT',
                slideType: 'DEFAULT',
            }, {
                id: resourceUid + '.residualValue',
                attribute: 'residualValue',
                label: i18n.global.t('demand.sliders.residualValue'),
                interval: 1,
                hideCalculationButton: false,
                min: rvMin,
                max: rvMax,
                value: residualValuePercentage,
                percentMin: residualResponse.minResidualValue,
                percentMax: residualResponse.maxResidualValue,
                percentValue: residualValuePercentage,
                suffix: '€',
                typeSelected: 'PERCENT',
                slideType: 'PERCENT',
                calculationType: SearchVariableType.outstandingBalanceAfter,
            })
        } catch (e) {
            const minResidualValue = 1
            const maxResidualValue = 40
            const defaultResidualValue = 20

            const minMileageValue = 10000
            const maxMileageValue = 100000
            const defaultMileageValue = 36000

            const rvMin = price * minResidualValue / 100
            const rvMax = price * maxResidualValue / 100

            items.push(
                { label: i18n.global.t('demand.sliders.mileage'), value: `${defaultMileageValue} KM` },
                { label: i18n.global.t('demand.sliders.residualValue'), value: `${defaultResidualValue} €` }
            )
            sliders.push({
                id: resourceUid + '.mileage',
                attribute: 'mileage',
                label: i18n.global.t('demand.sliders.mileage'),
                interval: 1,
                hideCalculationButton: true,
                min: minMileageValue,
                max: maxMileageValue,
                value: defaultMileageValue,
                suffix: 'KM',
                typeSelected: 'DEFAULT',
                slideType: 'DEFAULT',
            }, {
                id: resourceUid + '.residualValue',
                attribute: 'residualValue',
                label: i18n.global.t('demand.sliders.residualValue'),
                interval: 1,
                hideCalculationButton: false,
                min: rvMin,
                max: rvMax,
                value: defaultResidualValue,
                percentMin: minResidualValue,
                percentMax: maxResidualValue,
                percentValue: defaultResidualValue,
                suffix: '€',
                typeSelected: 'PERCENT',
                slideType: 'PERCENT',
                calculationType: SearchVariableType.outstandingBalanceAfter,
            })
        }
    }
    return {
        proposalResourceUid: proposal.resourceUid,
        proposalItemResourceUid: proposalItem.resourceUid,
        index: 0,
        financingOfferResourceUid: financingOffer.resourceUid,
        loading: false,
        visible: true,
        items,
        monthlyPresentation,
        payment,
        sliders,
        services,
        rateFinancingTax: rateBaseValue
    }
}


async function getLeaseValuesEditMode(proposalParam: any, proposalItem: any) {
    const proposal = deepClone(proposalParam)
    proposal.duration = proposalItem.numberOfPaymentTerm
    const assetInfo = proposalItem.proposalAssets.map((asset: any) => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    removeAttributesRecursively(proposal)

    const mileageResponse: any = await calculateMileage({
        assetInfo: assetInfo,
        quoteInfo: JSON.stringify(proposal)
    });
    proposal.Mileage = proposalItem.mileage

    const residualResponse: any = await calculateResidualValue({
        assetInfo: assetInfo,
        quoteInfo: JSON.stringify(proposal)
    })

    return { mileageResponse, residualResponse }
}


function buildScaleServiceItems(pack: any, proposalItem: any) {
    const { proposalAccessories } = proposalItem
    return pack.items
        .filter((item: any) => item.objectType !== 'odm.product.financingproductpackitem.financingofferitem')
        .map((item: any) => {
            const accessory = proposalAccessories.filter((acc: any) => acc.label === item.resourceUid)[0]
            return {
                resourceUid: accessory ? accessory.resourceUid : undefined,
                objectType: item.objectType,
                systemUid: item.systemUid,
                businessData: item.businessData,
                daaq: item.daaq,
                amountValue: item.amount?.amount,
                calculationMethodOption: item.calculationMethodOption,
                annualRate: item.annualRate,
                basis: item.basis,
                paymentDatesOption: item.paymentDatesOption,
                reference: item.resourceUid,
                taxValue: item.taxValue,
                config: {
                    "selected": !!accessory,
                    "required": item.qualification === "required"
                }
            }
        })
}

async function callCompleteOfferApi(financingOfferResourceUid: string, offer: Offer) {
    const proposal = offer.proposals[0]
    const proposalItem = proposal.proposalItems[0]
    const accessoryProductInfo = proposalItem.proposalAccessories
        .map((item: any) => {
            const {
                amountWoTax,
                taxValue,
                basisValue,
                quantity,
                proposalAccessoryCalculationMethodOption,
                proposalAccessoryPaymentDatesOption
            } = item
            return {
                quantity,
                annualRate: taxValue,
                calculationMethodOption: proposalAccessoryCalculationMethodOption?.resourceUid,
                basis: basisValue,
                currency: amountWoTax.currency,
                paymentDatesOption: proposalAccessoryPaymentDatesOption?.resourceUid,
                amount: amountWoTax,
            }
        })

    const assetList = proposal.proposalItems
        .map(item => item.proposalAssets)
        .reduce((a, b) => {
            a.push(deepClone(b));
            return a
        }, [])

    const partyInfo = (offer.associatedParties || []).map((offerAssociatedParty: any) => {
        const { role_code } = offerAssociatedParty
        const { third } = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })

    const assetInfo = assetList.map(asset => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })

    return api().post(`/${UON.SystemUid.odmProduct}/api/1/${UON.SystemUid.odmProduct}/financingOffer/${financingOfferResourceUid}/completion/`, {
        applicationName: 'ORIGINATION',
        context: '',
        maxResults: 100,
        partyInfo,
        assetInfo,
        accessoryProductInfo,
        quoteInfo: JSON.stringify(proposal),
    })
}

export async function getAssociatedParty(resourceUid?: string) {
    const result = await useApi(UON.SystemUid.odmOffer, 'associatedparty').get(resourceUid);
    const associatedParty = result.response;
    if (associatedParty.party?.objectType?.includes('organization')) {
        return getPartyOrganization(associatedParty);
    }
    else {
        return getPartyPerson(associatedParty);
    }
}

function buildPartyCorrespondents(associatedParty: any) {
    associatedParty.third.organization.correspondents.map(async (partyCorrespondent: PartyCorrespondent) => {
        for (const partyContactCorrespondent of partyCorrespondent.contactCorrespondent) {
            const result = await useApi(UON.SystemUid.odmParty, 'contactmechanism').get(partyContactCorrespondent.contactMechanism.resourceUid);
            if (result.response) {
                const contactMechanism = result.response
                const objectTypeSplit = contactMechanism.objectType.split('.');
                partyContactCorrespondent.contactMechanism = {
                    ...contactMechanism,
                    isDisabled: true,
                    type: {
                        config: {
                            disabled: true
                        },
                        id: i18n.global.t(`demand.party.contactMechanism.${objectTypeSplit[objectTypeSplit.length - 1]}`),
                        label: i18n.global.t(`demand.party.contactMechanism.label.${objectTypeSplit[objectTypeSplit.length - 1]}`)
                    }
                }
            }
        }
    })
}

export async function getPartyOrganization(associatedParty: any) {
    const party = await getEntity('odm-party', 'frenchorganization', associatedParty?.party?.resourceUid);

    associatedParty.third = party
    associatedParty.third = {
        type: {
            id: ThirdType.ORGANIZATION,
            label: i18n.global.t('demand.party.type.organization')
        },
        ...associatedParty.third
    }
    const organization: Organization = new OrganizationClass('', '', '', '')
    copyProperties(party, organization)
    associatedParty.third.organization = organization
    delete associatedParty.party
    mountSelectFields(associatedParty, ['legalCategory', 'activitySector', 'qualificationType', 'partyAddress', 'codeNACE'])
    if (!associatedParty.third.organization.legalCategory) {
        associatedParty.third.organization.legalCategory = store.getters["demandModule/getLegalCategory"];
    }
    if (!associatedParty.third.organization.codeNACE) {
        associatedParty.third.organization.codeNACE = store.getters["demandModule/getCodeNACE"];
    }

    buildPartyCorrespondents(associatedParty);

    associatedParty.third.organization.reference = associatedParty.third.reference

    for (const role of associatedParty.third.organization.roles) {
        await buildPartyTypePreference(role.preferences, associatedParty.third.organization.addresses);
    }

    const promises = associatedParty.third.organization.facilities.map((item: any) => buildFacility(item))
    await Promise.all(promises)

    const promisesAccounts = associatedParty.third.organization.accounts.map((item: any) => buildBankAccount(item))
    await Promise.all(promisesAccounts)

    associatedParty.third.organization.financialExposures = associatedParty.third.organization.financialExposures?.map((item: any) => buildFinancialExposure(item))
    associatedParty.third.organization.contacts = await buildContactMechanism(associatedParty.third.organization.contacts);
    associatedParty.third.organization.addresses = await buildAddressWithPostalCode(associatedParty.third.organization.addresses);
    const relations = (await buildManagerRelation(associatedParty.third.organization))?.load();
    if (associatedParty.third.taxGeographicZone && associatedParty.third.taxGeographicZone.resourceUid) {
        associatedParty.third.organization.taxGeographicZone = associatedParty.third.taxGeographicZone.resourceUid
        delete associatedParty.third.taxGeographicZone
    }
    if (relations) {
        associatedParty.third.organization.relations = buildRelations(relations, associatedParty.third.organization);
    }

    return associatedParty
}

function buildNationality(associatedParty: any) {
    function create(citizen?: Citizenship) {
        associatedParty.third.person.nationality = {
            type: {
                value: citizen?.country?.resourceUid ?? ''
            }
        }
    }

    if (associatedParty.third.person.citizenships.length > 0) {
        associatedParty.third.person.citizenships.map((citizen: Citizenship) => {
            create(citizen);
        })
    }
    else {
        create();
    }
}

function buildCurrentMaritalSituation(associatedParty: any) {
    function create(status?: PersonMaritalStatus) {
        associatedParty.third.person.currentMaritalStatus = {
            type: {
                value: status?.maritalStatus?.resourceUid ?? ''
            }
        }
        associatedParty.third.person.currentMatrimonialRegime = {
            type: {
                value: status?.matrimonialRegime?.resourceUid ?? ''
            }
        }
    }

    if (associatedParty.third.person.maritalStatuses.length > 0) {
        associatedParty.third.person.maritalStatuses.map((status: PersonMaritalStatus) => {
            create(status);
        })
    }
    else {
        create();
    }
}

function buildBirthPlace(associatedParty: any) {
    associatedParty.third.person.birthplace = {
        type: {
            value: '',
            label: '',
            config: {
                city: '',
                department: '',
                country: {
                    id: "",
                    label: ""
                }
            }
        }
    }
}

export async function buildPostalCode(resourceUid: string) {
    const resultPostalCode = await useApi(UON.SystemUid.odmParty, 'frenchpostalcode').get(resourceUid);
    if (resultPostalCode.response) {
        const postalCode = resultPostalCode.response
        const countryName = i18n.global.t(`demand.party.country.${postalCode.country.resourceUid}`)
        return {
            ...postalCode,
            country: {
                ...postalCode.country,
                name: countryName
            },
            type: {
                value: postalCode.resourceUid,
                label: `${postalCode.city} - ${postalCode.postalCode}`,
                config: {
                    city: postalCode.city,
                    department: `${postalCode.department}`,
                    country: {
                        id: postalCode.country.resourceUid,
                        label: countryName
                    },
                }
            }
        }
    } else console.error(`postalCode => ${resultPostalCode.error}`)
}

async function buildContactMechanism(contacts: Array<PartyContactMechanism>) {
    const partyContacts: Array<PartyContactMechanism> = []
    for (const contact of contacts) {
        if (contact.contactMechanism ){
        const result = await useApi(UON.SystemUid.odmParty, 'contactmechanism').get(contact.contactMechanism.resourceUid);
        if (result.response) {
            const contactMechanism = result.response
            const objectTypeSplit = contactMechanism.objectType.split('.');
            contact.contactMechanism = {
                ...contactMechanism,
                isDisabled: true,
                type: {
                    config: {
                        disabled: true
                    },
                    id: i18n.global.t(`demand.party.contactMechanism.${objectTypeSplit[objectTypeSplit.length - 1]}`),
                    label: i18n.global.t(`demand.party.contactMechanism.label.${objectTypeSplit[objectTypeSplit.length - 1]}`)
                }
            }
            partyContacts.push(contact)
            }   else {console.error(`buildContactMechanism => ${result.error}`)}

        }
    }
    return partyContacts
}

async function buildAddressWithPostalCode(addresses: Array<PartyAddress>) {
    const partyAddresses: Array<PartyAddress> = []
    for (const partyAddress of addresses) {
        const result = await useApi(UON.SystemUid.odmParty, 'address').get(partyAddress.address.resourceUid);
        if (result.response) {
            const address = result.response
            partyAddress.address.postalCode = await buildPostalCode(address.postalCode.resourceUid);
            partyAddress.livingSituation = {
                type: {
                    value: partyAddress.livingSituation?.resourceUid || partyAddress.livingSituation?.type.value,
                    label: partyAddress.livingSituation?.resourceUid?.toString() !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + partyAddress.livingSituation?.resourceUid) : partyAddress.livingSituation?.type.value !== "" ? i18n.global.t("middleOffice.party.person.address.livingstatus." + partyAddress.livingSituation?.type.value) : '',
                }
            };
            partyAddresses.push(partyAddress)
        } else console.error(`buildAddress => ${result.error}`)
    }
    return partyAddresses
}

export async function getPartyPerson(associatedParty: any) {
    const result = await useApi(UON.SystemUid.odmParty, Paths.frenchPerson).get(associatedParty?.party?.resourceUid);

    if (result.response) {
        const party = result.response;
        associatedParty.third = party
        associatedParty.third = {
            type: {
                id: ThirdType.PERSON,
                label: i18n.global.t('demand.party.type.person')
            },
            ...associatedParty.third
        }
        const person: Person = new PersonClass('', '', '');
        copyProperties(party, person)
        associatedParty.third.person = person
        delete associatedParty.party
        if (associatedParty.third.taxGeographicZone && associatedParty.third.taxGeographicZone.resourceUid) {
            associatedParty.third.person.taxGeographicZone = associatedParty.third.taxGeographicZone.resourceUid
            delete associatedParty.third.taxGeographicZone
        }
        if (!associatedParty.third.person.title) {
            associatedParty.third.person.title = {
                type:{value:""}
            }
        }
        if (associatedParty.third.person.jobs.length) {
            associatedParty.third.person = await buildJobs(associatedParty.third.person, false)
            associatedParty.third.person.jobs.forEach((element: any) => {
                if (!element.occupationType) {
                    element.occupationType = {
                        type: {
                            id: undefined,
                            label: undefined
                        },
                    }
                }
                if (!element.contractType) {
                    element.contractType = {
                        type: {
                            id: undefined,
                            label: undefined
                        },
                    }
                }

            });
        }
        mountSelectFields(associatedParty, [
            'title',
            'activity',
            'occupationType',
            'positionType'
        ])
        if (!associatedParty.third.person.birthplace) {
            buildBirthPlace(associatedParty);
        }
        if (associatedParty.third.person.businessData && associatedParty.third.person.businessData.birthChildren) {
            buildBirthChildren(associatedParty)
        }

        associatedParty.third.person.financialExposures = associatedParty.third.person.financialExposures?.map((item: any) => buildFinancialExposure(item))
        associatedParty.third.person.homemakers = (party.businessData && party.businessData.homemakers) ? party.businessData.homemakers : undefined
        associatedParty.third.person.retirementPassage = (party.businessData && party.businessData.retirementPassage) ? party.businessData.retirementPassage : undefined
        associatedParty.third.person.age = moment().diff(formatDateLocale(party.birthDate, 'DD/MM/YYYY', 'us', 'YYYY-MM-DD'), 'years');
        if (party.birthplace) {
            associatedParty.third.person.birthplace = await buildPostalCode(party.birthplace.resourceUid);
        }
        if (associatedParty.third.person.gender && associatedParty.third.person.gender.resourceUid) {
            associatedParty.third.person.gender = {
                type: {
                    value: associatedParty.third.person.gender.resourceUid
                }
            }
        }

        buildNationality(associatedParty);
        buildCurrentMaritalSituation(associatedParty);
        buildExistingRevenues(associatedParty.third.person, await getNatureIncome('INCOME'))
        for (const correspondents of associatedParty.third.person.correspondents) {
            for (const contactCorrespondent of correspondents.contactCorrespondent) {
                contactCorrespondent.contactMechanism = await getEntity('odm-party', 'contactmechanism', contactCorrespondent.contactMechanism.resourceUid)
                await buildContacts(contactCorrespondent)
            }
            correspondents.qualificationType.type = {
                value: correspondents.qualificationType.resourceUid
            }
        }
        associatedParty.third.person.ids.forEach((node: any) => {

            if (node.documentType) {
                node.documentType.type = {
                    value: node.documentType.resourceUid,
                    label: node.documentType.resourceUid?.toString() !== "" ? i18n.global.t("middleOffice.party.person.identityDoc.docTypes." + node.documentType.resourceUid) : ''
                }
            }
            else {
                node.documentType = {
                    type: {
                        value: '',
                        label: ''
                    }
                }
            }

            node.validity = node.validity || { from: '', until: '' }
        })
        for (const role of associatedParty.third.person.roles) {
            await buildPartyTypePreference(role.preferences, associatedParty.third.person.addresses);
        }

        if (!associatedParty.third.person.gender) {
            associatedParty.third.person.gender = store.getters["demandModule/getGender"];
        }
        associatedParty.third.person.contacts = await buildContactMechanism(associatedParty.third.person.contacts)
        associatedParty.third.person.addresses = await buildAddressWithPostalCode(associatedParty.third.person.addresses)
        associatedParty.third.person.reference = associatedParty.third.reference
        const promisesAccounts = associatedParty.third.person.accounts.map((item: any) => buildBankAccount(item))
        await Promise.all(promisesAccounts)
        return associatedParty
    }
    else console.error(`getPartyPerson => ${result.error}`)
}

async function buildContacts(node: any) {

    let type
    if (node.contactMechanism.objectType.includes('emailcontact')) {
        node.contactMechanism = await getEntity('odm-party', 'emailcontact', node.contactMechanism.resourceUid)
        type = getContactType(ContactType.EMAIL_CONTACT, "E-mail", "odm.party.contactmechanism.emailcontact", ["emailAddress", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq", "consent", "facility"])
    } else if (node.contactMechanism.objectType.includes('phonecontact')) {
        node.contactMechanism = await getEntity('odm-party', 'phonecontact', node.contactMechanism.resourceUid)
        type = getContactType(ContactType.PHONE_CONTACT, "Phone", "odm.party.contactmechanism.phonecontact", ["phoneNumber", /*"country",*/ "acceptSMS", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq"])
    } else if (node.contactMechanism.objectType.indexOf('messengercontact')) {
        node.contactMechanism = await getEntity('odm-party', 'messengercontact', node.contactMechanism.resourceUid)
        type = getContactType(ContactType.MESSENGER_CONTACT, "Messenger", "odm.party.contactmechanism.messengercontact", ["identifier", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq"])
    } else if (node.contactMechanism.objectType.indexOf('postalmailcontact')) {
        node.contactMechanism = await getEntity('odm-party', 'postalmailcontact', node.contactMechanism.resourceUid)
        type = getContactType(ContactType.POSTAL_MAIL_CONTACT, "Postal Mail", "odm.party.contactmechanism.postalmailcontact", ["address", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "daaq"])
    }
    node.contactMechanism.type = type
    node.contactMechanism.isDisabled = true
    node.contactMechanism.blocked = true
}
export function getContactType(id: string, label: string, objectType: string, fields: string[]) {
    return {
        id: id,
        label: label,
        config: {
            objectType: objectType,
            fields: fields
        }
    }
}
function buildBirthChildren(associatedParty: any) {
    for (const child of associatedParty?.third?.person?.businessData?.birthChildren) {
        child.childrenAge = moment().diff(formatDateLocale(child.dateOfBirthChildren
            , 'DD/MM/YYYY', 'us', 'YYYY-MM-DD'), 'years');
    }

    associatedParty.third.person.birthChildren = associatedParty.third.person.businessData.birthChildren

}

const buildPartyCorrespondent = async (correspondents?: PartyCorrespondent[]) => {
    for (const correspondent of correspondents || []) {
        correspondent.objectType = 'odm.party.partycorrespondent'
        correspondent.status.resourceUid = PartyCorrespondentStatus.ACTIVE
        correspondent.qualificationType.resourceUid = correspondent.qualificationType.type?.value || correspondent.qualificationType.resourceUid
        if (correspondent.qualificationType.type) deleteAttributes(correspondent.qualificationType, ['type'])
        const promises = correspondent.contactCorrespondent.map(item => saveContactCorrespondent(item))
        await Promise.all(promises)
    }
}

async function buildOrganizationAddress(third: Third) {
    const organization = third.organization;
    if (organization && !!organization.addresses) {
        const promises =
            organization.addresses
                .filter(i => !!i.address.postalCode?.postalCode)
                .map(item => savePartyAddresses(item, organization.roles))
        const allPartyAddressSaved = await Promise.all(promises)
        if (organization.addresses) {
            organization.addresses.splice(0, organization.addresses.length)
        }
        organization.addresses?.push(...allPartyAddressSaved);
    }
}

export async function searchAssetProductList() {

    const body = {
        "applicationName": "ORIGINATION",
        "context": "AssetProduct",
        "maxResults": 100,
        "assetInfo": [
            {
                "assetData": "{\"objectType\":\"odm.offer.proposalasset.proposalgood.proposalvehicle.proposalcar\"}"
            }
        ]
    };

    const data = await upsertWithDataReturn('odm-product', body, 'product')
    data.sort((a : any, b : any) => a.productName?.localeCompare(b?.productName))
    const response = {
        systemUid: 'odm-product',
        items: data.map((resItem: any) => {
            return {
                id: resItem.resourceUid,
                label: resItem.productName,
                config: resItem
            }
        })
    }

    return response;
}
export async function searchGuaranteeList(offer: Offer, state: RootState) {
    const Body = await getBodySearchGuarantee(state)
    const data = await upsertWithDataReturn('odm-product', Body, 'product')
    return data
}

export async function searchAsset(id: string, category: string) {
    if (category.toUpperCase() == 'CAR') {
        const data = await getEntity('odm-product', 'product', id)
        data.taxe = await searchProductTax(data.tax.resourceUid)
        return data;
    }


    if (category.toUpperCase() == 'CARPACK') {
        const url = `/odm-product/api/1/odm-product/assetProductPack/${id}/packItems/`;
        const data = (await api().post(url, {})).data //TODO khaled need to review and confirm that the body is empty
        const carProduct = data.filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.carproductpackitem')[0]
        carProduct.taxe = await searchProductTax(carProduct.tax)

        const optionProduct = data.filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.optionassetproductpackitem')
        for (const optproduct of optionProduct) {
            optproduct.taxe = await searchProductTax(optproduct.tax)
        }

        const accesoryProduct = data.filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.accessoryassetproductpackitem')
        for (const accproduct of accesoryProduct) {
            accproduct.taxe = await searchProductTax(accproduct.tax);
        }

        return data;
    }

    const data = await getEntity('odm-product', 'vehicleproduct', id)
    const queryIdPack = {
        pack_id: id
    }
    const idPack: string = await searchIdPackMock(queryIdPack)
    const queryPack = {
        product: idPack,
        method: 'FIXED'
    }
    const packs: any = await searchPackMock(queryPack)
    if (packs.items) {
        data.options = packs.items.filter((item: any) => item.config.packTypeCode.includes('.OPTION'))
        data.accessories = packs.items.filter((item: any) => item.config.packTypeCode.includes('.ACCESSORY'))
        data.packs = packs.items.filter((item: any) => item.config.packTypeCode.includes('.PACKAGE'))
    }
    if (data.packs) {
        const promisesPacks = data.packs.map((pack: any) => buildPack(pack))
        await Promise.all(promisesPacks)
    }
    return data
}
async function buildPack(pack: any) {
    const queryPack = {
        product: pack.id,
        method: 'FIXED'
    }
    const packsPack: any = await searchPackMock(queryPack)
    const options = packsPack.items.filter((item: any) => item.config.packTypeCode.includes('.OPTION'))
    const accessories = packsPack.items.filter((item: any) => item.config.packTypeCode.includes('.ACCESSORY'))
    pack.content = [...options, ...accessories];
}
function searchPack(query: string): Promise<Picklist> {
    const qc_header: any = {
        qc: {
            queryId: 'offer-asset-pack-search',
            offset: 0,
            limit: 100,
            parameters: { query }
        }
    }
    return new Promise<Picklist>(async (resolve, reject) => {
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(api_query_url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm_product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.pack_product_id,
                        label: resItem.pack_product_id,
                        config: {
                            product: resItem.pack_code,
                            discount: 0.0,
                            internalCode: resItem.pack_internal_code,
                            packTypeCode: resItem.pack_package_feature_type_code,
                            currency: resItem.pack_currency,
                            unitaryPrice: resItem.pack_standard_value,
                            tax: resItem.pack_fixed_value,
                            includeInPackagePrice: resItem.pack_include_in_package_price
                        }
                    }
                })
            }
            resolve(response)
        }).catch(err => reject(err))
    })
}

export function getAssetCategory(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            if (res.data.length == 0) {
                resolve({
                    systemUid: 'odm-product',
                    items: [
                        {
                            id: 'NATURE.CAR',
                            label: 'Car'
                        },

                    ]
                })

            }
            else {
                const response = {
                    systemUid: 'odm-product',
                    items: res.data.map((resItem: any) => {
                        return {
                            id: resItem.id,
                            label: resItem.id
                        }
                    })

                }

                resolve(response)
            }
        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}

export function getAssetBodyStyle(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1),
                        config: {
                            name: filter.id
                        }
                    }
                })

            }
            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}


export function getAssets(): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const request = {

        }


        const _headers = headers()
        const url = `${settings.api_url}/odm-product/api/1/odm-product/product/`;
        axios.post(url, request, {
            headers: _headers
        }).then(res => resolve(res.data)).catch(err => reject(err))
    })

}

export const setEvaluatedTaxRate = async (taxCode: string, requestBody: any): Promise<EvaluateTaxRate[]> => {
    const url = `${settings.api_url}/${odm_product}/api/1/${odm_product}/evaluateTaxRate/${taxCode}/`;
    return (await api().post(url, requestBody)).data;
}

export function getAssetBrand(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1),
                        config: {
                            name: filter.id
                        }
                    }
                })

            }

            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}

export function getAssetModel(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))
        }
        if (filter && filter.marque_id != null) {
            operators.push(getOperator("product_product_category_class", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.marque_id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1),
                        config: {
                            name: filter.id
                        }
                    }
                })

            }

            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}

export function getAssetVersion(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))
        }
        if (filter && filter.marque_id != null) {
            operators.push(getOperator("product_product_category_class", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.marque_id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf("range") + 6, resItem.id.length + 1),
                        config: {
                            name: filter.id
                        }
                    }
                })

            }

            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}

export function getAssetCustom(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'asset-product-classification',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.category_code != null) {
            operators.push(getOperator("product_product_classification", "category_code", "EQUAL", `'${filter.category_code}'`, "category_code"))
        }
        if (filter && filter.category_class_code != null) {
            operators.push(getOperator("product_product_classification", "category_class_code", "EQUAL", `'${filter.category_class_code}'`, "category_class_code"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.product_id,
                        label: resItem.product_name
                    }
                })
            }
            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}
export function getAssetEnergy(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1)
                    }
                })
            }

            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}
export function getassetTax(): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-product-tax',
            }
        }
        const operators: any[] = []
        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {


            let response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    if (resItem.fixed_value) {
                        return {
                            id: deleteFormatMaskAndConvertToNumber(resItem.fixed_value),
                            label: `${deleteFormatMaskAndConvertToNumber(resItem.fixed_value)}`,
                        }
                    }
                })

            }
            response.items = response.items.filter((item: any) => item != undefined)
            resolve(response)

        }).catch(err => {


            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}

export function getNetworkNode(filter: Network): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-network-node',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("party_network_node", "network_id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "network_id"))
        }
        if (filter && filter.user != null) {
            operators.push(getOperator("party_party_role", "party_id", "LIKE", globalConfig.formatters.getLikeValue(filter.user), "id"))
        }
        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id,
                    }
                })

            }
            resolve(response)
        }).catch(err => {
            //FIXME: until debizium is fixed
            //reject(err)

            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-offer',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}


export function getNetwork(filter: Network): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-network-node',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        /*  if (filter && filter.id!=null) {
             operators.push(getOperator("party_network_node", "network_id", "LIKE", globalConfig.formatters.getLikeValue(filter.id),"network_id"))
         }*/
        if (filter && filter.user != null) {
            operators.push(getOperator("party_party_role", "party_id", "LIKE", globalConfig.formatters.getLikeValue(filter.user), "id"))
        }
        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.network_id,
                        label: resItem.network_id
                    }
                })

            }
            resolve(response)
        }).catch(err => {
            //FIXME: until debizium is fixed
            //reject(err)

            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}

export function getNetworkAll(filter: Network): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-network',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id
                    }
                })
            }
            resolve(response)
        }).catch(err => {
            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}


export function getNetworkNodeAll(filter: Network): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-party-network-node',
            }
        }

        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("party_network_node", "network_id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "network_id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        qc_header.qc.parameters = {
            ...filter
        }
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id
                    }
                })
            }
            resolve(response)
        }).catch(err => {
            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée2'
                    },

                ]
            })
        });


    })
}



export function getDestributer(filter: Network): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-destributor-network-node',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("party_party_role_network", "network_node_id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "network_node_id"))
        }
        if (filter && filter.user != null) {
            operators.push(getOperator("party_role", "internal_code", "LIKE", globalConfig.formatters.getLikeValue("DISTRIBUTOR"), "internal_code"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.internal_code,
                    }
                })

            }
            resolve(response)
        }).catch(err => {
            //FIXME: until debizium is fixed
            //reject(err)

            resolve({
                systemUid: '',
                items: [
                    {
                        id: 'odm-party',
                        label: 'Crée3'
                    },

                ]
            })
        });


    })
}



export function getCommercial(filter: Network): Promise<ComboBox> {
    return new Promise<ComboBox>((resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'party-commercial',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("party_party_role_network", "network_node_id", "LIKE", globalConfig.formatters.getLikeValue(filter.id), "network_node_id"))
        }
        if (filter && filter.user != null) {
            operators.push(getOperator("party_party_role", "party_id", "LIKE", globalConfig.formatters.getLikeValue(filter.user), "party_id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    return {
                        value: resItem.first_name + " " + resItem.family_name,
                        label: resItem.first_name + "  " + resItem.family_name,
                    }
                })

            }
            resolve(response)
        }).catch(err => {
            //FIXME: until debizium is fixed
            //reject(err)

            resolve({
                systemUid: '',
                items: [
                    {
                        value: 'odm-party',
                        label: 'Crée3'
                    },

                ]
            })
        });


    })
}




function searchPackMock(query: any): Promise<Picklist> {
    return Promise.resolve({
        systemUid: 'odm_simulation',
        items: [
            {
                id: 'automotive.5000',
                label: 'HYUNDAI I10',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.GOOD',
                    currency: 'EUR',
                    unitaryPrice: 15000.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'PACKPLUS',
                label: 'PACK PLUS',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.PACKAGE',
                    currency: 'EUR',
                    unitaryPrice: 900.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'PACKGOLD',
                label: 'PACK GOLD',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.PACKAGE',
                    currency: 'EUR',
                    unitaryPrice: 1500.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.ledlight',
                label: 'Antibrouillards avant à LED',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 300.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.childseat',
                label: 'SIEGE ENFANT',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.ACCESSORY',
                    currency: 'EUR',
                    unitaryPrice: 100.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.JANTESEnaluminium|15',
                label: 'JANTES En aluminium | 15',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.ACCESSORY',
                    currency: 'EUR',
                    unitaryPrice: 300.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.reversingcamera',
                label: 'caméra de recul',
                config: {
                    productId: 'PACK1',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 200.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.Lave-phares',
                label: 'Lave-phares',
                config: {
                    productId: 'PACKPLUS',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 200.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.HOUSSE_DE_PROTECTION',
                label: 'HOUSSE DE PROTECTION',
                config: {
                    productId: 'PACKPLUS',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 400.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.JANTESEnaluminium|15',
                label: 'JANTES En aluminium | 15',
                config: {
                    productId: 'PACKPLUS',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.ACCESSORY',
                    currency: 'EUR',
                    unitaryPrice: 300.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.Lave-phares',
                label: 'Lave-phares',
                config: {
                    productId: 'PACKGOLD',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 500.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.HOUSSE_DE_PROTECTION',
                label: 'HOUSSE DE PROTECTION',
                config: {
                    productId: 'PACKGOLD',
                    discount: 0.0,
                    internalCode: 'REQUIRED',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 500.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.reversingcamera',
                label: 'caméra de recul',
                config: {
                    productId: 'PACKGOLD',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.OPTION',
                    currency: 'EUR',
                    unitaryPrice: 200.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
            {
                id: 'HouseholdProduct.JANTESEnaluminium|15',
                label: 'JANTES En aluminium | 15',
                config: {
                    productId: 'PACKGOLD',
                    discount: 0.0,
                    internalCode: 'OPTIONAL',
                    packTypeCode: 'PACKAGE.ACCESSORY',
                    currency: 'EUR',
                    unitaryPrice: 300.0,
                    tax: 0.2,
                    includeInPackagePrice: true,
                }
            },
        ].filter(item => item.config.productId === query.product)
    })
}

function searchIdPack(query: any): Promise<string> {
    const qc_header: any = {
        qc: {
            queryId: 'offer-asset-pack-search-id',
            offset: 0,
            limit: 100,
            parameters: { query }
        }
    }
    return new Promise<string>(async (resolve, reject) => {
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(api_query_url, {
            headers: _headers
        }).then(res => {
            resolve(res.data)
        }).catch(err => reject(err))
    })
}

function searchIdPackMock(query: any): Promise<string> {
    return Promise.resolve('PACK1')
}

export function searchProductTax(code: string): Promise<ProductTax> {

    const qc_header: any = {
        qc: {
            queryId: 'product-tax-search',
            offset: 0,
            limit: 100,
            parameters: { code }
        }
    }
    return new Promise<ProductTax>(async (resolve, reject) => {
        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(api_query_url, {
            headers: _headers
        }).then(res => {
            if (res.data.length == 0) {
                resolve({
                    code: 'FR_TAX',
                    matrix: '',
                    evaluationMethod: {
                        id: 'FIXED',
                        label: 'FIXED'
                    },
                    validity: {
                        from: globalConfig.formatters.formatDateService(new Date(), '-'),
                        until: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-')
                    },
                    fixedValue: 0.0
                })
            }
            else {
                const response = {
                    code: res.data[0].code,
                    matrix: '',
                    evaluationMethod: {
                        id: res.data[0].method_code,
                        label: res.data[0].method_code
                    },
                    validity: {
                        from: res.data[0].validity_from,
                        until: res.data[0].validity_until
                    },
                    fixedValue: res.data[0].fixed_value
                }
                resolve(response)
            }
        }).catch(err => reject(err))
    })
}

export function searchProductTaxMock(code: string): Promise<ProductTax> {
    return Promise.resolve([
        {
            code: 'FR_TAX',
            matrix: '',
            evaluationMethod: {
                id: 'FIXED',
                label: 'FIXED'
            },
            validity: {
                from: globalConfig.formatters.formatDateService(new Date(), '-'),
                until: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-')
            },
            fixedValue: 0.2
        },
        {
            code: 'TN_TAX',
            matrix: '',
            evaluationMethod: {
                id: 'FIXED',
                label: 'FIXED'
            },
            validity: {
                from: globalConfig.formatters.formatDateService(new Date(), '-'),
                until: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-')
            },
            fixedValue: 0.17
        },
        {
            code: 'TN_TAX_EXO',
            matrix: '',
            evaluationMethod: {
                id: 'FIXED',
                label: 'FIXED'
            },
            validity: {
                from: globalConfig.formatters.formatDateService(new Date(), '-'),
                until: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-')
            },
            fixedValue: 0.0
        },
        {
            code: 'FR_TAX_EXO',
            matrix: '',
            evaluationMethod: {
                id: 'FIXED',
                label: 'FIXED'
            },
            validity: {
                from: globalConfig.formatters.formatDateService(new Date(), '-'),
                until: globalConfig.formatters.formatDateServiceAddDays(30, new Date(), '-')
            },
            fixedValue: 0.0
        },
    ].filter(item => item.code === code)[0])
}

export const getPartyRules = () => new Promise((resolve, reject) => {
    return axios.get(settings.api_query_url, {
        headers: headers({
            qc: JSON.stringify({
                queryId: 'party-roles-code',
                offset: 0,
                limit: 100,
            })
        })
    }).then(res => {
        const response = {
            role: res.data.map((resItem: any) => {
                return {
                    code: resItem.id,

                }
            })
        }
        resolve(response.role)
    }).catch(error => {
        reject(error)
    })
})

export function getPartyTypeByRole(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'role-party-picklist',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("party_role", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {
            const response = {
                systemUid: 'odm-party',
                items: res.data.map((resItem: any) => {
                    if (resItem.name != null) {
                        return {
                            id: resItem.id,
                            label: filter.label.concat(' - ').concat(resItem.name)
                        }
                    } else {
                        return {
                            id: resItem.id,
                            label: filter.label.concat(' - ').concat(resItem.first_name).concat(' ').concat(resItem.family_name)
                        }
                    }

                })

            }
            resolve(response)
        }).catch(err => {
            //FIXME: until debizium is fixed
            //reject(err)

            resolve({
                systemUid: '',
                items: [
                    {
                        id: undefined,
                        label: ''
                    },

                ]
            })
        });


    })
}

export async function getPartyUser() {
    const url = `${settings.api_url}/odm-user/api/1/odm-user/whoami/`;

    const userInfoVO = (await api().get(url)).data;

    return userInfoVO.associatedParty
}

export const saveFile = async (file: FileContent) => {
    const systemUid = UON.SystemUid.odmFileManagement
    return (await api().post(`/${systemUid}/api/1/${systemUid}/file/`, file)).data
}

export const downloadFile = async (resourceUid: string) => {
    const systemUid = UON.SystemUid.odmFileManagement
    return (await api().get(`/${systemUid}/api/1/${systemUid}/file/${resourceUid}/`)).data
}

export const PartyAssociated = async (resourceUid: string) => {
    const systemUid = UON.SystemUid.odmOffer
    return (await api().get(`/${systemUid}/api/1/${systemUid}/associatedparty/${resourceUid}/`)).data
}


export const updateOfferStatus = async (offerResourceUid: string, status: OfferStatus) => {
    const systemUid = UON.SystemUid.odmOffer
    return api().put(`/${systemUid}/api/1/${systemUid}/offer/${offerResourceUid}/changeStatus/`, {
        objectType: 'odm.offer.changestatusevent',
        status: status,
        label: status,
        checkInternalCode: false,
        systemUid
    })
}

export const updateProposalStatus = async (proposalResourceUid: string, status: ProposalStatus) => {
    const systemUid = UON.SystemUid.odmOffer
    return api().put(`/${systemUid}/api/1/${systemUid}/proposal/${proposalResourceUid}/changeStatus/`, {
        objectType: 'odm.offer.changestatusevent',
        status: status,
        label: status,
        checkInternalCode: false,
        systemUid
    })
}
function getContractDuration(proposalItem: any) {
    return proposalItem.numberOfPaymentTerm ? (proposalItem.numberOfPaymentTerm * proposalItem.periodBetween2Installments?.duration) : 0
}

function getAgeEndOfContract(proposalItem: any, partyData: any) {
    const contractDuration = getContractDuration(proposalItem)
    const partyAge = partyData.age || 0
    return partyAge && contractDuration ? (partyAge + (contractDuration / 12)) : 0
}

function getFinancedValue(proposalItem: any, state: any) {
    const price = getPrice(state)
    const { downPayment } = proposalItem
    return downPayment ? price - downPayment : price
}
export function getPrice(state: RootState) {
    const { financingModule } = state
    const { scalesModule } = financingModule
    const { scaleSelected } = scalesModule
    return getPriceScale(state, scaleSelected)
}

export function getPriceScale(state: RootState, scale?: FinancingProductPackInfo) {
    const { financingModule } = state
    return (scale?.investmentBasis === INVESTMENT_BASIS_TAX_EXCLUSIVE
        ? financingModule.proposalAssetItemsTotalWoTax
        : financingModule.proposalAssetTotalWithTax) || 0
}
function getPartyAge(partyInfo: any) {
    if (!partyInfo || !partyInfo.length) {
        return 0
    }
    const partyData = JSON.parse(partyInfo[0].partyData)
    return partyData.age || 0
}
const getBodySearchGuarantee = async (state: RootState) => {
    const helper: any = await getHelperFromState(state)
    helper.scaleSelected = state.financingModule.scalesModule.scaleSelected
    const proposalList: Proposal[] = await buildProposalFromSelectedScale(state, helper)
    const proposal = deepClone(proposalList[0])
    proposal.proposalItems.forEach((el: any) => {
        for (const key in el) {
            if (el[key] === undefined) {
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.financedValue = getFinancedValue(el, state)
    })
    const partyInfo = deepClone((state.demandModule?.offer?.associatedParties || [])).map((offerAssociatedParty: any) => {
        const { role_code } = offerAssociatedParty
        const { third } = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })
    const assetList = proposal.proposalItems
    const assetInfo = assetList.map((asset: any) => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    const quoteInfo = JSON.stringify({
        ...proposal
    })
    return {
        applicationName: "ORIGINATION",
        context: "GuaranteeProduct",
        maxResults: 100,
        partyInfo,
        assetInfo,
        quoteInfo
    }
}
export function getAssetSubCategory(filter: any): Promise<ComboBox> {
    return new Promise<ComboBox>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))
        }
        if (filter && filter.category_id != null) {
            operators.push(getOperator("product_product_category_class", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.category_id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        value: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1),
                    }
                })

            }

            resolve(response)

        }).catch((error) => {
            reject(error);
        });
    })
}

export function getGoodSubCategory(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
        const url = settings.api_query_url;

        const qc_header: any = {
            qc: {
                queryId: 'product-asset-category',
            }
        }
        qc_header.qc.parameters = {
            ...filter
        }
        const operators = []
        if (filter && filter.id != null) {
            operators.push(getOperator("product_product_category", "id", "EQUAL", `'${filter.id}'`, "id"))
        }
        if (filter && filter.category_id != null) {
            operators.push(getOperator("product_product_category_class", "id", "LIKE", globalConfig.formatters.getLikeValue(filter.category_id), "id"))
        }

        if (operators.length > 0) {
            qc_header.qc.otherFilters = {
                expressions: [
                    {
                        and: {
                            operators
                        }
                    }
                ]
            }
            qc_header.qc.otherOrders = null
            qc_header.qc.offset = 0
            qc_header.qc.limit = 50
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })

        axios.get(url, {
            headers: _headers
        }).then(res => {

            const response = {
                systemUid: 'odm-product',
                items: res.data.map((resItem: any) => {
                    return {
                        id: resItem.id,
                        label: resItem.id.substring(resItem.id.lastIndexOf(".") + 1),
                        config: {
                            name: filter.id
                        }
                    }
                })

            }

            resolve(response)

        }).catch((error) => {
            reject(error);
        });
    })
}
