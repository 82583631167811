
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import OnlyValue from "@/components/general/OnlyValue.vue";
import { globalConfig } from "@/utils";
import { ContactType, Person } from "@/types";
import {
  contacts,
  getPartyClass,
  populateContactsMap,
} from "@/components/summary-panel/summary";
import {
  getYearsOfDate,
  formatDateToMoment,
  formatDate,
} from "@/utils/configuration/formatters-config";
import store from "@/store";
import i18n from "@/i18n";

@Options({
  methods: { getYearsOfDate, getPartyClass, formatDateToMoment },
  computed: {
    contacts() {
      return contacts;
    },
    ContactType() {
      return ContactType;
    },
  },
  components: { LabelAndValue, OnlyValue },
  props: ["person","order"],
  created() {
    populateContactsMap(this.person?.contacts);
    store.dispatch("middleOfficeModule/searchClassification", {
      filter: {
        id: this.person.resourceUid,
      },
      callback: this.updateResultRows,
    });
  },
})
export default class SummaryPerson extends Vue {
  person?: Person;
  order?:string;
  rows: any[] = [];
  capitalize = globalConfig.capitalize;
  formatDate = formatDate;

  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };
  valueFinancialStyle = {
    "font-weight": "bold",
    "margin-left": "5px",
    color: "black",
  };
  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "10px",
  };
  labelStyleItem = {
    "font-size": "1rem",
    color: "gray",
    "font-weight": "bold",
  };


  adressType = (addresses: any[]) => {
    const address = addresses.find((el) => !!el)?.address;

    return address ? `${address.type.type.value}` : "";
  };

  adressStreetAndStreetNumber = (addresses: any[]) => {
    const address = addresses.find((el) => !!el)?.address;

    if (!address) {
      return "";
    } else if (address.street && address.streetNum) {
      return `${address.street} ${address.streetNum}`;
    } else if (address.street) {
      return `${address.street}`;
    } else if (address.streetNum) {
      return `${address.streetNum}`;
    }
  };
  get columns() {
    return globalConfig.table_config.FinancialexposureCols;
  }
  get financialExposureTable() {
    const rawData = this.person?.financialExposures as unknown as {
      financialInstitution: { value: string };
      outstandingType: { value: string };
      netOutstanding: number;
      grossOutstanding: number;
      residualValue: number;
      contractValidity: { until: string };
    }[];

    const currentDate = new Date(); // Get the current system date

    const groupedData: {
      [key: string]: {
        financialInstitution: string;
        outstandingTypes: {
          [key: string]: {
            netOutstanding: number;
            grossOutstanding: number;
            residualValue: number;
          }
        };
      }
    } = rawData.reduce((acc: any, el: any) => {
      const key = el.financialInstitution.value;
      if (el.contractValidity.until) {
        const contractUntilDateParts = el.contractValidity.until.split('/'); // Split the date string
        const contractUntilDate = new Date(
          Number(contractUntilDateParts[2]), // Year
          Number(contractUntilDateParts[1]) - 1, // Month (subtract 1 because months are zero-based)
          Number(contractUntilDateParts[0]) // Day
        );

        // Check if the contract is still valid (until date is greater than current system date)
        if (contractUntilDate > currentDate) {
          if (!acc[key]) {
            acc[key] = {
              financialInstitution: el.financialInstitution.value,
              outstandingTypes: {}
            };
          }
          const outstandingTypeKey = el.outstandingType.value;
          if (!acc[key].outstandingTypes[outstandingTypeKey]) {
            acc[key].outstandingTypes[outstandingTypeKey] = {
              netOutstanding: 0,
              grossOutstanding: 0,
              residualValue: 0
            };
          }
          // Accumulate values for specific outstanding type
          acc[key].outstandingTypes[outstandingTypeKey].netOutstanding += el.netOutstanding || 0;
          acc[key].outstandingTypes[outstandingTypeKey].grossOutstanding += el.grossOutstanding || 0;
          acc[key].outstandingTypes[outstandingTypeKey].residualValue += el.residualValue || 0;
        }
      }
      return acc;
    }, {});

    // Transforming data for display
    const transformedData = Object.values(groupedData).map(item => ({
      financialInstitution: i18n.global.t(
        "middleOffice.party.organization.financialInstitution." + item.financialInstitution),
      outstandingTypes: Object.entries(item.outstandingTypes).map(([key, value]) => ({
        outstandingType: i18n.global.t(
          "middleOffice.party.organization.typeOutstanding." + key),
        netOutstanding: value.netOutstanding.toFixed(2),
        grossOutstanding: value.grossOutstanding.toFixed(2),
        residualValue: value.residualValue.toFixed()
      }))
    }));

    return transformedData;
  }
  adressPostalCodeAndCity = (addresses: any[]) => {
    const address = addresses.find((el) => !!el)?.address;

    if (!address) {
      return "";
    } else if (address.postalCode.postalCode && address.postalCode.city) {
      return `${address.postalCode.postalCode} ${address.postalCode.city}`;
    } else if (address.postalCode.postalCode) {
      return `${address.postalCode.postalCode}`;
    } else if (address.postalCode.city) {
      return `${address.postalCode.city}`;
    }
  };

  updateResultRows(rows: any) {
    this.rows = this.filterLatestValidity(rows);
  }
  filterLatestValidity(rows: any[]) {
    const latestValidityMap = new Map();
    rows.forEach(row => {
      const classTypeCode = row.class_type_code;
      if (!latestValidityMap.has(classTypeCode) ||
        row.validity_from > latestValidityMap.get(classTypeCode).validity_from ||
        (row.validity_from === latestValidityMap.get(classTypeCode).validity_from &&
         row.validity_until > latestValidityMap.get(classTypeCode).validity_until)) {
      latestValidityMap.set(classTypeCode, row);
    }
    });
    const filteredRows = Array.from(latestValidityMap.values());
    return filteredRows;
  }
}
