import {AssociatedPartyRole, ComboBoxItem, Third, UniformOdmName} from "@/types";
import {UON} from "@/utils";

export class AssociatedPartyClass implements UniformOdmName {
    resourceUid?: string
    systemUid?: string
    objectType?: string
    party?: UniformOdmName | ComboBoxItem|any
    role?: UniformOdmName | AssociatedPartyRole
    third?: Third

    constructor() {
        this.systemUid = UON.SystemUid.odmOffer
        this.objectType = UON.ObjectType.associatedParty
    }

    static guarantorName = (third: Third) => {
        if ( third?.objectType?.includes('person') ) {
            return third?.person?.firstName + ' ' + third?.person?.familyName
        }
        else {
            return third?.organization?.name
        }
    }
}
