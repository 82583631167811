import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "col-lg-12 col-md-12 col-sm-12 form-control no-border" }
const _hoisted_2 = { class: "d-block mb-0 small text-muted" }
const _hoisted_3 = { class: "mr-2 font-weight-normal" }
const _hoisted_4 = { class: "theme-1" }
const _hoisted_5 = {
  class: "align-items-center d-flex justify-content-end p-2",
  style: {"gap":"10px"}
}
const _hoisted_6 = { class: "theme-1" }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = { class: "badge badge-primary" }
const _hoisted_9 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _directive_ripple = _resolveDirective("ripple")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("communication.comMessage.recipientLabel")), 1),
    _createVNode(_component_q_select, {
      outlined: "",
      "use-input": "",
      clearable: "",
      modelValue: _ctx.destinations,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.destinations) = $event)),
      options: _ctx.filteredOptions,
      rules: [_ctx.isRequired],
      dense: "",
      onFilter: _ctx.filterDestFn,
      multiple: "",
      "emit-value": "",
      "map-options": "",
      "input-debounce": "0"
    }, {
      "selected-item": _withCtx((scope) => [
        _createVNode(_component_q_chip, {
          removable: "",
          dense: "",
          onRemove: ($event: any) => (scope.removeAtIndex(scope.index)),
          tabindex: scope.tabindex,
          color: "white",
          "text-color": "warning",
          class: "badge badge-warning d-flex",
          onClick: _withModifiers(_ctx.abortDestFilter, ["stop","prevent"])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(
                "communication.comMessage.".concat(
                  _ctx.getSelectedRecipientLabel(scope).context
                )
              )), 1),
            _createTextVNode(" - " + _toDisplayString(_ctx.getSelectedRecipientLabel(scope).id), 1)
          ]),
          _: 2
        }, 1032, ["onRemove", "tabindex", "onClick"])
      ]),
      "before-options": _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass('tw-wrapper-recipients-' + _ctx.recipientType)
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", {
                class: "tw-recipient-selector-All",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.recipientType = 'All'))
              }, _toDisplayString(_ctx.$t("communication.comMessage.All")), 1),
              _createElementVNode("span", {
                class: "tw-recipient-selector-User",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.recipientType = 'User'))
              }, _toDisplayString(_ctx.$t("communication.comMessage.User")), 1),
              _createElementVNode("span", {
                class: "tw-recipient-selector-Group",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.recipientType = 'Group'))
              }, _toDisplayString(_ctx.$t("communication.comMessage.Group")), 1)
            ])
          ], 2)
        ])
      ]),
      option: _withCtx((scope) => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass('tw-wrapper-recipients-' + _ctx.recipientType)
          }, [
            _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps({
              class: ["border p-2", 'tw-option-for-' + _ctx.getSelectedRecipientLabel(scope).context],
              key: _ctx.getSelectedRecipientLabel(scope),
              clickable: ""
            }, scope.itemProps), [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(
                      "communication.comMessage.".concat(
                        _ctx.getSelectedRecipientLabel(scope).context
                      )
                    )), 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getSelectedRecipientLabel(scope).id), 1)
              ])
            ], 16)), [
              [_directive_ripple],
              [_directive_close_popup]
            ])
          ], 2)
        ])
      ]),
      "no-option": _withCtx(() => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_section, { class: "text-grey" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("main.noResults")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "options", "rules", "onFilter"])
  ]))
}