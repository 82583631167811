
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import PrintDocumentPreview from "@/components/document/PrintDocumentPreview.vue";
import PrintDocumentList from "@/components/document/list/PrintDocumentList.vue";
import PrintDocumentActions from "@/components/document/list/PrintDocumentActions.vue";
import PrintDocumentConfirmationDialog from "@/components/document/PrintDocumentConfirmationDialog.vue";
import PrintDocumentSender from "@/components/document/list/PrintDocumentSender.vue";

@Options({
  components: {
    PrintDocumentSender,
    PrintDocumentPreview,
    PrintDocumentList,
    PrintDocumentActions,
    PrintDocumentConfirmationDialog,
  }
})
export default class PrintDocumentDialog extends Vue {
  get show() {
    return store.state.printDocumentModule.show
  }

  get showSendTemplate() {
    return store.state.printDocumentModule.showSendTemplate;
  }

  get reference() {
    switch (this.currentRoute) {
      case "order-edit": {
        return store.state.orderModule.order?.reference
      }
      case "TaskDetail": {
        const entityType = store.getters['taskModule/isTaskModuleOffer'] ? 'offer' : 'order'
        if (entityType === 'offer') {
          return store.state.taskModule.offer?.reference
        } else {
          return store.state.taskModule.order?.reference
        }
      }
      case "demand-edit": {
        return store.state.demandModule.offer?.reference
      }
      case "third-edit": {
        return store.state.thirdModule.third?.person?.reference
      }
    }
    return '-'
  }

  get dialogState() {
    return store.state.printDocumentModule.printDocumentConfirmationDialogState
    && store.state.printDocumentModule.printDocumentConfirmationDialogState.show
  }

  toggleShowControls() {
    return store.dispatch('printDocumentModule/toggleShow')
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

  created() {
    store.dispatch("printDocumentModule/getTemplates", {
      currentRoute: this.currentRoute
    });
  }

}
