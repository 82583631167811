import { Proposal, RootState } from "@/types";
import {
    buildProposalFromSelectedScale,
    getAccessories,
    getHelperFromState
} from "@/store/services/financing/financingMapper";
import { deepClone, deleteAttributesRecursively, removeAttributesRecursively } from "@/utils";
import {
    getAgeEndOfContract,
    getContractDuration,
    getFinancedValue,
    verifyPartyInsured
} from "@/store/services/financing/financingService";


export const buildPartyControls = async (state: RootState) => {
    const { thirdModule } = state
    const { third } = thirdModule
    if (!third) throw new Error('third is mandatory')
    const { type, person, organization, roles, addresses } = third
    if (!person && !organization) throw new Error('person or organization is mandatory')

    const partyData: any = person ? person : organization
    if (type) partyData.type = type.id

    partyData.roles = partyData.roles || roles || []
    partyData.addresses = partyData.addresses || addresses || []
    partyData.status = partyData.status || {
        "resourceUid": ""
    }

    partyData.type = partyData.type || ''

    return {
        entity: 'Party',
        data: {
            partyInfo: [
                { partyData: JSON.stringify(partyData) }
            ],
            quoteInfo: JSON.stringify({
                status: {
                    "objectType": "odm.offer.proposalstatus",
                    "resourceUid": "INITIAL"
                }
            })
        }
    }
}


export const buildOfferControls = async (state: RootState) => {
    const scale = state.financingModule.scalesModule.scaleSelected
    const financingOfferItem = scale?.items.filter((s: any) => s.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
    if (scale && financingOfferItem) {
        const servicePartyInsurance: any = deepClone(scale.scalePresentation.servicePartyInsurance)
        const {
            partyInfo,
            assetInfo,
            quoteInfo
        } = await getControlsRequestBody(state)
        return {
            entity: 'offer',
            data: {
                quoteInfo,
                assetInfo,
                partyInfo,
                accessories: getAccessories(scale, servicePartyInsurance)
            }
        }
    }
}

export const buildActionsControls = (state: RootState, entity: string) => {
    return {
        entity: `tasks${entity}`,
        data: state.taskModule.selectedTask.variables
    }
}


const getControlsRequestBody = async (state: RootState) => {
    const helper: any = await getHelperFromState(state)
    helper.scaleSelected = state.financingModule.scalesModule.scaleSelected
    const proposalList: Proposal[] = await buildProposalFromSelectedScale(state, helper)
    const proposal = deepClone(proposalList[0])
    proposal.proposalItems.forEach((el: any) => {
        for (const key in el) {
            if (el[key] === undefined) {
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.financedValue = getFinancedValue(el, state, helper.scaleSelected)
    })
    const partyInfo = deepClone((state.demandModule?.offer?.associatedParties || [])).map((offerAssociatedParty: any) => {
        const { role_code } = offerAssociatedParty
        const { third } = offerAssociatedParty.associatedParty
        const type = third.type.id
        const body = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })
    const assetList = proposal.proposalItems
    const assetInfo = assetList.map((asset: any) => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
    const quoteInfo = JSON.stringify({
        ...proposal
    })
    return {
        proposal,
        partyInfo,
        assetInfo,
        quoteInfo
    }
}


export const buildGenericInfo = async (state: any) => {
 
    const third = state.third
    if (!third) throw new Error('third is mandatory')
    const { type, person, organization, roles, addresses } = third
    if (!person && !organization) throw new Error('person or organization is mandatory')

    const partyData: any = person ? person : organization
    if (type) partyData.type = type.id

    partyData.roles = partyData.roles || roles || []
    partyData.addresses = partyData.addresses || addresses || []
    partyData.status = partyData.status || {
        "resourceUid": ""
    }

    partyData.type = partyData.type || ''

    return {
            partyInfo: [
                { partyData: JSON.stringify(partyData) }
            ],
            quoteInfo: {
                status: {
                    "objectType": "odm.offer.proposalstatus",
                    "resourceUid": "INITIAL"
                }
            }

    }
}