
import { Options, Vue } from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import { globalConfig } from "@/utils";
import { formatWithMask } from "@/utils/configuration/formatters-config";
import SummaryFinancingPartyInsurance from "@/components/summary-panel/SummaryFinancingPartyInsurance.vue";
import store from "@/store";

@Options({
  components: { LabelAndValue, SummaryFinancingPartyInsurance },
  props: ["proposal", "valueStyle", "labelStyle", "divValueStyle"],
})
export default class SummaryFinancingService extends Vue {

  proposalPartyInsuranceObjectType = 'odm.offer.proposalaccessories.proposalinsurance.proposalpartyinsurance'
  capitalize = globalConfig.capitalize;
  proposal?: any;
  labelStyle?: any;

  baseServiceKey = "demand.product.services.names";

  get partyInsurance() {
    return (store.state.summaryPanelModule?.proposalScale?.scalePresentation?.servicePartyInsurance || [])
  }

  get proposalAccessories() {
    return (this.proposal.proposalAccessories || []).filter((acc: any) => acc.objectType !== this.proposalPartyInsuranceObjectType)
  }

  get serviceList() {
    return this.proposalAccessories.map((service: any) => {
      return {
        label: `${this.baseServiceKey}.${service.accessoryProduct.resourceUid.replaceAll(
          ".",
          "_"
        )}`,
        value: `${formatWithMask(
          service.amountWTax.amount,
          2
        )} ${globalConfig.currencyMapper(service.amountWTax.currency)}`,
      };
    });
  }
  valueStyle = {
    "font-weight": "bold",
    "margin-left": "20px",
    color: "black",
  };

  divValueStyle = {
    display: "flex",
    "justify-content": "end",
    "padding-right": "10px",
  };
  labelStyleItem = {
    "font-size": "1rem",
    color: "gray",
    "font-weight": "bold",
  };
}
