import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-1 flex justify-end" }
const _hoisted_5 = { class: "col-9 q-ml-md" }
const _hoisted_6 = { class: "text-black bold float-left checkbox-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_checkbox, {
            outlined: "",
            dense: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectChange(_ctx.noticeTemplate))),
            modelValue: _ctx.noticeTemplate.selected,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.noticeTemplate.selected) = $event)),
            class: "bg-white"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('task.printDocument.filesName.'+ _ctx.noticeTemplate.fileName)), 1)
        ])
      ])
    ])
  ]))
}