
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import store from "@/store";
import { adminAuthorities } from "@/types";

@Options({
  name: "app-sidebar",
  watch: {
    "$store.state.module"(newValue) {
      this.module = newValue;
    },
  },
})
export default class Sidebar extends Vue {
  classPrimary = "text-grey-6 text-justify";
  classSecundary = "text-white text-justify";
  colorPrimary = "grey-6";
  colorSecundary = "grey-1";
  changeHome = true;
  changeDemand = true;
  changeThird = true;
  changeTask = true;
  changeScale = true;
  changeGaranty = true;
  changeAsset = true;
  changeDossier = true;
  openConfiguration = false;
  openUserAdmin = false;
  changeUser = true;
  module = "";

  get connectedUserAuthorities() {
    return store.state.authModule?.user.authorities;
  }
  hasAdminOrSuperAdminRole(data: any): boolean {
    return data?.some(
      (item: any) =>
        item.authority === adminAuthorities.ADMIN ||
        item.authority === adminAuthorities.SUPERADMIN
    );
  }

  navigate(to: string) {
    router.push(to);
  }
  created() {
    this.module = store.state.module || "";
  }
  changeColor(change: boolean, id: string) {
    if (!change) {
      this.changeHome = true;
      this.changeDemand = true;
      this.changeThird = true;
      this.changeTask = true;
      this.changeScale = true;
      this.changeGaranty = true;
      this.changeAsset = true;
      this.changeDossier = true;
      this.changeUser = true;
    }
    if (change && id === "home") {
      this.changeHome = false;
    } else if (change && id === "demand") {
      this.changeDemand = false;
    } else if (change && id === "third") {
      this.changeThird = false;
    } else if (change && id === "task") {
      this.changeTask = false;
    } else if (change && id === "scale") {
      this.changeScale = false;
    } else if (change && id === "asset") {
      this.changeAsset = false;
    } else if (change && id === "garanty") {
      this.changeGaranty = false;
    } else if (change && id === "dossier") {
      this.changeDossier = false;
    } else if (change && id === "userAdmin") {
      this.changeUser = false;
    }
  }

  hide() {
    this.$emit("closeDrawer", "Close the drawer");
  }

  openSection() {
    this.openConfiguration = !this.openConfiguration;
  }
  openUserAdminSection() {
    this.openUserAdmin = !this.openUserAdmin;
  }
}
