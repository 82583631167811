import {useApi} from "@/requests/useApi";
import {Paths, UON} from "@/utils";
import {getPartyName} from "@/store/services/order/orderService";
import {getOrderParty} from "@/store/services/order/orderApi";

export const getFinancing = async (resourceUid: any) => {
    return (await useApi(UON.SystemUid.odmOrder, `${Paths.order.financing.get(resourceUid)}`).get()).response
}

export async function getOrderFinancingInit(){
    return {
        type: {
            label: '',
            value: ''
        },
        label: '',
        offerReference: '',
        scale: '',
        financingProductPack: {},
        financingOrganization:{},
        associatedParty: {
            orderParty: {
                party: {},
                role: {},
                third: {}
            }
        },
        financedAmountWTax: {
            amount: 0,
            currency: 'EUR'
        },
        financedAmountWoTax: {
            amount: 0,
            currency: 'EUR'
        },
        contribution: {
            amount: 0,
            currency: 'EUR'
        },
        firstRent: {
            amount: 0,
            currency: 'EUR'
        },
        numberOfInsurances: undefined,
    }
}

export async function getOrderFinancing(resourceUid: any) {
    const financing = await getFinancing(resourceUid)
    const orderPartyResourceEntity = await getOrderParty(financing.financingOrganization.resourceUid)
    const type = orderPartyResourceEntity.party.objectType ==="odm.party.party.person.frenchperson"?"party-FrenchPerson":"party-FrenchOrganization"
    const partyName = await getPartyName(orderPartyResourceEntity.party.resourceUid, orderPartyResourceEntity.party.objectType)


    return {
        type: {
            label: financing.type && financing.type.resourceUid ? financing.type.resourceUid : '',
            value: financing.type && financing.type.resourceUid ? financing.type.resourceUid : '',
        },
        label: financing.label ? financing.label : '',
        offerReference: financing.offerReference ? financing.offerReference : '',
        financingProductPack: {},
        financingOrganization:{
        },
        associatedParty: {
            orderParty: {
                party: {},
                role: {}
            }
        },
        financedAmountWTax: financing.financedAmountWTax && financing.financedAmountWTax.amount ?  financing.financedAmountWTax.amount : 0,
        financedAmountWoTax: financing.financedAmountWoTax && financing.financedAmountWoTax.amount ?  financing.financedAmountWoTax.amount : 0,
        contribution: financing.contribution &&financing.contribution.amount ?  financing.contribution.amount : 0,
        firstRent: financing.firstRent && financing.firstRent.amount ?  financing.firstRent.amount : 0,
        numberOfInsurances: financing.numberOfInsurances ?  financing.numberOfInsurances : '',
        config: {
            partyName: partyName,
            partyType: type,
            partyResourceUid: orderPartyResourceEntity.party.resourceUid
        }
    }
}

