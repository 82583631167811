import {Order, Task, TaskExecuteAction,  TaskState} from "@/types";
import {executeTask} from "@/store/services/task/workflow";
import {deepClone} from "@/utils";

export async function executeValidateOrder(taskState: TaskState, order: Order, task: Task) {
    const validationOrder = deepClone(taskState.validationOrderModule.bind)
    const {
        decisionDate,
        comment,
        decision
    } = validationOrder
    // TODO verify if need to save the decision on the database
    const taskExecuteAction: TaskExecuteAction = {
        taskId: task.taskId || '',
        variables: {
            orderStatus: { value: decision === 'validate' ? 'ORDER_VALIDATED' : 'ORDER_REJECTED', type: "String" }
        }
    }
    try {
        return executeTask(taskExecuteAction)
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}
