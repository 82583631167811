import { GroupReturn } from "./../../types/index";
import { Dossier, FilterParty, ProposalAssetItemType } from "@/types";
import { settings } from "@/settings";
import { getOperator, globalConfig, headers } from "@/utils";
import axios from "axios";

export const dossiers = async () => {
  return [
    {
      reference: "TS30991",
      cdtLoueur: "TS30997",
      client: {
        id: "luRo2l5qTbakR3-TdXxiWw",
        _discriminator: "party-FrenchPerson",
        firstname: "Adolfo",
        middlename: null,
        familyname: "Dubois",
        birthdate: "2002-06-05",
        title: "M",
        usagename: "ABC",
        territory: "",
        street: "q",
        street_num: "3",
        mailbox: null,
        line_1: "",
        line_2: "",
        line_3: "",
        postal_code_id: "CP28741",
        phonenumber: "",
        country_code: null,
        email_address: null,
        validity_until: null,
        _daaq: "/",
      },
      vendeur: {
        id: "-QeWa9D9TiGSIHv6oRcd8Q",
        _discriminator: "party-FrenchOrganization",
        name: "supplier A",
        commercial_name: "Supplier A",
        siren: "565465465",
        acronym: "Supplier A",
        activity_sector_code: "CONSULTING",
        legal_category_code: null,
        territory: null,
        street: null,
        street_num: null,
        mailbox: null,
        line_1: null,
        line_2: null,
        line_3: null,
        postal_code_id: null,
        postal_code: null,
        city: null,
        country_code: null,
        phone_number: null,
        email_address: "heladmk@gmail.com",
        label: null,
        product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
        provider_id: "SUPPLIER_A",
        validity_until: null,
        _daaq: "/",
      },
      dateCommande: "10/09/2023",
      dateRDVClient: "30/01/2024",
      dateLivraison: "30/01/2024",
      status: {
        value: "APPROVED",
      },
      adresseLivraison: "SARP-OSIS..",
      conditionsDossier: [],
      prix: 23267,
      prixHT: 23267,
      remise: 0,
      prixTTC: 25139,
      commandePrincipale: {
        reference: "TS30992",
        type: "Véhicule",
        asset: {
          totalAmountWTax: {
            amount: 0,
            currency: "",
          },
          referenceCommandFournisseur: "",
          assetItems: [
            {
              referenceCommandFournisseur: "",
              label: "Peugeot Partner B...",
              type: ProposalAssetItemType.other,
            },
          ],
        },
        dateCommande: "01/01/2024",
        dateLivraison: "03/01/2024",
        vendeur: {
          id: "-QeWa9D9TiGSIHv6oRcd8Q",
          _discriminator: "party-FrenchOrganization",
          name: "supplier A",
          commercial_name: "Supplier A",
          siren: "565465465",
          acronym: "Supplier A",
          activity_sector_code: "CONSULTING",
          legal_category_code: null,
          territory: null,
          street: null,
          street_num: null,
          mailbox: null,
          line_1: null,
          line_2: null,
          line_3: null,
          postal_code_id: null,
          postal_code: null,
          city: null,
          country_code: null,
          phone_number: null,
          email_address: "heladmk@gmail.com",
          label: null,
          product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
          provider_id: "SUPPLIER_A",
          validity_until: null,
          _daaq: "/",
        },
        adresseLivraison: "SARP-OSIS..",
        prix: 23267,
        status: {
          value: "APPROVED",
        },
      },
      commandesFournisseurs: [
        {
          reference: "TS30993",
          type: "C. Fournisseur",
          asset: {
            totalAmountWTax: {
              amount: 0,
              currency: "",
            },
            referenceCommandFournisseur: "",
            assetItems: [
              {
                referenceCommandFournisseur: "",
                label: "-",
                type: ProposalAssetItemType.other,
              },
            ],
          },
          dateCommande: "10/09/2023",
          dateLivraison: "30/01/2024",
          vendeur: {
            id: "-QeWa9D9TiGSIHv6oRcd8Q",
            _discriminator: "party-FrenchOrganization",
            name: "supplier A",
            commercial_name: "Supplier A",
            siren: "565465465",
            acronym: "Supplier A",
            activity_sector_code: "CONSULTING",
            legal_category_code: null,
            territory: null,
            street: null,
            street_num: null,
            mailbox: null,
            line_1: null,
            line_2: null,
            line_3: null,
            postal_code_id: null,
            postal_code: null,
            city: null,
            country_code: null,
            phone_number: null,
            email_address: "heladmk@gmail.com",
            label: null,
            product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
            provider_id: "SUPPLIER_A",
            validity_until: null,
            _daaq: "/",
          },
          adresseLivraison: "SARP-OSIS..",
          prix: 237,
          status: {
            value: "APPROVED",
          },
          referenceCommande: "TS30992",
        },
        {
          reference: "TS30994",
          type: "Véhicule",
          asset: {
            totalAmountWTax: {
              amount: 0,
              currency: "",
            },
            referenceCommandFournisseur: "",
            assetItems: [
              {
                referenceCommandFournisseur: "",
                label: "Peugeot Partner B...",
                type: ProposalAssetItemType.other,
              },
            ],
          },
          dateCommande: "10/09/2023",
          dateLivraison: "30/01/2024",
          vendeur: {
            id: "-QeWa9D9TiGSIHv6oRcd8Q",
            _discriminator: "party-FrenchOrganization",
            name: "supplier A",
            commercial_name: "Supplier A",
            siren: "565465465",
            acronym: "Supplier A",
            activity_sector_code: "CONSULTING",
            legal_category_code: null,
            territory: null,
            street: null,
            street_num: null,
            mailbox: null,
            line_1: null,
            line_2: null,
            line_3: null,
            postal_code_id: null,
            postal_code: null,
            city: null,
            country_code: null,
            phone_number: null,
            email_address: "heladmk@gmail.com",
            label: null,
            product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
            provider_id: "SUPPLIER_A",
            validity_until: null,
            _daaq: "/",
          },
          adresseLivraison: "SARP-OSIS..",
          prix: 23267,
          status: {
            value: "APPROVED",
          },
          referenceCommande: "TS30992",
        },
      ],
      tasks: [],
      documents: {},
      detailsLivraison: {},
      messages: [],
      historique: {},
    },
    {
      reference: "TS30995",
      cdtLoueur: "T230927",
      client: {
        id: "luRo2l5qTbakR3-TdXxiWw",
        _discriminator: "party-FrenchPerson",
        firstname: "Adolfo",
        middlename: null,
        familyname: "Dubois",
        birthdate: "2002-06-05",
        title: "M",
        usagename: "ABC",
        territory: "",
        street: "q",
        street_num: "3",
        mailbox: null,
        line_1: "",
        line_2: "",
        line_3: "",
        postal_code_id: "CP28741",
        phonenumber: "",
        country_code: null,
        email_address: null,
        validity_until: null,
        _daaq: "/",
      },
      vendeur: {
        id: "-QeWa9D9TiGSIHv6oRcd8Q",
        _discriminator: "party-FrenchOrganization",
        name: "supplier A",
        commercial_name: "Supplier A",
        siren: "565465465",
        acronym: "Supplier A",
        activity_sector_code: "CONSULTING",
        legal_category_code: null,
        territory: null,
        street: null,
        street_num: null,
        mailbox: null,
        line_1: null,
        line_2: null,
        line_3: null,
        postal_code_id: null,
        postal_code: null,
        city: null,
        country_code: null,
        phone_number: null,
        email_address: "heladmk@gmail.com",
        label: null,
        product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
        provider_id: "SUPPLIER_A",
        validity_until: null,
        _daaq: "/",
      },
      dateCommande: "30/09/2023",
      dateRDVClient: "05/10/2023",
      dateLivraison: "05/10/2023",
      status: {
        value: "CREATECOMMAND",
      },
      adresseLivraison: "SARP-OSIS..",
      conditionsDossier: [],
      prix: 30952,
      prixHT: 30952,
      remise: 2,
      prixTTC: 45139,
      commandePrincipale: {
        reference: "TS30996",
        type: "Véhicule",
        asset: {
          totalAmountWTax: {
            amount: 0,
            currency: "",
          },
          referenceCommandFournisseur: "",
          assetItems: [
            {
              referenceCommandFournisseur: "",
              label: "Peugeot Partner B...",
              type: ProposalAssetItemType.other,
            },
          ],
        },
        dateCommande: "10/09/2023",
        dateLivraison: "30/01/2024",
        vendeur: {
          id: "-QeWa9D9TiGSIHv6oRcd8Q",
          _discriminator: "party-FrenchOrganization",
          name: "supplier A",
          commercial_name: "Supplier A",
          siren: "565465465",
          acronym: "Supplier A",
          activity_sector_code: "CONSULTING",
          legal_category_code: null,
          territory: null,
          street: null,
          street_num: null,
          mailbox: null,
          line_1: null,
          line_2: null,
          line_3: null,
          postal_code_id: null,
          postal_code: null,
          city: null,
          country_code: null,
          phone_number: null,
          email_address: "heladmk@gmail.com",
          label: null,
          product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
          provider_id: "SUPPLIER_A",
          validity_until: null,
          _daaq: "/",
        },
        adresseLivraison: "SARP-OSIS..",
        prix: 23267,
        status: {
          value: "CREATECOMMAND",
        },
      },
      commandesFournisseurs: [
        {
          reference: "TS30997",
          type: "Véhicule",
          asset: {
            totalAmountWTax: {
              amount: 0,
              currency: "",
            },
            referenceCommandFournisseur: "",
            assetItems: [
              {
                referenceCommandFournisseur: "",
                label: "Peugeot Partner B...",
                type: ProposalAssetItemType.other,
              },
            ],
          },
          dateCommande: "10/09/2023",
          dateLivraison: "30/01/2024",
          vendeur: {
            id: "-QeWa9D9TiGSIHv6oRcd8Q",
            _discriminator: "party-FrenchOrganization",
            name: "supplier A",
            commercial_name: "Supplier A",
            siren: "565465465",
            acronym: "Supplier A",
            activity_sector_code: "CONSULTING",
            legal_category_code: null,
            territory: null,
            street: null,
            street_num: null,
            mailbox: null,
            line_1: null,
            line_2: null,
            line_3: null,
            postal_code_id: null,
            postal_code: null,
            city: null,
            country_code: null,
            phone_number: null,
            email_address: "heladmk@gmail.com",
            label: null,
            product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
            provider_id: "SUPPLIER_A",
            validity_until: null,
            _daaq: "/",
          },
          adresseLivraison: "SARP-OSIS..",
          prix: 23267,
          status: {
            value: "CREATECOMMAND",
          },
          referenceCommande: "TS30996",
        },
        {
          reference: "TS30998",
          type: "Véhicule",
          asset: {
            totalAmountWTax: {
              amount: 0,
              currency: "",
            },
            referenceCommandFournisseur: "",
            assetItems: [
              {
                referenceCommandFournisseur: "",
                label: "Peugeot Partner B...",
                type: ProposalAssetItemType.other,
              },
            ],
          },
          dateCommande: "10/09/2023",
          dateLivraison: "30/01/2024",
          vendeur: {
            id: "-QeWa9D9TiGSIHv6oRcd8Q",
            _discriminator: "party-FrenchOrganization",
            name: "supplier A",
            commercial_name: "Supplier A",
            siren: "565465465",
            acronym: "Supplier A",
            activity_sector_code: "CONSULTING",
            legal_category_code: null,
            territory: null,
            street: null,
            street_num: null,
            mailbox: null,
            line_1: null,
            line_2: null,
            line_3: null,
            postal_code_id: null,
            postal_code: null,
            city: null,
            country_code: null,
            phone_number: null,
            email_address: "heladmk@gmail.com",
            label: null,
            product_code: "CarProduct.Huyndai i10  1.2 L HIGH GRADE",
            provider_id: "SUPPLIER_A",
            validity_until: null,
            _daaq: "/",
          },
          adresseLivraison: "SARP-OSIS..",
          prix: 23267,
          status: {
            value: "CREATECOMMAND",
          },
          referenceCommande: "TS30996",
        },
      ],
      tasks: [],
      documents: {},
      detailsLivraison: {},
      messages: [],
      historique: {},
    },
  ];
};

export const statusOptions = [
  { value: "GROUPCOMMANDDOCS", label: "Regrouper Docs command" },
  { value: "ACTIVATESIGNATURE", label: "Activer Signature Electronique" },
  { value: "CREATECOMMAND", label: "Creation de Dossier" },
  { value: "APPROVED", label: "Approuvé" },
];
