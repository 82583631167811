import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22687200"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tw-controls-wrapper z-top controls-background" }
const _hoisted_3 = { class: "card-header bg-white d-flex justify-content-between align-items-center pl-3 pr-2" }
const _hoisted_4 = { class: "card-title mb-0 d-flex align-items-center" }
const _hoisted_5 = { class: "font-weight-bold" }
const _hoisted_6 = { class: "card-title mb-0 d-flex align-items-end" }
const _hoisted_7 = { class: "row card-body pl-3 pr-3" }
const _hoisted_8 = { class: "text-red-10 font-size-12" }
const _hoisted_9 = { class: "font-medium" }
const _hoisted_10 = { class: "text-yellow-10 font-size-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("controls.title")), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_icon, {
                  name: "chevron_right",
                  size: "20px",
                  onClick: _ctx.toggleShowControls
                }, null, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-lg-12 col-md-12 col-sm-12 form-control controls-border-error bg-red-2 border-danger border-dashed p-2 mb-2",
                  key: index
                }, [
                  _createElementVNode("span", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, " (" + _toDisplayString(item.control) + ")", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`controls.${item.message}`)), 1)
                  ])
                ]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warnings, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-lg-12 col-md-12 col-sm-12 form-control controls-border-warn bg-amber-1 border-warning border-dashed p-2 mb-2",
                  key: index
                }, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t(`controls.${item.message}`)), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}