import {Task, TaskDefinition, TaskExecuteAction, TaskState,} from "@/types";
import {Paths, taskVariables, UON} from "@/utils";
import {api} from "@/auth/api";
import {executeTask} from "@/store/services/task/workflow";
import {getTasks} from "@/store/services/task/taskService";

export async function executeDelegationLevelAction(payload: {taskState: TaskState, task: Task}) {
    const {taskState, task} = payload

    const delegationLvl = taskState.updateDelegationLvlProcess

    const updateDelegationLevelWhenExistsOne = async () => {
        if (delegationLvl) {
            await updateDelegationLevel({
                decisionProcessId: task.variables.decisionProcessId,
                delegationLevelId: delegationLvl,
                systemUid: UON.SystemUid.odmDecision,
                daaq: "/"
            });
        } else console.info('No delegation level provided');
    }

    try {
        const recentTask = await getMostRecentTaskStep(task);
        if (TaskDefinition.DELEGATION_LEVEL === recentTask.taskDefinition) {
            const taskExecuteAction: TaskExecuteAction = {
                taskId: recentTask.taskId || "",
                variables: {
                    updatedDelegationLvlProcess: {
                        value: delegationLvl,
                        type: "String"
                    }
                },
            };
            await updateDelegationLevelWhenExistsOne();
            return executeTask(taskExecuteAction);
        }
        else {
            console.log(`The task ${recentTask.taskId} is not a delegation level task.`)
        }
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
}

/**
 * Update the delegation level using the provided payload.
 *
 * @param {unknown} payload - The data payload for updating the delegation level.
 * @return {void}
 */
const updateDelegationLevel = async (payload: unknown): Promise<void> => {
    const response = await api().put(
        `/${UON.SystemUid.odmDecision}/api/1/${UON.SystemUid.odmDecision}/${Paths.updateDelegationLevel}/`,
        payload
    );
    console.info(response.data);
}

const getMostRecentTaskStep = async (task: Task) => {
    const taskFilter = {
        searchVariables: JSON.stringify([{
            name: "offerReference",
            operator: "eq",
            value: task.variables.offerReference || '',
        }]),
        initializeVariables: taskVariables.offer,
        includeAssignedTasks: true,
    }
    return await getTasks(taskFilter).then(async res => {
        return {
            taskDefinition: res.data[0].taskDefinition,
            taskId: res.data[0].taskId
        }
    })
}

