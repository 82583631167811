
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["i18nKey", "value", "labelStyle", "valueStyle", "oneLine", "divValueStyle"],
})
export default class LabelAndValue extends Vue {
  i18nKey?: string;
  value?: string;
  labelStyle?: any;
  valueStyle?: any;
  divValueStyle?: any;
  oneLine?: boolean;
}
