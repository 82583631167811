
import {Options, Vue} from "vue-class-component";
import {NoticeTemplate} from "@/types";
import store from "@/store";
import {Emit} from "vue-property-decorator";

@Options({
  props: ['noticeTemplate','notice']
})
export default class PrintDocumentListPackItem extends Vue {

  noticeTemplate?: NoticeTemplate
  notice: any

  get templates() {
    return store.state.printDocumentModule.templates
  }

  onSelectChange(noticeTemplate: any) {
    store.dispatch('printDocumentModule/setTemplateSelectedItem',
        { template: this.noticeTemplate, selected: this.noticeTemplate?.selected , parentNotice :this.notice })

    this.checkTemplatesItemsSelected()
  }

  @Emit('child-boolean-changed')
  checkTemplatesItemsSelected() {}

}
