
import {Options, Vue} from "vue-class-component";
import {formatAmoutswitchCurrency} from "@/utils/configuration/formatters-config";
import {globalConfig} from "@/utils";

@Options({
  props: ["data"],
})
export default class SummaryFinancingPartyInsurance extends Vue {

  expanded = true;

  data?: any;

  get columns() {
    return globalConfig.table_config.partyInsuranceCol;
  }

  get amountHT() {
    const amount = this.data.amountValueWoTax
    return `${formatAmoutswitchCurrency("EUR", amount)} ${this.data.paymentDatesOption === "INSTALLMENT" ? "/Mois" : "HT"}`
  }

  get amountTTC() {
    const amount = this.data.amountValueWTax
    return `${formatAmoutswitchCurrency("EUR", amount)} ${this.data.paymentDatesOption === "INSTALLMENT" ? "/Mois" : "TTC"}`
  }


}
