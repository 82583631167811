import {objectType_odmTransferReplyMsg} from "@/types";

export const mockState = {
  communicationModule: {
    // mock of message
    messagefake: {},

    message:
        {
          objectType: objectType_odmTransferReplyMsg,
          systemUid: "odm-contactmechanism",
          daaq: "/COMPANY/BRANCH2/SALES",
          sender: "Pedro Bragança",
          priority: false,
          subject: {
            objectType: "odm.contactmechanism.messagesubject",
            systemUid: "odm-contactmechanism",
            resourceUid: "Négociation commerciale"
          },
          type: {
            objectType: "odm.contactmechanism.messagetype",
            systemUid: "odm-contactmechanism",
            resourceUid: "MESSAGE"
          },
          recipients: [
            {
              recipient: {
                objectType: "odm.user.leaseforgeuser",
                systemUid: "odm-contactmechanism",
                resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
              }
            },
            {
              recipient: {
                objectType: "odm.user.leaseforgeuser",
                systemUid: "odm-contactmechanism",
                resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
              }
            }
          ],
          body: "Message envoyé",
          relatedObjects: null,
          clientApplication: {
            objectType: "odm.contactmechanism.contactmechanismclient",
            systemUid: "odm-contactmechanism",
            resourceUid: "68815"
          },
          _parameters: {
            Context: "OFFER",
            Entity_Reference: "o4o1p",
            Entity_Status: "INITIAL"
          },
          _messageTemplate: {
            objectType: "odm.contactmechanism.messagetemplate",
            systemUid: "odm-contactmechanism",
            resourceUid: "TEMPLATE_MSG"
          },
          test: ""
        },
    // mock of ComboBox lists
    subjects: [
      {
        objectType: "odm.contactmechanism.messagetype",
        systemUid: "odm-contactmechanism",
        resourceUid: "subject1"
      },
      {
        objectType: "odm.contactmechanism.messagetype",
        systemUid: "odm-contactmechanism",
        resourceUid: "subject2"
      },
      {
        objectType: "odm.contactmechanism.messagetype",
        systemUid: "odm-contactmechanism",
        resourceUid: "subject3"
      }
    ],
    users: [],
    groups: [],
    // mock Notification
    notification: {
      resourceUid: "jZKTcWl1SESdScM7pR5QkQ",
      objectType: "odm.contactmechanism.communicationevent.messageevent",
      systemUid: "odm-contactmechanism",
      daaq: "/",
      sender: "Pedro Bragança",
      priority: false,
      subject: null,
      type: {
        objectType: "odm.contactmechanism.messagetype",
        systemUid: "odm-contactmechanism",
        resourceUid: "NOTIFICATION"
      },
      recipients: [
        {
          recipient: {
            objectType: "odm.user.leaseforgeuser",
            systemUid: "odm-contactmechanism",
            resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
          }
        },
        {
          recipient: {
            objectType: "odm.user.leaseforgeuser",
            systemUid: "odm-contactmechanism",
            resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
          }
        }
      ],
      body: "Lorem Ipsum Lorem Ipsum",
      relatedObjects: null,
      clientApplication: null,


      status: {
        objectType: "odm.contactmechanism.messagestatus",
        systemUid: "odm-contactmechanism",
        resourceUid: "NEW"
      },
      businessData: null,
      communicationDate: null,
      metadata: [
        {
          resourceUid: "kpKGjxkqSUuDkqL8Gqk4EA",
          objectType: "odm.contactmechanism.communicationmetadata",
          systemUid: "odm-contactmechanism",
          businessData: null,
          daaq: "/",
          key: "Context",
          value: "OFFER"
        },
        {
          resourceUid: "zmfoz076Q1GjVfTUnHGIQg",
          objectType: "odm.contactmechanism.communicationmetadata",
          systemUid: "odm-contactmechanism",
          businessData: null,
          daaq: "/",
          key: "Entity_Reference",
          value: "o4o1p"
        },
        {
          resourceUid: "P2ykUIwKRfe829p8iL-Smw",
          objectType: "odm.contactmechanism.communicationmetadata",
          systemUid: "odm-contactmechanism",
          businessData: null,
          daaq: "/",
          key: "Entity_Status",
          value: "READ"
        }
      ],

      replyMsgParent: null,
      transferMsgParent: null
    },
    notifications: [
      {
        resourceUid: "jZKTcWl1SESdScM7pR5QkQ", // id of message
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        systemUid: "odm-contactmechanism",
        daaq: "/",
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        recipients: [
          {
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ],
        body: "Lorem Ipsum Lorem Ipsum",
        relatedObjects: null,
        clientApplication: null,


        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        businessData: null,
        communicationDate: null,
        metadata: [
          {
            resourceUid: "kpKGjxkqSUuDkqL8Gqk4EA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "zmfoz076Q1GjVfTUnHGIQg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "P2ykUIwKRfe829p8iL-Smw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "READ"
          }
        ],

        replyMsgParent: null,
        transferMsgParent: null
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "zhFjZPOsQherB4uQOqtSlw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: []
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "cSUmlZJNSeOgA14f0hcq5A",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ],
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "LEcJSRbyTK-BLoBzfnjdDw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: []
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "NR0f5uQyQT-hkIp-nDdfkA",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: []
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "gCR2DD-7SlGUogyBu55Mpg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "1SO61YZRS0alRx5NH2OUUQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "P_sEphbOQUezcNmbv96sVQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "OqHa7q9tRWKcHp0mgWTbXg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "READ"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "YjMFlZ4ERC2MIimFzWpTqg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.contactmechanism.recipient",
              systemUid: "odm-contactmechanism",
              resourceUid: "n8m-1_kHT2eE3s5cWtIKFQ"
            }
          },
          {
            resourceUid: "jPpQ6hPuQI61Xkdkudh0tQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.contactmechanism.recipient",
              systemUid: "odm-contactmechanism",
              resourceUid: "zpucY32NT3iZ-Gyqr8Hb4Q"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "orsKkDGgRAy3hEltNw80_w",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "hbBEohQMSAS3aXzGVboNgg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "irBNgClfQkKhHxxkIrInhA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "rvglhcPjSsiwCJInyBUM3Q",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "READ"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "NOTIFICATION"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "cngWLKMeSq6MYXRvAqrKIw",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "n8m-1_kHT2eE3s5cWtIKFQ"
            }
          },
          {
            resourceUid: "yEfDKMvXRVGqtWQ9Mm6avg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "zpucY32NT3iZ-Gyqr8Hb4Q"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "Om_k6Y4vRhuZNX-NVEjScg",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "iEYFECkoTGiGLP9soMC_yQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "2VMxN6YFRuGz0POSxKo-3A",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "Igdue0OeQI2g3qkbJYO8cA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "TMdsvzHSSg68X-17GnXnUg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "mOxtfytpRXimRWVO0iWqXg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "HeJYy_-PSn-SGOJdmMvL3w",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: null,
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "Ix7jL9EKQQuiwAJJRTAubw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "1MZsn64oTxC3FomQfz9bgw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "rx48RgD2SY6HkWs7WBH_sw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "4lpBt5EMSjix53l7mr5Gig",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "2RyD7U6GR-qT_1V09y5PTQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "fAOjb90vSoGxolDwCFeV2A",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: null,
        communicationDate: "2023-05-04 14:42:39",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "Pm9e4Ug5SX-jg6S1VgyBTw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "lYTprcqqQJyfEd54gSihCg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "XRgrLmp0QQ2bYL1ktOfN3g",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "NEW"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "i65Cfl4lRomgSV2XjUAvzQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "Kq0ZQNKrTq-bsBmV9j5HaQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      }
    ],


    _notifications: [
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "2vgwzWuzSWGWYbOW-EloQw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:31:45",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "8eVOVgR1QuyqIFsNJrvRsw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "D71Yo0u3Sx215-yRUbVY1A",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "Xc5tYBF2SriglSk9Adj51g",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "kra8Qbk_Qf-COCKB0uWHkQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "4U6B4xcxR5WGzqjfpQMn5Q",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:47:35",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "T5jwugWsRMm6Hg8x1eaehg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "zTaMkyrYQxqACdc33Tzqyw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "_nTL9AbaQVqLKVfblwreoQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "as5jaD5aRqSrdaQ1H4OYGQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "7yMws_LtRLaU3ALLyzIKwg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-07 00:53:25",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "3073Uj-2R5eTfiPiSTWzvA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER "
          },
          {
            resourceUid: "uInxJpTbQPycJXOY47HZGA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "zv2d5"
          },
          {
            resourceUid: "RGKW2OM4S3qNXoMAYWKjCw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL "
          }
        ],
        sender: "MO USER",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "terhgh",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "uwVuNQbZTy2eu7_m5xwvYg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "9uQF701XQfyfonzjAKLBUg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:48:14",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "FYbelHP7RkWbu7CoxlSJ3w",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "V5BPCXknS0CCH4kkzmBgbg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "a0J_8HLsQhKmlO-TjECc8g",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "rbwYE6T4TcuCvQGUuBTtDg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "Ax8RkuJKSsKNOY4sEDpymQ",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-07 00:54:18",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "1NRuQaOPQN-NzY513M8Qxw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER "
          },
          {
            resourceUid: "aiCFEOUnTie3f09TDMUrng",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "zv2d5"
          },
          {
            resourceUid: "0gSDR2_TReueY7F8Edh9bw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL "
          }
        ],
        sender: "MO USER",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "terhgh",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "6JrCo5GqQbm-iz17iFfolA",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "fAOjb90vSoGxolDwCFeV2A",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-04 14:42:39",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "Pm9e4Ug5SX-jg6S1VgyBTw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "lYTprcqqQJyfEd54gSihCg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "XRgrLmp0QQ2bYL1ktOfN3g",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "i65Cfl4lRomgSV2XjUAvzQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "Kq0ZQNKrTq-bsBmV9j5HaQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "GB15NoC4Tdaaiq_MrilsYw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-05 12:17:39",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "paAe1TrgT4mnJJ8dTvZ3lg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "Sfvpy9ciQ--DyUlTwQCVtw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "iIJ0Ca-xTtKVS5_pl6XZEw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "PhMMzsDPQ0aZoyZNa6nr0Q",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "5CLd-RLYSmaEaDjUxuCmOw",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "HeJYy_-PSn-SGOJdmMvL3w",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-03-08 16:45:01",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "Ix7jL9EKQQuiwAJJRTAubw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "1MZsn64oTxC3FomQfz9bgw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "rx48RgD2SY6HkWs7WBH_sw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "4lpBt5EMSjix53l7mr5Gig",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "2RyD7U6GR-qT_1V09y5PTQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "JYNO1OGzTp-KZYRO1cP03g",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:45:01",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "HanIm7T4Rzuzm7A6WgolQA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "FaGVFGIqSlKewzBxKVv24g",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "PeWldTxLRa6Wk3d9qME6CQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "unOqMpu3QzGnCnOPeHNQaA",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "Om_k6Y4vRhuZNX-NVEjScg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-04-08 16:45:01",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "iEYFECkoTGiGLP9soMC_yQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "2VMxN6YFRuGz0POSxKo-3A",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "Igdue0OeQI2g3qkbJYO8cA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: null,
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "TMdsvzHSSg68X-17GnXnUg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "mOxtfytpRXimRWVO0iWqXg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "PE_s3IlpQX6iA8ubbZzJyA",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:12:52",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "Nm2XJoABSSeE-w_85NNUuQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "12ZFeiPxRVCXZ-IRzxV6xQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "L3pNwZ0dTGO6GpTUhGgP-Q",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "jhbrUMNVRgu_eFpiuklntg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "pQkAqG7tQIGExlAAyDJkhQ",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:44:09",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "ODHJdA2CSSClSazrU9jKgQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "FLhs-UheRjWwjnadBsjlrQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "UJ9h272eT56LR_T546zwBA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "Pd-2Qlf_Siqi9fnBAu6Sfg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "PSM-cV1QQO2Jp61junZWKw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-05 12:29:54",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "6uTfoQlvSwKdK8r82VEldA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "ErhnL1vXSFmC6-bENPv8Gg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "iCe77A9QRd6kKPaXTstuRw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "ejRXhs-eSDSPLMtB3oAxVQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "VFRtJpe3Qd2CQJ1SfON2Bw",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "Q_BlAWBkT3uFexVkh7f8fg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-05 12:00:57",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "jjldgdwsRJGcG4nxDXVMQA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "bMSRlQMUTk-J9p3jIbADCg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "_yTDg04dT5SNOlfJZezyGw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "2WtAtTv6QYuqeQV-6BRcUg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "i8f5t7nrQ8K7jAiNHyajbg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "q-yVGmAOTE6NKluD7FgtUA",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:48:06",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "IVnedXg3RBqC5NByINk8Nw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "EpesxQ6KQdKFwNA07_3T8Q",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "oWwdgWwQQKaymvhW8cO8UQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "Bf2Q1F9ETluIsDGht5R0og",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "stIWa7mbTWq9xKwfZDzxqQ",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-05 12:31:16",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "Z7zDYq3FTvmtjVAjIwPbBg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "Cxzy8EtsQsiBrQfMchg_tQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "Vz4kRAEEReGVQh-JEErgkA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "PXz74AKHRTiK_0kb1QEQ7Q",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "XWyKn_f1ThSWdbUnsDL5oQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "tuUO8su6TEKyl15on_I5Ow",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-05 12:29:38",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "e3HSGEWaTk2gk6aZo9cBdQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "EngStCWDQQGxOSRrCTYW2w",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "iUHo129HScSv9uUh-uG9mg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "tvotKf7_RlSYvkmezItC9A",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "8sNYKfIVQhWKrA25bx3oEQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "uOUM-JIaQxKyUVizK2VCTA",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-09 23:10:44",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "US2wYiQ0R_yTpt0Z9JGh5Q",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER "
          },
          {
            resourceUid: "wFdCsXw7R4qiHrZSa5fUfg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "zzw3r"
          },
          {
            resourceUid: "INRJz44FRsC6_n4zn8ei3w",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "CUSTACCEPT "
          }
        ],
        sender: "Lane LaneTest",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "test riadh new",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "p-4rsDFeROmGjrNe_reXWw",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "uZvgfG0sTiCftGeArwsljA",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:28:32",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "stS-vP-aRV6lKWls5L6_KA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "SApaBQMYRc6ltr08f8-vIA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "389CPYw0S0qygaOYRlXscg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "4bT-3PjfSpiOoWth3mjreg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "V0ACxjlXRl2AuvRzTaSCqg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-06 21:07:21",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "OanzQsq4SJGza2c5SCkKOw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER "
          },
          {
            resourceUid: "qT6XLQMrRXOiVfIqfJKhGQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "zzw3r"
          },
          {
            resourceUid: "vxmPTNVRQK6ab11dCJiNiw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "CUSTACCEPT "
          }
        ],
        sender: "MO USER",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "test riadh for Lane",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "obyIuHvSTEGOqhPJbKotPw",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "WGi1NWkjRj-aKyrV4v9tGw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-04 21:17:30",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "lwHs91SESNK_CPVUUY3yhw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "_5jF9h0nSneQzcI_Wy9NLw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "wCJrVhtOQXWbUDxXEXW2UA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "bn6XhE-pQnW3A6KKOJXrOA",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "SWNv39HtRR6JscQdGk36YQ",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "WPR0iGZuRtW3vG5pRdiVdw",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:29:25",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "_WZkBt_oTHChUw-zz6eiNw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "BsHXRJAmSh6GSKWRew7Wrw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "oC1CuzzZQUGVcYO4P83RMQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "gWROmeVQSPWGZB4k81PwSg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "WSBEAWWAQF-KzKCvjfB2JQ",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:13:08",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "b7dHU4tcT9qYUN2qbVqmwg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "6pknJZQCSFq9xPGRS55sMw",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "sl7GF-07SOCS1hA5Jbgl4Q",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "UUo4KN4ZRFi9iI-ONF3vdA",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "xRps6DdhSs-zBfWD0_R4Hg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-05 12:31:04",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "lZrPyx0oQeezY3htTYnY2g",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "OFFER"
          },
          {
            resourceUid: "-adgOZ4RT6-Sq3cauhEkiQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "ehORtBD1Sg2u5Smx7VxTAQ",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Pedro Bragança",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Message envoyé",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "CvjYp13-SFWgHev6L6rC7g",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          },
          {
            resourceUid: "SqGAjGXhQAmGD_SXxdn1sg",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-contactmechanism",
              resourceUid: "216c067e-b50d-4a88-aac9-64d9491c2ec6"
            }
          }
        ]
      },
      {
        objectType: "odm.contactmechanism.communicationevent.messageevent",
        resourceUid: "Zq0jO1KRQnmMgMjklyriRg",
        systemUid: "odm-contactmechanism",
        businessData: null,
        daaq: "/",
        clientApplication: {
          objectType: "odm.contactmechanism.contactmechanismclient",
          systemUid: "odm-contactmechanism",
          resourceUid: "ApplicationClient"
        },
        communicationDate: "2023-05-08 16:43:31",
        relatedObjects: null,
        metadata: [
          {
            resourceUid: "jzVTG_FuRK2SG09DTVECLg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Context",
            value: "PARTY"
          },
          {
            resourceUid: "yXwq9HNGSDenSuc-oSGtOg",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Reference",
            value: "o4o1p"
          },
          {
            resourceUid: "P50l3rbbSju55VVrjrZprA",
            objectType: "odm.contactmechanism.communicationmetadata",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            key: "Entity_Status",
            value: "INITIAL"
          }
        ],
        sender: "Lane Harber",
        priority: false,
        subject: {
          objectType: "odm.contactmechanism.messagesubject",
          systemUid: "odm-contactmechanism",
          resourceUid: "Négociation commerciale"
        },
        type: {
          objectType: "odm.contactmechanism.messagetype",
          systemUid: "odm-contactmechanism",
          resourceUid: "MESSAGE"
        },
        status: {
          objectType: "odm.contactmechanism.messagestatus",
          systemUid: "odm-contactmechanism",
          resourceUid: "READ"
        },
        body: "Lorem Ipsum Lorem Ipsum",
        replyMsgParent: null,
        transferMsgParent: null,
        recipients: [
          {
            resourceUid: "5K4L5g7NQF-sq2AWiRUgUw",
            objectType: "odm.contactmechanism.messagerecipient",
            systemUid: "odm-contactmechanism",
            businessData: null,
            daaq: "/",
            recipient: {
              objectType: "odm.user.leaseforgeuser",
              systemUid: "odm-user",
              resourceUid: "3bad7d89-2783-4ada-8ab0-ed2c3de52b7c"
            }
          }
        ]
      }
    ]
  }
}
