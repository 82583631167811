
import {Options, Vue} from 'vue-class-component';
import {Emit} from "vue-property-decorator";
import store from "@/store";
import router from "@/router";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";

@Options({
  props: {
    show: Boolean
  }
})
export default class PrintDocumentConfirmationDialog extends Vue {

  name = 'confirmation-dialog'
  show?: boolean;

  get dialogState() {
    return store.state.printDocumentModule.printDocumentConfirmationDialogState
  }

  @Emit("onConfirm")
  async onConfirm() {
  //   TODO finish when remove mock
    await store.dispatch('printDocumentModule/hideConfirmationDialog')
    setTimeout(() => {
      Notify.create({
        message: i18n.global.t('printDocument.confirmationDialog.waitTimeout'),
        color: 'warning'
      })
      this.closeModal()
    }, 5000)
  
  }

  @Emit("onCancel")
  onCancel() {
    this.closeModal()
  }

  async closeModal() {
    await store.dispatch('printDocumentModule/cleanConfirmationDialogState')
    await store.dispatch('printDocumentModule/toggleShow')
  }

}
