import {api} from "@/auth/api";
import {settings} from "@/settings";
import {
    CalculationResponse,
    ComboBoxItem,
    ElapseTime,
    FinancingOfferItem,
    FinancingProductPackInfo,
    Offer,
    ProductSummary,
    ProductTax,
    Proposal,
    RootState,
    ScalePresentationItem,
    SearchVariableType
} from "@/types";
import {
    buildProposalFromSelectedScale,
    buildProposalInfoFromSate,
    buildProposalInfoFromScale,
    getAccessories,
    getHelperFromState,
    getPrice,
    getPriceScale,
    getProductSelectionEditMode,
    getProductSelectionParametersRequest,
    INVESTMENT_BASIS_TAX_EXCLUSIVE,
    mapProposalAccessories
} from "@/store/services/financing/financingMapper";
import {
    deepClone,
    deleteAttributesRecursively,
    formatDateRecursively,
    headers,
    Masks,
    nonNullHelper,
    removeAttributesRecursively
} from "@/utils";
import i18n from "@/i18n";
import {
    calculateMileage,
    calculateResidualValue,
    calculateSimulation,
    extractNormalModeValue,
    generateSimulationReport,
} from "@/store/services/simulation";
import {formatDateService, formatWithMask} from "@/utils/configuration/formatters-config";
import {searchProductTax} from "@/store/services/offerService";
import axios from "axios";
import moment from "moment";
import {evaluateAllPartyInsuranceAccessory} from "./product";
import {updatePartyInsurance,mergeBeneficiariesData} from "@/store/services/financing/partyInsurance";
import {ref} from "vue";

const basePath = `${settings.api_url}/odm-product/api/1/odm-product`

const rateFinancingTaxMap: any = {}

export const getPacks = async (state: RootState) => {
    try {
        const requestBody = await getProductSelectionParametersRequest(state)
        const res = await api().post(`${basePath}/product/`, requestBody);
        const packs: ProductSummary[] = res.data
        for (let i=0; i < packs.length; i++) packs[i].index = i
        const financingProducts: ComboBoxItem[] = res.data
            .map((s: ProductSummary) => {
                const packItem = s.classifications.filter((p: any) => p.category === 'PROFILE')[0]
                if (packItem) {
                    const {categoryClass} = packItem
                    return {
                        label: i18n.global.t(`demand.product.financingProduct.productLabel.ACTIVITY_${(categoryClass || '').split('.').join('_')}`),
                        value: categoryClass,
                        categoryClass
                    }
                } else {
                    return null
                }
            }).filter((s: any) => !!s)
        packs.forEach(pack => {
            pack.categoryClassList = pack.classifications.map((s: any) => s.categoryClass)
            pack.activityType = pack.classifications
                .filter((s: ProductSummary) => s.category === 'ACTIVITY_TYPE')
                .map((s: ProductSummary) => s.categoryClass)[0]
            pack.categoryClass = pack.classifications
                .filter((s: ProductSummary) => s.category === 'PROFILE')
                .map((s: ProductSummary) => s.categoryClass)[0]
            pack.category = 'PROFILE'
        })
        return Promise.resolve({packs, financingProducts})
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function evaluateTaxRate(taxCode:string, state: RootState) {
 if (taxCode){
    const type = state.thirdModule.third?.type?.id
    const body = type?.includes("Organization") ? { ...state.thirdModule.third?.organization } : { ...state.thirdModule.third?.person }
    const taxGeographicZone = body.taxGeographicZone
    let country
    if(type?.includes("Organization")){
      if(state.thirdModule.third && state.thirdModule.third.organization && state.thirdModule.third.organization.addresses )
       country = state.thirdModule.third?.organization?.addresses[0]?.address.postalCode?.country?.resourceUid
    }else{
      if(state.thirdModule.third && state.thirdModule.third.addresses)
       country = state.thirdModule.third?.addresses[0]?.address?.postalCode?.country?.resourceUid
    }
    const startDate = state.demandModule.offer?.validity.from

    const evaluationDate = startDate ?startDate : ref(formatDateService(new Date(), "-")).value;

        const requestBody = {
        objectType: "",
        systemUid: "odm-product",
        resourceUid: "",
        businessData: {},
        geographicZone: taxGeographicZone,
        country: country,
        evaluationDate: evaluationDate,
        daaq: "",
      };
        formatDateRecursively(requestBody, 'DD/MM/YYYY', Masks.dateMask)
        const _headers = headers()
        const url = `${settings.api_url}/odm-product/api/1/odm-product/evaluateTaxRate/${taxCode}/`;
        const res = await axios.post(url, requestBody, {
            headers: _headers
        })
        return res?.data.length!=0 ?  res?.data[0].taxRate : 0;
    }
    return 0;
}
export const getPackItems = async (financingProductPackUid: string, state: RootState, accessoryProductInfo?: any) => {
    try {
        const requestBody = await getProductSelectionParametersRequest(state)
        if (accessoryProductInfo) {
            requestBody.accessoryProductInfo = accessoryProductInfo
        }
        const res = await api().post(`${basePath}/financingProductPack/${financingProductPackUid}/packItems/`, requestBody);
        const packInfo: FinancingProductPackInfo = res.data
        for (const item of packInfo.items) {
            item.taxValue =  await evaluateTaxRate(item.tax, state)
        }
        return Promise.resolve(packInfo)
    } catch (e) {
        return Promise.reject(e)
    }
}

export const getPackItemsEditMode = async (financingProductPackUid: string, offer: Offer, accessoryProductInfo?: any) => {
    try {
        const requestBody = await getProductSelectionEditMode(offer)
        if (accessoryProductInfo) {
            requestBody.accessoryProductInfo = accessoryProductInfo
        }
        const res = await api().post(`${basePath}/financingProductPack/${financingProductPackUid}/packItems/`, requestBody);
        const packInfo: FinancingProductPackInfo = res.data
        //TODO TP-3076 check it evaluateTaxRate
        for (const item of packInfo.items) {
            //item.taxValue =  await evaluateTaxRate(item.tax, offer)
        }
        return Promise.resolve(packInfo)
    } catch (e) {
        return Promise.reject(e)
    }
}

export const getScales = async (pack: ProductSummary, state: RootState) => {
    if (pack.productUid && pack.productUid.resourceUid) {
        const scale = await getPackItems(pack.productUid.resourceUid, state)
        scale.resourceUid = pack.productUid.resourceUid
        scale.productName = pack.productName
        scale.category = pack.category
        scale.categoryClass = pack.categoryClass
        scale.categoryClassList = pack.categoryClassList
        scale.activityType = pack.activityType
        await setScalePresentation(scale, state)
        scale.scalePresentation.index = pack.index
        return scale
    } else {
        throw new Error('ProductUid is required')
    }
}

export const getDurationLabels = (periodBetween2Installments: ElapseTime) => {
    const result: any = {
        'slider': null,
        'presentation': null,
        'label': null,
        'serviceInfo': null
    }
    const basePath = 'main.labels.sliders'
    switch (periodBetween2Installments.duration) {
        case 1:
            result.slider = `${basePath}.1_months.slider`
            result.presentation = `${basePath}.1_months.presentation`
            result.label = `${basePath}.1_months.label`
            break;
        case 3:
            result.slider = `${basePath}.3_months.slider`
            result.presentation = `${basePath}.3_months.presentation`
            result.label = `${basePath}.3_months.label`
            break;
        case 6:
            result.slider = `${basePath}.6_months.slider`
            result.presentation = `${basePath}.6_months.presentation`
            result.label = `${basePath}.6_months.label`
            break;
        case 12:
            result.slider = `${basePath}.12_months.slider`
            result.presentation = `${basePath}.12_months.presentation`
            result.label = `${basePath}.12_months.label`
            break;
        default:
            result.slider = `${basePath}.default.slider`
            result.presentation = `${basePath}.default.presentation`
            result.label = `${basePath}.default.label`
            result.serviceInfo = `${basePath}.default.serviceInfo`
            break;
    }
    return result
}

const setScalePresentation = async (scale: FinancingProductPackInfo, state: RootState) => {
    let financingOfferResourceUid = ''
    const price = getPriceScale(state, scale)
    const items: ScalePresentationItem[] = []
    const sliders: any = []
    let monthlyPresentation = ''
    let monthly: any
    let gracePeriodPresentation = ''
    let gracePeriod = ''
    let rateFinancingTax = 0
    let periodBetween2Installments
    let period = ''
    if (scale.items && scale.items.length) {
        for (let i=0; i<scale.items.length; i++) {
            const scaleItem = scale.items[i]
            if (scaleItem.objectType === "odm.product.financingproductpackitem.financingofferitem") {
                financingOfferResourceUid = scaleItem.resourceUid
                if(scaleItem.typeRate) {
                    scale.productTax = await searchProductTax(scaleItem.tax)
                }
                if (scaleItem.basisRate) {
                    rateFinancingTax = await getRateFinancingTax(scaleItem.basisRate)
                }
                const camelSentence = function camelSentence(str: string) {
                    return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
                    {
                        return chr.toUpperCase();
                    });
                }
                nonNullHelper(scaleItem, 'numberOfPaymentTerms', (item: FinancingOfferItem) => {
                                  
                    periodBetween2Installments = scaleItem.periodBetween2Installments
                    const labelConfig = getDurationLabels(periodBetween2Installments)
                    items.push({ label: i18n.global.t('demand.sliders.duration'), value: i18n.global.t(labelConfig.presentation, { param: item.numberOfPaymentTerms }) })
                    sliders.push({
                        id: item.resourceUid + '.duration',
                        attribute: 'duration',
                        label: i18n.global.t('demand.sliders.duration'),
                        interval : 1,
                        hideCalculationButton: true,
                        min : item.minDuration?.duration,
                        max : item.maxDuration?.duration,
                        value: item.numberOfPaymentTerms,
                        suffix: i18n.global.t(labelConfig.label),
                        typeSelected: 'DEFAULT',
                        slideType: 'DEFAULT',
                        model: item.numberOfPaymentTerms,
                        minSuffix: i18n.global.t(labelConfig.slider, { param: item.minDuration?.duration, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration }),
                        maxSuffix: i18n.global.t(labelConfig.slider, { param: item.maxDuration?.duration, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration }),
                        isCustomSuffix: true
                    })
                    monthlyPresentation = labelConfig.presentation;
                    monthly = item.numberOfPaymentTerms
                    period = scaleItem.periodBetween2Installments.unit.toLowerCase()
                })

                nonNullHelper(scaleItem, 'defaultNumberGracePeriodTerms', (item: FinancingOfferItem) => {
                    periodBetween2Installments = scaleItem.periodBetween2Installments
                    const labelConfig = getDurationLabels(periodBetween2Installments)
                    periodBetween2Installments = scaleItem.periodBetween2Installments
                    const labelParams = {
                        value: item.defaultNumberGracePeriodTerms,
                        period: `${i18n.global.t('main.labels.sliders.1_months.label')}`
                    }
                    // items.push({ label: i18n.global.t('demand.sliders.duration'), value: i18n.global.t('main.labels.sliders.gracePeriodTerms', labelParams) })
                    items.push({ label: i18n.global.t('demand.sliders.duration'), value: i18n.global.t(labelConfig.presentation, { param: item.numberOfPaymentTerms }) })
                    sliders.push({
                        id: item.resourceUid + '.gracePeriodTerms',
                        attribute: 'gracePeriodTerms',
                        label: i18n.global.t('demand.sliders.gracePeriodTerms'),
                        interval : 1,
                        hideCalculationButton: true,
                        min : item.minNumberGracePeriodTerms,
                        max : item.maxNumberGracePeriodTerms,
                        value: item.defaultNumberGracePeriodTerms,
                        suffix: i18n.global.t(labelConfig.label),
                        minSuffix: i18n.global.t(labelConfig.slider, { param: item.minNumberGracePeriodTerms, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration }),
                        maxSuffix: i18n.global.t(labelConfig.slider, { param: item.maxNumberGracePeriodTerms, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration }),
                        typeSelected: 'DEFAULT',
                        slideType: 'DEFAULT',
                        model: item.defaultNumberGracePeriodTerms,
                        isCustomSuffix: true
                    })
                    // gracePeriodPresentation = i18n.global.t(labelConfig.presentation, { param: item.numberOfPaymentTerms, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration})
                    gracePeriodPresentation = 'main.labels.sliders.gracePeriodTerms'
                    gracePeriod = String(item.defaultNumberGracePeriodTerms)
                })

                nonNullHelper(scaleItem, 'downPayment', (item: FinancingOfferItem) => {
                    items.push({ label: i18n.global.t('demand.sliders.downPayment'), value: `${scaleItem.downPayment?.amount} %` })
                    const downPaymentValueMinPercent = (item.minDownPayment?.amount || 0)
                    const downPaymentValueMaxPercent = (item.maxDownPayment?.amount || 0.5)
                    const downPaymentPercent = item.downPayment?.amount || downPaymentValueMinPercent
                    const downPaymentValueMin = (downPaymentValueMinPercent * price) / 100
                    const downPaymentValueMax = (downPaymentValueMaxPercent * price) / 100
                    const downPaymentPercentValue = (downPaymentPercent * price )
                    sliders.push({
                        id: item.resourceUid + '.downPayment',
                        attribute: 'downPayment',
                        label: i18n.global.t('demand.sliders.downPayment'),
                        interval : .01,
                        min : downPaymentValueMin,
                        max : downPaymentValueMax,
                        value: downPaymentPercent,
                        percentValue: downPaymentPercentValue,
                        percentMin: downPaymentValueMinPercent,
                        percentMax : downPaymentValueMaxPercent,
                        suffix: '€',
                        typeSelected: 'PERCENT',
                        slideType: 'PERCENT',
                        model: downPaymentPercent,
                        calculationType: SearchVariableType.outstandingBalanceBefore,
                    })

                })

                nonNullHelper(scaleItem, 'firstPayment', (item: FinancingOfferItem) => {
                    items.push({ label: i18n.global.t('demand.sliders.firstPayment'), value: `${scaleItem.firstPayment?.amount} %` })
                    const firstPaymentValueMinPercent = (item.minFirstPayment?.amount || 0)
                    const firstPaymentValueMaxPercent = (item.maxFirstPayment?.amount || 0.5)
                    const firstPaymentPercent = item.firstPayment?.amount || firstPaymentValueMinPercent
                    const firstPaymentValueMin = (firstPaymentValueMinPercent * price) / 100
                    const firstPaymentValueMax = (firstPaymentValueMaxPercent * price) / 100
                    const firstPaymentPercentValue = (firstPaymentPercent * price )
                    sliders.push({
                        id: item.resourceUid + '.firstPayment',
                        attribute: 'firstPayment',
                        label: i18n.global.t('demand.sliders.firstPayment'),
                        interval : .01,
                        min : firstPaymentValueMin,
                        max : firstPaymentValueMax,
                        value: firstPaymentPercent,
                        percentValue: firstPaymentPercentValue,
                        percentMin: firstPaymentValueMinPercent,
                        percentMax : firstPaymentValueMaxPercent,
                        suffix: '€',
                        typeSelected: 'PERCENT',
                        slideType: 'PERCENT',
                        model: firstPaymentPercent,
                        calculationType: SearchVariableType.firstPayment,
                    })
                })


                nonNullHelper(scaleItem, 'annualRate', (item: FinancingOfferItem) => {
                    const minAnnualRate = (item.minAnnualRate || 0) + rateFinancingTax
                    const maxAnnualRate = (item.maxAnnualRate || 0) + rateFinancingTax
                    items.push({ label: i18n.global.t('demand.sliders.annualRate'), value: `${scaleItem.annualRate} %` })
                    sliders.push({
                        id: item.resourceUid + '.AnnualRate',
                        attribute: 'rate',
                        label: i18n.global.t('demand.sliders.annualRate'),
                        interval : .1,
                        minSpread: item.minAnnualRate || 0,
                        maxSpread: item.maxAnnualRate || 0,
                        value: item.annualRate,
                        percentValue: item.annualRate,
                        percentMin: minAnnualRate,
                        percentMax: maxAnnualRate,
                        suffix: '%',
                        optional: false,
                        typeSelected: 'RATE',
                        slideType: 'RATE',
                        model: item.annualRate,
                        calculationType: SearchVariableType.annualRate,
                    })
                })

                await getLeaseValues(scale, scaleItem, state, sliders, items)

            }
        }
    }

    scale.scalePresentation = {
        financingOfferResourceUid,
        loading: true,
        visible: true,
        items,
        monthly,
        sliders,
        rateFinancingTax,
        monthlyPresentation,
        periodBetween2Installments,
        gracePeriodPresentation,
        gracePeriod,
        period
        }
    scale.scalePresentationDefault = deepClone(scale.scalePresentation)

    return scale
}

const mockScale = (scaleItem: any) => {
    if (scaleItem.resourceUid === 'finance.LOA_freq_weekly') {
        scaleItem.minNumberGracePeriodTerms = 1
        scaleItem.maxNumberGracePeriodTerms = 12
        scaleItem.defaultNumberGracePeriodTerms = 3
    }
}

const getLeaseValues = async (scale: FinancingProductPackInfo,
                              scaleItem: FinancingOfferItem,
                              state: RootState,
                              sliders: any,
                              items: ScalePresentationItem[]
) => {
    if(scale.categoryClassList.includes("LEASE")) {
        const {financingModule} = state
        const amount = (scale?.investmentBasis === INVESTMENT_BASIS_TAX_EXCLUSIVE
            ? financingModule.proposalAssetItemsTotalWoTax
            : financingModule.proposalAssetTotalWithTax) || 0
        try {
            const requestBody = await getProductSelectionParametersRequest(state)
            const { assetInfo, quoteInfo } = requestBody
            const quoteObject = JSON.parse(quoteInfo)
            quoteObject.duration = scaleItem.numberOfPaymentTerms
            const asset = assetInfo.map(element => {
                const assetData = JSON.parse(element.assetData)[0]
                assetData.outstandingBalanceBefore = assetData.amountWTax.amount
                assetData.vehicleProductUID = assetData.assetProduct?.resourceUid || null
                element.assetData = JSON.stringify(assetData)
                return element
            })
            const mileageResponse: any = await calculateMileage({
                assetInfo: asset,
                quoteInfo: JSON.stringify(quoteObject)
            });
            quoteObject.Mileage = mileageResponse.defaultMileage
            const residualResponse: any = await calculateResidualValue({
                assetInfo: asset,
                quoteInfo: JSON.stringify(quoteObject)
            })

            const rvMin = amount * residualResponse.minResidualValue / 100
            const rvMax = amount * residualResponse.maxResidualValue / 100

            items.push(
                { label: i18n.global.t('demand.sliders.mileage'), value: `${mileageResponse.defaultMileage} KM` },
                { label: i18n.global.t('demand.sliders.residualValue'), value: `${residualResponse.defaultResidualValue} €` }
            )
            sliders.push({
                id: scaleItem.resourceUid + '.mileage',
                attribute: 'mileage',
                label: i18n.global.t('demand.sliders.mileage'),
                interval : 1,
                hideCalculationButton: true,
                min : mileageResponse.minMileage,
                max : mileageResponse.maxMileage,
                value: mileageResponse.defaultMileage,
                suffix: 'KM',
                typeSelected: 'DEFAULT',
                slideType: 'DEFAULT',
            },{
                id: scaleItem.resourceUid + '.residualValue',
                attribute: 'residualValue',
                label: i18n.global.t('demand.sliders.residualValue'),
                interval : 1,
                hideCalculationButton: false,
                min : rvMin,
                max : rvMax,
                value: residualResponse.defaultResidualValue,
                percentMin: residualResponse.minResidualValue,
                percentMax: residualResponse.maxResidualValue,
                percentValue: residualResponse.defaultResidualValue,
                suffix: '€',
                typeSelected: 'PERCENT',
                slideType: 'PERCENT',
                calculationType: SearchVariableType.outstandingBalanceAfter,
            })
        } catch (e) {
            const minResidualValue = 1
            const maxResidualValue = 40
            const defaultResidualValue = 20

            const minMileageValue = 10000
            const maxMileageValue = 100000
            const defaultMileageValue = 36000

            const rvMin = amount * minResidualValue / 100
            const rvMax = amount * maxResidualValue / 100

            items.push(
                { label: i18n.global.t('demand.sliders.mileage'), value: `${defaultMileageValue} KM` },
                { label: i18n.global.t('demand.sliders.residualValue'), value: `${defaultResidualValue} €` }
            )
            sliders.push({
                id: scaleItem.resourceUid + '.mileage',
                attribute: 'mileage',
                label: i18n.global.t('demand.sliders.mileage'),
                interval : 1,
                hideCalculationButton: true,
                min : minMileageValue,
                max : maxMileageValue,
                value: defaultMileageValue,
                suffix: 'KM',
                typeSelected: 'DEFAULT',
                slideType: 'DEFAULT',
            },{
                id: scaleItem.resourceUid + '.residualValue',
                attribute: 'residualValue',
                label: i18n.global.t('demand.sliders.residualValue'),
                interval : 1,
                hideCalculationButton: false,
                min : rvMin,
                max : rvMax,
                value: defaultResidualValue,
                percentMin: minResidualValue,
                percentMax: maxResidualValue,
                percentValue: defaultResidualValue,
                suffix: '€',
                typeSelected: 'PERCENT',
                slideType: 'PERCENT',
                calculationType: SearchVariableType.outstandingBalanceAfter,
            })
        }
    }
}

const getRateFinancingTax = async (key: string) => {
    if (!rateFinancingTaxMap[key]) {
        rateFinancingTaxMap[key] = await searchRateFinancingTax(key)
    }
    return rateFinancingTaxMap[key]
}

async function searchRateFinancingTax(code: string): Promise<ProductTax> {
    const result = (await api().get(`${basePath}/ratebasis/${code}/`)).data
    const dateTime = moment().valueOf()
    const validResult = result.values.filter((item: any) => {
        if (!item.validity.from) {
            return false
        }
        const from = moment(item.validity.from, Masks.dateMask).valueOf()
        const until = item.validity.until ? moment(item.validity.until, Masks.dateMask).valueOf() : null
        return from <= dateTime && (!until || dateTime <= until)
    })[0]
    return validResult ? validResult.value : 0
}

export const recalculateMillageValue = async (state: RootState) =>  {
    const requestBody = await getProductSelectionParametersRequest(state)
    const { simulationModule, financingModule } = state
    const scale = financingModule.scalesModule.scaleSelected
    if (scale) {
        const normalMode = simulationModule.normalModeState
        const { assetInfo, quoteInfo } = requestBody
        try {
            const asset = assetInfo.map(element => {
                const assetData = JSON.parse(element.assetData)[0]
                assetData.outstandingBalanceBefore = assetData.amountWTax.amount
                assetData.vehicleProductUID = assetData.assetProduct.resourceUid
                element.assetData = JSON.stringify(assetData)
                return element
            })
            const duration = extractNormalModeValue(normalMode, 'duration')
            const quoteObject = JSON.parse(quoteInfo)
            quoteObject.proposalItems[0].numberOfPaymentTerm = duration.value
            quoteObject.duration = duration.value
            const mileageResponse: any = await calculateMileage({
                assetInfo: asset,
                quoteInfo: JSON.stringify(quoteObject)
            });
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'mileage')[0]
            const millage = extractNormalModeValue(normalMode, 'mileage')
            millage.percentValue = mileageResponse.defaultMileage
            millage.value = mileageResponse.defaultMileage
            if(slider){
            slider.value = mileageResponse.defaultMileage
            slider.min = mileageResponse.minMileage
            slider.max = mileageResponse.maxMileage
            slider.model = `${mileageResponse.defaultMileage}`
             }
            return millage
        } catch (e) {
            console.error(e)
            return Promise.reject(e)
        }
    }
}

export const recalculateResidualValue = async (state: RootState) =>  {
    const requestBody = await getProductSelectionParametersRequest(state)
    const { simulationModule, financingModule } = state
    const scale = financingModule.scalesModule.scaleSelected
    if (scale) {
        const normalMode = simulationModule.normalModeState
        const { assetInfo, quoteInfo } = requestBody
        const amount = state.financingModule.proposalAssetTotalWithTax || 0
        try {
            const asset = assetInfo.map(element => {
                const assetData = JSON.parse(element.assetData)[0]
                assetData.outstandingBalanceBefore = assetData.amountWTax.amount
                assetData.vehicleProductUID = assetData.assetProduct.resourceUid
                element.assetData = JSON.stringify(assetData)
                return element
            })
            const quoteObject = JSON.parse(quoteInfo)
            const residualValue = extractNormalModeValue(normalMode, 'residualValue')
            const millage = extractNormalModeValue(normalMode, 'mileage')
            const duration = extractNormalModeValue(normalMode, 'duration')
            quoteObject.Mileage = millage.value
            const sliderMileage = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'mileage')[0]
            if(sliderMileage)
            {sliderMileage.value = millage.value}
            quoteObject.proposalItems[0].numberOfPaymentTerm = duration.value
            quoteObject.duration = duration.value
            const residualResponse: any = await calculateResidualValue({
                assetInfo: asset,
                quoteInfo: JSON.stringify(quoteObject)
            })
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'residualValue')[0]
            const rvMin = amount * residualResponse.minResidualValue / 100
            const rvMax = amount * residualResponse.maxResidualValue / 100

            slider.percentValue = residualResponse.defaultResidualValue
            slider.percentMin = residualResponse.minResidualValue
            slider.percentMax = residualResponse.maxResidualValue
            slider.min = rvMin
            slider.max = rvMax
            slider.model = `${residualResponse.defaultResidualValue}`

            residualValue.percentValue = residualResponse.defaultResidualValue
            if (residualValue.type === 'PERCENT') {
                residualValue.value = residualResponse.defaultResidualValue
                slider.value = residualResponse.defaultResidualValue
            } else {
                residualValue.value = amount * residualResponse.defaultResidualValue / 100
                slider.value = amount * residualResponse.defaultResidualValue / 100
            }


            return residualValue
        } catch (e) {
            console.error(e)
            return Promise.reject(e)
        }
    }
}

export const recalculateScaleSelected = async (scale: FinancingProductPackInfo, state: RootState) => {
    const helper: any = await getHelperFromState(state)
    helper.scaleSelected = scale
    const proposalList: Proposal[] = await buildProposalFromSelectedScale(state, helper)
    const proposal = proposalList[0]
    const proposalItem: any = deepClone(proposal.proposalItems[0])
    const financedValue = proposalItem.financedValue
    proposalItem.financedValue = null
    proposalItem.price = financedValue
    removeAttributesRecursively(proposal)

    const partDirig: any = []
    const partyInfo = (state.demandModule?.offer?.associatedParties || []).map((offerAssociatedParty: any) => {
        const {role_code} = offerAssociatedParty
        const {third} = offerAssociatedParty.associatedParty
        const type = third.type.id
        const bodyOP = type.includes("Organization") ? { ...third.organization } : { ...third.person }
        const body = deepClone(bodyOP)
        removeAttributesRecursively(body)
        deleteAttributesRecursively(body, ['isDisabled'])
        body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
        body.flagInsured = verifyPartyInsured(offerAssociatedParty)
        if (type.includes("Organization")) {
            if (body.manager) partDirig.push(body.manager)
        }
        return {
            partyData: JSON.stringify({
                type,
                role: role_code || 'CLIENT',
                ...body
            })
        }
    })
    if (partDirig.length > 0) {
        partyInfo.push({
            partyData: JSON.stringify({
                type: 'party-FrenchPerson',
                role: partDirig[0].roles[0].role.resourceUid,
                flagInsured: true,
                ...partDirig[0]
            })
        })
    }

    const taxCode = scale.taxOnInterest;
    const taxValue = state.demandModule.partyTaxRate;//setTaxValue(taxRequest, taxCode);

    proposal.proposalItems.forEach((el: any) => {
        for ( const key in el ) {
            if ( el[key] === undefined ) {
                el[key] = null
            }
        }
        el.contractDuration = getContractDuration(el)
        el.financedValue = getFinancedValue(el, state, scale)
        delete el.taxCode
        delete el.taxValue
        el.taxCode = taxCode
        el.taxValue = taxValue
    })
    const quoteInfo = JSON.stringify({
        ...proposal
    })
    const assetList = proposal.proposalItems
    const assetInfo = assetList.map(asset => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })


    const evaluateAllPartyInsuranceAccessoryBody = {
        applicationName: "ORIGINATION",
        context: "",
        maxResults: 100,
        partyInfo: partyInfo,
        assetInfo: assetInfo,
        accessoryProductInfo: proposalItem.proposalAccessories.map((o: any) => mapProposalAccessories(o)),
        quoteInfo
    }
    try {
        const servicePartyInsurance = await evaluateAllPartyInsuranceAccessory(scale.resourceUid,evaluateAllPartyInsuranceAccessoryBody, state)
        const calcResponse = await calculateSimulation({
            searchVariable: SearchVariableType.payment,
            financialProductUid: {
                resourceUid: scale.resourceUid,
                systemUid: 'odm-product'
            },
            productUid: {
                resourceUid: scale.resourceUid,
                systemUid: 'odm-product'
            },
            quoteInfo,
            assetInfo,
            partyInfo,
            accessories: getAccessories(scale, servicePartyInsurance)
        })

        const calculatedPartyInsuranceList = calcResponse?.paymentSchedules[1].items.filter((item: any) => item.objectType === 'odm.simulation.paymentscheduleitem.accessorypaymentscheduleitem.partyinsurancepaymentscheduleitem')
        if (calculatedPartyInsuranceList && calculatedPartyInsuranceList.length) {
            updatePartyInsurance(calculatedPartyInsuranceList, servicePartyInsurance)
        }
        scale.scalePresentation.services = buildScaleServices(calcResponse, scale)
        const att = 'payment'
        let paymentSchedule = 2

        const gracePeriod = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'gracePeriodTerms')[0]
        if (gracePeriod) {
            paymentSchedule = +gracePeriod.model + 2 // Number 2 because as describe by DA this payment schedule dont change if you skip one step more or not
        }

        const result = calcResponse.paymentSchedules[paymentSchedule].financialInstallmentWoTax
        const sliders = (scale.scalePresentation.sliders || []).filter((s: any) => s.attribute !== att)

        scale.scalePresentation.servicePartyInsurance = servicePartyInsurance
        scale.scalePresentation.sliders = [{
            id: `${scale.resourceUid}.payment`,
            attribute: 'payment',
            label: i18n.global.t('demand.sliders.payment'),
            interval: 50,
            min: 50,
            max: 10000,
            value: result,
            suffix: '€',
            calculationType: 'Payment',
            typeSelected: 'DEFAULT',
            slideType: 'DEFAULT',
            model: result,
        }, ...sliders]
        scale.scalePresentation.payment = formatWithMask(result, 2)
        scale.scalePresentation.monthly = String(calcResponse.duration)
        scale.scalePresentation.loading = false
        scale.calculationResult = calcResponse
    } catch (e) {
        console.error(e)
        scale.scalePresentation.loadingError = true
        scale.scalePresentation.loading = false
    }
    return deepClone(scale)
}



//2955
export const calculateScales = async (searchType: SearchVariableType, scale: FinancingProductPackInfo, state: RootState) => {
    const financingOfferItem = scale.items.filter((s: any) => s.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
    if (financingOfferItem) {
        const proposalList: Proposal[] = await buildProposalInfoFromScale(state, scale)
        const proposal = proposalList[0]
        removeAttributesRecursively(proposal)
        const proposalItem: any = deepClone(proposal.proposalItems[0])
        const financedValue = proposalItem.financedValue
        proposalItem.financedValue = null
        proposalItem.price = financedValue
        const partDirig: any = []
        const partyInfo = (state.demandModule?.offer?.associatedParties || []).map((offerAssociatedParty: any) => {
            const {role_code} = offerAssociatedParty
            const {third} = offerAssociatedParty.associatedParty
            const type = third.type.id
            const bodyOP = type.includes("Organization") ? { ...third.organization } : { ...third.person }
            const body = deepClone(bodyOP)
            removeAttributesRecursively(body)
            deleteAttributesRecursively(body, ['isDisabled'])
            body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
            body.flagInsured = verifyPartyInsured(offerAssociatedParty)
            if (type.includes("Organization")) {
                if (body.manager) partDirig.push(body.manager)
            }
            return {
                partyData: JSON.stringify({
                    type,
                    role: role_code || 'CLIENT',
                    ...body
                })
            }
        })
        if (partDirig.length > 0) {
            partyInfo.push({
                partyData: JSON.stringify({
                    type: 'party-FrenchPerson',
                    role: partDirig[0].roles[0].role.resourceUid,
                    flagInsured: true,
                    ...partDirig[0]
                })
            })
        }
        const taxCode=scale.taxOnInterest
        proposal.proposalItems.forEach(async (el:any)=>{
            for (const key in el) {
                if (el[key] === undefined){
                    el[key]=null
                }
            }
            el.contractDuration = getContractDuration(el)
            el.financedValue = getFinancedValue(el, state, scale)
            delete el.taxCode
            delete el.taxValue
            el.taxCode=taxCode

        })

        for (const item of proposal.proposalItems) {
            item.taxValue= taxCode ? await evaluateTaxRate(taxCode , state): 0
        }
        const quoteInfo = JSON.stringify({
            ...proposal,
        })
        const assetList = proposal.proposalItems
        const assetInfo = assetList.map(asset => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })


        const evaluateAllPartyInsuranceAccessoryBody = {
            applicationName: "ORIGINATION",
            context: "",
            maxResults: 100,
            partyInfo: partyInfo,
            assetInfo: assetInfo,
            accessoryProductInfo: proposalItem.proposalAccessories.map((o: any) => mapProposalAccessories(o)),
            quoteInfo
        }
        try {
            const servicePartyInsurance = await evaluateAllPartyInsuranceAccessory(scale.resourceUid,evaluateAllPartyInsuranceAccessoryBody, state)
            const calcResponse = await calculateSimulation({
                searchVariable: searchType,
                financialProductUid: {
                    resourceUid: scale.resourceUid,
                    systemUid: 'odm-product'
                },
                productUid: {
                    resourceUid: financingOfferItem.resourceUid,
                    systemUid: 'odm-product'
                },
                quoteInfo,
                partyInfo,
                assetInfo,
                accessories: getAccessories(scale, servicePartyInsurance)
            })
            scale.scalePresentation.services = buildScaleServices(calcResponse, scale)
            const calculatedPartyInsuranceList = calcResponse?.paymentSchedules[1].items.filter((item: any) => item.objectType === 'odm.simulation.paymentscheduleitem.accessorypaymentscheduleitem.partyinsurancepaymentscheduleitem')
            if (calculatedPartyInsuranceList && calculatedPartyInsuranceList.length) {
                updatePartyInsurance(calculatedPartyInsuranceList, servicePartyInsurance)
            }
            scale.scalePresentation.servicePartyInsurance = servicePartyInsurance
            if (searchType === SearchVariableType.payment) {
                const att = 'payment'
                let paymentSchedule = 2

                const gracePeriod = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'gracePeriodTerms')[0]
                if (gracePeriod) {
                    paymentSchedule = +gracePeriod.model + 2 // Number 2 because as describe by DA this payment schedule dont change if you skip one step more or not
                }

                const result = calcResponse.paymentSchedules[paymentSchedule].financialInstallmentWoTax
                const sliders = (scale.scalePresentation.sliders || []).filter((s: any) => s.attribute !== att)
                scale.scalePresentation.payment = formatWithMask(result, 2)
                scale.scalePresentation.sliders = [{
                    id: `${financingOfferItem.resourceUid}.payment`,
                    attribute: 'payment',
                    label: i18n.global.t('demand.sliders.payment'),
                    interval: 50,
                    min: 50,
                    max: 10000,
                    value: result,
                    suffix: '€',
                    calculationType: 'Payment',
                    typeSelected: 'DEFAULT',
                    slideType: 'DEFAULT',
                    model: result,
                }, ...sliders]
                scale.scalePresentation.monthly = String(calcResponse.duration)
            }
            scale.scalePresentation.loading = false
            scale.calculationResult = calcResponse
        } catch (e) {
            console.error(e)
            scale.scalePresentation.loadingError = true
            scale.scalePresentation.loading = false
        }
    }
    return deepClone(scale)
}


export function buildScaleServices(calculationResponse: CalculationResponse, scale: FinancingProductPackInfo | any) {
    const services: any = []
    for (const payment of calculationResponse.paymentSchedules) {
        payment.items
            .filter( (servicesItemParam: any) => servicesItemParam.objectType.includes('servicepaymentscheduleitem') && servicesItemParam.amountValue)
            .forEach((service: any) => {
                const current = services.filter((s: any) => s.reference === service.reference)[0]
                if (!current) {
                    const scaleItem = scale.items.filter((s: any) => service.reference === (s.productUid || s.resourceUid))[0]
                    service.config = {
                        "selected": true,
                        "required": scaleItem?.qualification === "required"
                    }
                    service.calculationMethodOption = scaleItem?.calculationMethodOption
                    service.paymentDatesOption = scaleItem?.paymentDatesOption
                    service.taxValue = scaleItem?.taxValue
                    scaleItem.basisValue = service.basisValue
                    service.objectType = scaleItem.objectType
                    service.basis=scaleItem.basis
                    services.push(service)
                }
            })
    }
    scale.scalePresentation.services?.filter((service: any) => !service.config.selected && service.amountValue)
        .forEach((s: any) => services.push(s))
    return services
}

export function buildNormalModeFromScale(scale: FinancingProductPackInfo, searchType: SearchVariableType, state: RootState) {
    let attribute = ''
    switch (searchType) {
        case SearchVariableType.payment:
            attribute = 'payment'
            break
        case SearchVariableType.firstPayment:
            attribute = 'firstPayment'
            break
        case SearchVariableType.outstandingBalanceAfter:
            attribute = 'residualValue'
            break
        case SearchVariableType.outstandingBalanceBefore:
            attribute = 'downPayment'
            break
        case SearchVariableType.annualRate:
            attribute = 'rate'
            break
    }
    const result = {
        attribute: '',
        value: '',
        typeSelected: '',
    }
    const normalModeModel = state.simulationModule.normalModeState.attributes.filter(s => s.attribute === attribute)[0]
    result.attribute = attribute
    const scaleModeModel = scale.scalePresentation.sliders.filter((s: any) => s.attribute === attribute)[0]
    if (normalModeModel && scaleModeModel) {
        result.value = normalModeModel.type === 'PERCENT' || normalModeModel.type === 'RATE' ? scaleModeModel.percentValue : scaleModeModel.value
        result.typeSelected = normalModeModel.type
    }
    return result
}

export const calculateFromState = async (searchType: SearchVariableType, scale: FinancingProductPackInfo, state: RootState) => {
    const financingOfferItem = scale.items.filter((s: any) => s.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
    if (financingOfferItem) {
        const price = getPrice(state)
        const proposalList: Proposal[] = await buildProposalInfoFromSate(state, scale)
        const proposal = proposalList[0]
        const proposalItem: any = deepClone(proposal.proposalItems[0])
        const financedValue = proposalItem.financedValue
        proposalItem.financedValue = null
        proposalItem.price = financedValue
        removeAttributesRecursively(proposal)
        const partDirig: any = []
        const partyInfo = (state.demandModule?.offer?.associatedParties || []).map((offerAssociatedParty: any) => {
            const {role_code} = offerAssociatedParty
            const {third} = offerAssociatedParty.associatedParty
            const type = third.type.id
            const bodyOP = type.includes("Organization") ? { ...third.organization } : { ...third.person }
            const body = deepClone(bodyOP)
            removeAttributesRecursively(body)
            deleteAttributesRecursively(body, ['isDisabled'])
            body.ageEndOfContract = getAgeEndOfContract(proposal.proposalItems[0], body)
            body.flagInsured = verifyPartyInsured(offerAssociatedParty)
            if (type.includes("Organization")) {
                if (body.manager) partDirig.push(body.manager)
            }
            return {
                partyData: JSON.stringify({
                    type,
                    role: role_code || 'CLIENT',
                    ...body
                })
            }
        })
        if (partDirig.length > 0) {
            partyInfo.push({
                partyData: JSON.stringify({
                    type: 'party-FrenchPerson',
                    role: partDirig[0].roles[0].role.resourceUid,
                    flagInsured: true,
                    ...partDirig[0]
                })
            })
        }

        const taxCode = scale.taxOnInterest;
        const taxValue = state.demandModule.partyTaxRate;//setTaxValue(taxRequest, taxCode);

        proposal.proposalItems.forEach((el:any)=>{
            for (const key in el) {
                if (el[key] === undefined){
                    el[key]=null
                }
            }
            el.contractDuration = getContractDuration(el)
            el.financedValue = getFinancedValue(el, state, scale)
            delete el.taxCode
            delete el.taxValue
            el.taxCode=taxCode,
            el.taxValue=taxValue
        })
        const quoteInfo = JSON.stringify({
            ...proposal
        })
        const assetList = proposal.proposalItems
        const assetInfo = assetList.map(asset => {
        removeAttributesRecursively(asset)
        return {
            assetData: JSON.stringify(asset)
        }
    })
        const evaluateAllPartyInsuranceAccessoryBody = {
            applicationName: "ORIGINATION",
            context: "",
            maxResults: 100,
            partyInfo: partyInfo,
            assetInfo: assetInfo,
            accessoryProductInfo: proposalItem.proposalAccessories.map((o: any) => mapProposalAccessories(o)),
            quoteInfo
        }
        const servicePartyInsurance = await evaluateAllPartyInsuranceAccessory(scale.resourceUid,evaluateAllPartyInsuranceAccessoryBody, state)
        mergeBeneficiariesData(servicePartyInsurance, scale)
        const calcResponse = await calculateSimulation({
            searchVariable: searchType,
            financialProductUid: {
                resourceUid: scale.resourceUid,
                systemUid: 'odm-product'
            },
            productUid: {
                resourceUid: financingOfferItem.resourceUid,
                systemUid: 'odm-product'
            },
            quoteInfo,
            assetInfo,
            partyInfo,
            accessories: getAccessories(scale, servicePartyInsurance)
        })
        scale.scalePresentation.services = buildScaleServices(calcResponse, scale)
        const calculatedPartyInsuranceList = calcResponse?.paymentSchedules[1].items.filter((item: any) => item.objectType === 'odm.simulation.paymentscheduleitem.accessorypaymentscheduleitem.partyinsurancepaymentscheduleitem')
        if (calculatedPartyInsuranceList && calculatedPartyInsuranceList.length) {
            updatePartyInsurance(calculatedPartyInsuranceList, servicePartyInsurance)
        }
        scale.scalePresentation.servicePartyInsurance = servicePartyInsurance
        if (searchType === SearchVariableType.payment) {
            let paymentSchedule = 2

            const gracePeriod = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'gracePeriodTerms')[0]
            if (gracePeriod) {
                paymentSchedule = +gracePeriod.model + 2 // Number 2 because as describe by DA this payment schedule dont change if you skip one step more or not
            }

            const result = calcResponse.paymentSchedules[paymentSchedule].financialInstallmentWoTax
            scale.scalePresentation.payment = formatWithMask(result, 2)
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'payment')[0]
            const {max, min} = slider
            validateMaxMin('demand.sliders.payment', result, max, min)
            if (slider) {
                slider.value = result
                slider.model = result
            } else {
                scale.scalePresentation.sliders = [{
                    id: `${financingOfferItem.resourceUid}.payment`,
                    attribute: 'payment',
                    label: i18n.global.t('demand.sliders.payment'),
                    interval : 50,
                    min : 50,
                    max : 10000,
                    value: result,
                    suffix: '€',
                    calculationType: 'Payment',
                    typeSelected: 'DEFAULT',
                    slideType: 'DEFAULT',
                    model: result,
                }, ...scale.scalePresentation.sliders]
            }
        }
        if (searchType === SearchVariableType.firstPayment) {
            const result = calcResponse.firstPaymentWoTax;
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'firstPayment')[0]
            const {max, min} = slider
            validateMaxMin('demand.sliders.firstPayment', result, max, min)
            if (slider) {
                slider.value = Number((result).toFixed(5))
                slider.model = Number((result).toFixed(5))
                slider.percentValue = Number((result / price * 100).toFixed(5))
            }
        }
        if (searchType === SearchVariableType.outstandingBalanceBefore) {
            const result = calcResponse.downPayment
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'downPayment')[0]
            const {max, min} = slider
            validateMaxMin('demand.sliders.downPayment', result, max, min)
            if (slider) {
                slider.value = Number((result).toFixed(5))
                slider.model = Number((result).toFixed(5))
                slider.percentValue = Number((result / price * 100).toFixed(5))
            }
        }
        if (searchType === SearchVariableType.outstandingBalanceAfter) {
            const result = calcResponse.residualValue
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'residualValue')[0]
            const {max, min} = slider
            validateMaxMin('demand.sliders.residualValue', result, max, min)
            if (slider) {
                slider.value = Number((result).toFixed(5))
                slider.model = Number((result).toFixed(5))
                slider.percentValue = Number((result / price * 100).toFixed(5))
            }
        }
        if (searchType === SearchVariableType.annualRate) {
            const result = (calcResponse.paymentSchedules.filter((item: any) => !!item.stepNo)[0] || { nominalRate: 0 }).nominalRate
            const rateFinancingTax = scale.scalePresentation.rateFinancingTax || 0
            const resultCalc = Number((result - rateFinancingTax).toFixed(5))
            const slider = scale.scalePresentation.sliders.filter((s: any) => s.attribute === 'rate')[0]
            const {max, min} = slider
            validateMaxMin('demand.sliders.annualRate', result, max, min)
            if (slider) {
                slider.value = resultCalc
                slider.model = resultCalc
                slider.percentValue = resultCalc
            }
        }
        scale.calculationResult = calcResponse
        const periodBetween2Installments = proposalItem.periodBetween2Installments
        const labelConfig = getDurationLabels(periodBetween2Installments)
        scale.scalePresentation.monthlyPresentation = i18n.global.t(labelConfig.presentation, { param: proposalItem.numberOfPaymentTerm, period: camelSentence(periodBetween2Installments.unit), duration: periodBetween2Installments.duration})
        scale.scalePresentation.monthly = String(proposalItem.numberOfPaymentTerm)
    }

    return scale
}


function validateMaxMin(labelKey: string, result: number, max: number, min: number) {
    if (result < min) {
        throw new Error(`${i18n.global.t(labelKey)}: ${i18n.global.t('main.dialog.validation.min')}`)
    } else if(result > max) {
        throw new Error(`${i18n.global.t(labelKey)}: ${i18n.global.t('main.dialog.validation.max')}`)
    }
}

export const getSimulationReport = async (state: RootState) => {
    const { financingModule } = state
    const scale = financingModule.scalesModule.scaleSelected
    if (scale) {
        const financingOfferItem = scale.items.filter((s: any) => s.objectType === 'odm.product.financingproductpackitem.financingofferitem')[0]
        if (financingOfferItem) {
            const proposalList: Proposal[] = await buildProposalInfoFromSate(state)
            const proposal = proposalList[0]
            removeAttributesRecursively(proposal)
            const quoteInfo = JSON.stringify({
                ...proposal
            })
            return generateSimulationReport({
                searchVariable: SearchVariableType.payment,
                financialProductUid: {
                    resourceUid: scale.resourceUid,
                    systemUid: 'odm-product'
                },
                productUid: {
                    resourceUid: financingOfferItem.resourceUid,
                    systemUid: 'odm-product'
                },
                quoteInfo,
                accessories : getAccessories(scale, [])
            })
        }
    }
}
export function getContractDuration(proposalItem: any) {
    return proposalItem.numberOfPaymentTerm ? (proposalItem.numberOfPaymentTerm * proposalItem.periodBetween2Installments?.duration) : 0
}

export function getAgeEndOfContract(proposalItem: any, partyInfo: any) {
    const contractDuration = getContractDuration(proposalItem)
    const partyAge = getPartyAge(partyInfo)
    return partyAge && contractDuration ? (partyAge + (contractDuration/12)) : 0
}

export function getFinancedValue(proposalItem: any, state: any, scale?: FinancingProductPackInfo) {
    const price = getPriceScale(state, scale)
    const {downPayment} = proposalItem
    return downPayment ? price - downPayment : price
}
function getPartyAge(partyInfo: any) {
    return partyInfo.age || 0
}


export function verifyPartyInsured(party: any) {
    const { associatedParty, role_code } = party
    const { third } = associatedParty
    const partyType = third?.type?.id || ''
    let result = false
    if(partyType.includes("Person")) {
        result = role_code === 'CUSTOMER' || role_code === 'COEMPRUNT'
    }
    if(partyType.includes("Organization")) {
        result = role_code === 'DIRG' || role_code === 'GUARANTOR'
    }
    return result
}

export const camelSentence = (str?: string)  => {
    return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
    {
        return chr.toUpperCase();
    });
}
