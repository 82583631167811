import {Paths, UON} from "@/utils";

import { useApi } from "@/requests/useApi";

export const evaluateControls = async (entity: string, payload: any) => {
    const result = await getControls(entity, payload)
    const errors = result.filter((item: any) => item.block)
    const warnings = result.filter((item: any) => !item.block)
    return { errors, warnings }
}

const getControls = async (entity: string, payload: any) => {
    return (await useApi(UON.SystemUid.odmConfiguration, `${Paths.configuration.controls}`).upsert({
        entity,
        applicationName: "origination",
        body: payload,
    })).response
}
