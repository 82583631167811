import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row flex-column" }
const _hoisted_3 = { class: "col-4 mb-2" }
const _hoisted_4 = { class: "col-4 mb-2" }
const _hoisted_5 = {
  key: 0,
  class: "col-4 mb-2"
}
const _hoisted_6 = {
  key: 1,
  class: "col-4 mb-2"
}
const _hoisted_7 = { class: "col-4 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_expansion_item, {
        "default-opened": "",
        "expand-icon-toggle": "",
        "header-class": "align-items-center bg-secondary justify-content-between mb-3 mt-3 "
      }, {
        header: _withCtx(() => [
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.$t("summaryPanel.financing.guarantee.label").toLowerCase())), 1)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proposalGuarantees, (guarantee, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              key: index
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'guarantees.labels.guaranteeType',
                  value: guarantee.productGuarantee?.guaranteeType,
                  oneLine: true,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'guarantees.labels.label',
                  value: guarantee.productGuarantee?.name,
                  oneLine: true,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"])
              ]),
              (guarantee.associatedParty?.third.person || guarantee.associatedParty?.third.organization)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (guarantee.associatedParty?.third.person)
                      ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                          key: 0,
                          "i18n-key": 'guarantees.labels.guarantor',
                          value: guarantee.associatedParty?.third.person.firstName+' '+guarantee.associatedParty?.third.person.familyName,
                          oneLine: true,
                          "label-style": _ctx.labelStyleItem,
                          "div-value-style": _ctx.divValueStyle
                        }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"]))
                      : (_openBlock(), _createBlock(_component_LabelAndValue, {
                          key: 1,
                          "i18n-key": 'guarantees.labels.guarantor',
                          value: guarantee.associatedParty?.third.organization.name,
                          oneLine: true,
                          "label-style": _ctx.labelStyleItem,
                          "div-value-style": _ctx.divValueStyle
                        }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"]))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_LabelAndValue, {
                      "i18n-key": 'guarantees.labels.guarantor',
                      value: '-',
                      oneLine: true,
                      "label-style": _ctx.labelStyleItem,
                      "div-value-style": _ctx.divValueStyle
                    }, null, 8, ["i18n-key", "label-style", "div-value-style"])
                  ])),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'guarantees.labels.value',
                  value: guarantee.amount?.amount ?? 0,
                  oneLine: true,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "label-style", "div-value-style"])
              ])
            ]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}