
import store from "@/store";
import {Options, Vue} from "vue-class-component";
import {getOrderFromState} from "@/utils";
import OrderSummaryAsset from "@/components/summary-panel/order/OrderSummaryAsset.vue";
import OrderSummaryParty from "@/components/summary-panel/order/OrderSummaryParty.vue";
import OrderSummarySupplier from "@/components/summary-panel/order/OrderSummarySupplier.vue";
import OrderSummaryMessage from "@/components/summary-panel/order/OrderSummaryMessage.vue";
import OrderSummaryPreConditions from "@/components/summary-panel/order/OrderSummaryPreConditions.vue";
import Notify from 'quasar/src/plugins/Notify.js';;
import router from "@/router";
import { TaskModule } from "@/types";

@Options({
  components: {
    OrderSummaryParty,
    OrderSummaryAsset,
    OrderSummarySupplier,
    OrderSummaryMessage,
    OrderSummaryPreConditions
  },
})
export default class OrderSummaryPanel extends Vue {
  summarySelected = "PARTY";
  order: any;

  get isTaskModuleOrder() {
    return store.getters['taskModule/isTaskModuleOrder']
  }

  get assets() {
    return store.getters['summaryPanelModule/getOrderAssets']
  }

  get parties() {
    return store.getters['summaryPanelModule/getOrderParties']
  }

  get summaryPanel() {
    return store.state.middleOfficeModule.summaryPanel;
  }

  get tabSelected() {
    return this.summarySelected ? this.summarySelected : "PARTY";
  }

  setSummary(summaryBlock: string) {
    this.summarySelected = summaryBlock;
  }

  openSummaryPanel() {
    store.state.middleOfficeModule.summaryPanel = !store.state.middleOfficeModule
      .summaryPanel;
  }

  async openNewTab() {
    const routeData = router.resolve(`${'/order/edit/'}${this.order?.resourceUid}`);
        window.open(routeData.href, "_blank");

  }

  created() {
    if (this.isTaskModuleOrder) {
      this.order = getOrderFromState(store.state, this.$route.name);
      if (this.order?.resourceUid) {
        store.dispatch("summaryPanelModule/setOrder", { orderId: this.order?.resourceUid });
      }
    }
  }

}
