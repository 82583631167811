import { TableState } from '@/types';
import {  Module } from 'vuex';
import {rows as searchRows } from '@/store/services/tabledata';

const tabledata: Module<TableState, unknown> = {
  state : {
    selectedColumns: [],
    currentRows: [],
    rowFilter : {},
    selectedRows: [], 
  },
  mutations: {
    setRowFilter(state, payload){
      state.rowFilter = payload
    },    
    setRows(state, payload){
      state.currentRows = payload
    },
    setCurrentRows(state, payload){
      state.currentRows = payload
    },
    filterCurrentRows(state, payload) {
      const { filter } = payload;
       searchRows().then((res) => {
        const filteredResult = res?.filter((row: any) => {
          return Object.keys(filter).every((field) => {
            const filterValue = filter[field];
            const rowValue = row[field];
            if (Array.isArray(filterValue)) {
              return filterValue.includes(rowValue);
            } else {
              return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
            }
          });
        });
        state.currentRows = filteredResult;  
      }).catch((err) => {
        console.error(err);
      });
      
    },
    setColumns(state, columns) {
      state.selectedColumns = columns;
    },
    registerSelectedRow(state, rowData) {
      state.selectedRows.push(rowData);
    },
    deleteSelectedRow(state, rowData) {
      const index = state.selectedRows.findIndex((row: any) => row === rowData);
      if (index !== -1) {
        state.selectedRows.splice(index, 1);
      }
    },
  },
  actions: {
    filterRows({commit}, payload){
      commit('setRowFilter', payload.filter)
      commit('filterCurrentRows', payload)
    },
    searchRows({commit}, payload) {
      const { callback } = payload;
      searchRows().then((res) => {
          callback(res);
          commit('setCurrentRows', res)
        }).catch((err) => {
          console.error(err);
        });
    },
    setColumns({ commit }, columns) {
      commit('setColumns', columns);
    },
    registerSelectedRow({ commit }, rowData) {
      commit('registerSelectedRow', rowData);
    },

    // Action to clear selected row data
    deleteSelectedRow({ commit }, rowData) {
      commit('deleteSelectedRow', rowData);
    },

  },
}
export default tabledata