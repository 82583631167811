import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_SummaryPerson = _resolveComponent("SummaryPerson")!
  const _component_SummaryOrganization = _resolveComponent("SummaryOrganization")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_tabs, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
      dense: "",
      class: "nav-tabs",
      "active-color": "primary",
      "indicator-color": "primary",
      align: "left",
      "narrow-indicator": ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildParties, (item, index) => {
          return (_openBlock(), _createBlock(_component_q_tab, {
            name: item.orderParty.resourceUid,
            label: _ctx.$t(`demand.party.roles.${item.orderParty?.role?.resourceUid}`),
            key: index,
            class: "nav nav-item flex-grow-0"
          }, null, 8, ["name", "label"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_tab_panels, {
      modelValue: _ctx.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildParties, (item, index) => {
          return (_openBlock(), _createBlock(_component_q_tab_panel, {
            name: item.orderParty.resourceUid,
            key: index,
            class: "p-0 pt-3"
          }, {
            default: _withCtx(() => [
              (item.orderParty.party.objectType.includes('frenchperson'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_SummaryPerson, {
                      person: item.orderParty.third.person,
                      order: 'order'
                    }, null, 8, ["person"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_SummaryOrganization, {
                      organization: item.orderParty.third.organization,
                      order: 'order'
                    }, null, 8, ["organization"])
                  ]))
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}