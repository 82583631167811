import {AssociatedGuaranteeUnderlyingAssets, MoneyAmount, UniformOdmName} from "@/types";
import {GuaranteeProductClass} from "@/store/services/financing/guarantee/domain/GuaranteeProductClass";
import {AssociatedPartyClass} from "@/store/services/financing/guarantee/domain/AssociatedPartyClass";

export class ProposalGuaranteeClass implements UniformOdmName {
    resourceUid?: string
    objectType?: string
    systemUid: string
    businessData?: {
        [key: string]: string
    }
    index: number
    rate: number
    label: string
    description: string
    amount: MoneyAmount
    basisValue: number
    basis: UniformOdmName
    productGuarantee: GuaranteeProductClass
    associatedParty: AssociatedPartyClass
    productFinancingBasis: UniformOdmName
    associatedGuaranteeUnderlying?: Array<AssociatedGuaranteeUnderlyingAssets>
    associatedPartyFlag = false

    constructor(proposalGuarantee: ProposalGuaranteeClass) {
        this.systemUid = 'odm-offer'
        this.index = proposalGuarantee.index
        this.rate = proposalGuarantee.rate?? 0
        this.label = proposalGuarantee.label
        this.description = proposalGuarantee.description
        this.basisValue = proposalGuarantee.basisValue
        this.basis = proposalGuarantee.basis
        this.amount = this.setAmountValue(proposalGuarantee.amount)
        this.productGuarantee = proposalGuarantee.productGuarantee
        this.associatedParty = proposalGuarantee.associatedParty
        this.productFinancingBasis = proposalGuarantee.productFinancingBasis
    }

    setAmountValue(value: MoneyAmount) {
        if ( value ) {
            this.amount = value
        }
        else if ( this.basisValue ) {
            this.amount = this.setMoneyAmount(this.basisValue)
        }
        else {
            this.amount = this.setMoneyAmount(0)
        }
        return this.amount
    }

    setMoneyAmount(value: number): MoneyAmount {
        return {amount: value, currency: "EUR"}
    }

}
