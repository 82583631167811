import i18n from "@/i18n";
import {settings} from "@/settings";
import store from "@/store";
import {
    getAvailableNoticeTemplateList,
    getDocumentDownload,
    getDocumentGenerated, getNoticeTemplateBody,
    getNoticeTemplatePackItems,
    sendMail,
} from "@/store/services/task/contactMechanism";
import {
    ActionType, AssociatedParty,
    ComboBox, ComboBoxItem,
    ContactType,
    EmailMessage,
    NoticeContent,
    NoticeMessage,
    NoticeTemplate,
    NoticeTemplateSelectionContext, Offer, OfferAssociatedParty,
    Task,
    TaskExecuteAction,
    TaskState,
} from "@/types";
import {deepClone, headers, mountNoticeMessageDataInfo, mountNoticeMessageItems} from "@/utils";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import {downloadFile} from "../../offerService";
import {executeTask} from "../workflow";

export async function getTemplates() {
    const request: NoticeTemplateSelectionContext = {
        applicationName: 'TWDDemo',
        context: 'offerFinancing',
        maxResults: 100,
        dataInfo: '',
    }
    try {
        const templates = await getAvailableNoticeTemplateList(request)
        for ( const template of templates ) {
            template.selected = true
            if ( template.objectType === 'odm.contactmechanism.noticetemplate.packnoticetemplate' ) {
                const packItems: any = await getNoticeTemplatePackItems(request, template.resourceUid || '')
                if ( template.items ) {
                    for ( let i = 0; i < packItems.items.length; i++ ) {
                        packItems.items[i].resourceUid = template.items[i].resourceUid;
                        packItems.selected = true;
                        packItems.items[i].selected = true;
                    }
                }
                template.isPack = true
                template.items = [];
                template.items = packItems.items
            }
        }
        return templates
    } catch (e) {
        return Promise.reject(e)
    }

}

export async function downloadDocument(noticeContent: NoticeContent) {
  return await getDocumentDownload(noticeContent);
}

export async function generateDocument(noticeMessage: NoticeMessage) {
  return await getDocumentGenerated(noticeMessage);
}

export async function sendDocument(
  noticeTemplate?: NoticeTemplate,
  emailSendList?: string[]
) {
  return Promise.reject("TODO! sendDocument Not Implemented!");
}

export async function executePrintDocument(
  taskState: TaskState,
  actionType: ActionType,
  task: Task
) {
  const { printOfferModule } = taskState;
  const { previewTemplate, emailSendList,templates} = printOfferModule;
  try {
    switch (actionType) {
      case ActionType.VALIDATE:
        const taskExecuteAction: TaskExecuteAction = {
          taskId: task.taskId || "",
          variables: {},
        };
        return executeTask(taskExecuteAction);
      case ActionType.DOWNLOAD_DOCUMENT:
        const templatesToDownload = templates.filter((t: any) => t.selected).map((item: any) => download(deepClone(item)))
        return await Promise.all(templatesToDownload)
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}
export function getOperator(
  table: string,
  field: string,
  operator: string,
  value: any,
  parameter: string
) {
  return {
    expression: {
      table: table,
      field: field,
      operator: operator,
      value: value,
      parameter: parameter,
    },
  };
}
export async function getMail(offer: Offer): Promise<ComboBox> {
    const comboEmail: ComboBoxItem[] = []
    offer.associatedParties?.forEach((p: any) => {

      if (p.role_code === "CUSTOMER" && p.associatedParty?.third?.type.id === 'party-FrenchOrganization'){
        p.associatedParty?.third?.correspondents.forEach((b:any) => {
        b.contactCorrespondent.filter((c:any) =>
            c.contactMechanism.type.id === ContactType.EMAIL_CONTACT)
            .forEach((c:any) => {
                const emailLabel = b.qualificationType?.type?.value
                comboEmail.push({
                    value: `${c.contactMechanism.emailAddress}`,
                    label: `${emailLabel}: ${c.contactMechanism.emailAddress}`,
                })
        })
      })
      }
      else if ((p.role_code === "CUSTOMER" && p.associatedParty?.third?.type.id === 'party-FrenchPerson' ) || p.role_code === "DIRG" ){
        p.associatedParty?.third?.contacts.filter((c:any) => c.contactMechanism.type.id === 'EMAIL_CONTACT')
              .forEach((c:any) => {
                  const emailLabel = `demand.party.roles.${p.role_code}`
                  comboEmail.push({
                      value: `${c.contactMechanism.emailAddress}`,
                      label: `${i18n.global.t(emailLabel)}: ${c.contactMechanism.emailAddress}`,
                  })
          })
      }
    })
    return {items: comboEmail};
}
export async function getAttachment(
  noticeTemplate?: NoticeTemplate,
  dataInfo?: string
) {
    if ( noticeTemplate ) {
        let documentGenerated: any = {};
        if ( noticeTemplate.items && noticeTemplate.items.length > 0 ) {
            documentGenerated = {
                dataInfo: dataInfo,
                objectType: "odm.contactmechanism.communicationrequest.noticemessage",
                clientApplication: {
                    resourceUid: "TWDDemo"
                },
                parameters: {},
                items: [
                    {
                        resourceUid: noticeTemplate.resourceUid,
                        validity: noticeTemplate.validity,
                        fileName: noticeTemplate.fileName,
                        filePath: noticeTemplate.filePath,
                        isPack: noticeTemplate.isPack || true,
                        items: noticeTemplate.items,
                    },
                ]
            };
        } else {
            documentGenerated = {
                dataInfo: dataInfo,
                objectType: "odm.contactmechanism.communicationrequest.noticemessage",
                clientApplication: {
                    resourceUid: "TWDDemo"
                },
                parameters: {},
                items: [
                    {
                        resourceUid: noticeTemplate.resourceUid,
                        validity: noticeTemplate.validity,
                        fileName: noticeTemplate.fileName,
                        filePath: noticeTemplate.filePath,
                        isPack: noticeTemplate.isPack || false,
                        items: [],
                    },
                ],
            };
        }

        return await getDocumentGenerated(documentGenerated).then(
            async (noticeContent: NoticeContent) => {
                noticeContent.clientApplication = {
                    resourceUid: "TWDDemo"
                }
                noticeContent.parameters = {}
                return await getDocumentDownload(noticeContent).then((res) => {
                    return {
                        objectType: "odm.contactmechanism.emailattachment",
                        systemUid: "odm-contactmechanism",
                        resourceUid: res.document.resourceUid,
                        attachment: {
                            objectType: "contactmechanism-emailattachment",
                            systemUid: "odm-contactmechanism",
                            resourceUid: res.document.resourceUid,
                        },
                        label: noticeTemplate?.fileName,
                        originalFileName: noticeTemplate?.fileName,
                        format: "application/pdf",
                        content: noticeContent.content,
                    };
                })
            }
        )
    } else {
        return Promise.reject("Notice template is empty");
    }


}
export async function sendMailWithAllDocument(
  noticeTemplate: NoticeTemplate[],
  emailSendList: string[]
) {
  const noticeOffer=store.state.taskModule.offer
  const dataInfo =JSON.stringify(mountNoticeMessageDataInfo(noticeOffer))

  const body: EmailMessage = {
    objectType: "odm.contactmechanism.communicationrequest.emailmessage",
    clientApplication: {
      systemUid: "inttest",
      objectType: "esign-ESignClient",
      resourceUid: "ApplicationClient",
    },
    emailTemplate: {
      objectType: "odm.contactmechanism.emailtemplate",
      systemUid: "odm-contactmechanism",
      resourceUid: "SendNoticeTemplate",
    },
    parameters: {
      signatory_name: emailSendList.join(),
      signatory_email: emailSendList.join().replace(",", ";"),
    },
    businessData: null,
    relatedObjects: null,
    attachments:[]
  };
 const result=await getListAttachements(noticeTemplate,dataInfo)
 body.attachments=  result
 await sendMail(body)
    
}

export  async function getListAttachements(noticeTemplate: NoticeTemplate[],dataInfo:string){
  const result:any[]=[]
  for (const notice of noticeTemplate) {
     const attachment = await getAttachment(notice, dataInfo);
    result.push(attachment)
  }
  return result

}
export async function download(
  noticeTemplate: NoticeTemplate
) {
    let documentGenerated: NoticeMessage = {};
    const dataInfo = JSON.stringify(mountNoticeMessageDataInfo(store.state.taskModule.offer));

    documentGenerated = {
      dataInfo: dataInfo,
      objectType:"odm.contactmechanism.communicationrequest.noticemessage",
         clientApplication:{
              resourceUid:"TWDDemo"
         },
      parameters:{},
      items: mountNoticeMessageItems(noticeTemplate),
    }

    return  getDocumentGenerated(documentGenerated).then(
      async (noticeContent: NoticeContent) => {
        noticeContent.clientApplication={
          resourceUid:"TWDDemo"
          }
          noticeContent.parameters={}
          return getDocumentDownload(noticeContent).then((res) => {
            return downloadFile(res.document.resourceUid).then(response=>{
              const {content} = response
                const linkSource = `data:${response.format};base64,${content}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = response.originalFileName;
                downloadLink.click();

            })
        });

      }
    );
}
export async function getNoticeBody(): Promise<string>{
    const result = await getNoticeTemplateBody()
    return result.body
}
