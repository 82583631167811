import store from "@/store";

import { CommandState } from "@/types";
import { Module } from "vuex";
import {
  tabAssetMock,
  vehiculeMock,
  commandeFournisseurMock,
} from "@/store/services/commande";

const commandModule: Module<CommandState, any> = {
  namespaced: true,
  state: {
    openCommande: false,
    openFournisseur: false,
    mode: "view",
    selectedCommanddetails: {},
    vehicule: {},
    listTab: {},
    listArticles: [],
  },
  getters: {
    getCommandSelectedInit() {
      return {
        reference: "",
        type: "C. Fournisseur",
        asset: {
          totalAmountWTax: {
            amount: 0,
            currency: ""
          },
          referenceCommandFournisseur: "",
          assetItems: [
            {
              referenceCommandFournisseur: "",
              label: "-",
              type: "OTHER_CAR"
            }
          ]
        },
        dateCommande: "",
        dateLivraison: "",
        vendeur: {
        },
        adresseLivraison: "",
        prix: 0,
        status: {
          value: ""
        },
        referenceCommande: "",
      }
    },
    getMode(state) {
      return state.mode
    },
  },
  actions: {
    resetCommande({ commit }, payload) {
      commit("resetCommande", payload);
    },

    toggleOpenCommand({ commit }) {
      commit("toggleOpenCommand");
    },
    toggleOpenFournisseur({ commit }) {
      commit("toggleOpenFournisseur");
    },
    setMode({ commit }, payload) {
      commit("setMode", payload);
    },
    acceptProps({ commit }, payload) {
      const getter = store.getters["commandModule/getCommandSelectedInit"];
      commit("resetCommande", getter);

      commit("setSelectedCommanddetails", payload.row);
    },
    async VehiculeAction({ commit }, payload) {
      try {
        const response = await vehiculeMock();
        commit("setVehicule", response[0]);
      } catch (error) {
        console.error(error);
      }
    },

    listTabAction(context, payload) {
      const { callback } = payload;
      tabAssetMock()
        .then((response: any) => {
          callback(response);
        })
        .catch((err: any) => console.error(err));
    },

    updateSelectedItems({ commit }, newSelected) {
      commit("setRowsSelected", newSelected);
    },
    initListeArticle({ commit }) {
      commit("setListeArticle");
    },
    listArticleAction(context, payload) {
      const { callback } = payload;
      const response = commandeFournisseurMock();
      callback(response);
    },
    setVendeur({ commit }, payload) {
      commit('setVendeur', payload)
    }
  },

  mutations: {
    setVendeur(state, payload) {
      if (state.selectedCommanddetails) {
        state.selectedCommanddetails.vendeur = payload;
      }
    },
    resetCommande(state, payload) {
      state.selectedCommanddetails = payload;
    },
    toggleOpenCommand(state) {
      state.openCommande = !state.openCommande;
    },
    toggleOpenFournisseur(state) {
      state.openFournisseur = !state.openFournisseur;
    },
    setMode(state, payload) {
      state.mode = payload;
    },
    setSelectedCommanddetails(state, payload) {
      // 
      state.selectedCommanddetails = payload;
    },
    setVehicule(state, payload) {
      state.vehicule = payload;
    },
    setListTab(state, payload) {
      state.listTab = payload;
    },
    setRowsSelected(state, newSelected) {
      state.listArticles = newSelected;
    },
    setListeArticle(state) {
      state.listArticles = [];
      const getter = store.getters["commandModule/getCommandSelectedInit"];
      state.selectedCommanddetails = getter;
    },
  },
};
export default commandModule;
