
import {Options, Vue} from "vue-class-component";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import {formatWithMask} from "@/utils/configuration/formatters-config";

@Options({
  methods: { formatWithMask },
  components: { LabelAndValue },
  props: [""],
})
export default class OrderSummaryMessage extends Vue {

}
