
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import PrintDocumentDialog from "@/components/document/PrintDocumentDialog.vue";

@Options({
  components: { PrintDocumentDialog }
})
export default class PrintDocumentButton extends Vue {

  toggleShowControls() {
    return store.dispatch('printDocumentModule/toggleShow')
  }

}
