
import store from "@/store";
import SummaryTiers from "@/components/summary-panel/SummaryTiers.vue";
import SummaryAsset from "@/components/summary-panel/SummaryAsset.vue";
import SummaryFinancement from "@/components/summary-panel/SummaryFinancing.vue";
import SummaryMessage from "@/components/summary-panel/SummaryMessage.vue";
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import { getOfferFromState } from "@/utils";
import { OfferStatus } from "@/types";

@Options({
  components: { SummaryFinancement, SummaryAsset, SummaryTiers, SummaryMessage },
  watch: {
    '$store.state.demandModule.isSummaryCharge'(newValue) {
      store.dispatch("summaryPanelModule/setOffer", { offerId: this.offer?.resourceUid });
    },
  }
})
export default class SummaryPanel extends Vue {
  summarySelected = "TIERS";
  offer: any;

  get isTaskModuleOrder() {
    return store.getters['taskModule/isTaskModuleOrder']
  }

  setSummary(summaryBlock: string) {
    this.summarySelected = summaryBlock;
  }

  created() {
    if (!this.isTaskModuleOrder) {
      this.offer = getOfferFromState(store.state, this.$route.name);
      if (this.isTaskAnalysis) {
        store.dispatch("summaryPanelModule/setOfferFromTask", { offer: this.offer });
      } else {
        store.dispatch("summaryPanelModule/setOffer", { offerId: this.offer?.resourceUid });
      }
    }
  }

  get isTaskAnalysis() {
    return store.getters["taskModule/isTaskAnalysis"];
  }

  get summaryPanel() {
    return store.state.middleOfficeModule.summaryPanel;
  }

  get asset() {
    return store.state.summaryPanelModule.offer!.proposals[0].proposalItems[0];
  }

  get parties() {
    return store.state.summaryPanelModule.offer?.associatedParties;
  }

  get tabSelected() {
    return this.summarySelected ? this.summarySelected : "TIERS";
  }

  openSummaryPanel() {
    store.state.middleOfficeModule.summaryPanel = !store.state.middleOfficeModule
      .summaryPanel;
  }

  openNewTab() {
    store.dispatch("demandModule/getProposalStatus", {
      statusId: this.offer.status.resourceUid,
      callback: (result: any) => {
        const routePath = [
          OfferStatus.DECISION,
          OfferStatus.CONTRACT,
          OfferStatus.CANCELLED,
        ].includes(result.internalCode)
          ? "/mo/demand/detail/"
          : "/demand/edit/";
        const routeData = router.resolve(`${routePath}${this.offer?.resourceUid}`);
        window.open(routeData.href, "_blank");
      },
    });
  }
}
