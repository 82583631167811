
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import i18n from "../../i18n";

@Options({})
export default class ControlsDialog extends Vue {

  get show() {
    return store.state.controlsModule.show
  }

  get errors() {
    return store.state.controlsModule.errors
  }

  get warnings() {
    return store.state.controlsModule.warnings
  }

  toggleShowControls() {
    return store.dispatch('controlsModule/toggleShow')
  }


}
