import { Order, Task, TaskExecuteAction, TaskState } from "@/types";
import { executeTask } from "@/store/services/task/workflow";
import { settings } from "@/settings";
import { api } from "@/auth/api";

const odm_supportingdocument = 'odm-supportingdocument'
const odm_order = 'odm-order'
export async function executeValidateCompletePreconditions(userId: string, order: Order, task: Task) {
    const taskExecuteAction: TaskExecuteAction = {
        taskId: task.taskId || '',
        variables: {}
    }
    try {
        return executeTask(taskExecuteAction)
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}
export const getGatheringProcessFromOrder = async (orderId: any) => {
    const baseUrl = `${settings.api_url}/${odm_order}/api/1/${odm_order}`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().get(`${baseUrl}/order/` + orderId + '/ordergatheringprocess/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}


export const getCheckPointsGathering = async (processId: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().get(`${baseUrl}/gatheringprocess/` + processId + '/checkpointsgathering/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const updateDocumentValue = async (payload: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().put(`${baseUrl}/documentvalue/` + payload.resourceUid + '/',payload)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const getDocumentValueConfig = async (payload: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().get(`${baseUrl}/documentvalueconfig/` + payload.resourceUid + '/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const updatefieldValue = async (payload: any) => {
    delete payload.howmodified
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().put(`${baseUrl}/fieldvalue/` + payload.resourceUid + '/',payload)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const saveCheckPointsValue = async (payload: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`
    const objectType = payload.objectType === 'odm.supportingdocument.checkpoints.businessrulevalue' ? 'executeActionBR' : 'executeActionQuery';
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().post(`${baseUrl}/checkPoints/` + payload.resourceUid + '/' + objectType + '/' ,payload)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
